import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Recommendations: [],
  loading: false,
  error: "",
};

const RecommendationsSlice = createSlice({
  name: "Recommendations",
  initialState,
  reducers: {
    fetchRecommendationsRequest(state) {
      state.loading = true;
      state.Recommendations = [];
      state.error = "";
    },
    fetchRecommendationsSuccess(state, action) {
      state.loading = false;
      state.Recommendations = action.payload;
      state.error = "";
    },
    fetchRecommendationsFail(state, action) {
      state.loading = false;
      state.Recommendations = [];
      state.error = action.payload;
    },
  },
});

export const RecommendationsAction = RecommendationsSlice.actions;
export default RecommendationsSlice;
