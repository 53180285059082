import { React, useState, useEffect } from "react";
import "./Checkin_out_evaluation.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { useParams } from "react-router-dom";
import i18n from "../../i18n";
import check_in_out_image from "../../assets/images/hagat_mental.png";
import { addEvaluation } from "../../Redux/evaluations/EvaluationsActions";
import { useNavigate } from "react-router-dom";
import PopUp from "../../components/PopUp/PopUp";

function Checkin_out_evaluation() {
  const [lang, setLang] = useState(i18n.language);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const { checks } = useSelector((state) => state.opportunitiesReducer);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);
  const handleEvaluation = () => {
    dispatch(addEvaluation(id));
    navigate("/opportunities/1");
  };
  const handleEvaluation1 = () => {
    navigate("/");
  };


  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpen1 = () => {
    setOpen1(true);
  };
  return (
    <div className="checkin_out_evaluation_container">

      {open && (
        <PopUp
          setOpen={setOpen}
          body={
            <div className="popup_body">
              <div className="popup_body_header">{t("checkin_out_evaluation.notification_title")}</div>
              <div className="popup_body_emoji_info_cont">
                <div className="popup_body_emoji_info">
                  <div>
                    {t("checkin_out_evaluation.notification_msg")}<br /><br />{t("checkin_out_evaluation.notification_msg2")}<br /><span className='bold-font'>{t("checkin_out_evaluation.notification_msg3")}</span>{t("checkin_out_evaluation.notification_msg4")}
                  </div>
                </div>
                <div className="popup_body_emoji_info green-color">
                  {t("checkin_out_evaluation.notification_msg5")}
                </div>
                <div className="popup_body_info_data">
                  <button className="evaluation_button what_div" style={{ marginLeft: '0px', marginRight: '0px' }} onClick={() => { setOpen(false); }}>
                    {" "}
                    {t("checkin_out_evaluation.got_it")}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}


      {open1 && (
        <PopUp
          setOpen={setOpen1}
          body={
            <div className="popup_body">
              <div className="popup_body_emoji_info_cont">
                <div className="popup_body_emoji_info">
                  <div>
                    {t("checkin_out_evaluation.notification_msg6")}
                  </div>
                </div>
                <div className="popup_body_info_data">
                  <button className="evaluation_button what_div" style={{ marginLeft: '0px', marginRight: '0px' }} onClick={() => { setOpen1(false); }}>
                    {" "}
                    {t("checkin_out_evaluation.got_it")}
                  </button>
                </div>
              </div>
            </div>
          }
        />
      )}

      <div
        className={
          lang === "ar" ? "check_in_out_top_div_ar" : "check_in_out_top_div"
        }
      >
        <div className="check_in_out_top_header_div">
          <div className="check_in_out_top_header">
            {t("checkin_out_evaluation.title")}
          </div>
          <AiOutlineQuestionCircle
            className="question_mark_evaluation"
            size={30}
            onClick={handleOpen}
          />
        </div>
        <div className="opp_type_div">
          {t("checkin_out_evaluation.this_is")} {checks.type}{" "}
          {t("checkin_out_evaluation.opportunity")}
        </div>
        <div className="opp_type_div_calc">
          {checks.type == "Time-based"
            ? "1 " +
            t("checkin_out_evaluation.hour") +
            " = 10 " +
            t("checkin_out_evaluation.points")
            : "1 " +
            t("checkin_out_evaluation.hour") +
            " = 20 " +
            t("checkin_out_evaluation.points")}
        </div>
      </div>
      <div className="divider_line_solid"></div>
      <div
        className={
          lang === "ar"
            ? "check_in_out_bottom_div_ar"
            : "check_in_out_bottom_div"
        }
      >
        <div className="check_in_out_bottom_img">
          <img src={check_in_out_image} className="check_in_out_image" />
        </div>
        <div className="divider_line_solid margin-30"></div>
        <div className="check_in_out_bottom_evaluation">
          {t("checkin_out_evaluation.calculating_points")}
          <div className="checkin_color">
            You Volunteered {checks.hours} hours and you gained {checks.points}{" "}
            points
          </div>
          <div className="divider_line_solid margin-top-bottom"></div>
          <div> {t("checkin_out_evaluation.request_org")}</div>
          <div className="what_div" onClick={handleOpen1}>
            {" "}
            {t("CompleteProf.complete_your_profile_what")}
          </div>
          <div className="eval_div">
            <div className="div_text">
              {t("checkin_out_evaluation.thank_you")} &nbsp; &nbsp; &nbsp;{" "}

            </div>

            {/* <button className="evaluation_button what_div"  onClick={handleEvaluation1}>
              {" "}
              {t("CompleteProf.complete_your_profile_what1")}
            </button> */}
          </div>
          <button className="evaluation_button what_div" style={{ marginLeft: '0px', marginRight: '0px' }} onClick={handleEvaluation1}>
            {" "}
            {t("CompleteProf.complete_your_profile_what1")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Checkin_out_evaluation;
