import React, { useEffect, useState } from "react";

import "./CompanyInst.css";
import company_image1 from "../../assets/images/comany_image1.png";
import video from "../../assets/videos/trial_video.mp4";
import company_image2 from "../../assets/images/company_group.png";
import company_image3 from "../../assets/images/swim_image.png";
import blue_check from "../../assets/images/blue_check.png";
import green_check from "../../assets/images/green_check.png";
import inst_filter from "../../assets/images/company_filter.png";
import inst_time from "../../assets/images/company_time.png";
import inst_link from "../../assets/images/company_link.png";
import inst_tech from "../../assets/images/inst_tech.png";
import inst_acc from "../../assets/images/inst_acc.png";
import inst_wp from "../../assets/images/inst_wp.png";
import inst_team from "../../assets/images/inst_team.png";
import ContactUs from "../../components/ContactUs/ContactUs";
import { scroller } from "react-scroll";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

function Companies_Institutions() {
  const location = useLocation().state;
  const scrollToSection = (classname) => {
    scroller.scrollTo(classname, {
      duration: 800,
      delay: 0,
      offset: -100,
      smooth: "easeInOutQuart",
    });
  };
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const { pageNum } = useParams();

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (location == 0) {
      scrollToSection("contact-container");
    }
  }, location);


  useEffect(() => {
    if (pageNum) {
      setPage(pageNum)
    }
  }, pageNum);

  return (
    <div className="companies_inst_main_comp">
      <h1
        className={
          lang === "ar"
            ? "company_inst_header_arabic company_inst_header"
            : "company_inst_header"
        }
      >
        {" "}
        <span style={{ color: "var(--primaryBlue)" }}>
          {t("company&inst.header1")}
        </span>{" "}
        <span style={{ color: "var(--primary)" }}>
          {t("company&inst.header2")}
        </span>
      </h1>
      <div className="company_inst_1st_cont">
        <h2
          className={
            page == 1
              ? "company_inst_sub_header green_color"
              : "company_inst_sub_header"
          }
          onClick={() => setPage(1)}
        >
          {t("company&inst.option_1")}

          {page == 1 && (
            <div
              className={
                lang === "ar"
                  ? "company_underline_green_arabic company_underline_green"
                  : "company_underline_green"
              }
            ></div>
          )}
        </h2>
        <h2
          className={
            page == 2
              ? "company_inst_sub_header blue_color"
              : "company_inst_sub_header"
          }
          onClick={() => setPage(2)}
        >
          {t("company&inst.option_2")}

          {page == 2 && (
            <div
              className={
                lang === "ar"
                  ? "company_underline_blue company_underline_blue_arabic"
                  : "company_underline_blue"
              }
            ></div>
          )}
        </h2>
      </div>
      {page == 1 && (
        <>
          <div className="company_inst_2nd_cont">
            <div
              className={
                lang === "ar"
                  ? "company_sub_header company_sub_header_arabic"
                  : "company_sub_header"
              }
            >
              {t("company&inst.company_header1")}

              <span className="blue_color">
                {t("company&inst.company_header2")}
              </span>
            </div>
            <div className="company_inst_2nd_sub_cont">
              <div className="comapny_inst_sub_1st">
                <div
                  className={
                    lang === "ar"
                      ? "company_inst_2nd_sub_cont_title company_inst_2nd_sub_cont_title_arabic"
                      : "company_inst_2nd_sub_cont_title"
                  }
                >
                  {t("company&inst.company_header3_1")}{" "}
                  <span className="blue_color">
                    {t("company&inst.company_header3_2")}
                  </span>
                  {t("company&inst.company_header3_3")}
                </div>
                <div
                  className={
                    lang === "ar"
                      ? "company_inst_2nd_sub_cont_subtitle company_inst_2nd_sub_cont_subtitle_arabic"
                      : "company_inst_2nd_sub_cont_subtitle"
                  }
                >
                  {t("company&inst.company_management")}
                </div>
              </div>

              <img className="company_2nd_sub_img" src={company_image1} />
            </div>
          </div>
          <div className="company_inst_3rd_cont">
            <div className="company_inst_3rd_cont_title">
              {t("company&inst.both_worlds1")}

              <span className="green_color">
                {" "}
                {t("company&inst.both_worlds2")}
              </span>
            </div>

            <div className="company_inst_3rd_cont_subtitle">
              {t("company&inst.both_worlds3")}
            </div>
            <div className="company_inst_3rd_cont_span">
              <span className="green_color">
                {t("company&inst.both_worlds4")}
              </span>{" "}
              {t("company&inst.both_worlds5")}
            </div>
            <button
              className="company_inst_3rd_cont_button"
              onClick={() => scrollToSection("contact-container")}
            >
              {t("company&inst.reach_out")}
            </button>
          </div>
          <div className="company_inst_4th_cont">
            <div className="company_sub_4th_header">
              {t("company&inst.company_hiring1")}{" "}
              <span className="green_color">
                {t("company&inst.company_hiring2")}{" "}
              </span>
              {t("company&inst.company_hiring3")}{" "}
              <span className="blue_color">
                {t("company&inst.company_hiring4")}
              </span>
            </div>
            <video className="company_video" controls>
              <source src={video} type="video/mp4" />
            </video>
          </div>
          <div className="company_inst_5th_cont">
            <div className="company_sub_5th_header">
              {t("company&inst.company_impact1")}{" "}
              <span className="green_color">
                {" "}
                {t("company&inst.company_impact2")}{" "}
              </span>
              {t("company&inst.company_impact3")}
            </div>
            <div className="company_underline"></div>
            <div className="company_sub_cont">
              <img src={company_image2} className="company_4th_sub_img" />
              <div className="company_4th_sub_cont_comp">
                <div className="company_4th_sub_cont_title">
                  {t("company&inst.company_impact4")}{" "}
                  <span className="blue_color">
                    {t("company&inst.company_impact5")}
                  </span>
                </div>
                <p className="company_4th_sub_cont_subtitle">
                  {t("company&inst.company_impact6")}
                  <br /> <br />
                  {t("company&inst.company_impact7")}
                </p>
                <div className="company_4th_sub_cont_title">
                  {t("company&inst.company_impact8")}{" "}
                  <span className="blue_color">
                    {t("company&inst.company_impact9")}{" "}
                  </span>{" "}
                  {t("company&inst.company_impact10")}
                </div>
              </div>
            </div>
          </div>
          <ContactUs />
        </>
      )}

      {page == 2 && (
        <>
          <div className="inst_1st_cont">
            <img className="inst_1st_img" src={company_image3} />
            <div className="inst_1st_sub_cont">
              <div className="inst_1st_sub_title">
                {t("company&inst.key_platform_features1")}

                <span className="green_color">
                  {" "}
                  {t("company&inst.key_platform_features2")}
                </span>
              </div>
              <div className="inst_1st_underline"></div>

              <div className="inst_1st_list_element">
                <img src={blue_check} />
                <div className="inst_1st_list_text">
                  {t("company&inst.feature_1")}
                </div>
              </div>

              <div className="inst_1st_list_element">
                <img src={green_check} />
                <div className="inst_1st_list_text">
                  {t("company&inst.feature_2")}
                </div>
              </div>
              <div className="inst_1st_list_element">
                <img src={blue_check} />
                <div className="inst_1st_list_text">
                  {t("company&inst.feature_3")}
                </div>
              </div>
              <div className="inst_1st_list_element">
                <img src={green_check} />
                <div className="inst_1st_list_text">
                  {t("company&inst.feature_4")}
                </div>
              </div>
            </div>
          </div>
          <div className="inst_2nd_cont">
            <div className="inst_2nd_sub_title">
              {t("company&inst.engagement_1_1")}

              <span className="blue_color">
                {" "}
                {t("company&inst.engagement_1_2")}
              </span>
            </div>
            <div className="inst_2nd_underline"></div>
            <div className="inst_2nd_sub_cont">
              <div className="inst_2nd_sub_comp">
                <img className="inst_2nd_cont_img" src={inst_time} />
                <div className="inst_2nd_cont_text">
                  {t("company&inst.engagement_2")}
                </div>
              </div>
              <div className="inst_2nd_sub_comp">
                <img className="inst_2nd_cont_img" src={inst_filter} />
                <div className="inst_2nd_cont_text">
                  {t("company&inst.engagement_3")}
                </div>
              </div>{" "}
              <div className="inst_2nd_sub_comp">
                <img className="inst_2nd_cont_img" src={inst_link} />
                <div className="inst_2nd_cont_text">
                  {t("company&inst.engagement_4")}
                </div>
              </div>
            </div>
          </div>
          <div className="inst_3rd_cont">
            <div className="inst_2nd_sub_title">
              <span className="green_color">
                {t("company&inst.support_1_1")}
              </span>{" "}
              {t("company&inst.support_1_2")}
            </div>
            <div className="inst_3rd_underline"></div>
            <div className="inst_3rd_sub_cont">
              <div className="inst_3rd_sub_comp">
                <img src={inst_tech} />
                <div className="inst_3rd_sub_comp_text">
                  {t("company&inst.support_2")}
                </div>
              </div>
              <div className="inst_3rd_sub_comp">
                <img src={inst_acc} />
                <div className="inst_3rd_sub_comp_text">
                  {t("company&inst.support_3")}
                </div>
              </div>{" "}
              <div className="inst_3rd_sub_comp">
                <img src={inst_wp} />
                <div className="inst_3rd_sub_comp_text">
                  {t("company&inst.support_4")}
                </div>
              </div>{" "}
              <div className="inst_3rd_sub_comp">
                <img src={inst_team} />
                <div className="inst_3rd_sub_comp_text">
                  {t("company&inst.support_5")}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Companies_Institutions;
