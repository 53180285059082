import React, { useEffect } from "react";
import "./CompleteProfile.css";

import volunteer from "../../assets/images/auth/volunteer.png";

export default function CompleteProfile(props) {
  const width2 = props.steps;
  return (
    <div className="complete_profile_main_cont">
      <div className="complete_profile_card_main">
        <div className="complete_profile_card">
          <h2 className="complete_profile_title">{props.header}</h2>
          {props.subtitle && (
            <p className="complete_profile_sub_title">{props.subtitle}*</p>
          )}
          <div className="complete_card_body">{props.body}</div>
        </div>
        <div className="complete_profile_footer_slider">
         {props.footer}
        </div>
        <img className="complete_profile_astro" src={volunteer} />
      </div>
    </div>
  );
}
