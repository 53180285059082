import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  OpportunityQuestions: [],
  loading: false,
  error: "",
};

const OpportunityQuestionsSlice = createSlice({
  name: "OpportunityQuestions",
  initialState,
  reducers: {
    getOpportunityQuestions(state) {
      state.loading = true;
      state.OpportunityQuestions = [];
      state.error = "";
    },
    getOpportunityQuestionsSuccess(state, action) {
      state.loading = false;
      state.OpportunityQuestions = action.payload;
      state.error = "";
    },
    getOpportunityQuestionsFail(state, action) {
      state.loading = false;
      state.OpportunityQuestions = [];
      state.error = action.payload;
    },


  },
});

export const OpportunityQuestionsAction = OpportunityQuestionsSlice.actions;
export default OpportunityQuestionsSlice;
