import "./DeleteAcount.css"
function DeleteAcount() {
    return ( 
    <div className="deletecontainer">
      <div className="deleteheader">Contact Us</div>  

      <div >If you need assistance or have any questions, please feel free to contact us:</div>

      <div>Contact Information</div>

      <div>Email: info@thevolunteercircle.com <br></br>If you wish to delete your account, please send an email to:</div>
     
      <div className="deleteheader">Delete Account</div>

      <div>Email: info@thevolunteercircle.com <br></br><div>We value your feedback and will get back to you as soon as possible.</div></div>
      

    </div> );
}

export default DeleteAcount;