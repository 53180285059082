import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  masterClass: [],
  reqMasterClass: [],
  reqMasterClassError : "",
  reqMasterClassLoading : false ,
  episodes: [],
  videos: [],
  VidProgress: [],
  Verified: [],
  masterClassLoading: false,
  masterClassError: null,
  nbVid: 0,
  allVid: [],
  createLoad: false,
  createError: "",
  userQuizes: [],
  paymentverified: [],
  mastertestimonials: [],
  finishLoad: false,
  finishError: "",
  status: "",
};

const masterClassSlice = createSlice({
  name: "masterClass",
  initialState,
  reducers: {
    SendMasterClassRequest: (state) => {
      state.reqMasterClassLoading = true;
      state.reqMasterClass = [];
      state.reqMasterClassError = "";
    },
    SendMasterClassSuccess: (state, action) => {
      state.reqMasterClassLoading = false;
      state.reqMasterClass = action.payload;
      state.reqMasterClassError = "";
    },
    SendMasterClassFailure: (state, action) => {
      state.reqMasterClassLoading = false;
      state.reqMasterClass = [];
      state.reqMasterClassError = action.payload;
    },
    fetchAllEpisodesRequest: (state) => {
      state.masterClassLoading = true;
      state.episodes = [];
    },
    fetchAllEpisodesSuccess: (state, action) => {
      state.masterClassLoading = false;
      state.episodes = action.payload;
    },
    fetchAllEpisodesFailure: (state, action) => {
      state.masterClassLoading = false;
      state.masterClassError = action.payload;
    },
    fetchvideosRequest: (state) => {
      state.masterClassLoading = true;
      state.videos = [];
    },
    fetchvideosSuccess: (state, action) => {
      state.masterClassLoading = false;
      state.videos = action.payload;
    },
    fetchvideosFailure: (state, action) => {
      state.masterClassLoading = false;
      state.masterClassError = action.payload;
    },

    fetchAllvideosRequest: (state) => {
      state.masterClassLoading = true;
      state.masterClassError = "";

      state.nbVid = 0;
      state.allVid = [];
    },
    fetchAllvideosSuccess: (state, action) => {
      state.masterClassLoading = false;
      state.nbVid = action.payload.length;
      state.allVid = action.payload;
    },
    fetchAllvideosFailure: (state, action) => {
      state.masterClassLoading = false;
      state.masterClassError = action.payload;
      state.nbVid = 0;
      state.allVid = [];
    },

    fetchvideoProgressRequest: (state, action) => {
      state.masterClassLoading = false;
      state.masterClassError = action.payload;
      state.VidProgress = [];
    },
    fetchvideoProgressSuccess: (state, action) => {
      state.masterClassLoading = false;
      state.VidProgress = action.payload;
    },
    fetchvideoProgressFailure: (state, action) => {
      state.masterClassLoading = false;
      state.masterClassError = action.payload;
    },
    fetchMasterClassVerificationRequest: (state) => {
      state.masterClassLoading = true;
      state.Verified = [];
    },
    fetchMasterClassVerificationSuccess: (state, action) => {
      state.masterClassLoading = false;
      state.Verified = action.payload;
    },
    fetchMasterClassVerificationFailure: (state, action) => {
      state.masterClassLoading = false;
      state.masterClassError = action.payload;
    },

    createVidProgReq: (state, action) => {
      state.createLoad = true;
      state.createError = "";
    },
    createVidProgSuccess: (state, action) => {
      state.createLoad = false;
      state.createError = "";
    },
    createVidProgFail: (state, action) => {
      state.createLoad = false;
      state.createError = action.payload;
    },

    createUserQuizesReq: (state, action) => {
      state.createLoad = true;
      state.createError = "";
    },
    createUserQuizesSuccess: (state, action) => {
      state.createLoad = false;
      state.createError = "";
    },
    createUserQuizesFail: (state, action) => {
      state.createLoad = false;
      state.createError = action.payload;
    },

    getUserQuizesRequest: (state) => {
      state.masterClassLoading = true;
      state.userQuizes = [];
      state.masterClassError = "";
    },
    getUserQuizesSuccess: (state, action) => {
      state.masterClassLoading = false;
      state.userQuizes = action.payload;
      state.masterClassError = "";
    },
    getUserQuizesFailure: (state, action) => {
      state.masterClassLoading = false;
      state.masterClassError = action.payload;
    },

    FinishMasterReq: (state, action) => {
      state.finishLoad = true;
      state.finishError = "";
    },
    FinishMasterSuccess: (state, action) => {
      state.finishLoad = false;
      state.finishError = "";
      state.status = action.payload.status;
    },
    FinishMasterFail: (state, action) => {
      state.finishLoad = false;
      state.finishError = action.payload;
    },
    editmasterclassverificationRequest: (state) => {
      state.masterClassLoading = true;
      state.paymentverified = [];
    },
    editmasterclassverificationSuccess: (state, action) => {
      state.masterClassLoading = false;
      state.paymentverified = action.payload;
    },
    editmasterclassverificationFailure: (state, action) => {
      state.masterClassLoading = false;
      state.masterClassError = action.payload;
    },
    fetchMasterTestimonialsRequest: (state) => {
      state.masterClassLoading = true;
      state.mastertestimonials = [];
    },
    fetchMasterTestimonialsSuccess: (state, action) => {
      state.masterClassLoading = false;
      state.mastertestimonials = action.payload;
    },
    fetchMasterTestimonialsFailure: (state, action) => {
      state.masterClassLoading = false;
      state.masterClassError = action.payload;
    },
  },
});

export const MasterClassActions = masterClassSlice.actions;
export default masterClassSlice;
