import React, { useEffect, useState } from "react";
import "./Mayor.css";
import { scroller } from "react-scroll";
import i18n from "../../i18n";

import firstimage from "../../assets/images/mayor_bg.png";
import image1 from "../../assets/images/mayor1.png";
import image2 from "../../assets/images/mayor2.png";
import image3 from "../../assets/images/mayor3.png";
import mayorimage1 from "../../assets/images/mayor_image1.png";
import mayorimage2 from "../../assets/images/mayor_image2.png";
import mayorimage3 from "../../assets/images/mayor_image3.png";
import { useTranslation } from "react-i18next";
import mayorspic from "../../assets/images/mayorspic.jpg";

function Mayor_of_impact() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);

  const scrollToSection = (classname) => {
    scroller.scrollTo(classname, {
      duration: 800,
      delay: 0,
      offset: -100,
      smooth: "easeInOutQuart",
    });
  };
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);
  return (
    <div className="mayor_impact_main_cont">
      <h1
        className={
          lang === "ar"
            ? "mayor_impact_title mayor_impact_title_arabic"
            : "mayor_impact_title"
        }
      >
        {t("mayorOfImpact.header1")}{" "}
        <span style={{ color: "var(--pink)" }}>
          {t("mayorOfImpact.header2")}
        </span>{" "}
        <span style={{ color: "var(--primary)" }}>
          {t("mayorOfImpact.header3")}
        </span>{" "}
        {t("mayorOfImpact.header4")}
      </h1>
      <div className="mayor_impact_1st_cont">
        {" "}
        <img className="mayor_impact_img" src={firstimage} />
        <button
          className="mayor_impact_button1_pink"
          onClick={() => scrollToSection("mayor_impact_4th_main_cont")}
        >
          {t("mayorOfImpact.join_family")}
        </button>
      </div>

      <div
        className={
          lang == "en"
            ? "mayor_impact_2nd_main_cont"
            : " mayor_impact_2nd_main_cont font_arabic"
        }
      >
        <div className="mayor_impact_2nd_container">
          <img className="mayor_2nd_cont_img" src={image2} />
          <div
            className={
              lang === "ar"
                ? "mayor_impact_2nd_cont_text mayor_impact_2nd_cont_text_arabic"
                : "mayor_impact_2nd_cont_text"
            }
          >
            {t("mayorOfImpact.mayor1")}
          </div>
        </div>
        <div className="mayor_impact_2nd_container">
          <img className="mayor_2nd_cont_img" src={image1} />
          <div
            className={
              lang === "ar"
                ? "mayor_impact_2nd_cont_text mayor_impact_2nd_cont_text_arabic"
                : "mayor_impact_2nd_cont_text"
            }
          >
            {t("mayorOfImpact.mayor2")}
          </div>
        </div>
        <div className="mayor_impact_2nd_container">
          <img className="mayor_2nd_cont_img" src={image3} />
          <div
            className={
              lang === "ar"
                ? "mayor_impact_2nd_cont_text mayor_impact_2nd_cont_text_arabic"
                : "mayor_impact_2nd_cont_text"
            }
          >
            {t("mayorOfImpact.mayor3")}
          </div>
        </div>
      </div>
      <div className="mayor_impact_3rd_main_cont">
        <h2
          className={
            lang === "ar"
              ? "mayor_impact_header2 mayor_impact_header2_arabic "
              : "mayor_impact_header2"
          }
        >
          {t("mayorOfImpact.meet_cohorts1")}{" "}
          <span style={{ color: "var(--primary)" }}>
            {" "}
            {t("mayorOfImpact.meet_cohorts2")}
          </span>
        </h2>
        <div className="mayor_underline"></div>

        <div
          className={
            lang === "ar"
              ? "mayor_impact_3rd_sub mayor_impact_3rd_sub_arabic"
              : " mayor_impact_3rd_sub"
          }
        >
          {t("mayorOfImpact.meet_cohorts3")}
        </div>
        <a
          target="_blank"
          href="https://l.facebook.com/l.php?u=https%3A%2F%2Fwww.instagram.com%2Fthevolunteercircle%2Fguide%2Fmayors-of-impact%2F17980925710350152%2F%3Figshid%3DYmMyMTA2M2Y%253D%26fbclid%3DIwAR35S12SIQnv6p-6MPHUeDeMz8iBcwtEA5n4WHscIuSvgaXSL00vZ4vUuxc&h=AT3IGj-y1sNAfpmT7hhpoRr2vGA5_a3DaRutuL5CSNPubXi_gBDCz2qSCXv9zMCD_hOxOWxWmmjjWb2SMcBB2mBEl_GcLVuYhtxDkHuyL18ENiJ4Uxo3gcdIMhCOSzp9p9z7TA"
        >
          <button className="mayor_impact_button_pink">
            {t("mayorOfImpact.check_cohorts")}
          </button>
        </a>
      </div>

      <div className="mayor_impact_4th_main_cont">
        <h2
          className={
            lang === "ar"
              ? "mayor_impact_header2 mayor_impact_header2_arabic "
              : "mayor_impact_header2"
          }
        >
          {t("mayorOfImpact.can_join1")}{" "}
          <span style={{ color: "var(--pink)" }}>
            {" "}
            {t("mayorOfImpact.can_join2")}
          </span>
          {t("mayorOfImpact.can_join3")}{" "}
          <span style={{ color: "var(--primary)" }}>
            {t("mayorOfImpact.can_join4")}
          </span>
          {t("mayorOfImpact.can_join5")}
        </h2>
        <div className="mayor_underline"></div>
        <div className="mayor_impact_image_cont">
          <img className="mayor_impact_4th_image" src={mayorimage3} />
          <img className="mayor_impact_4th_image" src={mayorimage1} />
          <img className="mayor_impact_4th_image" src={mayorimage2} />
        </div>
        <a
          target="_blank"
          href="https://my.forms.app/form/632b44fab1411e76e67d0c61"
        >
          <button className="mayor_impact_button_pink">
            {t("mayorOfImpact.apply_now")}
          </button>
        </a>
      </div>
    </div>
  );
}

export default Mayor_of_impact;
