import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hearingAboutTvc: [],
  hearingAboutTvcLoading: false,
  hearingAboutTvcError: ""
};
const hearingAboutTvcSlice = createSlice({
  name: "HearingAboutTvc",
  initialState,
  reducers: {
    hearingAboutTvcRequest(state) {
      state.hearingAboutTvcLoading = true;
      state.hearingAboutTvc = [];
      state.hearingAboutTvcError = "";
    },

    hearingAboutTvcSuccess(state, action) {
      state.hearingAboutTvcLoading = false;
      state.hearingAboutTvc = action.payload;
      state.hearingAboutTvcError = "";
    },

    hearingAboutTvcFail(state, action) {
      state.hearingAboutTvcLoading = false;
      state.hearingAboutTvc = [];
      state.hearingAboutTvcError = action.payload;
    }
  },
});

export const hearingAboutTvcAction = hearingAboutTvcSlice.actions;
export default hearingAboutTvcSlice;
