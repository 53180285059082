import React from "react";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import "./OrgOpportunities.css";
import { useState } from "react";
import { useEffect } from "react";
import OppCard from "../../../components/OppCard/OppCard";
import { useDispatch, useSelector } from "react-redux";
import { getOrgOpp } from "../../../Redux/opportunities/opportunityActions";
import { AiFillPlusCircle } from "react-icons/ai";
import CreateOpp_Step1 from "../../CreateOpp/CreateOpp_steps/Step1/CreateOpp_Step1";
import { getuserinfo } from "../../../Redux/user/userActions";
import CreateOpp_Pop from "../../CreateOpp/CreateOpp_Pop/CreateOpp_Pop";

function OrgOpportunities() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const { orgOpp } = useSelector((store) => store.opportunitiesReducer);
  const { userinfo } = useSelector((store) => store.userReducer);
  const [page, setPage] = useState(1);
  const [footerWidth, setFooterWidth] = useState("8.33%");
  const [orgShow, setOrgShow] = useState();

  const [showPop, setShowPop] = useState(false);
  const [acessCreateopp, setacessCreateopp] = useState(false);

  const token = localStorage.getItem("token");
  var step = localStorage.getItem("step");
  var percentage = step == -1 ? 100 : ((step / 5) * 100)?.toFixed(0);
  useEffect(() => {
    if (orgOpp && orgOpp.length > 0) {
      if (page == 1) {
        var ongoing = [...orgOpp];

        setOrgShow(ongoing.filter((s) => s.status == "Ongoing"));
      }

      if (page == 2) {
        var ongoing = [...orgOpp];

        setOrgShow(ongoing.filter((s) => s.status == "Drafts"));
      }

      if (page == 3) {
        var ongoing = [...orgOpp];

        setOrgShow(ongoing.filter((s) => s.status == "Paused"));
      }

      if (page == 4) {
        var ongoing = [...orgOpp];

        setOrgShow(ongoing.filter((s) => s.status == "Past"));
      }

      if (page == 5) {
        var ongoing = [...orgOpp];

        setOrgShow(ongoing.filter((s) => s.status == "Closed"));
      }

    }
  }, [page, orgOpp]);

  useEffect(() => {
    dispatch(getOrgOpp(token));
    dispatch(getuserinfo(token));
  }, []);
  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);
  useEffect(() => {
    if (userinfo) {
      if (
        // (userinfo.verified == 1 && localStorage.getItem("step") >= 4) ||
        (userinfo.subscription == 1 && localStorage.getItem("step") >= 4)
      ) {
        setacessCreateopp(true);
      }
    }
  }, [userinfo]);
  return (
    <div className="org_opp_main_cont">
      <div className="org_opp_header">
        <button
          className={
            page == 1
              ? "eval_cert_button eval_cert_active_btn"
              : "eval_cert_button"
          }
          onClick={() => setPage(1)}
        >
          {t("opportunity.Ongoing")}
        </button>
        <button
          className={
            page == 2
              ? "eval_cert_button eval_cert_active_btn"
              : "eval_cert_button"
          }
          onClick={() => setPage(2)}
        >
          {t("opportunity.Drafts")}
        </button>
        <button
          className={
            page == 3
              ? "eval_cert_button eval_cert_active_btn"
              : "eval_cert_button"
          }
          onClick={() => setPage(3)}
        >
          {t("opportunity.Paused")}
        </button>
        <button
          className={
            page == 4
              ? "eval_cert_button eval_cert_active_btn"
              : "eval_cert_button"
          }
          onClick={() => setPage(4)}
        >
          {t("opportunity.past_deadline")}
        </button>

        <button
          className={
            page == 5
              ? "eval_cert_button eval_cert_active_btn"
              : "eval_cert_button"
          }
          onClick={() => setPage(5)}
        >
          {t("opportunity.closed")}
        </button>

      </div>
      {acessCreateopp ? (
        <>
          <div className="create_button" onClick={() => setShowPop(true)}>
            {" "}
            <AiFillPlusCircle size="2rem" /> {t("opportunity.create_opp")}
          </div>
          {showPop && (

            <CreateOpp_Pop
              setOpen={setShowPop}
              header={
                t("opportunity.create_vol_opp")
              }
              body={
                <CreateOpp_Step1
                  setOpen={setShowPop}
                  community={false}
                  setFooterWidth={setFooterWidth}
                />
              }
              footer={
                <div
                  className={
                    lang == "en"
                      ? "create_opp_footer_slider_green"
                      : "create_opp_footer_slider_green create_opp_footer_slider_green_ar"
                  }
                  style={{ width: footerWidth }}
                ></div>
              }
            />
          )}
        </>
      ) : (
        <div className="button_disabled">
          <div className="create_button_disables">
            {" "}
            <AiFillPlusCircle size="2rem" />{" "}
            <span className="underline_text">
              {t("opportunity.create_opp")}
            </span>
          </div>
          <div className="create_button_disables_text">
            { }
            {t("opportunity.create_opportunities_message")}
            <ul>
              <li>
                {t("opportunity.create_opportunities_message1", { percentage })}
              </li>
              <li>
                {t("opportunity.create_opportunities_message2")}{" "}
                <a href="/subscription">
                  {t("opportunity.go to Subscription Page")}
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
      <div className="opp_main_sec">
        {orgShow &&
          orgShow?.map((opportunity, key) => {
            //oppuritnity flag
            let flags = [];
            if (opportunity.wheelchair_accessible === true)
              flags = [...flags, "handicap"];
            if (opportunity.transporation === "Provided")
              flags = [...flags, "transport"];
            if (opportunity.food === "Provided") flags = [...flags, "food"];
            return (
              <OppCard
                id={opportunity?.id}
                flags={flags}
                image={opportunity?.img_url}
                title={opportunity?.title}
                respond={opportunity?.respond_time}
                location={opportunity?.district?.name}
                deadline={
                  opportunity?.deadline_date
                    ? new Date(opportunity?.deadline_date).toLocaleDateString(
                      "en-GB"
                    )
                    : null
                }
                spots={opportunity?.nb_of_spots - opportunity?.nb_of_volunteers}
                impact={opportunity?.tasks_required}
                points={opportunity?.type === "Time-based" ? 10 : 20}
                key={key}
                status={opportunity.status}
                closedEnable = {opportunity?.nb_of_spots == opportunity?.nb_of_volunteers_checkout}
              />
            );
          })}
      </div>
    </div>
  );
}

export default OrgOpportunities;
