import React from "react";
import CompleteProfile from "../../CompleteProfile";
import "./Complete_Step2.css";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import i18n from "../../../../i18n";
import { useEffect } from "react";
import Select from "react-select";
import {
  AiFillPlusCircle,
  AiOutlineClose,
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
} from "react-icons/ai";

import { BiSave } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import {
  updateUserinfo,
  getuserinfo,
} from "../../../../Redux/user/userActions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Loading from "../../../../components/Loading/Loading";
function Complete_Step2() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const { userinfo, loading } = useSelector((store) => store.userReducer);
  const [volExp, setVolExp] = useState();

  const size = useWindowSize();
  const [lang, setLang] = useState(i18n.language);

  const [occupation, setOccupation] = useState(null);

  const allOcupations = [
    {
      value: "Unoccupied",
      label: lang == "en" ? "Unoccupied" : "شاغر",
    },
    {
      value: "School Student",
      label: lang == "en" ? "School Student" : "طالب مدرسة",
    },

    {
      value: "University Student",
      label: lang == "en" ? "University Student" : "طالب جامعي",
    },
    {
      value: "Employee",
      label: lang == "en" ? "Employee" : "موظف",
    },
    {
      value: "Self Employed",
      label: lang == "en" ? "Self Employed" : "موطف لحسابي الخاص",
    },
  ];

  const [isNext, setIsNext] = useState(true);
  const [fields, setFields] = useState([]);

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const [exp, setExp] = useState([]);

  const [allExp, setAllExp] = useState([]);

  const setSelectedExp = (event) => {
    if (event.length > 0) {
      var found = false;
      if (exp.length > 0)
        for (var i = 0; i < exp.length; i++) {
          if (event[0].value == exp[i].value) {
            found = true;
          }
        }
      if (!found) setExp((e) => [...e, event[0]]);
    }
  };

  const removeExp = (index) => {
    const newfields = [...exp];
    newfields.splice(index, 1);
    setExp(newfields);
  };

  const getExp = async () => {
    const response = await axios.get(process.env.REACT_APP_API + "/skills");
    const newE = [];
    response.data.data.map((d, i) => {
      if (d.type == "E") newE.push(d);
    });
    const newExp = newE.map((d, i) => {
      return { value: d.id, label: lang == "en" ? d.name : d.name_ar };
    });

    setAllExp(newExp);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const step = localStorage.getItem("step");
    if (step == 1) {
      localStorage.setItem("step", 2);
    }
    const user = {
      occupation: occupation.value,
    };

    const newExpet = exp.map((d, i) => {
      return d.value.toString();
    });

    await axios.post(
      process.env.REACT_APP_API + "/volunteer_expertises",
      {
        expertise_id: newExpet,
      },
      { Authorization: "Bearer " + localStorage.getItem("token") }
    );
    if (isNext) {
      dispatch(updateUserinfo(token, user, navigate, 3));
    } else {
      dispatch(updateUserinfo(token, user, navigate, 0));
    }
  };

  const getVolExp = async () => {
    const resp = await axios.get(
      process.env.REACT_APP_API + "/volunteer_expertises",
      {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    );

    setVolExp(resp.data.data);
  };
  useEffect(() => {
    getExp();
    dispatch(getuserinfo(token));
    getVolExp();
  }, []);

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  useEffect(() => {
    if (userinfo) {
      if (userinfo.occupation) {
        for (var i = 0; i < allOcupations.length; i++) {
          if (allOcupations[i].value == userinfo.occupation) {
            setOccupation(allOcupations[i]);
          }
        }

        if (volExp && volExp.length > 0 && allExp) {
          var vE = [];

          for (var i = 0; i < volExp.length; i++) {
            vE.push(
              allExp.filter(
                (s) => s.value.toString() == volExp[i].expertise_id
              )[0]
            );
          }
          setExp(vE);
        }
      }
    }
  }, [userinfo, lang, volExp, allExp]);

  return (
    <div className="complete_profile_step1_main_cont">
      <CompleteProfile
        header={t("CompleteProf.Join_the_Circle_3_Title")}
        subtitle={t("CompleteProf.complete_your_profile_13")}
        steps={"20%"}
        body={
          loading ? (
            <Loading />
          ) : (
            <form
              className="complete_step2_main_body"
              onSubmit={(e) => handleSubmit(e)}
            >
              <div>
                <Select
                  value={occupation && occupation}
                  className="complete_profile_select"
                  name="occupation"
                  onChange={setOccupation}
                  placeholder={t("CompleteProf.complete_your_profile_13")}
                  required
                  isSearchable={false}
                  options={allOcupations}
                />

                <div className="complete_step2_fields_cont">
                  <div className="complete_step2_main">
                    <div>
                      <div className="complete_step3_header">
                        <p
                          className="complete_input_header"
                          style={{ marginBottom: "1rem" }}
                        >
                          {t("CompleteProf.complete_your_profile_15")}
                        </p>
                      </div>

                      <Select
                        value={null}
                        className="complete_profile_select complete_step3_search"
                        name="skills"
                        onChange={(e) => setSelectedExp(e)}
                        placeholder={t("CompleteProf.searchExp")}
                        isMulti={true}
                        options={allExp}
                      />
                      <div className="complete_step3_skills_cont">
                        {exp &&
                          exp.map((e, index) => {
                            return (
                              <div className="complete_step3_skills_input_cont">
                                <div className="complete_step3_skills_input">
                                  {" "}
                                  {e.label}{" "}
                                </div>{" "}
                                <AiOutlineClose
                                  className="complete_step2_close"
                                  size="1.2rem"
                                  onClick={() => removeExp(index)}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="complete_step1_footer">
                <div className="divider_line"></div>
                <div className="complete_step_footer_button">
                  <button
                    className={
                      size.width > 768
                        ? "primary_button complete_step_1_next_button"
                        : "primary_button complete_step_2_next_button_resp"
                    }
                    type="button"
                    onClick={() => navigate("/completeProfile/step1")}
                  >
                    {size.width > 768 ? (
                      t("CompleteProf.complete_your_profile_btn_5")
                    ) : lang == "en" ? (
                      <AiOutlineArrowLeft size="1.5rem" />
                    ) : (
                      <AiOutlineArrowRight size="1.5rem" />
                    )}
                  </button>

                  <div
                    className={
                      size.width > 768
                        ? "complete_step_right_footer"
                        : "complete_step_right_footer_resp"
                    }
                  >
                    <button
                      className={
                        size.width > 500
                          ? "pink_button complete_step_2_complete_button"
                          : "pink_button complete_step_2_next_button_resp"
                      }
                      onClick={() => setIsNext(false)}
                    >
                      {size.width > 500 ? (
                        t("CompleteProf.complete_your_profile_btn_3")
                      ) : (
                        <BiSave size="1.5rem" />
                      )}
                    </button>
                    <button
                      className={
                        size.width > 768
                          ? "primary_button complete_step_2_next_button"
                          : "primary_button complete_step_2_next_button_resp"
                      }
                      onClick={() => setIsNext(true)}
                    >
                      {size.width > 768 ? (
                        t("CompleteProf.complete_your_profile_btn_4")
                      ) : lang == "en" ? (
                        <AiOutlineArrowRight size="1.5rem" />
                      ) : (
                        <AiOutlineArrowLeft size="1.5rem" />
                      )}
                    </button>
                  </div>

                  {/* 
                <button
                  className="primary_button complete_step_2_next_button_resp"
                  type="button"
                  onClick={() => navigate("/completeProfile/step1")}
                >
                  <AiOutlineArrowLeft size="1.5rem" />
                </button>

                <div className="complete_step_right_footer_resp">
                  <button
                    className="pink_button complete_step_2_complete_button"
                    onClick={() => setIsNext(false)}
                  >
                    {t("CompleteProf.complete_your_profile_btn_3")}
                  </button>

                  <button
                    className="primary_button complete_step_2_next_button_resp "
                    onClick={() => setIsNext(true)}
                  >
                    <AiOutlineArrowRight size="1.5rem" />
                  </button>
                </div> */}
                </div>
              </div>
            </form>
          )
        }
        footer={
          <div
            className={
              lang == "en"
                ? "complete_profile_footer_slider_green"
                : " complete_profile_footer_slider_green complete_profile_footer_slider_green_ar"
            }
            style={{ width: "20%" }}
          ></div>
        }
      />
    </div>
  );
}

export default Complete_Step2;
