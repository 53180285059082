import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  emails: [],
  loading: false,
  error: null,
  success:false
};

const newsLetterSlice = createSlice({
  name: "newsLetter",
  initialState,
  reducers: {
    subscribeRequest: (state) => {
      state.loading = true;
      state.error = null;
      state.emails = [];
    },
    subscribeSuccess: (state, action) => {
      state.loading = false;
      state.emails = action.payload;
      state.success=true
    },
    subscribeFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success=false

    },
  },
});

export const newsLetterAction = newsLetterSlice.actions;
export default newsLetterSlice;
