import React, { useEffect, useState } from "react";
import Filters from "../../components/Filters/Filters";
import OppCard from "../../components/OppCard/OppCard";
import UpperSection from "../../components/UpperSection/UpperSection";

import "./Opportunities.css";
import { FaFilter } from "react-icons/fa";
//translation
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import {
  getOpportunities,
  getFavoritesOpportunities,
} from "../../Redux/opportunities/opportunityActions";
import { useDispatch, useSelector } from "react-redux";

import { GoOrganization } from "react-icons/go";
import isAuth from "../../Utils/isAuth";
import MyOpportunities from "./Volunteer/MyOpportunities.js";
import OrgOpportunities from "./Organization/OrgOpportunities";
import Loading from "../../components/Loading/Loading";
import { useParams } from "react-router-dom";

function Opportunities() {
  //translation
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { stepCnt } = useParams();
  const [lang, setLang] = useState(i18n.language);
  const [favoritesOpportunitiesArray, setFavoritesOpportunitiesArray] =
    useState([]);

  const { opportunities, loading, favOpp } = useSelector(
    (store) => store.opportunitiesReducer
  );
  const [page, setPage] = useState(stepCnt ? stepCnt : 1);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    dispatch(getFavoritesOpportunities({}));
  }, []);

  useEffect(() => {
    if (favOpp.length > 0) {
      favOpp.map((favorite) => {
        if (!favoritesOpportunitiesArray.includes(favorite?.opportunity_id)) {
          setFavoritesOpportunitiesArray((favoritesOpportunitiesArray) => [
            ...favoritesOpportunitiesArray,
            favorite?.opportunity_id,
          ]);
        }
      });
    } else {
      setFavoritesOpportunitiesArray([]);
    }
    dispatch(getOpportunities({}));
  }, [favOpp]);

  //filters
  const [showFilt, setShowFilt] = useState(false);
  return (
    <div className="opp_main_cont">
      {!isAuth() ? (
        <>
          <UpperSection
            title={t("opportunity.title")}
            subtitle={t("opportunity.sub_title")}
            page={page}
            setPage={setPage}
          />
          <div className="opp_main_section_cont">
            <div className="opp_filters_comp">
              <Filters />
            </div>

            {!showFilt && (
              <div
                className="filter_title_resp"
                onClick={() => setShowFilt(true)}
              >
                <FaFilter className="filter_main_icon" />
                {t("opportunity.filter.view_filter")}
                <div className="filers_main_underline"></div>
              </div>
            )}

            <div className="opp_filters_comp_resp">
              {showFilt && <Filters close={() => setShowFilt(false)} />}
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div
                className="opp_main_sec"
                onClick={() => {
                  if (showFilt) {
                    setShowFilt(false);
                  }
                }}
              >
                {opportunities?.map((opportunity, key) => {
                  //oppuritnity flag
                  let flags = [];
                  if (opportunity.wheelchair_accessible === true)
                    flags = [...flags, "handicap"];
                  if (opportunity.transporation === "Provided")
                    flags = [...flags, "transport"];
                  if (opportunity.food === "Provided")
                    flags = [...flags, "food"];

                  let isSaved = false;
                  if (favoritesOpportunitiesArray.includes(opportunity?.id)) {
                    isSaved = true;
                  }
                  return (
                    <OppCard
                      id={opportunity?.id}
                      flags={flags}
                      image={opportunity?.organization?.logo}
                      title={opportunity?.title}
                      respond={opportunity.respond_time}
                      location={opportunity.district?.name}
                      deadline={new Date(
                        opportunity.deadline_date
                      ).toLocaleDateString()}
                      spots={
                        opportunity.nb_of_spots - opportunity.nb_of_volunteers
                      }
                      saved={localStorage.getItem("token") ? true : false}
                      isSaved={isSaved}
                      impact={opportunity.tasks_required}
                      points={opportunity.type === "Time-based" ? 10 : 20}
                      key={key}
                    />
                  );
                })}
              </div>
            )}
          </div>{" "}
        </>
      ) : isAuth() && localStorage.role == 3 ? (
        <>
          <UpperSection
            title={t("opportunity.title")}
            subtitle={t("opportunity.sub_title2")}
            subtitle2={t("opportunity.my_opportunities")}
            page={page}
            setPage={setPage}
          />
          <div className="opp_main_section_cont">
            {page == 1 ? (
              <>
                {" "}
                <div className="opp_filters_comp">
                  <Filters />
                </div>
                {!showFilt && (
                  <div
                    className="filter_title_resp"
                    onClick={() => setShowFilt(true)}
                  >
                    <FaFilter className="filter_main_icon" />
                    {t("opportunity.filter.view_filter")}
                    <div className="filers_main_underline"></div>
                  </div>
                )}
                <div className="opp_filters_comp_resp">
                  {showFilt && <Filters close={() => setShowFilt(false)} />}
                </div>
                {loading ? (
                  <Loading />
                ) : (
                  <div
                    className="opp_main_sec"
                    onClick={() => {
                      if (showFilt) {
                        setShowFilt(false);
                      }
                    }}
                  >
                    {opportunities?.map((opportunity, key) => {
                      //oppuritnity flag
                      let flags = [];
                      if (opportunity.wheelchair_accessible === true)
                        flags = [...flags, "handicap"];
                      if (opportunity.transporation === "Provided")
                        flags = [...flags, "transport"];
                      if (opportunity.food === "Provided")
                        flags = [...flags, "food"];
                      let isSaved = false;
                      if (
                        favoritesOpportunitiesArray.includes(opportunity?.id)
                      ) {
                        isSaved = true;
                      }
                      return (
                        <OppCard
                          id={opportunity?.id}
                          flags={flags}
                          image={opportunity?.organization?.logo}
                          title={opportunity?.title}
                          respond={opportunity.respond_time}
                          location={opportunity.district?.name}
                          deadline={new Date(
                            opportunity.deadline_date
                          ).toLocaleDateString()}
                          spots={
                            opportunity.nb_of_spots -
                            opportunity.nb_of_volunteers
                          }
                          saved={localStorage.getItem("token") ? true : false}
                          isSaved={isSaved}
                          impact={opportunity.tasks_required}
                          points={opportunity.type === "Time-based" ? 10 : 20}
                          key={key}
                        />
                      );
                    })}
                  </div>
                )}
              </>
            ) : (
              <MyOpportunities />
            )}
          </div>
        </>
      ) : (
        isAuth() &&
        localStorage.role == 4 && (
          <>
            <UpperSection
              title={t("opportunity.title")}
              subtitle={t("opportunity.my_opportunities")}
              page={page}
              setPage={setPage}
            />
            <div>{page == 1 && <OrgOpportunities />}</div>
          </>
        )
      )}
    </div>
  );
}

export default Opportunities;
