import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  communityAct: [],
  volcommunityAct: [],
  reqsvolcommunityAct: [],
  communityActLoading: false,
  communityActError: null,
  actionDetails: [],
  commId: "",
  createCommError: "",
  createCommSucess: false,
  createCommLoading: false,
  favComm: [],
  loading: false,

};

const communityActSlice = createSlice({
  name: "communityAct",
  initialState,
  reducers: {
    getCommunityAct: (state) => {
      state.communityActLoading = true;
      state.communityActError = null;
      state.communityAct = [];
    },
    getCommunityActSuccess: (state, action) => {
      state.communityActLoading = false;
      state.communityAct = action.payload;
    },
    getCommunityActError: (state, action) => {
      state.communityActLoading = false;
      state.communityActError = action.payload;
    },
    fetchvolCommunityAct: (state) => {
      state.communityActLoading = true;
      state.communityActError = null;
      state.volcommunityAct = [];
    },
    fetchvolCommunityActSuccess: (state, action) => {
      state.communityActLoading = false;
      state.volcommunityAct = action.payload;
    },
    fetchvolCommunityActError: (state, action) => {
      state.communityActLoading = false;
      state.communityActError = action.payload;
    },
    fetchvolreqCommunityAct: (state) => {
      state.communityActLoading = true;
      state.communityActError = null;
      state.reqsvolcommunityAct = [];
    },
    fetchvolreqCommunityActSuccess: (state, action) => {
      state.communityActLoading = false;
      state.reqsvolcommunityAct = action.payload;
    },
    fetchvolreqCommunityActError: (state, action) => {
      state.communityActLoading = false;
      state.communityActError = action.payload;
    },
    createActionRequest(state, action) {
      state.createCommSucess = "";
      state.createCommSucess = "";
      state.commId = "";
      state.createCommLoading = true;
    },

    createActionSuccess(state, action) {
      state.createCommError = "";
      state.createCommSucess = true;
      state.commId = action.payload.id;
      state.createCommLoading = false;
    },

    createActionFail(state, action) {
      state.createCommError = action.payload;
      state.createCommSucess = false;
      state.commId = "";
      state.createCommLoading = false;
    },

    fetchFavCommRequest(state) {
      state.loading = true;
      state.favComm = [];
      state.error = "";
    },

    fetchFavCommSuccess(state, action) {
      state.loading = false;
      state.favComm = action.payload;

      state.error = "";
    },

    fetchFavCommFail(state, action) {
      state.loading = false;
      state.favComm = action.payload;
      state.error = "";
    },



  
  },
});

export const CommunityActActions = communityActSlice.actions;
export default communityActSlice;
