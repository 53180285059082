import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import "./VolunteerMang.css";
import video from "../../assets/images/v2.mp4";
import ContactUs from "../../components/ContactUs/ContactUs";
import contact from "../../assets/images/contact.png";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

function VolunteerMang() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);
  return (
    <div className="vol-manager-container">
      <h1 className="vol-manager-title">
        {t("volunteerMang.header1")}

        <span style={{ color: "var(--pink)" }}>
          {" "}
          {t("volunteerMang.header2")}
        </span>
      </h1>
      <div className="video-cont">
        <video className="vol-manager-video" controls>
          <source src={video} type="video/mp4" />
        </video>
      </div>
      <h2 className="title-2">
        {t("volunteerMang.volunteer_mang1")}
        <span className="primary-b">
          {" "}
          {t("volunteerMang.volunteer_mang2")}
        </span>{" "}
        {t("volunteerMang.volunteer_mang3")}
        <br />
        {t("volunteerMang.volunteer_mang4")}
      </h2>
      <hr className="pink-hr" />
      <div className="help-cont">
        <img src={contact} />
        <div>
          <p>{t("volunteerMang.volunteer_mang5")}</p>
          <b>
            {t("volunteerMang.volunteer_mang6")}{" "}
            <span style={{ color: "var(--pink)" }}>
              {" "}
              {t("volunteerMang.volunteer_mang7")}
            </span>{" "}
            {t("volunteerMang.volunteer_mang8")}
          </b>
        </div>
      </div>
      <ContactUs />
    </div>
  );
}

export default VolunteerMang;
