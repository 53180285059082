import React from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import i18n from "../../../../i18n";
import { useEffect } from "react";
import Select from "react-select";
import "./CompleteOrg_Step1.css";
import PhoneInput from "react-phone-input-2";
import CompleteProfile from "../../CompleteProfile";
//date picker
import "react-modern-calendar-datepicker/lib/DatePicker.css";

import {
  updateUserinfo,
  getuserinfo,
} from "../../../../Redux/user/userActions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useRef } from "react";
import Loading from "../../../../components/Loading/Loading";

function CompleteOrg_Step1() {
  const { userinfo, loading } = useSelector((store) => store.userReducer);
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [name, setName] = useState();
  const [type, setType] = useState();
  const [regNum, setRegNum] = useState();
  const [nationality, setNationality] = useState(null);
  const [email, setEmail] = useState();
  const [country, setCountry] = useState(null);
  const [allCountries, setAllCountries] = useState({});
  const [error, setError] = useState("");
  const [image, setImage] = useState();
  const [imgName, setImgName] = useState("");
  const dispatch = useDispatch();
  const defaultEmail = localStorage.getItem("email");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const size = useWindowSize();
  const [selectedGov, setSelectedGov] = useState();
  const [districts, setDistricts] = useState();
  const [governate, setGovernate] = useState();
  const [selectedDis, setSelectedDis] = useState();
  const [counter, setCounter] = useState(0);
  const [phone, setphone] = useState("");
  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const asyncfunction = async () => {
    const resp_countries = await axios.get(
      process.env.REACT_APP_API + "/countries"
    );

    const countries = resp_countries.data.data.map((d, i) => {
      return { value: d.id, label: lang == "en" ? d.name : d.name_ar };
    });

    setAllCountries(countries);
  };

  useEffect(() => {
    asyncfunction();
  }, [lang]);

  useEffect(() => {
    dispatch(getuserinfo(token));
    asyncfunction();
  }, []);

  useEffect(() => {
    if (userinfo) {
      setName(userinfo.organization_name);
      setType(userinfo.type);
      setRegNum(userinfo.registration_number);
      setEmail(userinfo.email_address ? userinfo.email_address : defaultEmail);
      const newimg = { name: userinfo.img_url };
      setImage(newimg);

      // if (allCountries && allCountries.length > 0) {
      //   for (var i = 0; i < allCountries.length; i++) {
      //     if (allCountries[i].value == userinfo.country_of_registration) {
      //       setCountry(allCountries[i]);
      //     }
      //   }
      // }

      if (userinfo.country_of_registration !== 1) {
        var newC = {
          value: userinfo.country_of_registration,
          label:
            lang == "en" ? userinfo.country_name : userinfo.country_name_ar,
        };

        setCountry(newC);
        setCounter(1);
      } else {
        if (
          userinfo.district_id &&
          userinfo.country_of_registration == 1 &&
          userinfo.governate_id
        ) {
          var newDis = {
            value: userinfo.district_id,
            label:
              lang == "en" ? userinfo.district_name : userinfo.district_name_ar,
          };

          setSelectedDis(newDis);

          var newC = {
            value: userinfo.country_of_registration,
            label:
              lang == "en" ? userinfo.country_name : userinfo.country_name_ar,
          };

          setCountry(newC);

          var newGov = {
            value: userinfo.governate_id,
            label:
              lang == "en"
                ? userinfo.governate_name
                : userinfo.governate_name_ar,
          };

          setSelectedGov(newGov);
        }
        setCounter(1);
      }
    }

    if (userinfo.phone_number) {
      setphone(userinfo.phone_number);
    }
  }, [userinfo, allCountries]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (name && type && country && email && regNum) {
      const step = localStorage.getItem("step");
      if (step == 0) {
        localStorage.setItem("step", 1);
      }
      if (country?.value == 1) {
        const user = {
          name: name,
          email_address: email,
          registration_number: regNum,
          type: type,
          country_of_registration: country.value,
          district_id: selectedDis.value,
          phone_number: phone,
          image: image,
        };
        dispatch(updateUserinfo(token, user, navigate, 2));
      } else {
        const user = {
          name: name,
          email_address: email,
          registration_number: regNum,
          type: type,
          country_of_registration: country.value,
          district_id: null,
          phone_number: phone,
          image: image,
        };
        dispatch(updateUserinfo(token, user, navigate, 2));
      }

    } else setError("Please enter all required fields");
  };

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }
  const inputFile = useRef(null);

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const getGov = async (id) => {
    const response = await axios.get(
      process.env.REACT_APP_API + "/governates/getGovernateByCountry/" + id
    );

    const newGov = response.data.data.map((d, i) => {
      return {
        value: d.id,
        label: lang == "en" ? d.name : d.name_ar,
      };
    });

    setGovernate(newGov.filter((s) => s));
  };

  const getDis = async (id) => {
    const response = await axios.get(
      process.env.REACT_APP_API + "/districts/getDistrictByGovernate/" + id
    );

    const newDis = response.data.data.map((d, i) => {
      return {
        value: d.id,
        label: lang == "en" ? d.name : d.name_ar,
      };
    });
    setDistricts(newDis.filter((s) => s));
  };

  useEffect(() => {
    setCounter(counter + 1);
    if (country?.value != undefined) getGov(country?.value);

    if (counter == 1 && userinfo?.country_of_registration != country?.value) {
      setSelectedGov("");
      setSelectedDis("");
    }

    if (counter > 1) {
      setSelectedGov("");
      setSelectedDis("");
    }
  }, [country]);

  useEffect(() => {
    if (selectedGov?.value != undefined) getDis(selectedGov?.value);
    if (counter == 1 && userinfo?.governate_id != selectedGov?.value) {
      setSelectedDis("");
    }

    if (counter > 1) {
      setSelectedDis("");
    }
  }, [selectedGov]);

  return (
    <div className="complete_profile_step1_main_cont">
      <CompleteProfile
        header={t("CompleteProf.complete_your_profile_title")}
        subtitle={t("CompleteProf.complete_your_profile_1")}
        steps={1}
        body={
          loading ? (
            <Loading />
          ) : (
            <form
              className="complete_step1_main_body"
              onSubmit={(e) => onSubmit(e)}
            >
              <div className="complete_step1_body1">
                <input
                  className="complete_profile_input"
                  type="text"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder={t("CompleteProf.Join_the_Circle_1_Org")}
                />
                <input
                  className="complete_profile_input"
                  type="text"
                  required
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  placeholder={t("CompleteProf.Join_the_Circle_1_Type")}
                />
                <input
                  className="complete_profile_input"
                  type="text"
                  required
                  value={regNum}
                  onChange={(e) => setRegNum(e.target.value)}
                  placeholder={t("CompleteProf.Join_the_Circle_1_regisN")}
                />

                <input
                  className="complete_profile_input"
                  type="email"
                  required
                  value={email}
                  disabled
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t("CompleteProf.Join_the_Circle_1_Email")}
                />
                <div className="note_msg">
                  {t("CompleteProf.Join_the_Circle_1_note")}
                </div>
                <PhoneInput
                  country={"lb"}
                  value={phone}
                  required={true}
                  onChange={(e) => setphone(e)}
                  placeholder="Phone Number"
                  style={{ direction: "ltr" }}
                />
              </div>
              <div className="complete_step1_body3">
                <p className="complete_input_header2">
                  {t("CompleteProf.complete_your_profile_11_loc")}
                  <br />
                </p>
                <div className="complete_step1_location">
                  <Select
                    value={country && country}
                    className="complete_profile_select"
                    name="country"
                    onChange={setCountry}
                    placeholder={t("CompleteProf.complete_your_profile_5")}
                    required={country?.value == 1 ? true : false}
                    isSearchable={true}
                    options={allCountries}
                  />
                  {country && country?.value == 1 && governate && (
                    <Select
                      value={selectedGov && selectedGov}
                      className="complete_profile_select"
                      name="nationality"
                      onChange={setSelectedGov}
                      placeholder={t("opportunity.create.governate")}
                      required
                      options={governate}
                    />
                  )}
                  {country?.value == 1 && districts && (
                    <Select
                      value={selectedDis && selectedDis}
                      className="complete_profile_select"
                      name="nationality"
                      onChange={setSelectedDis}
                      placeholder={t("opportunity.create.district")}
                      required={country?.value == 1 ? true : false}
                      options={districts}
                    />
                  )}
                </div>
              </div>
              <div className="complete_step1_body3">
                <input
                  type="file"
                  name="logo"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e)}
                  accept="image/png, image/gif, image/jpeg"
                />

                <p className="complete_input_header">
                  {t("CompleteProf.Join_the_Circle_1_Logo")}
                </p>
                <div className="complete_input_sub_header"></div>
                {/* <img   src={(image && image?.name) && URL.createObjectURL(image)}/> */}

                <input
                  className="complete_profile_input"
                  type="text"
                  value={image?.name?.split("+").pop()}
                  style={{ cursor: "pointer" }}
                  onClick={() => onButtonClick()}
                  placeholder={t("CompleteProf.Join_the_Circle_1_image")}
                />
              </div>
              {error && <div className="register_error">{error}</div>}
              <div className="complete_step1_footer">
                <div className="divider_line"></div>

                <button
                  className={
                    size.width > 768
                      ? "primary_button complete_step_2_next_button"
                      : "primary_button complete_step_2_next_button_resp"
                  }
                >
                  {size.width > 768 ? (
                    t("CompleteProf.complete_your_profile_btn_4")
                  ) : lang == "en" ? (
                    <AiOutlineArrowRight size="1.5rem" />
                  ) : (
                    <AiOutlineArrowLeft size="1.5rem" />
                  )}
                </button>
              </div>
            </form>
          )
        }
        footer={
          <div
            className={
              lang == "en"
                ? "complete_profile_footer_slider_green"
                : "complete_profile_footer_slider_green complete_profile_footer_slider_green_ar"
            }
            style={{ width: "20%" }}
          ></div>
        }
      />
    </div>
  );
}

export default CompleteOrg_Step1;
