import React from "react";
import "./ReqCard.css";
import "../../pages/Requests/OppRequests.css";
import star from "../../assets/images/OppRequests/Group 1232.png";
import icon from "../../assets/images/Group 1553.png";
import bookmark from "../../assets/images/OppRequests/Group 1532.png";
import check from "../../assets/images/OppRequests/Group 1534.png";
import view from "../../assets/images/OppRequests/Group 1531.png";
import decline from "../../assets/images/OppRequests/Group 1533.png";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  acceptOppRequest,
  rejectOppRequest,
  acceptcommRequest,
  rejectcommRequest,
} from "../../Redux/OppRequests/OppRequestsActions";
import { useNavigate } from "react-router-dom";

function ReqCard(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleAccept = (id) => {
    if (props.reqs) {
      dispatch(acceptcommRequest(id));
    }
  };

  const handleReject = (id) => {
    if (props.reqs) {
      dispatch(rejectcommRequest(id));
    }
  };
  const handleView = (id) => {
    if (props.reqs) {
      navigate("/viewRequests/" + id);
    }
  };
  return (
    <div>
      <div className="opp_requests_cards_container">
        <div className="opp_requests_card">
          <div className="opp_requests_card_header">
            {props.points >= 8000 ? (
              <img src={star} className="opp_requests_star" />
            ) : (
              <img src={icon} className="opp_requests_star" />
              // <div className="opp_requests_card_level_logo" />
            )}
            <div className="opp_requests_card_text">
              <div className="opp_requests_card_text_title">
                {" "}
                {props.full_name}
                <div className="opp_requests_card_text_score">
                  {props.score}%
                </div>
              </div>
              <div className="opp_requests_card_text_subtitle">
                {" "}
                is interested in your Community Action {props.title}
              </div>
            </div>
          </div>
          <div className="opp_requests_card_body">
            <div
              className="opp_requests_card_body_action_container"
              onClick={() => handleView(props.id)}
            >
              <img src={view} className="action_logo" />{" "}
              <div className="opp_requests_card_body_action_text">
                {t("opprequests.opprequests_5")}
              </div>
            </div>

            <div
              className="opp_requests_card_body_action_container"
              onClick={() => handleReject(props.id)}
            >
              <img src={decline} className="action_logo" />{" "}
              <div className="opp_requests_card_body_action_text">
                {t("opprequests.opprequests_4")}
              </div>
            </div>
            <div
              className="opp_requests_card_body_action_container"
              onClick={() => handleAccept(props.id)}
            >
              <img src={check} className="action_logo" />{" "}
              <div className="opp_requests_card_body_action_text">
                {t("opprequests.opprequests_3")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReqCard;
