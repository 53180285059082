import React from 'react'
import "./SpotLightCard.css";
import { useNavigate } from 'react-router-dom'

function SpotlightCard(props) {
  const navigate =  useNavigate()
  return (
    <div className='spotlight_main_cont' style={{flexDirection : ( (props.index % 2 == 0 )? "row" : "row-reverse")}}>
        <img src={process.env.REACT_APP_API + "/VolunteerSpotlight/" + props.image} className="spotlight_image"/>
        <div className='spotlight_info'>
            <h3 className='spotlight_name'>{props.name}</h3>
            <div className='spotlight_text'>{props.text}</div>
            <button className='blog_card_readmore' onClick={()=>navigate("/volunteerSpotLight/details/"+props.id)}>Read More</button>

        </div>

    </div>
  )
}

export default SpotlightCard