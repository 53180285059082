import { React, useEffect, useState, useRef } from "react";
import "./NgoFeedbacks.css";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import notapp from "../../../assets/images/feedbacks/Group 1879.png";
import insuff from "../../../assets/images/feedbacks/Group 1878.png";
import suff from "../../../assets/images/feedbacks/Group 1877.png";
import good from "../../../assets/images/feedbacks/Group 1876.png";
import excellent from "../../../assets/images/feedbacks/Group 1875.png";
import PopUp from "../../../components/PopUp/PopUp";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
//redux
import { useDispatch, useSelector } from "react-redux";
import {
  getUserEval,
  edituserEval,
} from "../../../Redux/EvalRequests/EvalRequestsActions";
import { getOppRequestsbyid } from "../../../Redux/OppRequests/OppRequestsActions";
import { getuserinfobyid } from "../../../Redux/user/userActions";
import Loading from "../../../components/Loading/Loading";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function NgoFeedbacks() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [summary, setSummary] = useState("");
  const [emojie, setEmojie] = useState([1, 2, 3, 4, 5]);
  const [status, setStatus] = useState("Finished");
  const [Evaluatorfullname, setEvaluatorfullname] = useState("");
  const [Position, setPosition] = useState("");
  const dispatch = useDispatch();
  const { userEval, loading } = useSelector(
    (store) => store.evalRequestsReducer
  );

  const { ReqOpp } = useSelector((store) => store.oppRequestsReducer);
  const { idinfo } = useSelector((store) => store.userReducer);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);
  useEffect(() => {
    dispatch(getUserEval(id));
  }, [id]);


  useEffect(() => {
    if (userEval.request_id) {
      dispatch(getOppRequestsbyid(userEval.request_id));
    }
  }, [userEval]);

  const handleOpen = () => {
    setOpen(true);
  };
  const navigate = useNavigate();

  const divToCapture = useRef(null);

  const handleCaptureAndDownload = async () => {
    // Get the div element to capture
    const captureDiv = divToCapture.current;
  
    try {
      // Use html2canvas to capture the div as an image
      const canvas = await html2canvas(captureDiv);
  
      // Calculate the height and width of the captured content
      const contentHeight = (canvas.height * 300) / canvas.width; // Adjust '210' as needed based on your page size
      const contentWidth = 300; // Width should match the page width
  
      // Set your desired margins (left, top, and bottom) in millimeters
      const leftMargin = 10;
      const topMargin = 10;
      const bottomMargin = 10;
  
      // Calculate the effective height after considering top and bottom margins
      const effectiveHeight = contentHeight + topMargin + bottomMargin;
  
      // Create a PDF document with margins
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: [contentWidth, effectiveHeight], // Set page size to match content and margins
        marginLeft: leftMargin, // Left margin
        topMargin: topMargin, // Top margin
      });
  
      // Add the image to the PDF, considering the margins
      pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 10, topMargin, contentWidth, contentHeight);
  // pdf.save('xx.pdf')
      // Return the generated PDF
      return pdf;
    } catch (error) {
      console.error('Error generating PDF:', error);
      return null;
    }
  };

  const handleSubmit = async () => {
    const data = {
      id: id ,
      evaluator_summary:summary,
      overall_evaluation:emojie,
      evaluation_status:status,
      evaluator_name:Evaluatorfullname,
      evaluator_position:Position,
      time_management: (highlightedDivs.section1.length > 0 ? (parseInt(highlightedDivs.section1) + 1) : 0),
      planning_skills: (highlightedDivs.section2.length > 0 ? (parseInt( highlightedDivs.section2) + 1) : 0),
      accountability:(highlightedDivs.section3.length > 0 ? (parseInt(highlightedDivs.section3) + 1) : 0),
      resourcesfulness:(highlightedDivs.section4.length > 0 ? (parseInt(highlightedDivs.section4) + 1) : 0),
      perseverance:(highlightedDivs.section5.length > 0 ? (parseInt(highlightedDivs.section5) + 1 ): 0),
      stress_management:(highlightedDivs.section6.length > 0 ? (parseInt(highlightedDivs.section6) + 1) : 0),
      social_skills:(highlightedDivs.section7.length > 0 ? (parseInt(highlightedDivs.section7) + 1 ): 0),
      critical_thinking:(highlightedDivs.section8.length > 0 ? (parseInt(highlightedDivs.section8) + 1) : 0),
      adaptability:(highlightedDivs.section9.length > 0 ? (parseInt(highlightedDivs.section9) + 1) : 0),
      ethical_sensitivity:(highlightedDivs.section10.length > 0 ? (parseInt(highlightedDivs.section10) + 1) : 0),
      leadership_skills:(highlightedDivs.section11.length > 0 ? (parseInt(highlightedDivs.section11) + 1) : 0),
      proactiveness:(highlightedDivs.section12.length > 0 ? (parseInt(highlightedDivs.section12) + 1) : 0),
      self_development:(highlightedDivs.section13.length > 0 ? (parseInt(highlightedDivs.section13) + 1) : 0),
      assertiveness:(highlightedDivs.section14.length > 0 ? (parseInt(highlightedDivs.section14) + 1) : 0),
      teamwork: (highlightedDivs.section15.length > 0 ? (parseInt(highlightedDivs.section15) + 1) : 0),
      integrity:(highlightedDivs.section16.length > 0 ? (parseInt(highlightedDivs.section16) + 1) : 0),
      self_awereness:(highlightedDivs.section17.length > 0 ? (parseInt(highlightedDivs.section17) + 1) : 0),
      empathy:( highlightedDivs.section18.length > 0 ? (parseInt(highlightedDivs.section18) + 1 ): 0),
      openness:(highlightedDivs.section19.length > 0 ? (parseInt(highlightedDivs.section19) + 1) : 0),
      performance:(highlightedDivs.section20.length > 0 ? (parseInt(highlightedDivs.section20) + 1) : 0),
      autonomy:(highlightedDivs.section20.length > 0 ? (parseInt(highlightedDivs.section20) + 1) : 0)

    }

    dispatch(edituserEval(data)) && navigate("/oppRequests");
  
    
  };
  useEffect(() => {
    if (ReqOpp?.volunteer_id != undefined) {
      dispatch(getuserinfobyid(ReqOpp?.volunteer_id));
    }
  }, [ReqOpp]);


  useEffect(() => {
    if (userEval) {
      setSummary(userEval.evaluator_summary);
      setEmojie(userEval.overall_evaluation);
    }
  }, [userEval]);
  const [highlightedDivs, setHighlightedDivs] = useState({
    section1: [],
    section2: [],
    section3: [],
    section4: [],
    section5: [],
    section6: [],
    section7: [],
    section8: [],
    section9: [],
    section10: [],
    section11: [],
    section12: [],
    section13: [],
    section14: [],
    section15: [],
    section16: [],
    section17: [],
    section18: [],
    section19: [],
    section20: [],
  });

  const handleDivClick = (section, index) => {
    setHighlightedDivs((prevHighlightedDivs) => {
      const sectionHighlightedDivs = prevHighlightedDivs[section];

      // Create a new array to store the highlighted div
      const newHighlightedDivs = [index];

      return {
        ...prevHighlightedDivs,
        [section]: newHighlightedDivs,
      };
    });
  };

  const renderCircularDiv = (section, index) => {
    // Determine if the current div should be highlighted for this section
    const isHighlighted = highlightedDivs[section].includes(index);
    const circularDivClassName = `circular_div${index}`;
    const highlightedClassName = `highlighted_${circularDivClassName}`;
    let highlightedStyle = null;

    if (isHighlighted) {
      // Get the border color of the current circular div
      const circularDiv = document.querySelector(`.${circularDivClassName}`);
      const circularDivStyle = window.getComputedStyle(circularDiv);
      const borderColor = circularDivStyle.getPropertyValue("border-color");

      // Set the highlighted style to match the border color of the circular div
      highlightedStyle = { backgroundColor: borderColor };
    }

    const className = `${circularDivClassName} ${isHighlighted ? highlightedClassName : ""
      }`;

    return (
      <div
        className={className}
        style={highlightedStyle}
        onClick={() => handleDivClick(section, index)}
      ></div>
    );
  };

  return (
    <>
      <div className="Main_ngo_feedbacks" ref={divToCapture}>
        <div className="ngo_feedbacks_header">
          <div className="ngo_feedbacks_header_div">
            <div className="ngo_feedbacks_body_header">{t("ngofeedbacks.vol_name")}</div>
            <div className="volname_ngofeed_name">
              {idinfo[0]?.first_name + " " + idinfo[0]?.last_name}
            </div>
          </div>
          <div className="ngo_feedbacks_header_div">
            <div className="ngo_feedbacks_body_header">{t("CompleteProf.Join_the_Circle_1_Org")}</div>
            <div className="volname_ngofeed_name">
              {ReqOpp?.opportunity?.organization?.name}
            </div>
          </div>
          <div className="ngo_feedbacks_header_div">
            <div className="ngo_feedbacks_body_header">{t("ngofeedbacks.vol_position")}</div>
            <div className="volname_ngofeed_name">
              {ReqOpp?.opportunity?.title}
            </div>
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_header">
            {t("ngofeedbacks.title1")}
          </div>
          <div className="ngo_feedbacks_body_name_cont">
            <textarea
              className="ngo_feedbacks_body_textarea1"
              placeholder={t("ngofeedbacks.placeholder1")}
              value={Evaluatorfullname}
              onChange={(e) => setEvaluatorfullname(e.target.value)}
            />
            <textarea
              className="ngo_feedbacks_body_textarea1"
              placeholder={t("ngofeedbacks.placeholder2")}
              value={Position}
              onChange={(e) => setPosition(e.target.value)}
            />
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_header">
            {t("ngofeedbacks.title2")}
          </div>
          <div className="ngo_feedbacks_body_name_cont marginss">
            <div className="scale_container">
              <div className="scale_container_color g1"> </div>
              <div className="scale_container_text cq ">
                {t("ngofeedbacks.Scale")}{" "}
              </div>
            </div>
            <div className="scale_container">
              <div className="scale_container_color g2"> </div>
              <div className="scale_container_text cw ">
                {t("ngofeedbacks.Scale1")}{" "}
              </div>
            </div>
            <div className="scale_container">
              <div className="scale_container_color g3"> </div>
              <div className="scale_container_text ce ">
                {t("ngofeedbacks.Scale2")}{" "}
              </div>
            </div>
            <div className="scale_container">
              <div className="scale_container_color g4"> </div>
              <div className="scale_container_text cr ">
                {t("ngofeedbacks.Scale3")}{" "}
              </div>
            </div>
            <div className="scale_container">
              <div className="scale_container_color g5"> </div>
              <div className="scale_container_text ct">
                {t("ngofeedbacks.Scale4")}{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_header">
            {t("ngofeedbacks.title3")}
          </div>
          <div className="ngo_feedbacks_body_name_cont">
            <div className="title_body_cont">
              <div className="title_cont">{t("ngofeedbacks.title4")}</div>
              <div className="subtitle_cont">{t("ngofeedbacks.subtitle4")}</div>
            </div>
            <div className="info_cont_feeds">
              {renderCircularDiv("section1", 0)}
              {renderCircularDiv("section1", 1)}
              {renderCircularDiv("section1", 2)}
              {renderCircularDiv("section1", 3)}
              {renderCircularDiv("section1", 4)}
            </div>
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_name_cont">
            <div className="title_body_cont">
              <div className="title_cont">{t("ngofeedbacks.title5")}</div>
              <div className="subtitle_cont">{t("ngofeedbacks.subtitle5")}</div>
            </div>
            <div className="info_cont_feeds">
              {renderCircularDiv("section2", 0)}
              {renderCircularDiv("section2", 1)}
              {renderCircularDiv("section2", 2)}
              {renderCircularDiv("section2", 3)}
              {renderCircularDiv("section2", 4)}
            </div>
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_name_cont">
            <div className="title_body_cont">
              <div className="title_cont">{t("ngofeedbacks.title6")}</div>
              <div className="subtitle_cont">{t("ngofeedbacks.subtitle6")}</div>
            </div>
            <div className="info_cont_feeds">
              {renderCircularDiv("section3", 0)}
              {renderCircularDiv("section3", 1)}
              {renderCircularDiv("section3", 2)}
              {renderCircularDiv("section3", 3)}
              {renderCircularDiv("section3", 4)}
            </div>
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_name_cont">
            <div className="title_body_cont">
              <div className="title_cont">{t("ngofeedbacks.title7")}</div>
              <div className="subtitle_cont">{t("ngofeedbacks.subtitle7")}</div>
            </div>
            <div className="info_cont_feeds">
              {renderCircularDiv("section4", 0)}
              {renderCircularDiv("section4", 1)}
              {renderCircularDiv("section4", 2)}
              {renderCircularDiv("section4", 3)}
              {renderCircularDiv("section4", 4)}
            </div>
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_name_cont">
            <div className="title_body_cont">
              <div className="title_cont">{t("ngofeedbacks.title8")}</div>
              <div className="subtitle_cont">{t("ngofeedbacks.subtitle8")}</div>
            </div>
            <div className="info_cont_feeds">
              {renderCircularDiv("section5", 0)}
              {renderCircularDiv("section5", 1)}
              {renderCircularDiv("section5", 2)}
              {renderCircularDiv("section5", 3)}
              {renderCircularDiv("section5", 4)}
            </div>
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_name_cont">
            <div className="title_body_cont">
              <div className="title_cont">{t("ngofeedbacks.title9")}</div>
              <div className="subtitle_cont">{t("ngofeedbacks.subtitle9")}</div>
            </div>
            <div className="info_cont_feeds">
              {renderCircularDiv("section6", 0)}
              {renderCircularDiv("section6", 1)}
              {renderCircularDiv("section6", 2)}
              {renderCircularDiv("section6", 3)}
              {renderCircularDiv("section6", 4)}
            </div>
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_name_cont">
            <div className="title_body_cont">
              <div className="title_cont">{t("ngofeedbacks.title10")}</div>
              <div className="subtitle_cont">{t("ngofeedbacks.subtitle10")}</div>
            </div>
            <div className="info_cont_feeds">
              {renderCircularDiv("section7", 0)}
              {renderCircularDiv("section7", 1)}
              {renderCircularDiv("section7", 2)}
              {renderCircularDiv("section7", 3)}
              {renderCircularDiv("section7", 4)}
            </div>
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_name_cont">
            <div className="title_body_cont">
              <div className="title_cont">{t("ngofeedbacks.title11")}</div>
              <div className="subtitle_cont">{t("ngofeedbacks.subtitle11")}</div>
            </div>
            <div className="info_cont_feeds">
              {renderCircularDiv("section8", 0)}
              {renderCircularDiv("section8", 1)}
              {renderCircularDiv("section8", 2)}
              {renderCircularDiv("section8", 3)}
              {renderCircularDiv("section8", 4)}
            </div>
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_name_cont">
            <div className="title_body_cont">
              <div className="title_cont">{t("ngofeedbacks.title12")}</div>
              <div className="subtitle_cont">{t("ngofeedbacks.subtitle12")}</div>
            </div>
            <div className="info_cont_feeds">
              {renderCircularDiv("section9", 0)}
              {renderCircularDiv("section9", 1)}
              {renderCircularDiv("section9", 2)}
              {renderCircularDiv("section9", 3)}
              {renderCircularDiv("section9", 4)}
            </div>
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_name_cont">
            <div className="title_body_cont">
              <div className="title_cont">{t("ngofeedbacks.title13")}</div>
              <div className="subtitle_cont">{t("ngofeedbacks.subtitle13")}</div>
            </div>
            <div className="info_cont_feeds">
              {renderCircularDiv("section10", 0)}
              {renderCircularDiv("section10", 1)}
              {renderCircularDiv("section10", 2)}
              {renderCircularDiv("section10", 3)}
              {renderCircularDiv("section10", 4)}
            </div>
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_name_cont">
            <div className="title_body_cont">
              <div className="title_cont">{t("ngofeedbacks.title14")}</div>
              <div className="subtitle_cont">{t("ngofeedbacks.subtitle14")}</div>
            </div>
            <div className="info_cont_feeds">
              {renderCircularDiv("section11", 0)}
              {renderCircularDiv("section11", 1)}
              {renderCircularDiv("section11", 2)}
              {renderCircularDiv("section11", 3)}
              {renderCircularDiv("section11", 4)}
            </div>
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_name_cont">
            <div className="title_body_cont">
              <div className="title_cont">{t("ngofeedbacks.title15")}</div>
              <div className="subtitle_cont">{t("ngofeedbacks.subtitle15")}</div>
            </div>
            <div className="info_cont_feeds">
              {renderCircularDiv("section12", 0)}
              {renderCircularDiv("section12", 1)}
              {renderCircularDiv("section12", 2)}
              {renderCircularDiv("section12", 3)}
              {renderCircularDiv("section12", 4)}
            </div>
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_name_cont">
            <div className="title_body_cont">
              <div className="title_cont">{t("ngofeedbacks.title16")}</div>
              <div className="subtitle_cont">{t("ngofeedbacks.subtitle16")}</div>
            </div>
            <div className="info_cont_feeds">
              {renderCircularDiv("section13", 0)}
              {renderCircularDiv("section13", 1)}
              {renderCircularDiv("section13", 2)}
              {renderCircularDiv("section13", 3)}
              {renderCircularDiv("section13", 4)}
            </div>
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_name_cont">
            <div className="title_body_cont">
              <div className="title_cont">{t("ngofeedbacks.title17")}</div>
              <div className="subtitle_cont">{t("ngofeedbacks.subtitle17")}</div>
            </div>
            <div className="info_cont_feeds">
              {renderCircularDiv("section14", 0)}
              {renderCircularDiv("section14", 1)}
              {renderCircularDiv("section14", 2)}
              {renderCircularDiv("section14", 3)}
              {renderCircularDiv("section14", 4)}
            </div>
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_name_cont">
            <div className="title_body_cont">
              <div className="title_cont">{t("ngofeedbacks.title18")}</div>
              <div className="subtitle_cont">{t("ngofeedbacks.subtitle18")}</div>
            </div>
            <div className="info_cont_feeds">
              {renderCircularDiv("section15", 0)}
              {renderCircularDiv("section15", 1)}
              {renderCircularDiv("section15", 2)}
              {renderCircularDiv("section15", 3)}
              {renderCircularDiv("section15", 4)}
            </div>
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_name_cont">
            <div className="title_body_cont">
              <div className="title_cont">{t("ngofeedbacks.title19")}</div>
              <div className="subtitle_cont">{t("ngofeedbacks.subtitle19")}</div>
            </div>
            <div className="info_cont_feeds">
              {renderCircularDiv("section16", 0)}
              {renderCircularDiv("section16", 1)}
              {renderCircularDiv("section16", 2)}
              {renderCircularDiv("section16", 3)}
              {renderCircularDiv("section16", 4)}
            </div>
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_name_cont">
            <div className="title_body_cont">
              <div className="title_cont">{t("ngofeedbacks.title20")}</div>
              <div className="subtitle_cont">{t("ngofeedbacks.subtitle20")}</div>
            </div>
            <div className="info_cont_feeds">
              {renderCircularDiv("section17", 0)}
              {renderCircularDiv("section17", 1)}
              {renderCircularDiv("section17", 2)}
              {renderCircularDiv("section17", 3)}
              {renderCircularDiv("section17", 4)}
            </div>
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_name_cont">
            <div className="title_body_cont">
              <div className="title_cont">{t("ngofeedbacks.title21")}</div>
              <div className="subtitle_cont">{t("ngofeedbacks.subtitle21")}</div>
            </div>
            <div className="info_cont_feeds">
              {renderCircularDiv("section18", 0)}
              {renderCircularDiv("section18", 1)}
              {renderCircularDiv("section18", 2)}
              {renderCircularDiv("section18", 3)}
              {renderCircularDiv("section18", 4)}
            </div>
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_name_cont">
            <div className="title_body_cont">
              <div className="title_cont">{t("ngofeedbacks.title22")}</div>
              <div className="subtitle_cont">{t("ngofeedbacks.subtitle22")}</div>
            </div>
            <div className="info_cont_feeds">
              {renderCircularDiv("section19", 0)}
              {renderCircularDiv("section19", 1)}
              {renderCircularDiv("section19", 2)}
              {renderCircularDiv("section19", 3)}
              {renderCircularDiv("section19", 4)}
            </div>
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_name_cont">
            <div className="title_body_cont">
              <div className="title_cont">{t("ngofeedbacks.title23")}</div>
              <div className="subtitle_cont">{t("ngofeedbacks.subtitle23")}</div>
            </div>
            <div className="info_cont_feeds">
              {renderCircularDiv("section20", 0)}
              {renderCircularDiv("section20", 1)}
              {renderCircularDiv("section20", 2)}
              {renderCircularDiv("section20", 3)}
              {renderCircularDiv("section20", 4)}
            </div>
          </div>
        </div>
        <div className="ngo_feedbacks_body">
          <div className="ngo_feedbacks_body_header">
            {t("ngofeedbacks.parag_1")}
          </div>
          <div className="ngo_feedbacks_body_name_cont">
            <textarea
              className="ngo_feedbacks_body_textarea"
              placeholder={t("ngofeedbacks.placeholder")}
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
            />
          </div>
        </div>
        <div className="ngo_feedbacks_body_2">
          <div className="ngo_feedbacks_body_header">
            {t("ngofeedbacks.parag_2")}
          </div>
          <AiOutlineQuestionCircle
            className="question_mark"
            size={25}
            onClick={handleOpen}
          />
        </div>
        {open && (
          <PopUp
            setOpen={setOpen}
            body={
              <div className="popup_body">
                <div className="popup_body_header">{t("ngofeedbacks.title")}</div>
                <div className="popup_body_emoji_info_cont">
                  <div className="popup_body_emoji_info">
                    <div className="img_container">
                      <img
                        src={notapp}
                        alt="notapp"
                        className="emojie_logo_selected"
                      />
                    </div>
                    <div className="popup_body_emoji_info_title">
                      {t("ngofeedbacks.notapp")}
                      <div className="popup_body_emoji_info_desc">
                        {t("ngofeedbacks.notapp_desc")}
                      </div>
                    </div>
                  </div>
                  <div className="popup_body_emoji_info_2">
                    <div className="img_container">
                      <img
                        src={insuff}
                        alt="notapp"
                        className="emojie_logo_selected"
                      />
                    </div>
                    <div className="popup_body_emoji_info_title">
                      {t("ngofeedbacks.insuf")}
                      <div className="popup_body_emoji_info_desc">
                        {t("ngofeedbacks.insuf_desc")}
                      </div>
                    </div>
                  </div>
                  <div className="popup_body_emoji_info_2">
                    <div className="img_container">
                      <img
                        src={suff}
                        alt="notapp"
                        className="emojie_logo_selected"
                      />
                    </div>
                    <div className="popup_body_emoji_info_title">
                      {t("ngofeedbacks.suff")}
                      <div className="popup_body_emoji_info_desc">
                        {t("ngofeedbacks.suff_desc")}
                      </div>
                    </div>
                  </div>
                  <div className="popup_body_emoji_info_2">
                    <div className="img_container">
                      <img
                        src={good}
                        alt="notapp"
                        className="emojie_logo_selected"
                      />
                    </div>
                    <div className="popup_body_emoji_info_title">
                      {t("ngofeedbacks.good")}
                      <div className="popup_body_emoji_info_desc">
                        {t("ngofeedbacks.good_desc")}
                      </div>
                    </div>
                  </div>
                  <div className="popup_body_emoji_info_2">
                    <div className="img_container">
                      <img
                        src={excellent}
                        alt="notapp"
                        className="emojie_logo_selected"
                      />
                    </div>
                    <div className="popup_body_emoji_info_title">
                      {t("ngofeedbacks.excellent")}
                      <div className="popup_body_emoji_info_desc">
                        {t("ngofeedbacks.excellent_desc")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        )}
        <div className="ngo_feedbacks_body_emojie_container">
          <div className="feedbacks_page_emojies_row">
            <div
              className={
                emojie === "1"
                  ? "feedbacks_page_emojies_row_2_active"
                  : "feedbacks_page_emojies_row_2"
              }
            >
              <img
                src={notapp}
                alt="notapp"
                value={emojie}
                className={
                  emojie === 1 ? "emojie_logo_selected" : "emojie_logo"
                }
                onClick={() => setEmojie(1)}
              />
              <div className="feedbacks_page_emojies_row_1_text_1">
                {t("feedbacks.emoji_1")}
              </div>
            </div>
          </div>
          <div className="feedbacks_page_emojies_row">
            <div
              className={
                emojie === "2"
                  ? "feedbacks_page_emojies_row_2_active"
                  : "feedbacks_page_emojies_row_2"
              }
            >
              <img
                src={insuff}
                value={emojie}
                alt="notapp"
                className={
                  emojie === 2 ? "emojie_logo_selected" : "emojie_logo"
                }
                onClick={() => setEmojie(2)}
              />
              <div className="feedbacks_page_emojies_row_1_text_1">
                {t("feedbacks.emoji_2")}
              </div>
            </div>
          </div>
          <div className="feedbacks_page_emojies_row">
            <div
              className={
                emojie === "3"
                  ? "feedbacks_page_emojies_row_2_active"
                  : "feedbacks_page_emojies_row_2"
              }
            >
              <img
                src={suff}
                value={emojie}
                alt="notapp"
                className={
                  emojie === 3? "emojie_logo_selected" : "emojie_logo"
                }
                onClick={() => setEmojie(3)}
              />
              <div className="feedbacks_page_emojies_row_1_text_1">
                {t("feedbacks.emoji_3")}
              </div>
            </div>
          </div>
          <div className="feedbacks_page_emojies_row">
            <div
              className={
                emojie === "4"
                  ? "feedbacks_page_emojies_row_2_active"
                  : "feedbacks_page_emojies_row_2"
              }
            >
              <img
                src={good}
                value={emojie}
                alt="notapp"
                className={
                  emojie === 4 ? "emojie_logo_selected" : "emojie_logo"
                }
                onClick={() => setEmojie(4)}
              />
              <div className="feedbacks_page_emojies_row_1_text_1">
                {t("feedbacks.emoji_4")}
              </div>
            </div>
          </div>
          <div className="feedbacks_page_emojies_row">
            <div
              className={
                emojie === "5"
                  ? "feedbacks_page_emojies_row_2_active"
                  : "feedbacks_page_emojies_row_2"
              }
            >
              <img
                src={excellent}
                value={emojie}
                alt="notapp"
                className={
                  emojie === 5 ? "emojie_logo_selected" : "emojie_logo"
                }
                onClick={() => setEmojie(5)}
              />
              <div className="feedbacks_page_emojies_row_1_text_1">
                {t("feedbacks.emoji_5")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ngo_feedbacks_footer">
        {loading ? (
          <Loading />
        ) : (
          <><button
            className="ngo_feedbacks_footer_button"
            onClick={handleSubmit}
          >
            {" "}
            {t("ngofeedbacks.button")}
          </button>
          </>
        )}
      </div>
    </>
  );
}

export default NgoFeedbacks;
