import axios from "axios";
import { organizationAction } from "./OrganizationsReducers";

export const getOrganizationsById = (id) => async (dispatch) => {
  dispatch(organizationAction.fetchOrganizationsRequest());
  try {
    let response = await axios.get(
      process.env.REACT_APP_API + "/organizations/getOne/" + id
    );
    dispatch(
      organizationAction.fetchOrganizationsSuccess(response.data.Organization)
    );
  } catch (error) {
    dispatch(organizationAction.fetchOrganizationsFail(error.message));
  }
};

export const getAllOrganizations = () => async (dispatch) => {
  dispatch(organizationAction.fetchAllOrganizationsRequest());
  try {
    let response = await axios.get(
      process.env.REACT_APP_API + "/organizations/getAll"
    );
    dispatch(
      organizationAction.fetchAllOrganizationsSuccess(
        response.data.Organizations
      )
    );
  } catch (error) {
    dispatch(organizationAction.fetchAllOrganizationsFail(error.message));
  }
};
