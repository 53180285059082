import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  volunteerSpotlightsQa: [],
  loading: false,
  error: ""
};
const volunteerSpotlightsQaSlice = createSlice({
  name: "VolunteerSpotlight",
  initialState,
  reducers: {
    fetchvolunteerSpotlightQaRequest(state) {
      state.loading = true;
      state.volunteerSpotlightsQa = [];
      state.error = "";
    },

    fetchvolunteerSpotlightQaSuccess(state, action) {
      state.loading = false;
      state.volunteerSpotlightsQa = action.payload;
      state.error = "";
    },

    fetchvolunteerSpotlightQaFail(state, action) {
      state.loading = false;
      state.volunteerSpotlightsQa = [];
      state.error = action.payload;
    },


  },
});

export const volunteerSpotlightQaAction = volunteerSpotlightsQaSlice.actions;
export default volunteerSpotlightsQaSlice;
