import React from "react";
import "./PopUp.css";

function PopUp({ body, setOpen,cont }) {
  return (
    <div id="myModal" class="modal">
      <div class="modal-content">
        <span class="close" onClick={(e) => {e.stopPropagation();setOpen(false)}}>
          &times;
        </span>
       {body && <p className="body_text">{body}</p>}
        {cont && <div className="body_cont">{cont}</div>}
      </div>
    </div>
  );
}

export default PopUp;
