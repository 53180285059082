import { React, useEffect, useState } from "react";
import "./Notifications.css";
import UpperSection from "../../components/UpperSection/UpperSection";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useDispatch, useSelector } from "react-redux";
import {
  getTotalNotifications,
  getAllUserNotifications,
  editnotificationbyid,
} from "../../Redux/Notifications/NotificationsActions";
import Loading from "../../components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { updateOpportunity } from "../../Redux/opportunities/opportunityActions";

function Notifications() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [totalNotifications, setTotalNotifications] = useState([]);
  const [allNotifications, setAllNotifications] = useState([]);
  const dispatch = useDispatch();
  const { notifications_total, notifications, loading } = useSelector(
    (store) => store.NotificationsReducer
  );
  const [activeBtn, setActiveBtn] = useState("updates");
  const navigate = useNavigate();
  const [is_read, setis_read] = useState(false);

  useEffect(() => {
    dispatch(getTotalNotifications());
    dispatch(getAllUserNotifications("Updates"));
  }, []);

  useEffect(() => {
    if (notifications_total !== [] && notifications_total !== undefined) {
      setTotalNotifications(notifications_total);
    }
  }, [notifications_total]);

  useEffect(() => {
    if (notifications !== [] && notifications !== undefined) {
      setAllNotifications(notifications);
    }
  }, [notifications]);
  const handlereadmore = (id) => {
    dispatch(editnotificationbyid(id));
  };

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const handleGetNotificationaByStatus = (status) => {
    dispatch(getAllUserNotifications(status));
  };

  return (
    <div className="main_notifications_div">
      <UpperSection
        title={t("notifications.my_notifications")}
        subtitle67={t("notifications.my_notifications_message")}
      />
      <div
        className={
          lang == "ar"
            ? "my_notifications_container_ar"
            : "my_notifications_container"
        }
      >
        <div className="notifications_tabs">
          <button
            className={
              activeBtn == "updates"
                ? "notification_btn notification_btn_active purple_btn"
                : "notification_btn"
            }
            onClick={() => {
              handleGetNotificationaByStatus("Updates");
              setActiveBtn("updates");
            }}
          >
            {t("notifications.updates")}

            <span
              className={
                activeBtn == "updates"
                  ? "notification_circle notification_circle_purple"
                  : "notification_circle"
              }
            >
              {totalNotifications?.Updates}
            </span>
          </button>

          <button
            className={
              activeBtn == "checkin_out"
                ? "notification_btn notification_btn_active blue_btn"
                : "notification_btn"
            }
            onClick={() => {
              handleGetNotificationaByStatus("Check");
              setActiveBtn("checkin_out");
            }}
          >
            {t("notifications.checkin_out")}
            <span
              className={
                activeBtn == "checkin_out"
                  ? "notification_circle notification_circle_blue"
                  : "notification_circle"
              }
            >
              {totalNotifications?.Check}
            </span>
          </button>

          <button
            className={
              activeBtn == "reminders"
                ? "notification_btn notification_btn_active"
                : "notification_btn"
            }
            onClick={() => {
              handleGetNotificationaByStatus("Reminders");
              setActiveBtn("reminders");
            }}
          >
            {t("notifications.reminders")}
            <span class="notification_circle">
              {totalNotifications?.Reminders}
            </span>
          </button>

          <button
            className={
              activeBtn == "motivation"
                ? "notification_btn notification_btn_active"
                : "notification_btn"
            }
            onClick={() => {
              handleGetNotificationaByStatus("Motivation");
              setActiveBtn("motivation");
            }}
          >
            {t("notifications.motivation")}
            <span class="notification_circle">
              {totalNotifications?.Motivation}
            </span>
          </button>
        </div>
        <div className="my_notifications_container_data">
          {loading ? (
            <Loading />
          ) : (
            allNotifications && (
              <div className="all_notifications_container">
                {allNotifications?.map((notification) => {
                  var date = new Date(notification.createdAt);
                  var time = date.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                  date = date.toLocaleDateString("en-GB", {
                    weekday: "long",
                  });
                  return (
                    <div className="notif_container">
                      <span className="notification_message">
                        {notification.message}
                      </span>
                      <span className="notification_date_time">
                        {" "}
                        {date + " at " + time}
                      </span>

                      {activeBtn == "updates" && (
                        <div className="notification_container_button">
                          <button
                            className="have_a_look_btn"
                            onClick={() => {
                              navigate("/opportunities/1");
                              {
                                !notification.is_read &&
                                  handlereadmore(notification.id);
                              }
                            }}
                          >
                            {t("notifications.have_a_look")}
                          </button>
                        </div>
                      )}
                      {activeBtn == "checkin_out" && (
                        <div className="notification_container_button">
                          <button
                            className="read_more_btn"
                            onClick={() => {
                              navigate("/opportunities/2");
                              {
                                !notification.is_read &&
                                  handlereadmore(notification.id);
                              }
                            }}
                          >
                            {t("notifications.read_more")}
                          </button>
                        </div>
                      )}

                      {activeBtn == "reminders" &&
                        notification.status == "Paused" && (
                          <div className="notification_container_button">
                            <button
                              className="read_more_btn"
                              onClick={() =>
                                dispatch(
                                  updateOpportunity(
                                    notification.opportunity_id,
                                    "Ongoing"
                                  )
                                )
                              }
                            >
                              {" "}
                              {t("notifications.reactivate")}
                            </button>
                          </div>
                        )}

                      {activeBtn == "reminders" &&
                        notification.status != "Paused" && (
                          <div className="notification_container_button">
                            {" "}
                            <button
                              className="read_more_btn"
                              onClick={() => {
                                navigate("/opportunities/1");
                                {
                                  !notification.is_read &&
                                    handlereadmore(notification.id);
                                }
                              }}
                            >
                              {t("notifications.read_more")}
                            </button>
                          </div>
                        )}

                      {activeBtn == "motivation" && (
                        <div className="notification_container_button">
                          <button
                            className="read_more_btn"
                            onClick={() => {
                              navigate("/opportunities/1");
                              {
                                !notification.is_read &&
                                  handlereadmore(notification.id);
                              }
                            }}
                          >
                            {t("notifications.explore_more")}
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default Notifications;
