import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Faqs: [],
  loading: false,
  error: "",
};

const FaqsSlice = createSlice({
  name: "Faqs",
  initialState,
  reducers: {
    fetchFaqsRequest(state) {
      state.loading = true;
      state.Faqs = [];
      state.error = "";
    },
    fetchFaqsSuccess(state, action) {
      state.loading = false;
      state.Faqs = action.payload;
      state.error = "";
    },
    fetchFaqsFail(state, action) {
      state.loading = false;
      state.Faqs = [];
      state.error = action.payload;
    },
  },
});

export const FaqsAction = FaqsSlice.actions;
export default FaqsSlice;
