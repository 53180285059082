import React from "react";
import { useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import "./CreateOpp_Step6.css";

import i18n from "../../../../i18n";
import { useSSR, useTranslation } from "react-i18next";

import { useEffect } from "react";

import { BiSave } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import CreateOpp_Step5 from "../Step5/CreateOpp_Step5";
import CreateOpp_Step7 from "../Step7/CreateOpp_Step7";
import { editOpportunity, getOpportunitiesById } from "../../../../Redux/opportunities/opportunityActions";


function CreateOpp_Step6({
  id,
  setOpen,
  setFooterWidth,
  community,
}) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [openStep7, setOpenStep7] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [comment, setComment] = useState();
  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);
  const {
    oppDetails,
  } = useSelector((store) => store.opportunitiesReducer);
  const [previousPage, setPreviousPage] = useState(false);
  const size = useWindowSize();
  const dispatch = useDispatch();

  const [isNext, setIsNext] = useState(true);
  useEffect(() => {
    if (id) {
      dispatch(getOpportunitiesById(id));
    }
  }, []);
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  useEffect(() => {
    setFooterWidth("50%");
  }, []);
  const handlechange = (e) => {
    const lines = e.target.value.split("\n");
    setComment(lines.map((line) => line.replace("• ", "")).join("\n"));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (comment) {
      const opp = {
        final_comments: comment,
      };
      if (community) {
        dispatch(editOpportunity(id, opp, community));
      } else dispatch(editOpportunity(id, opp));

      if (isNext) {
        setOpenStep7(true);
      } else {
        setOpen(false);
      }
    } else {
      setError(t("opportunity.create.error"));
    }
  };

  useEffect(() => {
    if (Object.keys(oppDetails).length > 0) {
      if(oppDetails.final_comments !== null)
      setComment(oppDetails.final_comments);
    }
  }, [oppDetails]);

  return (
    <div>
      {
        // (loading || editLoading)  ? <Loading/> :
        previousPage ? (
          <CreateOpp_Step5
              id={id}
              community={community}
              setOpen={setOpen}
              setFooterWidth={setFooterWidth}
              previous={true}
            />
        ) :( openStep7 ? (
          <CreateOpp_Step7
            id={id}
            setOpen={setOpen}
            community={community}
            setFooterWidth={setFooterWidth}
          />
        ) : (
          <form className="create_opp_step1" onSubmit={(e) => onSubmit(e)}>
            <div className="create_opp_step7_main_cont">
              <div className="create_opp_step10_cont">
                <div className="step1_cont_header">
                  {t("opportunity.create.comments_text")}*
                </div>

                <textarea
                  className="create_opp_step11_textarea"
                  placeholder={t("opportunity.create.comments")}
                  required
                  value={comment
                    ?.split("\n")
                    .map((line) => `• ${line}`)
                    .join("\n")}
                  onChange={handlechange}
                />
              </div>
            </div>

            {error && <div className="error_msg">{error}</div>}

            <div className="complete_step1_footer">
              <div className="divider_line"></div>
              <div className="complete_step_footer_button">
                <button
                  className={
                    size.width > 768
                      ? "primary_button complete_step_1_next_button"
                      : "primary_button complete_step_2_next_button_resp"
                  }
                  onClick={(e) => { e.preventDefault(); setPreviousPage(true); }}
                >
                  {size.width > 768 ? (
                    t("CompleteProf.complete_your_profile_btn_5")
                  ) : lang == "en" ? (
                    <AiOutlineArrowLeft size="1.5rem" />
                  ) : (
                    <AiOutlineArrowRight size="1.5rem" />
                  )}
                </button>

                <div
                  className={
                    size.width > 768
                      ? "complete_step_right_footer"
                      : "complete_step_right_footer_resp"
                  }
                >
                  <button
                    className={
                      size.width > 500
                        ? "pink_button complete_step_2_complete_button"
                        : "pink_button complete_step_2_next_button_resp"
                    }
                    onClick={() => setIsNext(false)}
                  >
                    {size.width > 500 ? (
                      t("CompleteProf.complete_your_profile_btn_3")
                    ) : (
                      <BiSave size="1.5rem" />
                    )}
                  </button>
                  <button
                    className={
                      size.width > 768
                        ? "primary_button complete_step_2_next_button"
                        : "primary_button complete_step_2_next_button_resp"
                    }
                    onClick={() => setIsNext(true)}
                  >
                    {size.width > 768 ? (
                      t("CompleteProf.complete_your_profile_btn_4")
                    ) : lang == "en" ? (
                      <AiOutlineArrowRight size="1.5rem" />
                    ) : (
                      <AiOutlineArrowLeft size="1.5rem" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        ))
      }
    </div>
  );
}

export default CreateOpp_Step6;
