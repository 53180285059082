import React from "react";
import { useState } from "react";
import {
  AiFillPlusCircle,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineClose,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import "./CreateOpp_Step12.css";

import i18n from "../../../../i18n";
import { useSSR, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useEffect } from "react";

import { BiSave } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { editOpportunity } from "../../../../Redux/opportunities/opportunityActions";
import { getOpportunityQuestions } from "../../../../Redux/OpportunityQuestions/OpportunityQuestionsActions";
import axios from "axios";
import CreateOpp_Step11 from "../Step11/CreateOpp_Step11";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
// Import other JavaScript components if needed

import "react-notifications/lib/notifications.css";

function CreateOpp_Step12({ id, setOpen, setFooterWidth, community }) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [finished, setFinished] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [previousPage, setPreviousPage] = useState(false);
  const { OpportunityQuestions } = useSelector((state) => state.OpportunityQuestionsReducer);
  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);
  const { editLoading, loading } = useSelector(
    (store) => store.opportunitiesReducer
  );

  const size = useWindowSize();
  const dispatch = useDispatch();

  const [isNext, setIsNext] = useState(true);
  const [fields, setFields] = useState([]);

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  useEffect(() => {
    setFooterWidth("100%");
    dispatch(getOpportunityQuestions(id));
  }, []);


  useEffect(() => {
    if (OpportunityQuestions) {
      setFields([])
      OpportunityQuestions?.map((option, index) => {
        setFields((fields) => [...fields, option?.question]);
      })
    }
  }, [OpportunityQuestions]);

  const addInput = () => {
    if (fields.length < 5) {
      setFields((fields) => [...fields, ""]);
    }
  };

  const updateInput = (e, index) => {
    const value = e.target.value;
    const newState = fields.map((obj, i) => {
      // 👇️ if id equals 2, update country property
      if (i === index) {
        return value;
      }

      // 👇️ otherwise return object as is
      return obj;
    });

    setFields(newState);
  };

  const removeInput = (index) => {
    const newfields = [...fields];
    newfields.splice(index, 1);
    setFields(newfields);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (fields) {
      const opp = {
        question: fields,
      };

      const response = axios.put(
        process.env.REACT_APP_API + "/opportunity_questions/" + id,
        opp
      );

      const status = { status: "Ongoing" };
      if (community) {
        dispatch(editOpportunity(id, status, community));
      } else dispatch(editOpportunity(id, status));

      setFinished(true);
    } else {
      setError(t("opportunity.create.error"));
    }
  };

  useEffect(() => {
    if (finished) {
      const notificationMessage = (
        <div>
          Thank you. Your opportunity has been created successfully.<br></br>
          <Link to={`/oppDetail/${id}`} className="view_it_link">View it</Link>
        </div>
      );
      NotificationManager.success(notificationMessage, "", 30000);
      setOpen(false);
    }
  }, [finished]);

  return (
    <>
       {  previousPage ? (
          <CreateOpp_Step11
            id={id}
            community={community}
            setOpen={setOpen}
            setFooterWidth={setFooterWidth}
            previous={true}
          />
        ):(<div className="step12_main_container">
        {
          //  (loading || editLoading)  ? <Loading/> :
          !finished && (
            <form className="create_opp_step1" onSubmit={(e) => onSubmit(e)}>
              <div className="create_opp_step7_main_cont">
                <div className="create_opp_step10_cont">
                  <div className="step1_cont_header">
                    {t("opportunity.create.qst_text")}
                    <p className="create_opp_note_dark">
                      {t("opportunity.create.qst_note")}
                    </p>
                  </div>

                  <div className="complete_step2_main">
                    <div className="complete_step2_add" onClick={addInput}>
                      {" "}
                      <AiFillPlusCircle size="2rem" />{" "}
                      <span> {t("opportunity.create.add_qst")}</span>
                    </div>
                    <div className="create_opp_all_input">
                      {fields.map((c, index) => {
                        return (
                          <div id={index} className="create_opp_input_cont">
                            <input
                              placeholder={t("opportunity.create.qst")}
                              value={c}
                              required
                              className="create_opp_input"
                              key={index}
                              type="text"
                              onChange={(e) => updateInput(e, index)}
                            ></input>
                            <AiOutlineClose
                              className="complete_step2_close"
                              size="1.2rem"
                              onClick={() => removeInput(index)}
                            />
                          </div>
                        );
                      })}
                      {
                        (fields.length > 0) && <div className="create_opp_note_dark italic-text">
                          {t("opportunity.create.question_msg1")}  <br /> {t("opportunity.create.question_msg2")}
                        </div>
                      }
                    </div>

                  </div>
                </div>
              </div>

              {error && <div className="error_msg">{error}</div>}

              <div className="complete_step1_footer">
                <div className="divider_line"></div>
                <div className="complete_step_footer_button">
                  <button
                    className={
                      size.width > 768
                        ? "primary_button complete_step_1_next_button"
                        : "primary_button complete_step_2_next_button_resp"
                    }
                    onClick={(e) => { e.preventDefault(); setPreviousPage(true); }}
                  >
                    {size.width > 768 ? (
                      t("CompleteProf.complete_your_profile_btn_5")
                    ) : lang == "en" ? (
                      <AiOutlineArrowLeft size="1.5rem" />
                    ) : (
                      <AiOutlineArrowRight size="1.5rem" />
                    )}
                  </button>

                  <div
                    className={
                      size.width > 768
                        ? "complete_step_right_footer"
                        : "complete_step_right_footer_resp"
                    }
                  >
                    <button
                      className={
                        size.width > 768
                          ? "primary_button complete_step_2_next_button"
                          : "primary_button complete_step_2_next_button_resp"
                      }
                      onClick={() => setIsNext(true)}
                    >
                      {size.width > 768 ? (
                        t("CompleteProf.complete_your_profile_btn_6")
                      ) : lang == "en" ? (
                        <AiOutlineArrowRight size="1.5rem" />
                      ) : (
                        <AiOutlineArrowLeft size="1.5rem" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )
        }
      </div>)}
    </>
  );
}

export default CreateOpp_Step12;
