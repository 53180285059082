import React, { useEffect, useState } from "react";
import CompleteProfile from "../../CompleteProfile";
import i18n from "../../../../i18n";
import "./CompleteOrg_Step2.css";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { BiSave } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  getuserinfo,
  updateUserDisc,
} from "../../../../Redux/user/userActions";
import Loading from "../../../../components/Loading/Loading";

function CompleteOrg_Step2() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [orgDisc, setOrgDisc] = useState("");
  const [isNext, setIsNext] = useState(true);
  const size = useWindowSize();

  const token = localStorage.getItem("token");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    dispatch(getuserinfo(token));
  }, []);

  const { about, loading } = useSelector((store) => store.userReducer.userinfo);

  useEffect(() => {
    setOrgDisc(about);
  }, [about]);

  //submit function
  const onSubmit = (e) => {
    e.preventDefault();
    if (orgDisc) {
      const step = localStorage.getItem("step");
      if (step == 1) {
        localStorage.setItem("step", 2);
      }

      if (isNext) {
        dispatch(updateUserDisc(orgDisc, token, navigate, 3));
      } else {
        dispatch(updateUserDisc(orgDisc, token, navigate, 0));
      }

      dispatch(updateUserDisc(orgDisc, token, navigate, 3));
    }
  };

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  return (
    <div className="complete_profile_step2_main_cont">
      <CompleteProfile
        header={t("CompleteProf.complete_your_profile_step9")}
        subtitle={t("CompleteProf.complete_your_profile_org_step2_subtitle")}
        steps={2}
        body={
          loading ? (
            <Loading />
          ) : (
            <form
              className="complete_step2_main_body"
              onSubmit={(e) => onSubmit(e)}
            >
              <div className="complete_step6_all_avail">
                <textarea
                  className="complete_step9_textarea"
                  placeholder="Tell us more about your organization"
                  required
                  value={orgDisc}
                  onChange={(e) => setOrgDisc(e.target.value)}
                />
              </div>
              <div className="complete_step1_footer">
                <div className="divider_line"></div>
                <div className="complete_step_footer_button">
                  <button
                    className={
                      size.width > 768
                        ? "primary_button complete_step_1_next_button"
                        : "primary_button complete_step_2_next_button_resp"
                    }
                    type="button"
                    onClick={() => navigate("/completeProfile/org/step1")}
                  >
                    {size.width > 768 ? (
                      t("CompleteProf.complete_your_profile_btn_5")
                    ) : lang == "en" ? (
                      <AiOutlineArrowLeft size="1.5rem" />
                    ) : (
                      <AiOutlineArrowRight size="1.5rem" />
                    )}
                  </button>

                  <div
                    className={
                      size.width > 768
                        ? "complete_step_right_footer"
                        : "complete_step_right_footer_resp"
                    }
                  >
                    <button
                      className={
                        size.width > 500
                          ? "pink_button complete_step_2_complete_button"
                          : "pink_button complete_step_2_next_button_resp"
                      }
                      onClick={() => setIsNext(false)}
                    >
                      {size.width > 500 ? (
                        t("CompleteProf.complete_your_profile_btn_3")
                      ) : (
                        <BiSave size="1.5rem" />
                      )}
                    </button>
                    <button
                      className={
                        size.width > 768
                          ? "primary_button complete_step_2_next_button"
                          : "primary_button complete_step_2_next_button_resp"
                      }
                      onClick={() => setIsNext(true)}
                    >
                      {size.width > 768 ? (
                        t("CompleteProf.complete_your_profile_btn_4")
                      ) : lang == "en" ? (
                        <AiOutlineArrowRight size="1.5rem" />
                      ) : (
                        <AiOutlineArrowLeft size="1.5rem" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )
        }
        footer={
          <div
            className={
              lang == "en"
                ? "complete_profile_footer_slider_green"
                : "complete_profile_footer_slider_green complete_profile_footer_slider_green_ar"
            }
            style={{ width: "40%" }}
          ></div>
        }
      />
    </div>
  );
}

export default CompleteOrg_Step2;
