import React, { useEffect, useState } from "react";
import "./MyOpportunities.css";
//translation
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getuserinfo } from "../../../Redux/user/userActions";
import {
  getOpportunitiesByStatus,
  getFavoritesOpportunities,
} from "../../../Redux/opportunities/opportunityActions";
import OppCard from "../../../components/OppCard/OppCard";
import org from "../../../assets/images/opportunity/organization.jpg";
import Loading from "../../../components/Loading/Loading";

function MyOpportunities() {
  //translation
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [activeBtn, setActiveBtn] = useState("pending");
  const { user } = useSelector((store) => store.userReducer);
  const { oppByStatus, favOpp, loading } = useSelector(
    (store) => store.opportunitiesReducer
  );
  const dispatch = useDispatch();
  const [userType, setUserType] = useState(0);
  const [favoritesOpportunitiesArray, setFavoritesOpportunitiesArray] = useState([]);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    dispatch(getuserinfo());
    dispatch(getOpportunitiesByStatus("Pending"));
    dispatch(getFavoritesOpportunities());
  }, []);

  useEffect(() => {
    if (favOpp.length > 0) {
      favOpp.map((favorite) => {
        if (!favoritesOpportunitiesArray.includes(favorite?.opportunity_id)) {
          setFavoritesOpportunitiesArray((favoritesOpportunitiesArray) => [
            ...favoritesOpportunitiesArray,
            favorite?.opportunity_id,
          ]);
        }
      });
    }else{
      setFavoritesOpportunitiesArray([])
    }
  }, [favOpp]);

  useEffect(() => {
    if (user != undefined) {
      setUserType(user.role);
    }
  }, [user]);

  const handleGetOpportunitiesByStatus = (status) => {
    dispatch(getOpportunitiesByStatus(status));
  };

  return (
    <div className="my_opp_main_cont">
      <div
        className={
          lang == "ar"
            ? "my_opportunities_status_container_ar"
            : "my_opportunities_status_container"
        }
      >
        <div className="opportunities_status">
          <button
            className={
              activeBtn == "pending"
                ? "opportunities_status_btn opportunities_status_btn_active"
                : "opportunities_status_btn"
            }
            onClick={() => {
              setActiveBtn("pending");
              handleGetOpportunitiesByStatus("Pending");
            }}
          >
            {t("opportunity.pending")}
          </button>
          <button
            className={
              activeBtn == "confirmed"
                ? "opportunities_status_btn opportunities_status_btn_active"
                : "opportunities_status_btn"
            }
            onClick={() => {
              setActiveBtn("confirmed");
              handleGetOpportunitiesByStatus("Accepted");
            }}
          >
            {t("opportunity.confirmed")}
          </button>
          <button
            className={
              activeBtn == "check_in_out"
                ? "opportunities_status_btn opportunities_status_btn_active"
                : "opportunities_status_btn"
            }
            onClick={() => {
              setActiveBtn("check_in_out");
              handleGetOpportunitiesByStatus("InOut");
            }}
          >
            {t("opportunity.check_in_out")}
          </button>
          <button
            className={
              activeBtn == "saved"
                ? "opportunities_status_btn opportunities_status_btn_active"
                : "opportunities_status_btn"
            }
            onClick={() => {
              setActiveBtn("saved");
              dispatch(getFavoritesOpportunities());
            }}
          >
            {t("opportunity.saved")}
          </button>
          <button
            className={
              activeBtn == "history"
                ? "opportunities_status_btn opportunities_status_btn_active"
                : "opportunities_status_btn"
            }
            onClick={() => {
              setActiveBtn("history");
              handleGetOpportunitiesByStatus("History");
            }}
          >
            {t("opportunity.history")}
          </button>
        </div>
      </div>

      <div className="my_opportunities_container">
        {loading ? (
          <Loading />
        ) : (
          <div className="all_opportunities">
            {activeBtn != "saved"
              ? oppByStatus &&
                oppByStatus?.length > 0 &&
                oppByStatus?.map((oppprtunityData, index) => {
                  //oppuritnity flag
                  let flags = [];
                  if (oppprtunityData?.opportunity.is_call_to_action)
                    flags = [...flags, "calltoaction"];
                  if (
                    oppprtunityData?.opportunity?.wheelchair_accessible === true
                  )
                    flags = [...flags, "handicap"];
                  if (
                    oppprtunityData?.opportunity?.transporation === "Provided"
                  )
                    flags = [...flags, "transport"];
                  if (oppprtunityData?.opportunity?.food === "Provided")
                    flags = [...flags, "food"];
                    let isSaved = false ;
                  if(favoritesOpportunitiesArray.includes(oppprtunityData?.opportunity.id)){
                    isSaved = true
                  }
                  return (
                    <OppCard
                      id={oppprtunityData.opportunity?.id}
                      flags={flags}
                      image={oppprtunityData.opportunity?.img_url}
                      title={oppprtunityData.opportunity?.title}
                      respond={oppprtunityData.opportunity?.respond_time}
                      location={
                        lang == "ar"
                          ? oppprtunityData.opportunity?.district?.name_ar
                          : oppprtunityData.opportunity?.district?.name
                      }
                      deadline={new Date(
                        oppprtunityData.opportunity?.deadline_date
                      ).toLocaleDateString()}
                      spots={
                        oppprtunityData.opportunity?.nb_of_spots -
                        oppprtunityData.opportunity?.nb_of_volunteers
                      }
                      impact={oppprtunityData.opportunity?.tasks_required}
                      points={
                        oppprtunityData.opportunity?.type === "Time-based"
                          ? 10
                          : 20
                      }
                      isSaved = {isSaved}
                      status={oppprtunityData?.status}
                      key={index}
                      requestId={oppprtunityData?.id}
                    />
                  );
                })
              : favOpp &&
                favOpp?.length > 0 &&
                favOpp?.map((oppprtunityData, index) => {
                  //oppuritnity flag
                  let flags = [];
                  if (oppprtunityData?.opportunity.is_call_to_action)
                    flags = [...flags, "calltoaction"];
                  if (
                    oppprtunityData?.opportunity.wheelchair_accessible === true
                  )
                    flags = [...flags, "handicap"];
                  if (oppprtunityData?.opportunity.transporation === "Provided")
                    flags = [...flags, "transport"];
                  if (oppprtunityData?.opportunity.food === "Provided")
                    flags = [...flags, "food"];
                    let isSaved = false ;
                    if(favoritesOpportunitiesArray.includes(oppprtunityData?.opportunity.id)){
                      isSaved = true
                    }
                  return (
                    <OppCard
                      id={oppprtunityData?.opportunity.id}
                      flags={flags}
                      image={oppprtunityData?.opportunity.img_url}
                      title={oppprtunityData.opportunity.title}
                      respond={oppprtunityData.opportunity.respond_time}
                      location={
                        lang == "ar"
                          ? oppprtunityData.opportunity.name_ar
                          : oppprtunityData.opportunity.name
                      }
                      deadline={new Date(
                        oppprtunityData.opportunity.deadline_date
                      ).toLocaleDateString()}
                      spots={
                        oppprtunityData.opportunity.nb_of_spots -
                        oppprtunityData.opportunity.nb_of_volunteers
                      }
                      isSaved = {isSaved}
                      impact={oppprtunityData.opportunity.tasks_required}
                      points={
                        oppprtunityData.opportunity.type === "Time-based"
                          ? 10
                          : 20
                      }
                      status="Saved"
                      key={index}
                      opportunityId={oppprtunityData.opportunity.opportunity_id}
                    />
                  );
                })}
          </div>
        )}
      </div>
    </div>
  );
}
export default MyOpportunities;
