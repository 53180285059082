import React, { useEffect, useState } from "react";
import "./OppRequestsFilters.css";
import { FaFilter } from "react-icons/fa";
import { VscDebugRestart } from "react-icons/vsc";
import { IoIosArrowDown } from "react-icons/io";
import { AiFillCloseCircle } from "react-icons/ai";
//tranlsation
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

//date picker
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import { useDispatch } from "react-redux";
import { getOppRequestsbyfilters } from "../../Redux/OppRequests/OppRequestsActions";
import { useSelector } from "react-redux";
import axios from "axios";
import { getOrgOpp } from "../../Redux/opportunities/opportunityActions";

function OppRequestsFilters(props) {
  //translation
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [lang, setLang] = useState(i18n.language);

  const today = new Date();
  const date = new Date(today);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const defaultValue = {
    year: year,
    month: month + 1,
    day: day,
  };

  const [selectedOppTitle, setOppTitle] = useState(null);
  const [selectedStatus, setStatus] = useState(null);
  const [selectedType, setType] = useState(null);
  const [Agesmap, setAgesmap] = useState(null);
  const [showopptype, setShowopptype] = useState(false);
  const [showLoc, setShowLoc] = useState(false);
  const [showCt, setShowCt] = useState(false);
  const [showNat, setShowNat] = useState(false);
  const [showAge, setShowAge] = useState(false);
  const [Country, setCountry] = useState();
  const [selectedAge, setAge] = useState(null);
  const [Nationality, setNationality] = useState();
  const [selectedNationality, setselectedNationality] = useState(null);
  const [location, setLocation] = useState();
  const [selectedcountry, setSelectedcountry] = useState(null);
  const [opptitle, setopptitle] = useState();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const { orgOpp } = useSelector((store) => store.opportunitiesReducer);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    dispatch(
      getOppRequestsbyfilters({
        selectedStatus,
        selectedOppTitle,
        selectedAge,
        selectedNationality,
        selectedcountry,
        selectedLocation,
        selectedType,
      })
    );
  }, [
    selectedStatus,
    selectedOppTitle,
    selectedAge,
    selectedNationality,
    selectedcountry,
    selectedLocation,
    selectedType,
  ]);

  const clearAll = () => {
    setStatus(null);
    setOppTitle(null);
    setAge(null);
    setselectedNationality(null);
    setSelectedcountry(null);
    setSelectedLocation(null);

    setShowopptype(false);
    setShowCt(false);
    setShowLoc(false);
    setShowNat(false);
    setShowAge(false);
    setopptitle(false);
  };
  const getCountry = async () => {
    const response = await axios.get(process.env.REACT_APP_API + "/countries");
    setCountry(response.data.data);
  };
  const getNationality = async () => {
    const response = await axios.get(
      process.env.REACT_APP_API + "/nationalities"
    );
    setNationality(response.data.data);
  };
  const getAges = async () => {
    const response = await axios.get(
      process.env.REACT_APP_API + "/nationalities"
    );
    setAgesmap(response.data.data);
  };
  const getLocation = async () => {
    const response = await axios.get(process.env.REACT_APP_API + "/governates");
    setLocation(response.data.data);
  };

  useEffect(() => {
    getCountry();
    getNationality();
    getAges();
    getLocation();
    dispatch(getOrgOpp());
  }, []);

  return (
    <div className="filters_main_cont_1">
      <div className="filters_header_1">
        <div className="filter_title_1">
          <FaFilter className="filter_main_icon" />
          {t("opportunity.filter.title")}
        </div>
        <div className="filters_clear" onClick={() => clearAll()}>
          <VscDebugRestart className="filter_clear_icon" />{" "}
          {t("opportunity.filter.clear")}
        </div>
        <div className="filters_close" onClick={props.close}>
          <AiFillCloseCircle size="2rem" className="filter_clear_icon" />
        </div>
      </div>
      <div className="filers_main_underline"></div>
      <div className="filters_container">
        <div className="filter_sub_cont_1">
          <div
            className="filter_sub_cont_header"
            onClick={() => setShowopptype(!showopptype)}
          >
            <div className="filter_sub_cont_title">
              {t("opprequests.filter_7")}
            </div>
            <IoIosArrowDown
              size="1.2rem"
              className={
                !showopptype
                  ? "filter_icon_arrow"
                  : "filter_icon_arrow rotate_arrow"
              }
            />
          </div>
          {showopptype && (
            <div className="filter_sub_cont_list">
              <div className="filter_list_check">
                <input
                  type="radio"
                  id="time"
                  name="time"
                  value="Time-based"
                  className="filter_checkbox"
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                />
                <label for="time" className="filter_list_comp">
                  {" "}
                  Time-based
                </label>
              </div>
              <div className="filter_list_check">
                <input
                  type="radio"
                  id="skill"
                  name="time"
                  value="Skill-based"
                  className="filter_checkbox"
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                />
                <label for="time" className="filter_list_comp">
                  {" "}
                  Skill-based
                </label>
              </div>
            </div>
          )}
        </div>

        <div className="filter_sub_cont_1">
          <div
            className="filter_sub_cont_header"
            onClick={() => setShowCt(!showCt)}
          >
            <div className="filter_sub_cont_title">
              {t("opprequests.filter_5")}
            </div>
            <IoIosArrowDown
              size="1.2rem"
              className={
                !showNat
                  ? "filter_icon_arrow"
                  : "filter_icon_arrow rotate_arrow"
              }
            />
          </div>
          {showCt && (
            <div className="filter_sub_cont_list">
              {Country &&
                Country?.map((ct, i) => {
                  return (
                    <div className="filter_list_check">
                      <input
                        type="radio"
                        id={"ct" + i}
                        name="ct"
                        value="ct"
                        className="filter_checkbox"
                        onChange={() => {
                          setSelectedcountry(ct.id);
                        }}
                      />
                      <label for={"ct"+i} className="filter_list_comp filter_radio">
                        {lang == "en" ? ct.name : ct.name_ar}
                      </label>
                    </div>
                  );
                })}
            </div>
          )}
        </div>

        <div className="filter_sub_cont_1">
          <div
            className="filter_sub_cont_header"
            onClick={() => setShowNat(!showNat)}
          >
            <div className="filter_sub_cont_title">
              {t("opprequests.filter_4")}
            </div>
            <IoIosArrowDown
              size="1.2rem"
              className={
                !showNat
                  ? "filter_icon_arrow"
                  : "filter_icon_arrow rotate_arrow"
              }
            />
          </div>
          {showNat && (
            <div className="filter_sub_cont_list">
              {Nationality &&
                Nationality?.map((nt, i) => {
                  return (
                    <div className="filter_list_check">
                      <input
                        type="radio"
                        id={"sec" + i}
                        name="sec"
                        value="sec"
                        className="filter_checkbox"
                      />
                      <label
                        for={"sec"+i}
                        className="filter_list_comp filter_radio"
                      >
                        {lang == "en" ? nt.name : nt.name_ar}
                      </label>
                    </div>
                  );
                })}
            </div>
          )}
        </div>

        <div className="filter_sub_cont_1">
          <div
            className="filter_sub_cont_header"
            onClick={() => setShowAge(!showAge)}
          >
            <div className="filter_sub_cont_title">
              {t("opprequests.filter_3")}
            </div>
            <IoIosArrowDown
              size="1.2rem"
              className={
                !showAge
                  ? "filter_icon_arrow"
                  : "filter_icon_arrow rotate_arrow"
              }
            />
          </div>
          {showAge && (
            <div className="filter_sub_cont_list">
              <div className="filter_list_check">
                <input
                  type="radio"
                  id="1"
                  value="Under 18"
                  name="age"
                  className="filter_checkbox"
                  onChange={(e) => {
                    setAge(e.target.value);
                  }}
                />
                <label className="filter_list_comp filter_radio">
                  {t("opprequests.opprequests_7")}
                </label>
              </div>
              <div className="filter_list_check">
                <input
                  type="radio"
                  id="2"
                  name="age"
                  value="18-25"
                  className="filter_checkbox"
                  onChange={(e) => {
                    setAge(e.target.value);
                  }}
                />
                <label className="filter_list_comp filter_radio">
                  {t("opprequests.opprequests_8")}
                </label>
              </div>
              <div className="filter_list_check">
                <input
                  type="radio"
                  id="3"
                  name="age"
                  value="25-35"
                  className="filter_checkbox"
                  onChange={(e) => {
                    setAge(e.target.value);
                  }}
                />
                <label className="filter_list_comp filter_radio">
                  {t("opprequests.opprequests_9")}
                </label>
              </div>
              <div className="filter_list_check">
                <input
                  type="radio"
                  id="4"
                  name="age"
                  value="Above 35 years old"
                  className="filter_checkbox"
                  onChange={(e) => {
                    setAge(e.target.value);
                  }}
                />
                <label className="filter_list_comp filter_radio">
                  {t("opprequests.opprequests_10")}
                </label>
              </div>

              <div className="filter_list_check"></div>
            </div>
          )}
        </div>
        <div className="filter_sub_cont_1">
          <div
            className="filter_sub_cont_header"
            onClick={() => setopptitle(!opptitle)}
          >
            <div className="filter_sub_cont_title">
              {t("opprequests.filter_2")}
            </div>
            <IoIosArrowDown
              size="1.2rem"
              className={
                !opptitle
                  ? "filter_icon_arrow"
                  : "filter_icon_arrow rotate_arrow"
              }
            />
          </div>
          {opptitle && (
            <div className="filter_sub_cont_list">
              {orgOpp &&
                orgOpp?.map((org, i) => {
                  return (
                    <div className="filter_list_check">
                      <input
                        type="radio"
                        id={"org" + i}
                        name="org"
                        value="org"
                        className="filter_checkbox"
                        onChange={() => {
                          setOppTitle(org.title);
                        }}
                      />
                      <label
                        for={"org"+i}
                        className="filter_list_comp filter_radio"
                      >
                        {lang == "en" ? org.title : org.name_ar}
                      </label>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        {/* <div className="filter_sub_cont_1">
          <div
            className="filter_sub_cont_header"
            onClick={() => setShowLoc(!showLoc)}
          >
            <div className="filter_sub_cont_title">
              {t("opprequests.filter_6")}
            </div>
            <IoIosArrowDown
              size="1.2rem"
              className={
                !showLoc
                  ? "filter_icon_arrow"
                  : "filter_icon_arrow rotate_arrow"
              }
            />
          </div>
          {showLoc && (
            <div className="filter_sub_cont_list">
              {location &&
                location?.map((loc, i) => {
                  return (
                    <div className="filter_list_check">
                      <input
                        type="radio"
                        id={"imp" + i}
                        name="imp"
                        value="imp"
                        className="filter_checkbox"
                        // onChange={(e) => {
                        //   setSelectedLocation(loc.id, i);
                        // }}
                      />

                      <label
                        for="imp"
                        className="filter_list_comp filter_radio"
                      >
                        {lang == "en" ? loc.name : loc.name_ar}
                      </label>
                    </div>
                  );
                })}
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
}

export default OppRequestsFilters;
