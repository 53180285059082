import React, { useEffect, useState } from "react";
import i18n from "../../../../i18n";
import { useTranslation } from "react-i18next";
import "./Apply_Step7.css";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import apply_img_step7 from "../../../../assets/images/opportunity/apply/apply_step7.png";
import appointment from "../../../../assets/images/opportunity/apply/appointment.png";
import moment from "moment";
import Apply_Step8 from "../Apply_Step8/Apply_Step8";
import Apply_Step5 from "../Apply_Step5/Apply_Step5";
import { getOpportunitiesById } from "../../../../Redux/opportunities/opportunityActions";
import { useDispatch, useSelector } from "react-redux";

function Apply_Step7({ setOpen, open, data, setFooterWidth , opportunityId ,previousFirstPage , openOutOfArea}) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [openApplyPopUpStep, setOpenApplyPopUpStep] = useState(false);
  const [oppData, setOppData] = useState("");
  const size = useWindowSize();
  const dispatch = useDispatch();
  const [previous, setPrevious] = useState(false);
  const { oppDetails } = useSelector((state) => state.opportunitiesReducer);
  const [opprtunityDetails, setOpprtunityDetails] = useState({});

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    setPrevious(false);
    dispatch(getOpportunitiesById(data.opportunityId));
    setOppData({ ...data });
    setFooterWidth("87.5%");
  }, []);

  useEffect(() => {
    if (oppDetails != {}) {
      setOpprtunityDetails(oppDetails);
    }
  }, [oppDetails]);

  const onSubmit = (e) => {
    e.preventDefault();
    setOpenApplyPopUpStep(true);
  };

  return (
    <>

      {previous && (
        <Apply_Step5
          setOpen={setOpen}
          open={open}
          data={data}
          previousFirstPage = {previousFirstPage}
          openOutOfArea={openOutOfArea}
          opportunityId = {opportunityId}
          setFooterWidth={setFooterWidth}
        />
      )}

      {!previous && !openApplyPopUpStep ? (
        <form className="apply_step7" onSubmit={(e) => onSubmit(e)}>
          <img className="apply_img" src={apply_img_step7} />
          <div className="avalability_content">
            <div className="dates_div">
              <img className="appointment_img" src={appointment} />
              <div className="dates_div_cont">
                <div> {t("opportunity.apply_popup.from")}</div>
                <div className="font-14">
                  {" "}
                  {moment(opprtunityDetails.start_date).format("DD/MMM/YYYY")}
                </div>
              </div>
            </div>
            <div className="dates_div">
              <img className="appointment_img" src={appointment} />
              <div className="dates_div_cont">
                <div> {t("opportunity.apply_popup.to")}</div>
                <div className="font-14">
                  {" "}
                  {moment(opprtunityDetails.end_date).format("DD/MMM/YYYY")}
                </div>
              </div>
            </div>
            <div
              className="grey_vertical_border"
              style={{ margin: "0 10px" }}
            ></div>
            <div className="frequency_div">
              <div> {t("opportunity.apply_popup.frequency") + ": "}</div>
              <div> {opprtunityDetails.frequency}</div>
            </div>
          </div>

          <div className="dotter_spacer" />
          <div class="complete_step_footer_button">

          <div className="div_buttons div_buttons_left">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setPrevious(true);
                }}
                className={
                  size.width > 768
                    ? "apply_primary_button app_step2_apply_later_button gray-btn"
                    : "apply_primary_button app_step2_apply_later_button_resp gray-btn"
                }
              >
                {t("opportunity.apply_popup.previous")}
              </button>
            </div>
            <div className="div_buttons">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(false);
                }}
                className={
                  size.width > 768
                    ? "apply_primary_button app_step7_apply_later_button"
                    : "apply_primary_button app_step7_apply_later_button_resp"
                }
              >
                {t("opportunity.apply_popup.apply_later")}
              </button>
              <button
                type="submit"
                className={
                  size.width > 768
                    ? "primary_button app_step7_next_button"
                    : "primary_button app_step7_next_button_resp"
                }
              >
                {size.width > 768 ? (
                  t("opportunity.apply_popup.next")
                ) : lang == "en" ? (
                  <AiOutlineArrowRight size="1.5rem" />
                ) : (
                  <AiOutlineArrowLeft size="1.5rem" />
                )}
              </button>
            </div>
          </div>
        </form>
      ) : (
        !previous && <Apply_Step8
          setOpen={setOpen}
          open={open}
          data={oppData}
          previousFirstPage = {previousFirstPage}
          openOutOfArea={openOutOfArea}
          opportunityId = {opportunityId}
          setFooterWidth={setFooterWidth}
        />
      )}
    </>
  );
}

export default Apply_Step7;
