import React from "react";
import "./Presscarousel.css";

function Presscarousel(props) {
  return (
    <div className="press_carousel_main">
      <a href={props.link} target="_blank">   <div className="first_container">
        <img src={props.image} className="image_press" />
        <div className="white_label">
          <div className="press_name">{props.name}</div>

          <div className="green_hr"></div>
        </div>
      </div></a>   
    </div>
  );
}

export default Presscarousel;
