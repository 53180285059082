import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications_total: [],
  notifications: [],
  noti: [],
  expired: [],
  loading: false,
  total_loading: false,
  error: "",
};

const NotificationsSlice = createSlice({
  name: "Notifications",
  initialState,
  reducers: {
    fetchTotalNotificationsRequest(state) {
      state.total_loading = true;
      state.notifications_total = [];
      state.error = "";
    },

    fetchTotalNotificationsSuccess(state, action) {
      state.total_loading = false;
      state.notifications_total = action.payload;
      state.error = "";
    },

    fetchTotalNotificationsFail(state, action) {
      state.total_loading = false;
      state.notifications_total = [];
      state.error = action.payload;
    },

    fetchAllUserNotificationsRequest(state) {
      state.loading = true;
      state.notifications = [];
      state.error = "";
    },

    fetchAllUserNotificationsSuccess(state, action) {
      state.loading = false;
      state.notifications = action.payload;
      state.error = "";
    },
    fetchAllUserNotificationsFail(state, action) {
      state.loading = false;
      state.notifications = [];
      state.error = action.payload;
    },
    fetchallusernotfrequest(state) {
      state.loading = true;
      state.noti = [];
      state.error = "";
    },
    fetchallusernotfsuccess(state, action) {
      state.loading = false;
      state.noti = action.payload;
      state.error = "";
    },
    fetchallusernotffail(state, action) {
      state.loading = false;
      state.noti = [];
      state.error = action.payload;
    },
    fetchexpirednotfrequest(state) {
      state.loading = true;
      state.expired = [];
      state.error = "";
    },
    fetchexpirednotfsuccess(state, action) {
      state.loading = false;
      state.expired = action.payload;
      state.error = "";
    },
    fetchexpirednotffail(state, action) {
      state.loading = false;
      state.expired = [];
      state.error = action.payload;
    },
  },
});

export const NotificationsAction = NotificationsSlice.actions;
export default NotificationsSlice;
