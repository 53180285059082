import React from "react";
import { FaLinkedin } from "react-icons/fa";
import "./Meettheteam.css";
function Meettheteam(props) {
  return (
    <div className="Main_meettheteam_section">
      <div className="title_mtt">{props.title}</div>
      <div className="team_name"> {props.name}</div>

      <div class="flip-container">
        <div class="flipper">
          <div class="front">
            <img className="team_photo" src={props.photo} />
          </div>
          <div class="back">
            {props.brief_bio}
          </div>
        </div>
      </div>



      <a href={props.link} target="_blank">
        <FaLinkedin size={"2rem"} className="team_logo" />{" "}
      </a>
      {/* <span className="team_link">{props.link}</span> */}
      <div className="team_content">{props.content}</div>
    </div>
  );
}

export default Meettheteam;
