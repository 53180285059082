import React from "react";
import YouTube from "react-youtube";
function VideoPlayer(props) {
  const { videoId } = props;
  const opts = {
    height: "400",
    width: "700",
    playerVars: {
      autoplay: 1,
      controls: 0,
      modestbranding: 1,
      showinfo: 0,
      iv_load_policy: 3,
      fs: 0,
      disablekb: 1,
    },
  };
  return (
    <div>
      <YouTube videoId={videoId} opts={opts} />
    </div>
  );
}

export default VideoPlayer;
