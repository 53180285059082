import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import i18n from "../../../../i18n";
import CompleteProfile from "../../CompleteProfile";
import "./Complete_Step7.css";
import Select from "react-select";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import {
  getuserinfo,
  updateUserinfo,
} from "../../../../Redux/user/userActions";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import { DateObject } from "react-multi-date-picker";
import Loading from "../../../../components/Loading/Loading";

function Complete_Step7() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const navigate = useNavigate();
  const size = useWindowSize();
  const dispatch = useDispatch();
  const [isNext, setIsNext] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { userinfo, loading } = useSelector((store) => store.userReducer);
  const [error, setError] = useState(null);
  const [defaultDate, setDefaultDate] = useState(null);
  const [anyenddate, setanyenddate] = useState(false);
  const [openfirstcal, setopenfirstcal] = useState(false);
  const [anydate, setAnydate] = useState("");
  const token = localStorage.getItem("token");

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const allDates = [
    {
      label: lang == "en" ? "Any start date" : "أي تاريخ بدء",
      value: "Any Date",
      id: 1,
    },

    {
      label:
        lang == "en"
          ? "I'd like to select my preferred dates"
          : "أود تحديد التواريخ المفضلة لدي",
      value: "I'd like to select my preferred dates",
      id: 2,
    },
  ];

  const handleCheck = (e, radio) => {
    setAnydate(e.target.value);
    setanyenddate(true);
  };

  useEffect(() => {
    dispatch(getuserinfo(token));
    const date = new Date();
    const defaultDat = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
    setDefaultDate(defaultDat);
  }, []);

  const renderCustomInput1 = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      className="complete_profile_input complete_date_input"
      placeholder={
        new Date().getDate() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getFullYear()
      }
      value={
        startDate &&
        startDate.day + "-" + startDate.month + "-" + startDate.year
      }
      onChange={setStartDate}
    />
  );
  const renderCustomInput2 = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      className="complete_profile_input complete_date_input"
      placeholder={
        new Date().getDate() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getFullYear()
      }
      value={endDate && endDate.day + "-" + endDate.month + "-" + endDate.year}
      onChange={setEndDate}
    />
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    if (anydate == "Any Date") {
      const ending_date = new Date();
      if(endDate){
        ending_date.setDate(endDate.day);
        ending_date.setMonth(endDate.month);
        ending_date.setFullYear(endDate.year);
      }
      const user = {
        any_date: true,
        date_from: null,
        date_to: openfirstcal ? ending_date : null,
      };
      const step = localStorage.getItem("step");
      if (step == 6) {
        localStorage.setItem("step", 7);
      }
      if (isNext) dispatch(updateUserinfo(token, user, navigate, 8));
      else dispatch(updateUserinfo(token, user, navigate, 0));
    } else if (anydate == "I'd like to select my preferred dates") {
      if (endDate && startDate) {
        setError(null);

        const starting_date = new Date();
        starting_date.setDate(startDate.day);
        starting_date.setMonth(startDate.month);
        starting_date.setFullYear(startDate.year);
        const ending_date = new Date();
        ending_date.setDate(endDate.day);
        ending_date.setMonth(endDate.month);
        ending_date.setFullYear(endDate.year);
        if (startDate && endDate) {
          const data = {
            any_date: false,
            date_from: starting_date,
            date_to: ending_date,
          };
          const step = localStorage.getItem("step");
          if (step == 6) {
            localStorage.setItem("step", 7);
          }
          if (isNext) dispatch(updateUserinfo(token, data, navigate, 8));
          else dispatch(updateUserinfo(token, data, navigate, 0));
        }
      } else {
        setError("Please fill all required fields");
      }
    }
  };

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  useEffect(() => {
    if (userinfo != undefined) {
    if (userinfo.any_date) {
      if (document.getElementById("dates_avail_radio1"))
        document.getElementById("dates_avail_radio1").checked = true;
      if (userinfo.date_to) {
        var end = new Date(userinfo.date_to);
        const newEnd = {
          day: end.getDate(),
          month: end.getMonth() + 1,
          year: end.getFullYear(),
        };
        setEndDate(newEnd);
        setanyenddate(true);
        setopenfirstcal(true);
      } else {
        setopenfirstcal(false);
      }
      setAnydate(document.getElementById("dates_avail_radio1").value);
    } else if (userinfo.date_from && userinfo.date_to) {
      if (document.getElementById("dates_avail_radio2")) {
        document.getElementById("dates_avail_radio2").checked = true;
        setAnydate(document.getElementById("dates_avail_radio2").value);
      }
      var start = new Date(userinfo.date_from);
      var end = new Date(userinfo.date_to);

      const newstart = {
        day: start.getDate(),
        month: start.getMonth() + 1,
        year: start.getFullYear(),
      };

      const newEnd = {
        day: end.getDate(),
        month: end.getMonth() + 1,
        year: end.getFullYear(),
      };
      setEndDate(newEnd);
      setStartDate(newstart);
    }
    }
  }, [userinfo]);
  return (
    <div className="complete_profile_step1_main_cont">
      <CompleteProfile
        header={t("CompleteProf.complete_your_profile7_1")}
        body={
          loading ? (
            <Loading />
          ) : (
            <form
              className="complete_step3_main_body"
              onSubmit={(e) => handleSubmit(e)}
            >
              <div>
                <div className="complete_step3_header">
                  <p
                    className="complete_input_header"
                    style={{ marginBottom: "1rem" }}
                  >
                    {t("CompleteProf.complete_your_profile7_2")}
                  </p>{" "}
                  <div className="complete_step6_all_avail">
                    {allDates &&
                      allDates.map((avail) => {
                        return (
                          <div className="complete_step6_avail">
                            <input
                              value={avail.value}
                              type="radio"
                              id={"dates_avail_radio" + avail.id}
                              name="date_available"
                              className="avail_radio"
                              required
                              onChange={(e) => handleCheck(e)}
                            />
                            <div className="complete_step4_sector_info">
                              <label
                                className="complete_step4_avail_name"
                                for={"avail_radio" + avail.id}
                              >
                                {" "}
                                {avail.label}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    {anydate == "Any Date" && (
                      <>
                        <div className="complete_step3_header">
                          Would you like to select an End date?
                        </div>
                        <div className="checkboxes_complete_prof">
                          <div className="checkboxes_complete_prof_cont">
                            <input
                              type="radio"
                              id="end_date"
                              name="end_date"
                              value={openfirstcal}
                              checked={openfirstcal ? true : false}
                              className="checkboxes_complete "
                              onChange={() => setopenfirstcal(true)}
                            />
                            <label for="end_date">Yes</label>
                          </div>
                          <div className="checkboxes_complete_prof_cont">
                            <input
                              type="radio"
                              id="end_date"
                              name="end_date"
                              value={!openfirstcal}
                              checked={!openfirstcal ? true : false}
                              className="checkboxes_complete "
                              onChange={() => setopenfirstcal(false)}
                            />
                            <label for="end_date">No</label>
                          </div>
                        </div>
                        {openfirstcal && (
                          <div className="complete_step3_header">
                            <DatePicker
                              id="end_date"
                              colorPrimary="var(--primary)"
                              value={endDate}
                              onChange={setEndDate}
                              renderInput={renderCustomInput2}
                              shouldHighlightWeekends
                              calendarClassName="responsive-calendar datepicker_cont "
                            />
                          </div>
                        )}
                      </>
                    )}
                    {anydate && anydate != "Any Date" && (
                      <div className="complete_step3_header">
                        <DatePicker
                          id="start_date"
                          colorPrimary="var(--primary)"
                          value={startDate}
                          onChange={setStartDate}
                          renderInput={renderCustomInput1}
                          shouldHighlightWeekends
                          calendarClassName="responsive-calendar datepicker_cont "
                          minimumDate={defaultDate}
                        />
                        <br />
                        <DatePicker
                          id="end_date"
                          colorPrimary="var(--primary)"
                          value={endDate}
                          onChange={setEndDate}
                          renderInput={renderCustomInput2}
                          shouldHighlightWeekends
                          calendarClassName="responsive-calendar datepicker_cont "
                          minimumDate={startDate}
                        />
                        {error && <div className="error_msg">{error}</div>}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="complete_step1_footer">
                <div className="divider_line"></div>
                <div className="complete_step_footer_button">
                  <button
                    className={
                      size.width > 768
                        ? "primary_button complete_step_1_next_button"
                        : "primary_button complete_step_2_next_button_resp"
                    }
                    type="button"
                    onClick={() => navigate("/completeProfile/step6")}
                  >
                    {size.width > 768 ? (
                      t("CompleteProf.complete_your_profile_btn_5")
                    ) : lang == "en" ? (
                      <AiOutlineArrowLeft size="1.5rem" />
                    ) : (
                      <AiOutlineArrowRight size="1.5rem" />
                    )}
                  </button>

                  <div
                    className={
                      size.width > 768
                        ? "complete_step_right_footer"
                        : "complete_step_right_footer_resp"
                    }
                  >
                    <button
                      className={
                        size.width > 500
                          ? "pink_button complete_step_2_complete_button"
                          : "pink_button complete_step_2_next_button_resp"
                      }
                      onClick={() => setIsNext(false)}
                    >
                      {size.width > 500 ? (
                        t("CompleteProf.complete_your_profile_btn_3")
                      ) : (
                        <BiSave size="1.5rem" />
                      )}
                    </button>
                    <button
                      className={
                        size.width > 768
                          ? "primary_button complete_step_2_next_button"
                          : "primary_button complete_step_2_next_button_resp"
                      }
                      onClick={() => setIsNext(true)}
                    >
                      {size.width > 768 ? (
                        t("CompleteProf.complete_your_profile_btn_4")
                      ) : lang == "en" ? (
                        <AiOutlineArrowRight size="1.5rem" />
                      ) : (
                        <AiOutlineArrowLeft size="1.5rem" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )
        }
        footer={
          <div
            className={
              lang == "en"
                ? "complete_profile_footer_slider_green"
                : " complete_profile_footer_slider_green complete_profile_footer_slider_green_ar"
            }
            style={{ width: "70%" }}
          ></div>
        }
      />
    </div>
  );
}

export default Complete_Step7;
