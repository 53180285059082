import { React, useEffect, useState } from "react";
import "./MasterClass_Det.css";
import UpperSection from "../../../components/UpperSection/UpperSection";
import i18n from "../../../i18n";
import { useSSR, useTranslation } from "react-i18next";
import play_img from "../../../assets/images/play.svg";
import { GiCheckMark } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
//redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAllEpisodes,
  getVideoProgress,
  getAllVideos,
  createVidProg,
  getQuizUser,
  createUserQuiz,
  finishMaster,
} from "../../../Redux/MasterClass/MasterClassActions";
import ProgressBar from "@ramonak/react-progress-bar";
import { IoIosArrowDown } from "react-icons/io";
import ReactPlayer from "react-player";
import { FaFilter } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import PopUp from "../../../components/PopUp/PopUp";

function MasterClass_Det() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [openQuiz, setOpenQuiz] = useState(false);
  const dispatch = useDispatch();
  const [played, setPlayed] = useState(0);
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const [video, setvideo] = useState(0);
  const [reachVid, setReachVid] = useState();
  const [toOpen, setToOpen] = useState([]);
  const [openDone, setOpenDone] = useState(false);
  const [openEp, setOpenEp] = useState([]);
  const [showFilt, setShowFilt] = useState(true);
  const [quiz, setQuiz] = useState();
  const [masterDone, setMasterDone] = useState(false);
  const [error, setError] = useState([]);
  const [errorToDisplay, setErrorToDisplay] = useState(null);
  const [quizDone, setQuizDone] = useState([]);
  const [arrayOfQuestonsAnswers, setArrayOfQuestonsAnswers] = useState([]);
  const [disabledForm, setDisabledForm] = useState(false);
  const { episodes, VidProgress, nbVid, userQuizes, status } = useSelector(
    (store) => store.masterClassReducer
  );
  const [Completed, setCompleted] = useState(0);

  useEffect(() => {
    dispatch(getVideoProgress());
    dispatch(getAllEpisodes());
    dispatch(getAllVideos());
    dispatch(getQuizUser());
  }, []);


  useEffect(() => {
    console.log('episodesepisodes', episodes);
    if (episodes.length > 0 && VidProgress.length > 0) {
      var lastVid = VidProgress[VidProgress.length - 1];

      var index_Ep = episodes.findIndex(
        (s) => s.id == lastVid?.episode_video?.episode_id
      );

      var index_Vid = episodes[index_Ep].episode_videos.findIndex(
        (s) => s.id == lastVid?.episode_video_id
      );

      if (index_Vid == episodes[index_Ep].episode_videos.length - 1) {
        setQuiz(episodes[index_Ep].episode_quizes);
        setOpenQuiz(true);
        console.log('lengthh    ', episodes[index_Ep].episode_quizes.length)
        if (episodes[index_Ep].episode_quizes.length > 0) {
          if (userQuizes.length > 0 && episodes[index_Ep].episode_quizes.length > 0) {
            if (
              userQuizes.find(
                (s) =>
                  s.episode_quiz_id == episodes[index_Ep].episode_quizes[0].id
              )
            ) {
              setOpenQuiz(false);
              if (episodes[index_Ep + 1])
                setReachVid(episodes[index_Ep + 1]?.episode_videos[0]);
              else {
                setMasterDone(true);
              }
            }
          }
        } else {
          console.log('index_Epindex_Ep', index_Ep)
          setOpenQuiz(false);
          var ep = openEp ? openEp : [];
          ep.push(episodes[index_Ep].id);
          setOpenEp(ep);
          if (episodes[index_Ep + 1])
            setReachVid(episodes[index_Ep + 1]?.episode_videos[0]);
          else {
            setMasterDone(true);
          }
        }
      } else {
        setReachVid(episodes[index_Ep]?.episode_videos[index_Vid + 1]);
      }
    }

    if (VidProgress.length == 0) {
      setReachVid(episodes[0]?.episode_videos[0]);
    }
  }, [episodes, VidProgress, userQuizes]);

  useEffect(() => {
    if (nbVid) {
      if (VidProgress.length > 0 && nbVid > 0) {
        const percentage = (VidProgress.length * 100) / nbVid;
        setCompleted(percentage.toFixed(2));
      }
    }
  }, [nbVid, VidProgress]);

  useEffect(() => {
    if (userQuizes.length > 0) {
      console.log('userQuizesuserQuizesuserQuizesuserQuizes', userQuizes)
      var ep = openEp ? openEp : [];
      var q = quizDone ? quizDone : [];

      for (let i = 0; i < userQuizes.length; i++) {
        console.log('userQuizes[i]userQuizes[i]   ', userQuizes[i])
        ep.push(userQuizes[i].episode_quize.episode_id);

        q.push(userQuizes[i].episode_quiz_id);
      }

      setQuizDone(q);
      setOpenEp(ep);
    }
  }, [userQuizes]);
  useEffect(() => {
    if (VidProgress.length > 0) {
      console.log("VidProgressVidProgressVidProgressVidProgress", VidProgress)
      for (let i = 0; i < VidProgress.length; i++) {
        var el = document.getElementById(
          "video" + VidProgress[i].episode_video_id
        );

        if (toOpen.includes(VidProgress[i].episode_video.episode_id) && el) {
          el?.classList.add("green_box");
        }
      }
    }
  }, [episodes, toOpen, video, VidProgress]);

  const handleClick = (id) => {
    if (toOpen.includes(id)) {
      setToOpen(toOpen.filter((s) => s != id));
      setvideo(0);
    } else {
      var i = toOpen;
      i.push(id);
      setToOpen(i);
      setvideo("video" + id);
    }
  };

  useEffect(() => {
    if (played) {
      const data = {
        episode_video_id: reachVid?.id,
        is_completed: true,
      };
      dispatch(createVidProg(data));
    }
  }, [played]);

  useEffect(() => {
    console.log('errorerror', error)
  }, [error]);


  const [finishAction, setFinishAction] = useState(false);
  const sendFinishQuizRequest = () => {
    dispatch(createUserQuiz(arrayOfQuestonsAnswers));
    if (error.length == 0) {
      setArrayOfQuestonsAnswers([]);
      setError([]);
      setErrorToDisplay(null);
    } else {
      setFinishAction(true)
      setErrorToDisplay(error);
    }
  };
  const calculateTotalEpisodeLength = (episode) => {
    // Initialize variables to store the total length in seconds, minutes, and hours
    let totalSeconds = 0;
    let totalMinutes = 0;
    let totalHours = 0;

    // Iterate through the episode_videos array
    episode.episode_videos.forEach((video) => {
      if (video) {
        // Split the video length into hours, minutes, and seconds
        const [hours, minutes, seconds] = video.length.split(":").map(Number);

        // Add the hours, minutes, and seconds to the respective totals
        if (!isNaN(hours)) {
          totalHours += hours;
        }
        if (!isNaN(minutes)) {
          totalMinutes += minutes;
        }
        if (!isNaN(seconds)) {
          totalSeconds += seconds;
        }
      }
    });

    // Calculate the totalMinutes and totalHours from the totalSeconds
    totalMinutes += Math.floor(totalSeconds / 60);
    totalSeconds %= 60;
    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes %= 60;

    // Round up minutes if seconds are more than 40
    if (totalSeconds > 40) {
      totalMinutes += 1;
    }

    return ` ${totalMinutes} mins`;
  };

  const handleQuiz = (quiz_id, answerVal, answeStat, type, isChecked) => {
    if (isChecked) {
      arrayOfQuestonsAnswers?.map((questionsAnswers, index) => {
        if (quiz_id == questionsAnswers.episode_quiz_id && type == 'radio') {
          arrayOfQuestonsAnswers.splice(index, 1);
        }

        if (quiz_id == questionsAnswers.episode_quiz_id && questionsAnswers.answer == answerVal && type == 'checkbox') {
          arrayOfQuestonsAnswers.splice(index, 1);
        }

      });


      const data = {
        episode_quiz_id: quiz_id,
        answer_id: answerVal,
      };
      setArrayOfQuestonsAnswers((arrayOfQuestonsAnswers) => [
        ...arrayOfQuestonsAnswers,
        data,
      ]);

      if (answeStat) {
        if (type == "checkbox") {
          setError((prevError) => {
            // Remove the entry if it exists
            return prevError.filter(([existingQuizId, existingAnswerValue]) => (parseInt(existingAnswerValue) !== answerVal));
          });

        }
        if (type == "radio") {
          setError(error.filter((item) => (item[0] != quiz_id)));
        }
        if (error.length == 0) {
          setError([]);
        }

      } else setError((prevError) => {
        const [quizId, answerValue] = [quiz_id, answerVal];

        // Check if [quizId, answerValue] already exists in the error array
        if (!prevError.some(([existingQuizId, existingAnswerValue]) => (existingQuizId == quizId && parseInt(existingAnswerValue) == answerValue))) {
          // If it doesn't exist, add it to the error array
          return [...prevError, [quizId, answerValue]];
        }
      });
    } else {
      setError((prevError) => {
        // Remove the entry if it exists
        return prevError.filter(([existingQuizId, existingAnswerValue]) => !(existingQuizId == quiz_id && parseInt(existingAnswerValue) == answerVal));
      });

      setArrayOfQuestonsAnswers((prevArray) => {
        // Remove the entry if it exists
        return prevArray.filter(({ episode_quiz_id, answer }) => !(episode_quiz_id == quiz_id && parseInt(answer) == answerVal));
      });


    }

  };

  useEffect(() => {
    if (masterDone) {
      dispatch(finishMaster());
      setOpenDone(true)
    }
  }, [masterDone]);



  useEffect(() => {
    if (error) {
      console.log('error :  ', error)
    }
  }, [error]);

  useEffect(() => {
    if (arrayOfQuestonsAnswers) {
      console.log("arrayOfAnswers : ", arrayOfQuestonsAnswers)
    }
  }, [arrayOfQuestonsAnswers]);

  return (
    <div className="masterclass_body">
      <div className="UpperSection">
        <UpperSection title={t("masterclass.title")} />
      </div>

      <div className="masterclass_body_divs">
        <div className="masterclass_body_left_div">
          {!showFilt && (
            <div
              className="master_title_resp"
              onClick={() => setShowFilt(true)}
            >
              <FaFilter className="filter_main_icon" />
              {/* {t("opportunity.filter.view_filter")}
               */}
              {t("masterclass.prog")}
              <div className="filers_main_underline"></div>
            </div>
          )}

          {showFilt && (
            <>
              <div className="master_close" onClick={() => setShowFilt(false)}>
                <AiFillCloseCircle size="2rem" className="filter_clear_icon" />
              </div>
              <div className="masterclass_body_first_div">
                <div className="masterclass_left_progress_bar">
                  <div className="masterclass_left_progress_bar_text">
                    <span className="master_subtext"> {Completed}% </span>{" "}
                    {/* <span className="master_subtext"> 100% </span>{" "} */}
                    {t("masterclass.masterclass_11")}
                  </div>
                  <ProgressBar
                    completed={Completed}
                    maxCompleted={100}
                    bgColor={"#72c095"}
                    height={"15px"}
                    customLabel={true}
                  />
                  {/* <ProgressBar
                    completed={100}
                    maxCompleted={100}
                    bgColor={"#72c095"}
                    height={"15px"}
                    customLabel={true}
                  /> */}
                </div>
                <div className="master_videos_container">
                  <div className="">
                    {episodes.length > 0 &&
                      episodes?.map((episode) => {
                        return (
                          <>
                            <div
                              className="filter_sub_cont_header filter_sub_cont"
                              onClick={() => {
                                handleClick(episode.id);
                              }}
                            >
                              <div className="master_list_check_cont">
                                <div
                                  className={
                                    openEp && openEp?.includes(episode.id)
                                      ? "trans_box green_box"
                                      : "trans_box  "
                                  }
                                  id={"ep" + episode?.id}
                                ></div>
                                <div className="filter_sub_cont_title">
                                  {lang == "en"
                                    ? episode?.title
                                    : episode?.title_ar}
                                </div>
                              </div>
                              <span className="length_span">
                                {calculateTotalEpisodeLength(episode)}
                              </span>
                              <IoIosArrowDown
                                size="1.2rem"
                                className={
                                  toOpen.includes(episode?.id) ||
                                    video == "video" + episode.id
                                    ? "filter_icon_arrow"
                                    : "filter_icon_arrow rotate_arrow"
                                }
                              />
                            </div>

                            {(toOpen.includes(episode.id) ||
                              video == "video" + episode.id) && (
                                <>
                                  <div className="filter_sub_cont_list">
                                    <div className="master_list_check">
                                      {episode.episode_videos.map((video, i) => {
                                        return (
                                          <>
                                            <div className="master_list_check_cont">
                                              <div
                                                className="trans_box"
                                                id={"video" + video?.id}
                                              ></div>

                                              <div className="filter_list_comp">
                                                {lang == "en"
                                                  ? video?.title
                                                  : video?.title_ar}
                                              </div>
                                            </div>

                                            {episode?.episode_quizes.length > 0 && <div className="line_trial"></div>}
                                          </>
                                        );
                                      })}

                                      {episode?.episode_quizes.length > 0 && <div className="master_list_check_cont">
                                        <div
                                          className={
                                            quizDone &&
                                              quizDone?.includes(
                                                episode?.episode_quizes[0]?.id
                                              )
                                              ? "trans_box green_box"
                                              : "trans_box"
                                          }
                                          id={
                                            "quiz" +
                                            episode?.episode_quizes[0]?.id
                                          }
                                        ></div>

                                        <div className="filter_list_comp">
                                          Quiz
                                        </div>
                                      </div>}
                                    </div>
                                  </div>
                                </>
                              )}
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {!openQuiz ? (
          <div className="masterclass_body_second">
            <div className="masterclass_video_container">
              <ReactPlayer
                url={reachVid?.link}
                width="100%"
                height="500px"
                onStart={() => {
                  setPlayed(false);
                }}
                onEnded={() => {
                  setPlayed(true);
                }}
                controls={true}
                playIcon={
                  <img
                    src={play_img}
                    alt="Video Play Button"
                    className="play_icon"
                  />
                }
                light
              />
            </div>

            {((lang == "ar" && reachVid?.desc_ar != null) || lang == "en") && <div className="about_master_class">
              <h1 className="about_master_header">
                {" "}
                {t("masterclass.about_episode")}
                <div className="about_master_underline"></div>
                <div className="about_master_info">
                  {lang == "en" ? reachVid?.desc : reachVid?.desc_ar}
                </div>
              </h1>
            </div>}

            <div className="about_master_class">
              <h1 className="about_master_header">
                {" "}
                {t("masterclass.master_train")}
                <div className="about_master_underline_green"></div>
                <div className="master_train_div">
                  <div className="about_master_train_info">
                    {lang == "en"
                      ? reachVid?.desc_trai
                      : reachVid?.desc_trai_ar}
                  </div>
                  <div className="master_train_image_div">
                    <img
                      src={process.env.REACT_APP_API + "" + reachVid?.img_url}
                      className="master_train_image"
                    ></img>
                    <div className="master_train_info">
                      {lang == "en"
                        ? reachVid?.name_trai
                        : reachVid?.name_trai_ar}
                      <a
                        href={reachVid?.linked_in}
                        target={"_blank"}
                        className="trainer_link"
                      >
                        LinkedIn
                      </a>
                    </div>
                  </div>
                </div>
              </h1>
            </div>
          </div>
        ) : (
          quiz.length > 0 && <div className="quizz_cont">
            {quiz?.map((q, i) => {
              return (
                <form className="masterclass_body_second">
                  <h2 className="quiz_answers">{q.question}</h2>
                  {q?.episode_quiz_answers?.map((item) => {
                    return (
                      <>
                        {(q.type == "radio" || q.type == "checkbox") && <div className="quiz_answers">
                          <input
                            key={item.id}
                            type={q.type}
                            name={"answer" + q.episode_id}
                            value={item.id}
                            disabled={disabledForm}
                            className={q.type == "checkbox" ? "master_checkbox" : "quiz_radio "}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              handleQuiz(q.id, e.target.value, item.is_true, q.type, isChecked);
                            }}
                          />

                          <label for="answer" className="label_radio">
                            {lang == "en" ? item.answer : item.answer_ar}
                          </label>
                          {"  "}
                          {finishAction && item.is_true && <GiCheckMark
                            className="check_mark"
                            size={20}
                            style={{ color: "#72c095", width: '100px' }}
                          />
                          }
                          {finishAction && error?.map((valErr) => {
                            if (valErr[1] == item.id) {
                              return <AiOutlineClose
                                className="check_mark"
                                size={20}
                                style={{ color: "#f06a80", width: '100px' }}
                              />;
                            }

                          })}

                        </div>}
                        {q.type == "string" && <div>
                          <textarea
                            style={{ width: "100%" }}
                            className="complete_step9_textarea"
                            disabled={disabledForm}
                            placeholder={t("masterclass.start_here")}
                            onChange={(e) => {
                              const updatedAnswer = {
                                episode_quiz_id: q.id,
                                answer_string: e.target.value,
                              };

                              setArrayOfQuestonsAnswers((prevArray) => {
                                // Check if episode_quiz_id already exists in the array
                                const existingIndex = prevArray.findIndex(item => item.episode_quiz_id === q.id);

                                if (existingIndex !== -1) {
                                  // If exists, update the existing entry
                                  const newArray = [...prevArray];
                                  newArray[existingIndex] = updatedAnswer;
                                  return newArray;
                                } else {
                                  // If doesn't exist, add a new entry
                                  return [...prevArray, updatedAnswer];
                                }
                              });
                            }}

                          />
                        </div>}
                      </>
                    );
                  })}
                </form>
              );
            })}

            {error.length > 0 && errorToDisplay !== null && (
              <div className="error_msg"> {t("masterclass.error")}</div>
            )}
            {!disabledForm && <button
              style={{ marginTop: '30px' }}
              onClick={() => {
                setDisabledForm(true);
                sendFinishQuizRequest();
              }}
              className="primary_button"
            >
              Finish
            </button>}
            {disabledForm && <button
              style={{ marginTop: '30px' }}
              onClick={() => {
                setError([]);
                setErrorToDisplay(null);
                setDisabledForm(false);
                setFinishAction(false)
                dispatch(getQuizUser());
              }}
              className="primary_button"
            >
              Next
            </button>}

          </div>
        )}{" "}
        {status && (
          <div className="masterclass_body_second">
            <div className="master_finish"> {t("masterclass.finshed2")}</div>
            <div className="master_finish"> {t("masterclass.finshed3")}</div>
            <div className="master_finish"> {t("masterclass.finshed4")}</div>
            <div className="master_finish"> {t("masterclass.finished5")}</div>
          </div>
        )}
        {/* {test && (
          <div className="masterclass_body_second">
            <div className="master_finish"> {t("masterclass.finshed2")}</div>
            <div className="master_finish"> {t("masterclass.finshed3")}</div>
            <div className="master_finish"> {t("masterclass.finshed4")}</div>
            <div className="master_finish"> {t("masterclass.finished5")}</div>
          </div>
        )} */}


        {openDone && (
          <PopUp
            setOpen={setOpenDone}
            body={
              <div className="popup_body">
                <div className="home_popup_body">
                  {t("masterclass.masterclass_done")}<br></br>
                  {t("masterclass.masterclass_done1")}<br></br>
                  {t("masterclass.masterclass_done2")}
                </div>
              </div>
            }
          />
        )}
      </div>
    </div>
  );
}

export default MasterClass_Det;
