import React, { useEffect, useState } from "react";
import i18n from "../../../../i18n";
import { useTranslation } from "react-i18next";
import "./Apply_Step5.css";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiTwotoneEnvironment,
} from "react-icons/ai";
import apply_img_step5 from "../../../../assets/images/opportunity/apply/apply_step5.png";
import Apply_Step7 from "../Apply_Step7/Apply_Step7";
import Apply_Step4 from "../Apply_Step4/Apply_Step4";
import Apply_Step1 from "../Apply_Step1/Apply_Step1";
import Apply_Step6 from "../Apply_Step6/Apply_Step6";
import { getOpportunitiesById } from "../../../../Redux/opportunities/opportunityActions";
import { useDispatch, useSelector } from "react-redux";

function Apply_Step5({ setOpen, open, data, setFooterWidth, previousFirstPage, opportunityId ,openOutOfArea}) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [openApplyPopUpStep, setOpenApplyPopUpStep] = useState(false);
  const [oppData, setOppData] = useState("");
  const { oppDetails } = useSelector((state) => state.opportunitiesReducer);
  const [opprtunityDetails, setOpprtunityDetails] = useState({});
  const size = useWindowSize();
  const dispatch = useDispatch();
  const [previous, setPrevious] = useState(false);
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    setPrevious(false);
    dispatch(getOpportunitiesById(data.opportunityId));
    setOppData({ ...data });
    setFooterWidth("75%");
  }, []);

  useEffect(() => {
    if (oppDetails != {}) {
      setOpprtunityDetails(oppDetails);
    }
  }, [oppDetails]);

  const onSubmit = (e) => {
    e.preventDefault();
    setOpenApplyPopUpStep(true);
  };
  return (
    <>

      {previous &&  !openOutOfArea && !previousFirstPage && (
        <Apply_Step4
          setOpen={setOpen}
          open={open}
          data={data}
          openOutOfAreaa={openOutOfArea}
          opportunityId={opportunityId}
          setFooterWidth={setFooterWidth}
        />
      )}

    {previous && openOutOfArea &&(
        <Apply_Step6
        setOpen={setOpen}
        open={open}
        data={data}
        previousFirstPage = {previousFirstPage}
        openOutOfArea = {openOutOfArea}
        opportunityId = {opportunityId}
        setFooterWidth={setFooterWidth}
        />
      )}

      {previous && previousFirstPage && !openOutOfArea && (
        <Apply_Step1
          setOpen={setOpen}
          open={open}
          opportunityId={opportunityId}
        />
      )}

      {!previous && !openApplyPopUpStep ? (
        <form className="apply_step5" onSubmit={(e) => onSubmit(e)}>
          <img className="apply_img" src={apply_img_step5} />
          <div>{t("opportunity.apply_popup.precise_loc")}</div>
          <div className="location_cont">
            {" "}
            <AiTwotoneEnvironment size="1.5rem" />{" "}
            {lang == "ar"
              ? opprtunityDetails?.district?.name_ar +
              ", " +
              opprtunityDetails?.district?.governate.name_ar +
              ", " +
              opprtunityDetails?.district?.governate.country?.name_ar
              : opprtunityDetails?.district?.name +
              ", " +
              opprtunityDetails?.district?.governate.name +
              ", " +
              opprtunityDetails?.district?.governate.country?.name}
          </div>
          <div className="dotter_spacer" />
          <div class="complete_step_footer_button">

            <div className="div_buttons div_buttons_left">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setPrevious(true);
                }}
                className={
                  size.width > 768
                    ? "apply_primary_button app_step2_apply_later_button gray-btn"
                    : "apply_primary_button app_step2_apply_later_button_resp gray-btn"
                }
              >
                {t("opportunity.apply_popup.previous")}
              </button>
            </div>


            <div className="div_buttons">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(false);
                }}
                className={
                  size.width > 768
                    ? "apply_primary_button app_step4_apply_later_button"
                    : "apply_primary_button app_step4_apply_later_button_resp"
                }
              >
                {t("opportunity.apply_popup.apply_later")}
              </button>
              <button
                type="submit"
                className={
                  size.width > 768
                    ? "primary_button app_step4_next_button"
                    : "primary_button app_step4_next_button_resp"
                }
              >
                {size.width > 768 ? (
                  t("opportunity.apply_popup.next")
                ) : lang == "en" ? (
                  <AiOutlineArrowRight size="1.5rem" />
                ) : (
                  <AiOutlineArrowLeft size="1.5rem" />
                )}
              </button>
            </div>
          </div>
        </form>
      ) : (
        !previous && <Apply_Step7
          setOpen={setOpen}
          open={open}
          data={oppData}
          previousFirstPage = {previousFirstPage}
          opportunityId={opportunityId}
          openOutOfArea={openOutOfArea}
          setFooterWidth={setFooterWidth}
        />
      )}
    </>
  );
}

export default Apply_Step5;
