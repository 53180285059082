import React from 'react'
import "./Goals.css"
function Goals({image,name,onClick,id,selectedImpacts}) {
  return (
    <div className={!selectedImpacts.includes(id) ? 'goals_main_cont' :'goals_main_cont active_goals' } onClick={()=>onClick(id)}>
        <div className='goals_img_cont'>
            <img src={process.env.REACT_APP_API+"/Impact/"+image}/>

        </div>
        <span className="divider"></span>
        <div className='goals_name'>{name}</div>
    </div>
  )
}

export default Goals