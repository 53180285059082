import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  SubscriptionValues: [],
};
const SubscriptionValuesSlice = createSlice({
  name: "SubscriptionValues",
  initialState,
  reducers: {
    SubscriptionValuesRequest(state) {
      state.loading = true;
      state.SubscriptionValues = [];
      state.error = "";
    },
    SubscriptionValuesSuccess(state, action) {
      state.loading = false;
      state.SubscriptionValues = action.payload;
      state.error = "";
    },
    SubscriptionValuesFail(state, action) {
      state.loading = false;
      state.SubscriptionValues = [];
      state.error = action.payload;
    },
  },
});

export const SubscriptionValuesAction = SubscriptionValuesSlice.actions;
export default SubscriptionValuesSlice;
