import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  oppRequests: [],
  loading: false,
  error: "",
  opps: [],
  ReqOpp: [],
  evalrequests: [],
  createLoading: false,
  createError: "",
  createSucess: false,
  scores: [],
  reqloading: false,
};

const oppRequestsSlice = createSlice({
  name: "oppRequests",
  initialState,
  reducers: {
    fetchOppRequests(state) {
      state.loading = true;
      state.oppRequests = [];
      state.opps = [];
      state.evalrequests = [];
      state.error = "";
    },
    fetchOppRequestsByid(state) {
      state.loading = true;
      state.ReqOpp = [];
      state.error = "";
    },
    fetchOppRequestsByidSuccess(state, action) {
      state.loading = false;
      state.ReqOpp = action.payload.data;
      state.error = "";
    },
    fetchOppRequestsByidFail(state, action) {
      state.loading = false;
      state.ReqOpp = [];
      state.error = action.payload;
    },

    fetchOppRequestsRequest(state, action) {
      state.reqloading = true;
      state.oppRequests = [];
      state.error = "";
      state.opps = [];
      state.ReqOpp = [];
    },

    fetchOppRequestsSuccess(state, action) {
      state.reqloading = false;
      state.oppRequests = action.payload[0];
      state.opps = action.payload[0];
      state.evalrequests = action.payload;
      state.error = "";
    },

    fetchOppRequestsFail(state, action) {
      state.reqloading = false;
      state.oppRequests = [];
      state.error = action.payload;
      state.opps = action.payload[0];
      state.ReqOpp = action.payload;
    },

    acceptedOppRequest(state, action) {
      state.reqloading = false;
      state.oppRequests = [];
      state.error = action.payload;
    },
    acceptedOppRequestSucess(state, action) {
      state.loading = false;
      state.oppRequests = action.payload;
      state.error = "";
    },

    acceptedOppRequestFail(state, action) {
      state.loading = false;
      state.oppRequests = [];
      state.error = action.payload;
    },
    rejectedOppRequest(state, action) {
      state.loading = false;
      state.oppRequests = [];
      state.error = action.payload;
    },
    rejectedOppRequestSucess(state, action) {
      state.loading = false;
      state.oppRequests = action.payload;
      state.error = "";
    },

    rejectedOppRequestFail(state, action) {
      state.loading = false;
      state.oppRequests = [];
      state.error = action.payload;
    },
    fetchmatchingscores(state) {
      state.loading = true;
      state.scores = [];
      state.error = "";
    },
    fetchmatchingscoresSuccess(state, action) {
      state.loading = false;
      state.scores = action.payload;
      state.error = "";
    },
    fetchmatchingscoresFail(state, action) {
      state.loading = false;
      state.scores = [];
      state.error = action.payload;
    },
  },
});

export const OppRequestsActions = oppRequestsSlice.actions;
export default oppRequestsSlice;
