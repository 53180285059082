import React, { useEffect } from "react";
import UpperSection from "../../components/UpperSection/UpperSection";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import BlogCard from "../../components/BlogCard/BlogCard";
import i18n from "../../i18n";
import { useDispatch, useSelector } from "react-redux";
import { getBlogs, getfirstBlogs } from "../../Redux/Blogs/BlogsActions";
import { getVolunteerSpotlights } from "../../Redux/VolunteerSpotlight/VolunteerSpotlightActions";
import { getBlogVideos } from "../../Redux/videoBlog/videoBlogActions";
import "./Blogs.css";
import SpotlightCard from "../../components/SpotlightCard/SpotlightCard";
import play_img from "../../assets/images/play.svg";
import ReactPlayer from "react-player";
import Loading from "../../components/Loading/Loading";

function Blogs() {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const { blogs, firstBlogs, loading } = useSelector(
    (store) => store.blogsReducer
  );
  const { volunteerSpotlights, spotLoading } = useSelector(
    (store) => store.volunteerSpotlightReducer
  );
  const { videos, blogLoading } = useSelector(
    (store) => store.videoBlogReducer
  );
  const [volunteerSpotlightsData, setVolunteerSpotlightsData] = useState([]);
  const [recent, setRecent] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [blogVideosData, setBlogVideosData] = useState([]);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (page == 1) dispatch(getfirstBlogs());
    if (page == 2) dispatch(getBlogs());
    if (page == 3) dispatch(getVolunteerSpotlights());
    if (page == 4) dispatch(getBlogVideos());
  }, [page]);

  useEffect(() => {
    if (firstBlogs?.length > 0) {
      const firstb = [];
      const prevb = [];

      for (let i = 0; i < 3; i++) {
        if (firstBlogs[i]) firstb.push(firstBlogs[i]);
      }
      setRecent(firstb);
      for (let i = 3; i < 6; i++) {
        if (firstBlogs[i]) prevb.push(firstBlogs[i]);
      }
      setPrevious(prevb);
    }
  }, [firstBlogs]);

  useEffect(() => {
    if (volunteerSpotlights !== undefined) {
      setVolunteerSpotlightsData(volunteerSpotlights);
    }
  }, [volunteerSpotlights]);

  useEffect(() => {
    if (videos !== undefined) {
      setBlogVideosData(videos);
    }
  }, [videos]);

  return (
    <div>
      <UpperSection
        page={page}
        setPage={setPage}
        title={t("blogs.title")}
        subtitle={t("blogs.blog")}
        subtitle2={t("blogs.press")}
        subtitle3={t("blogs.spotlight")}
        subtitle4={t("blogs.videos")}
      />
      {page == 1 ? (
        <div className="blog_main_page">
          <div className="blog_main_cont1">
            <h1 className="blog_main_header">{t("blogs.recent")}</h1>
            <div className="blog_divider"></div>
            {loading ? (
              <Loading />
            ) : (
              <div className="blogs_recent">
                {recent?.map((blog) => {
                  var date = new Date(blog.createdAt);
                  date = date.toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  });
                  return (
                    <BlogCard
                      id={blog.id}
                      title={blog.title}
                      image={
                        process.env.REACT_APP_API + "/Blogs/" + blog.picture
                      }
                      date={date}
                    />
                  );
                })}
              </div>
            )}
          </div>
          <div className="blog_main_cont2">
            <h1 className="blog_main_header">{t("blogs.previous")}</h1>
            <div className="blog_divider"></div>
            {loading ? (
              <Loading />
            ) : (
              <div className="blogs_recent">
                {previous?.map((blog) => {
                  var date = new Date(blog.createdAt);
                  date = date.toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  });
                  return (
                    <BlogCard
                      id={blog.id}
                      title={blog.title}
                      image={
                        process.env.REACT_APP_API + "/Blogs/" + blog.picture
                      }
                      date={date}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      ) : page == 2 ? (
        loading ? (
          <Loading />
        ) : (
          <div className="blogs_container">
            {blogs?.map((blog) => {
              var date = new Date(blog.createdAt);
              date = date.toLocaleDateString("en-GB", {
                day: "numeric",
                month: "long",
                year: "numeric",
              });
              return (
                <BlogCard
                  id={blog.id}
                  title={blog.title}
                  image={process.env.REACT_APP_API + "/Blogs/" + blog.picture}
                  date={date}
                />
              );
            })}
          </div>
        )
      ) : page == 3 ? (
        <div className="vol_spotlight_container">
          <h3 className="spot_header">
            {t("blogs.stories")}
            <br /> <span>{t("blogs.stories_cont")}</span>
          </h3>
          {spotLoading ? (
            <Loading />
          ) : (
            <div className="spotlight_list">
              {volunteerSpotlightsData !== [] &&
                volunteerSpotlightsData?.map((volunteerSpotlight, i) => {
                  return (
                    <SpotlightCard
                      index={i}
                      id={volunteerSpotlight?.id}
                      image={volunteerSpotlight?.image}
                      name={volunteerSpotlight?.title}
                      text={volunteerSpotlight?.description}
                    />
                  );
                })}
            </div>
          )}
        </div>
      ) : (
        page == 4 &&
        (blogLoading ? (
          <Loading />
        ) : (
          <div className="blog_videos_container">
            {blogVideosData !== [] &&
              blogVideosData?.map((blogVideos, i) => {
                return (
                  <div className="blog_video">
                    <ReactPlayer
                      url={blogVideos?.link}
                      width="100%"
                      height="100%"
                      playIcon={
                        <div className="home_VideoPlayButton">
                          <img src={play_img} alt="Video Play Button" />
                        </div>
                      }
                      light
                    />
                    <div className="video_title">{blogVideos?.title}</div>
                  </div>
                );
              })}
          </div>
        ))
      )}
    </div>
  );
}

export default Blogs;
