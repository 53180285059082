import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  info: [],
  loading: false,
  error: "",
  statistics: [],
};
const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    fetchstatisticRequest(state) {
      state.loading = true;
      state.statistics = [];
      state.error = "";
    },
    fetchstatisticSuccess(state, action) {
      state.loading = false;
      state.statistics = action.payload;
      state.error = "";
    },
    fetchstatisticFail(state, action) {
      state.loading = false;
      state.statistics = [];
      state.error = action.payload;
    },
  },
});

export const statisticsAction = statisticsSlice.actions;
export default statisticsSlice;
