import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  organizations: [],
  allorganizations: [],
  loading: false,
  error: "",
};

const organizationSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    fetchOrganizationsRequest(state) {
      state.loading = true;
      state.organizations = [];
      state.error = "";
    },
    fetchOrganizationsSuccess(state, action) {
      state.loading = false;
      state.organizations = action.payload;
      state.error = "";
    },
    fetchOrganizationsFail(state, action) {
      state.loading = false;
      state.organizations = [];
      state.error = action.payload;
    },
    fetchAllOrganizationsRequest(state) {
      state.loading = true;
      state.allorganizations = [];
      state.error = "";
    },
    fetchAllOrganizationsSuccess(state, action) {
      state.loading = false;
      state.allorganizations = action.payload;
      state.error = "";
    },
    fetchAllOrganizationsFail(state, action) {
      state.loading = false;
      state.allorganizations = [];
      state.error = action.payload;
    },
  },
});

export const organizationAction = organizationSlice.actions;
export default organizationSlice;
