import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  organizationEvaluations: [],
  loading: false,
  error: "",
  success: false,
};

const organizationEvaluationsSlice = createSlice({
  name: "organizationEvaluations",
  initialState,
  reducers: {
    AddOrganizationEvaluationsRequest(state) {
      state.loading = true;
      state.error = "";
      state.success = false;
    },
    AddOrganizationEvaluationsRequestSuccess(state, action) {
      state.loading = false;
      state.organizationEvaluations = action.payload;
      state.error = "";
      state.success = true;
    },
    AddOrganizationEvaluationsRequestFail(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
  },
});

export const OrganizationEvaluationsActions = organizationEvaluationsSlice.actions;
export default organizationEvaluationsSlice;
