import React from "react";
import { useState } from "react";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineClose,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import "./CreateOpp_Step9.css";

import i18n from "../../../../i18n";
import { useSSR, useTranslation } from "react-i18next";

import { useEffect } from "react";

import { BiSave } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import CreateOpp_Step8 from "../Step8/CreateOpp_Step8";
import CreateOpp_Step10 from "../Step10/CreateOpp_Step10";
import axios from "axios";
import { editOpportunity , getOpportunitiesById} from "../../../../Redux/opportunities/opportunityActions";

function CreateOpp_Step9({
  id,
  setOpen,
  setFooterWidth,
  community,
}) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [openStep10, setOpenStep10] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [physicalEf, setPhysicalEf] = useState();
  const [wheelchairAc, setWheelchairAc] = useState(null);
  const [wheelchairAcVal, setWheelchairAcVal] = useState();
  const [disabilitiesValues, setDisabilities] = useState();
  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const size = useWindowSize();
  const dispatch = useDispatch();

  const [isNext, setIsNext] = useState(true);
  const [previousPage, setPreviousPage] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const {
    oppDetails,
    disabilities
  } = useSelector((store) => store.opportunitiesReducer);
  useEffect(() => {
    if (id) {
      dispatch(getOpportunitiesById(id));
    }
  }, []);
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  const getDis = async () => {
    const response = await axios.get(
      process.env.REACT_APP_API + "/disabilities"
    );

    setDisabilities(response.data.data);
  };

  useEffect(() => {
    setFooterWidth("75%");
    getDis();
  }, []);

  const createOppDis = async () => {
    if (selectedValues) {
      await axios.post(
        process.env.REACT_APP_API + "/opportunity_disabilities",
        {
          opportunity_id: id,
          disability_id: selectedValues,
        }
      );
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (setWheelchairAc != null) {
      const opp = {
        wheelchair_accessible: wheelchairAc,
      };
      createOppDis();
      if (community) {
        dispatch(editOpportunity(id, opp, community));
      } else dispatch(editOpportunity(id, opp));

      if (isNext) {
        setOpenStep10(true);
      } else {
        setOpen(false);
      }
    } else {
      setError(t("opportunity.create.error"));
    }
  };

  const handleEvent = (e, gender) => {
    if (e.target.checked) {
        if (!selectedValues.includes(gender.id)) {
          setSelectedValues((prevSelectedValues) => [...prevSelectedValues, gender.id]);
        }
      
    } else {
      setSelectedValues((prevSelectedValues) => prevSelectedValues.filter((id) => id !== gender.id));
    }
  };

  const selectAll = (e) => {
    if (e.target.checked) {
      disabilitiesValues.map((a, i) => {
        if (a  && !selectedValues.includes(a?.id)) {
          setSelectedValues((prevSelectedValues) => [...prevSelectedValues, a?.id]);
        }
      });
      
    } else {
      setSelectedValues([]);
    }
  };



  useEffect(() => {
    setSelectedValues([]);
      disabilities.map((a, i) => {
        setSelectedValues((prevSelectedValues) => {
          const newId = a?.disability?.id;
          if (newId && !prevSelectedValues.includes(newId)) {
            return [...prevSelectedValues, newId];
          }
          return prevSelectedValues;
        });
        
      });
  }, [disabilities]);

  useEffect(() => {
    if (Object.keys(oppDetails).length > 0) {
      setWheelchairAc(oppDetails.wheelchair_accessible);
    }
  }, [oppDetails]);

  return (
    <div>
      {
        // (loading || editLoading)  ? <Loading/> :
        previousPage ? (
          <CreateOpp_Step8
            id={id}
            community={community}
            setOpen={setOpen}
            setFooterWidth={setFooterWidth}
            previous={true}
          />
        ):( openStep10 ? (
          <CreateOpp_Step10
            id={id}
            setOpen={setOpen}
            community={community}
            setFooterWidth={setFooterWidth}
          />
        ) : (
          <form className="create_opp_step1" onSubmit={(e) => onSubmit(e)}>
            <div className="create_opp_step7_main_cont">
              <div className="create_opp_step1_cont">
                <div className="step1_cont_header">
                  {t("opportunity.create.disabilities")}*
                </div>
                <div className="create_opp_checkbox_main create_opp_checkbox_step8">
                  <div className="step2_create_opp">
                    <input
                      type="checkbox"
                      id="create_opp_check5"
                      name="All"
                      value="All"
                      className="create_opp_check"
                      onChange={(e) => selectAll(e)}
                    />
                    <div className="step2_create_opp_info">
                      <label className="step2_create_opp_name" for="All">
                        {t("opportunity.create.all")}
                      </label>
                    </div>
                  </div>
                  {disabilitiesValues?.map((dis, index) => {
                    return (
                      <div className="step8_create_opp">
                        <input
                          type="checkbox"
                          id={"create_opp_check" + dis.id}
                          name={dis.text}
                          value={dis.text}
                          className="create_opp_check"
                          onChange={(e) => handleEvent(e, dis)}
                          checked={selectedValues.includes(dis.id) ? true :false}
                        />
                        <div className="step2_create_opp_info">
                          <label
                            className="step2_create_opp_name"
                            for={dis.text}
                          >
                            {dis.text}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="create_opp_step1_cont">
                <div className="step1_cont_header">
                  {t("opportunity.create.wheelchair")}*
                </div>
                <div className="create_opp_radio_main">
                  <div className="create_opp_radio_comp">
                    <input
                      onChange={() => setWheelchairAc(true)}
                      className={
                        lang == "en"
                          ? "create_opp_radio_btn"
                          : "create_opp_radio_btn_ar"
                      }
                      type="radio"
                      id="wheel_yes"
                      name="wheel"
                      value="wheel_yes"
                      checked={wheelchairAc}
                    />
                    <label
                      className="create_opp_radio_label"
                      for="wheel_yes"
                      style={{ cursor: "pointer" }}
                    >
                      {t("opportunity.create.yes")}
                    </label>
                  </div>
                  <div className="create_opp_radio_comp">
                    <input
                      onChange={() => setWheelchairAc(false)}
                      className={
                        lang == "en"
                          ? "create_opp_radio_btn"
                          : "create_opp_radio_btn_ar"
                      }
                      type="radio"
                      id="wheel_no"
                      name="wheel"
                      value="wheel_no"
                      checked={!wheelchairAc}
                    />
                    <label
                      className="create_opp_radio_label"
                      for="wheel_no"
                      style={{ cursor: "pointer" }}
                    >
                      {t("opportunity.create.no")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {error && <div className="error_msg">{error}</div>}

            <div className="complete_step1_footer">
              <div className="divider_line"></div>
              <div className="complete_step_footer_button">
                <button
                  className={
                    size.width > 768
                      ? "primary_button complete_step_1_next_button"
                      : "primary_button complete_step_2_next_button_resp"
                  }
                  onClick={(e) => { e.preventDefault(); setPreviousPage(true); }}
                >
                  {size.width > 768 ? (
                    t("CompleteProf.complete_your_profile_btn_5")
                  ) : lang == "en" ? (
                    <AiOutlineArrowLeft size="1.5rem" />
                  ) : (
                    <AiOutlineArrowRight size="1.5rem" />
                  )}
                </button>

                <div
                  className={
                    size.width > 768
                      ? "complete_step_right_footer"
                      : "complete_step_right_footer_resp"
                  }
                >
                  <button
                    className={
                      size.width > 500
                        ? "pink_button complete_step_2_complete_button"
                        : "pink_button complete_step_2_next_button_resp"
                    }
                    onClick={() => setIsNext(false)}
                  >
                    {size.width > 500 ? (
                      t("CompleteProf.complete_your_profile_btn_3")
                    ) : (
                      <BiSave size="1.5rem" />
                    )}
                  </button>
                  <button
                    className={
                      size.width > 768
                        ? "primary_button complete_step_2_next_button"
                        : "primary_button complete_step_2_next_button_resp"
                    }
                    onClick={() => setIsNext(true)}
                  >
                    {size.width > 768 ? (
                      t("CompleteProf.complete_your_profile_btn_4")
                    ) : lang == "en" ? (
                      <AiOutlineArrowRight size="1.5rem" />
                    ) : (
                      <AiOutlineArrowLeft size="1.5rem" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        ))
      }
    </div>
  );
}

export default CreateOpp_Step9;
