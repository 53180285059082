import "./App.css";
import PageSwitch from "./pages/PageSwitch";
import { BrowserRouter as Router } from "react-router-dom";
import Store from "./Redux/store";
import { Provider } from "react-redux";
import { io } from "socket.io-client";
import { createContext, useEffect, useState } from "react";

const SocketContext = createContext();

function App() {
  document.body.dir = document.documentElement.lang === "ar" ? "rtl" : "ltr";

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const newSocket = io(process.env.REACT_APP_API, {
        extraHeaders: {
          auth: token,
        },
      });
      setSocket(newSocket);

      // Cleanup the socket connection on component unmount
      return () => newSocket.close();
    }
  }, [localStorage.getItem("token")]);

  return (
    <div className="App">
      <Router basename="/">
        <SocketContext.Provider value={socket}>
          <Provider store={Store}>
            <PageSwitch />
          </Provider>
        </SocketContext.Provider>
      </Router>
    </div>
  );
}

export default App;
