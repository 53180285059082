import { React, useState, useEffect } from "react";
import "./OppRequests.css";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import OppRequestsFilters from "../../components/OppRequestsFilters/OppRequestsFilters";
import UpperSection from "../../components/UpperSection/UpperSection";
import { FaFilter } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import icon from "../../assets/images/Group 1553.png";

//images import
import star from "../../assets/images/OppRequests/Group 1232.png";
import bookmark from "../../assets/images/OppRequests/Group 1532.png";
import check from "../../assets/images/OppRequests/Group 1534.png";
import view from "../../assets/images/OppRequests/Group 1531.png";
import decline from "../../assets/images/OppRequests/Group 1533.png";
//redux
import { useSelector, useDispatch } from "react-redux";
import {
  getOppRequests,
  acceptOppRequest,
  rejectOppRequest,
  getmatchingscores,
} from "../../Redux/OppRequests/OppRequestsActions";
import { getOppRequestsbyfilters } from "../../Redux/OppRequests/OppRequestsActions";
import { getCountCheckInOutRequest } from "../../Redux/checkin_out/checkin_outActions";
import Loading from "../../components/Loading/Loading";

function OppRequests() {
  const { t } = useTranslation();
  // const { id } = useParams();
  const [lang, setLang] = useState(i18n.language);
  const [page, setPage] = useState(1);
  const [showFilt, setShowFilt] = useState(false);
  const dispatch = useDispatch();
  const [level, setlevel] = useState([]);
  const [selectedStatus, setStatus] = useState(null);

  const { oppRequests, opps, evalrequests, reqloading } = useSelector(
    (store) => store.oppRequestsReducer
  );
  const { CountCheckInOutRequest } = useSelector(
    (store) => store.checkin_outReducer
  );
  const [idreq, setReqId] = useState();
  const [oppRequestsData, setOppRequestsData] = useState([]);
  const [evalRequestsData, setEvalRequestsData] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    if (page === 2) {
      dispatch(getOppRequests());
    }
  }, [page]);

  useEffect(() => {
    if (oppRequests !== undefined) {
      if (oppRequests.length !== 0) {
        setOppRequestsData(oppRequests)
      } else {
        setOppRequestsData([])
      }
    }
  }, [oppRequests]);

  useEffect(() => {
    if (evalrequests !== undefined) {
      console.log('evalrequests', evalrequests)
      if (evalrequests.length !== 0) {
        setEvalRequestsData(evalrequests)
      } else {
        setEvalRequestsData([])
      }
    }
  }, [evalrequests]);


  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);
  const handleAccept = (id) => {
    if (oppRequests) {
      dispatch(acceptOppRequest(id));
    }
  };

  const handleReject = (id) => {
    if (oppRequests) {
      dispatch(rejectOppRequest(id));
    }
  };
  const handleView = (id) => {
    if (oppRequests) {
      navigate("/viewRequests/" + id);
    }
  };
  useEffect(() => {
    dispatch(getCountCheckInOutRequest());
    if (page === 2) {
      dispatch(getOppRequestsbyfilters({ selectedStatus: "History" }));
    }
  }, [page]);
  const handleEvaluate = (id) => {
    if (oppRequests) {
      navigate("/ngofeedbacks/" + id);
    }
  };
  // useEffect(() => {
  //   oppRequests?.opportunities?.map((opp) => {
  //     opp?.requests?.map((item) => {
  //       dispatch(getmatchingscores(item.volunteer?.id, item.opportunity_id));
  //     });
  //   });
  // }, [oppRequests]);

  return (
    <div className="Main_opp_requests">
      <UpperSection
        title={t("opprequests.title")}

        subtitle={(
          <>
            {t("opprequests.opprequests_1")}
            <span className="notification-badge">
              {CountCheckInOutRequest?.Count?.countRequest}
            </span>
          </>
        )}
        subtitle2={(
          <>
            {t("opprequests.opprequests_2")}
            <span className="notification-badge">
              {CountCheckInOutRequest?.Count?.countEval}
            </span>
          </>
        )}
        page={page}
        setPage={setPage}
      />
      {reqloading ? (
        <Loading />
      ) : (
        <div className="opp_requests_main_container">
          {page === 1 && (
            <div className="opp_requests_main_cont">
              <div className="opp_requests_filters_container">
                <OppRequestsFilters />
              </div>
              {!showFilt && (
                <div
                  className="filter_title_resp"
                  onClick={() => setShowFilt(true)}
                >
                  <FaFilter className="filter_main_icon" />
                  {t("opportunity.filter.view_filter")}
                  <div className="filers_main_underline"></div>
                </div>
              )}
              <div className="opp_filters_comp_resp">
                {showFilt && (
                  <OppRequestsFilters close={() => setShowFilt(false)} />
                )}
              </div>

              <div
                className={
                  lang === "en"
                    ? "opp_requests_table_container"
                    : "opp_requests_table_container_ar"
                }
                onClick={() => {
                  if (showFilt) {
                    setShowFilt(false);
                  }
                }}
              >
                <div className="opp_requests_start_info">
                  <img src={star} className="opp_requests_star" />
                  <div
                    className={
                      lang === "en"
                        ? "opp_requests_start_info_text"
                        : "opp_requests_start_info_text_ar"
                    }
                  >
                    {t("opprequests.opprequests_text")}
                  </div>
                </div>
                <div className="opp_requests_cards_container">
                  {oppRequestsData?.opportunities?.map((opp) => {
                    return opp?.requests?.map((item) => {
                      return (
                        <div className="opp_requests_card">
                          <div className="opp_requests_card_header">
                            {item.volunteer?.points >= 8000 ? (
                              <img src={star} className="opp_requests_star" />
                            ) : (
                              <img src={icon} className="opp_requests_star" />
                              // <div className="opp_requests_card_level_logo" />
                            )}
                            <div className="opp_requests_card_text">
                              <div className="opp_requests_card_text_title">
                                {item?.volunteer?.first_name +
                                  " " +
                                  item?.volunteer?.last_name}
                                <div className="opp_requests_card_text_score">
                                  {item?.score}%
                                </div>
                              </div>
                              <div className="opp_requests_card_text_subtitle">
                                {" "}
                                Is interested in your volunteer opportunity{" "}
                                {opp.title}
                              </div>
                            </div>
                          </div>
                          <div className="opp_requests_card_body">
                            <div
                              className="opp_requests_card_body_action_container"
                              onClick={() => handleView(item.id)}
                            >
                              <img src={view} className="action_logo" />{" "}
                              <div className="opp_requests_card_body_action_text">
                                {t("opprequests.opprequests_5")}
                              </div>
                            </div>
                            {/* <div className="opp_requests_card_body_action_container">
                            <img src={bookmark} className="action_logo" />{" "}
                            <div className="opp_requests_card_body_action_text">
                              {t("opprequests.opprequests_6")}
                            </div>
                          </div> */}
                            <div
                              className="opp_requests_card_body_action_container"
                              onClick={() => handleReject(item.id)}
                            >
                              <img src={decline} className="action_logo" />{" "}
                              <div className="opp_requests_card_body_action_text">
                                {t("opprequests.opprequests_4")}
                              </div>
                            </div>
                            <div
                              className="opp_requests_card_body_action_container"
                              onClick={() => handleAccept(item.id)}
                            >
                              <img src={check} className="action_logo" />{" "}
                              <div className="opp_requests_card_body_action_text">
                                {t("opprequests.opprequests_3")}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    });
                  })}
                </div>
              </div>
            </div>
          )}
          {page === 2 && (
            <div className="opp_requests_main_cont">
              <div className="opp_eval_requests_container">
                {/* <div className="opp_eval_requests_title">
                  {t("opprequests.opprequests_11")}
                </div> */}
                <div className="opp_eval_requests_subtitle">
                  {t("opprequests.opprequests_12")}
                </div>
                <div className="opp_eval_requests_cards_container_all">
                  {evalRequestsData?.map((evals) => {
                    return evals?.opportunities.map((item) => {
                      return item?.requests.map((reqs) => {
                        return (
                          <div>
                            {reqs?.evaluation_request?.evaluation_status === "Pending" && (
                              <div className="opp_eval_requests_cards_container">
                                <div className="opp_requests_card_header">
                                  {reqs?.volunteer?.points >= 8000 ? (
                                    <img
                                      src={star}
                                      className="opp_requests_star"
                                    />
                                  ) : (
                                    <img
                                      src={icon}
                                      className="opp_requests_star"
                                    />
                                  )}
                                  <div className="opp_requests_card_text">
                                    <div className="opp_requests_card_text_title">
                                      {reqs?.volunteer?.first_name}{" "}{reqs?.volunteer?.last_name}
                                    </div>
                                    <div className="opp_requests_card_text_subtitle">
                                      is requesting an evaluation on your
                                      volunteer opportunity:{" "}
                                      <div className="sub_title">
                                        {" "}
                                        {item.title}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="opp_requests_card_body">
                                  <div className="opp_requests_card_button_container">
                                    <button
                                      className="opp_requests_card_button"
                                      onClick={() =>
                                        handleEvaluate(
                                          reqs?.evaluation_request.id
                                        )
                                      }
                                    >
                                      {t("opprequests.opprequests_13")}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      });
                    });
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default OppRequests;
