import { React, useEffect } from "react";
import "./UpperSection.css";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import i18n from "../../i18n";
function UpperSection(props) {
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);
  const [lang, setLang] = useState(i18n.language);

  return (
    <div className="upper_section_green_bg">
      <h1 className="upper_section_title">{props.title}</h1>
      <div
        className={
          lang === "en"
            ? "upper_section_subs_main"
            : "upper_section_subs_main_ar"
        }
      >
        {props.text}
      </div>
      <div className="sub_menu">
        <p
          className={
            props.page == 1
              ? "upper_section_sub active_section_sub"
              : "upper_section_sub"
          }
          onClick={() => props.setPage(1)}
        >
          {props.subtitle}
        </p>
        {props.subtitle67 && (
          <p
            className={
              props.page == 1
                ? "upper_section_sub_4 active_section_sub"
                : "upper_section_sub_4"
            }
            onClick={() => props.setPage(1)}
          >
            {props.subtitle67}
          </p>
        )}
        {props.subtitle2 && (
          <>
            <div
              className="grey_vertical_border"
              style={{ margin: "0 20px" }}
            ></div>
            <div class="dotter_spacer"></div>
            <p
              className={
                props.page == 2
                  ? "upper_section_sub active_section_sub"
                  : "upper_section_sub"
              }
              onClick={() => props.setPage(2)}
            >
              {props.subtitle2}
            </p>
          </>
        )}
        {props.subtitle3 && (
          <>
            <div
              className="grey_vertical_border"
              style={{ margin: "0 20px" }}
            ></div>
            <div class="dotter_spacer"></div>
            <p
              className={
                props.page == 3
                  ? "upper_section_sub active_section_sub"
                  : "upper_section_sub"
              }
              onClick={() => props.setPage(3)}
            >
              {props.subtitle3}
            </p>
          </>
        )}{" "}
        {props.subtitle4 && (
          <>
            <div
              className="grey_vertical_border"
              style={{ margin: "0 20px" }}
            ></div>
            <div class="dotter_spacer"></div>
            <p
              className={
                props.page == 4
                  ? "upper_section_sub active_section_sub"
                  : "upper_section_sub"
              }
              onClick={() => props.setPage(4)}
            >
              {props.subtitle4}
            </p>
          </>
        )}
      </div>
    </div>
  );
}

export default UpperSection;
