import React, { useEffect, useState } from "react";
import Apply from "../Apply";
import i18n from "../../../../i18n";
import { useTranslation } from "react-i18next";
import { getuserinfo } from "../../../../Redux/user/userActions";
import { getOpportunitiesById } from "../../../../Redux/opportunities/opportunityActions";
import "./Apply_Step1.css";
import apply_img_individual from "../../../../assets/images/opportunity/apply/apply_individual.png";
import apply_img_group from "../../../../assets/images/opportunity/apply/apply_group.png";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import Apply_Step2 from "../Apply_Step2/Apply_Step2";
import Apply_Step5 from "../Apply_Step5/Apply_Step5";
import Apply_Step6 from "../Apply_Step6/Apply_Step6";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function Apply_Step1({ setOpen, open, opportunityId }) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [userTypeValue, setUserTypeValue] = useState("");
  const [openOutOfArea, setOpenOutOfArea] = useState(false);
  const [disabledButtons, setDisabledButtons] = useState(true);
  const [oppData, setOppData] = useState("");
  const size = useWindowSize();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const { userinfo } = useSelector((store) => store.userReducer);
  const { oppDetails } = useSelector((state) => state.opportunitiesReducer);
  const [opprtunityDetails, setOpprtunityDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const dispatch = useDispatch();
  const [openStep2, setOpenStep2] = useState(false);
  const [footerWidth, setFooterWidth] = useState("12.5%");

  useEffect(() => {
    setOpenOutOfArea(false);
    setDisabledButtons(true);
    dispatch(getOpportunitiesById(opportunityId));
    dispatch(getuserinfo(token));
    setOppData({ ...oppData, opportunityId: opportunityId });
  }, []);

  useEffect(() => {
    if (oppDetails != {} && userinfo != {} && oppDetails.length != 0 && userinfo.length != 0) {
      setOpprtunityDetails(oppDetails);
      setUserDetails(userinfo);
      setDisabledButtons(false);
    }
  }, [oppDetails, userinfo]);


  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      userDetails?.district_id != opprtunityDetails?.district_id &&
      opprtunityDetails?.district_id != undefined &&
      userDetails?.district_id != undefined
    ) {
      setOpenOutOfArea(true);
    }
    setOpenStep2(true); 
  };

  return (
    <>
      <Apply
        setOpen={setOpen}
        header={t("opportunity.apply_popup.apply_to_opp")}
        body={
          openStep2 ? (
            userTypeValue == "group" ? (
              <Apply_Step2
                setOpen={setOpen}
                open={open}
                data={oppData}
                opportunityId = {opportunityId}
                openOutOfArea={openOutOfArea}
                setFooterWidth={setFooterWidth}
              />
            ) : userTypeValue == "individual" && !openOutOfArea ? (
              <Apply_Step5
                setOpen={setOpen}
                open={open}
                data={oppData}
                previousFirstPage = {true}
                opportunityId = {opportunityId}
                openOutOfArea={openOutOfArea}
                setFooterWidth={setFooterWidth}
              />
            ) : userTypeValue == "individual" && openOutOfArea ? (
              <Apply_Step6
                setOpen={setOpen}
                open={open}
                data={oppData}
                previousFirstPage = {true}
                openOutOfArea = {openOutOfArea}
                opportunityId = {opportunityId}
                setFooterWidth={setFooterWidth}
              />
            ) : (
              ""
            )
          ) : (
            <form className="apply_step1" onSubmit={(e) => onSubmit(e)}>
              <div className="user_type_options">
                <div className="user_type_option">
                  <img className="apply_img" src={apply_img_individual} />
                  <div className="user_radio_comp">
                    <input
                      onChange={(e) => {
                        setUserTypeValue("individual");
                        setOppData({ ...oppData, is_group: false });
                      }}
                      type="radio"
                      id="individual"
                      name="user_type_radio"
                      value="individual"
                      class="individual_radio"
                      required
                    />
                    <label for="individual" style={{ cursor: "pointer" }}>
                      {" "}
                      {t("opportunity.apply_popup.individual_radio")}
                    </label>
                  </div>
                </div>
                <div className="user_type_option">
                  <img className="apply_img" src={apply_img_group} />
                  <div className="user_radio_comp">
                    <input
                      onChange={(e) => {
                        setUserTypeValue("group");
                        setOppData({ ...oppData, is_group: true });
                      }}
                      type="radio"
                      id="group"
                      name="user_type_radio"
                      value="group"
                      class="group_radio"
                      required
                    />
                    <label for="group" style={{ cursor: "pointer" }}>
                      {" "}
                      {t("opportunity.apply_popup.group_radio")}
                    </label>
                  </div>
                </div>
              </div>

              <div className="dotter_spacer" />
              <button
                type="submit"
                className={
                  size.width > 768
                    ? "primary_button app_step1_next_button"
                    : "primary_button app_step1_next_button_resp"
                }
                disabled={disabledButtons}
              >
                {size.width > 768 ? (
                  t("opportunity.apply_popup.next")
                ) : lang == "en" ? (
                  <AiOutlineArrowRight size="1.5rem" />
                ) : (
                  <AiOutlineArrowLeft size="1.5rem" />
                )}
              </button>
            </form>
          )
        }
        footer={
          <div
            className={
              lang == "en"
                ? "apply_footer_slider_green"
                : "apply_footer_slider_green apply_footer_slider_green_ar"
            }
            style={{ width: footerWidth }}
          ></div>
        }
      />
    </>
  );
}

export default Apply_Step1;
