import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  teams: [],
  loading: false,
  error: null,
};

const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    fetchteamsRequest(state) {
      state.loading = true;
      state.teams = [];
    },
    fetchteamsSuccess(state, action) {
      state.loading = false;
      state.teams = action.payload;
    },
    fetchteamsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const teamsAction = teamsSlice.actions;
export default teamsSlice;
