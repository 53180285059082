import React, { useEffect } from "react";
import { SlArrowDown } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getBlogId } from "../../Redux/Blogs/BlogsActions";
import "./BlogDetail.css";

import { useTranslation } from "react-i18next";
import { useState } from "react";

import i18n from "../../i18n";

import { useNavigate } from "react-router-dom";
import PopUp from "../PopUp/PopUp";
import { BsShare } from "react-icons/bs";

function BlogDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { blogDetail } = useSelector((store) => store.blogsReducer);
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [open, setOpen] = useState(false);
  const [urlshared, seturlshared] = useState("");
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (id) {
      dispatch(getBlogId(id));
    }
  }, [id]);
  const urlgenerator = () => {
    const url = window.location.href;
    seturlshared(url);
    setOpen(true);
    return url;
  };
  return (
    <div className="blog_detail_comp">
      <div className="opp_goback_div" onClick={() => navigate("/blogs_media")}>
        <SlArrowDown className="opp_goback_icon" /> <div>{t("blogs.back")}</div>
      </div>
      <div className="blog_detail_info">
        <h1 className="blog_detail_header">{blogDetail?.title}</h1>
        <i className="blog_detail_by">
          {t("blogs.by")} {blogDetail?.author}
        </i>

        <img
          src={process.env.REACT_APP_API + "/Blogs/" + blogDetail?.picture}
          className="blog_detail_img"
        />
        <div className="blog_detail_desc">{blogDetail?.text}</div>

        <div className="opp_detail_share_btn_cont">
          <div className="blog_detail_share" onClick={urlgenerator}>
            <BsShare size={25} className="opp_card_logo" />
            <div className="opp_card_save_text">{t("blogs.share")}</div>
          </div>
        </div>
        {open && (
          <PopUp
            setOpen={setOpen}
            body={
              <div className="popup_body">
                <input
                  className="home_popup_body_link"
                  value={urlshared}
                ></input>
                <button
                  className="home_popup_btn"
                  onClick={() => {
                    navigator.clipboard.writeText(urlshared);
                    setOpen(false);
                  }}
                >
                  {" "}
                  Copy
                </button>
              </div>
            }
          />
        )}
      </div>
    </div>
  );
}

export default BlogDetail;
