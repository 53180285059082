import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cachLoading: false,
  cachError: "",
  cashs: [],
  wishLoading: false,
  wishError: "",
  wish: [],
  stripLoading: false,
  stripError: "",
  strip: [],
  configLoading: false,
  configError: "",
  config: [],
  successLoading: false,
  successError: "",
  success: [],
  masterClassCachLoading: false,
  masterClassCachError: "",
  masterClassCashs: [],
  masterClassStripLoading: false,
  masterClassStripError: "",
  masterClassStrip: [],
};
const paySlice = createSlice({
  name: "pay",
  initialState,
  reducers: {
    cashsRequest(state) {
      state.cachLoading = true;
      state.cashs = [];
      state.cachError = "";
    },
    cashsSuccess(state, action) {
      state.cachLoading = false;
      state.cashs = action.payload;
      state.cachError = "";
    },
    cashsFail(state, action) {
      state.cachLoading = false;
      state.cashs = [];
      state.cachError = action.payload;
    },

    wishRequest(state) {
      state.wishLoading = true;
      state.wish = [];
      state.wishError = "";
    },
    wishSuccess(state, action) {
      state.wishLoading = false;
      state.wish = action.payload;
      state.wishError = "";
    },
    wishFail(state, action) {
      state.wishLoading = false;
      state.wish = [];
      state.wishError = action.payload;
    },

    stripRequest(state) {
      state.stripLoading = true;
      state.strip = [];
      state.stripError = "";
    },
    stripSuccess(state, action) {
      state.stripLoading = false;
      state.strip = action.payload;
      state.stripError = "";
    },
    stripFail(state, action) {
      state.stripLoading = false;
      state.strip = [];
      state.stripError = action.payload;
    },

    
    configRequest(state) {
      state.configLoading = true;
      state.config = [];
      state.configError = "";
    },
    configSuccess(state, action) {
      state.configLoading = false;
      state.config = action.payload;
      state.configError = "";
    },
    configFail(state, action) {
      state.configLoading = false;
      state.config = [];
      state.configError = action.payload;
    },

    successRequest(state) {
      state.successLoading = true;
      state.success = [];
      state.successError = "";
    },
    successSuccess(state, action) {
      state.successLoading = false;
      state.success = action.payload;
      state.successError = "";
    },
    successFail(state, action) {
      state.successLoading = false;
      state.success = [];
      state.successError = action.payload;
    },
    

    masterClassCashsRequest(state) {
      state.masterClassCachLoading = true;
      state.masterClassCashs = [];
      state.masterClassCachError = "";
    },
    masterClassCashsSuccess(state, action) {
      state.masterClassCachLoading = false;
      state.masterClassCashs = action.payload;
      state.masterClassCachError = "";
    },
    masterClassCashsFail(state, action) {
      state.masterClassCachLoading = false;
      state.masterClassCashs = [];
      state.masterClassCachError = action.payload;
    },


    masterClassStripRequest(state) {
      state.masterClassStripLoading = true;
      state.masterClassStrip = [];
      state.masterClassStripError = "";
    },
    masterClassStripSuccess(state, action) {
      state.masterClassStripLoading = false;
      state.masterClassStrip = action.payload;
      state.masterClassStripError = "";
    },
    masterClassStripFail(state, action) {
      state.masterClassStripLoading = false;
      state.masterClassStrip = [];
      state.masterClassStripError = action.payload;
    },
  },
});

export const payAction = paySlice.actions;
export default paySlice;
