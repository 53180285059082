import axios from "axios";
import { NotificationsAction } from "./NotificationsReducers";

export const getTotalNotifications = () => async (dispatch) => {
  dispatch(NotificationsAction.fetchTotalNotificationsRequest());
  try {
    let response = await axios.get(
      process.env.REACT_APP_API + "/notifications/user/count"
    );
    dispatch(NotificationsAction.fetchTotalNotificationsSuccess(response.data));
  } catch (error) {
    dispatch(NotificationsAction.fetchTotalNotificationsFail(error.message));
  }
};

export const getAllUserNotifications = (status) => async (dispatch) => {
  dispatch(NotificationsAction.fetchAllUserNotificationsRequest());
  try {
    let response = await axios.get(
      process.env.REACT_APP_API + "/notifications/user?status=" + status
    );
    dispatch(
      NotificationsAction.fetchAllUserNotificationsSuccess(response.data.data)
    );
  } catch (error) {
    dispatch(NotificationsAction.fetchAllUserNotificationsFail(error.message));
  }
};

export const editnotificationbyid = (id) => async (dispatch) => {
  dispatch(NotificationsAction.fetchallusernotfrequest());

  try {
    let response = await axios.put(
      process.env.REACT_APP_API + "/notifications/" + id,
      { is_read: 1 }
    );
    dispatch(NotificationsAction.fetchallusernotfsuccess(response.data));
  } catch (error) {
    dispatch(NotificationsAction.fetchallusernotffail(error.message));
  }
};
export const deletenoticationexpired = () => async (dispatch) => {
  dispatch(NotificationsAction.fetchexpirednotfrequest());

  try {
    let response = await axios.delete(
      process.env.REACT_APP_API + "/notifications/DeleteAfter10Days"
    );
    dispatch(NotificationsAction.fetchexpirednotfsuccess(response.data));
  } catch (error) {
    dispatch(NotificationsAction.fetchexpirednotffail(error.message));
  }
};
