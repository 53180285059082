import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./VolunteerSpotLightDetail.css";
import { SlArrowDown } from "react-icons/sl";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import i18n from "../../i18n";
import { getVolunteerSpotlightById } from "../../Redux/VolunteerSpotlight/VolunteerSpotlightActions";
import { getQABySpotlightId } from "../../Redux/VolunteerSpotlightQa/VolunteerSpotlightQaActions";
import { IoIosArrowDown } from "react-icons/io";

function VolunteerSpotLightDetail() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [lang, setLang] = useState(i18n.language);
  const navigate = useNavigate();
  const { volunteerSpotlightDetail } = useSelector(
    (store) => store.volunteerSpotlightReducer
  );
  const { volunteerSpotlightsQa } = useSelector(
    (store) => store.volunteerSpotlightQaReducer
  );
  const [volunteerSpotlightDetailsData, setVolunteerSpotlightDetailsData] =
    useState([]);
  const [volunteerSpotlightDetailsQaData, setVolunteerSpotlightDetailsQaData] =
    useState([]);
  const [arrowCollapsed, setArrowCollapsed] = useState([]);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (id) {
      dispatch(getVolunteerSpotlightById(id));
      dispatch(getQABySpotlightId(id));
    }
  }, [id]);

  useEffect(() => {
    if (volunteerSpotlightDetail != undefined) {
      setVolunteerSpotlightDetailsData(volunteerSpotlightDetail);
    }
  }, [volunteerSpotlightDetail]);

  useEffect(() => {
    if (volunteerSpotlightsQa != undefined) {
      setVolunteerSpotlightDetailsQaData(volunteerSpotlightsQa);
    }
  }, [volunteerSpotlightsQa]);

  const handleSetArrowCollapsed = (element) => {
    if (arrowCollapsed.includes(element)) {
      setArrowCollapsed(arrowCollapsed.filter((e) => e !== element));
    } else {
      setArrowCollapsed([...arrowCollapsed, element]);
    }
  };

  return (
    <div className="volunteerSpotLight_detail_comp">
      <div className="opp_goback_div" onClick={() => navigate("/blogs_media")}>
        <SlArrowDown className="opp_goback_icon" /> <div>{t("blogs.back")}</div>
      </div>
      {volunteerSpotlightDetailsData && (
        <div>
          <div className="volunteerSpotLight_detail_info">
            <h1 className="volunteerSpotLight_detail_header">
              {volunteerSpotlightDetailsData?.title}
            </h1>

            <img
              src={
                process.env.REACT_APP_API +
                "/VolunteerSpotlight/" +
                volunteerSpotlightDetailsData?.image
              }
              className="volunteerSpotLight_img"
            />
          </div>
        </div>
      )}
      <div className="questions_answers_container">
        {volunteerSpotlightDetailsQaData &&
          volunteerSpotlightDetailsQaData?.map((volunteerSpotlightQa, i) => {
            return (
              <div className="spotLightsDetails_container">
                <div className="spotLightsDetails_sub_cont">
                  <div
                    className="spotLightsDetails_sub_cont_header"
                    onClick={() => {
                      handleSetArrowCollapsed("spot_answer_answer_" + i);
                    }}
                  >
                    <div className="spotLightsDetails_sub_cont_title">
                      {volunteerSpotlightQa?.question}
                    </div>
                    <IoIosArrowDown
                      size="1.2rem"
                      className={
                        arrowCollapsed.includes("spot_answer_answer_" + i)
                          ? "spotLightsDetails_icon_arrow"
                          : "spotLightsDetails_icon_arrow spotLightsDetails_rotate_arrow"
                      }
                    />
                  </div>

                  {arrowCollapsed.includes("spot_answer_answer_" + i) && (
                    <div className="filter_sub_cont_list">
                      <div className="spotLightsDetails_list_check">
                        {volunteerSpotlightQa?.answer}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default VolunteerSpotLightDetail;
