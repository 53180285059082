import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  evalRequests: [],
  loading: false,
  userEval: [],
  certificates: [],
  error: null,
};

const evalRequestsSlice = createSlice({
  name: "evalRequests",
  initialState,
  reducers: {
    getEvalRequests(state, action) {
      state.loading = true;
      state.error = null;
      state.evalRequests = [];
    },
    getEvalRequestsSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.evalRequests = action.payload;
    },
    getEvalRequestsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.evalRequests = [];
    },
    getUserEval(state, action) {
      state.loading = true;
      state.error = null;
      state.userEval = [];
    },
    getUserEvalSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.userEval = action.payload;
    },
    getUserEvalFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.userEval = [];
    },
    fetchcirtificate(state, action) {
      state.loading = true;
      state.error = null;
      state.certificates = [];
    },
    fetchcirtificateSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.certificates = action.payload;
    },
    fetchcirtificateFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.certificates = [];
    },
  },
});

export const EvalRequestsActions = evalRequestsSlice.actions;
export default evalRequestsSlice;
