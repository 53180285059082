import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  feedbacks: [],
  loading: false,
  error: "",
  success: false,
};

const feedbacksSlice = createSlice({
  name: "feedbacks",
  initialState,
  reducers: {
    AddFeedbackRequest(state) {
      state.loading = true;
      state.error = "";
      state.success = false;
    },
    AddFeedbackRequestSuccess(state, action) {
      state.loading = false;
      state.feedbacks = action.payload;
      state.error = "";
      state.success = true;
    },
    AddFeedbackRequestFail(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
  },
});

export const FeedbacksActions = feedbacksSlice.actions;
export default feedbacksSlice;
