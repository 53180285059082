import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import i18n from "../../../../i18n";
import CompleteProfile from "../../CompleteProfile";
import "./Complete_Step6.css";
import Select from "react-select";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import {
  getuserinfo,
  updateUserinfo,
} from "../../../../Redux/user/userActions";
import { getAvailibilities } from "../../../../Redux/Availibilities/AvailibilitiesActions";
import Loading from "../../../../components/Loading/Loading";

function Complete_Step6() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const navigate = useNavigate();
  const size = useWindowSize();
  const dispatch = useDispatch();
  const [isNext, setIsNext] = useState(true);
  const { userinfo, loading } = useSelector((store) => store.userReducer);
  const { availibilities } = useSelector((store) => store.AvailibilitiesReducer);
  const [allAv, setAllAv] = useState([]);
  const token = localStorage.getItem("token");
  const [availability, setAvailability] = useState([]);
  const [text, setText] = useState(false);
  const [frequency, setFrequency] = useState();
  const [otherValue, setOtherValue] = useState("");


  useEffect(() => {
    if (availibilities) {
      setAllAv(availibilities)
    }
  }, [availibilities]);

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (availability && frequency) {
      const freq = frequency?.value;
      const user = {
        availability: [...new Set(availability)],
        other_availability: otherValue,
        frequency: freq,
      };
      const step = localStorage.getItem("step");
      if (step == 5) {
        localStorage.setItem("step", 6);
      }

      if (isNext) dispatch(updateUserinfo(token, user, navigate, 7));
      else dispatch(updateUserinfo(token, user, navigate, 0));
    }
  };

  const allFrequencies = [
    {
      value: "Any",
      label: lang == "en" ? "Any" : "أيما",
    },
    {
      value: "Daily",
      label: lang == "en" ? "Daily" : "يومي",
    },
    {
      value: "Weekly",
      label: lang == "en" ? "Weekly" : "اسبوعي",
    },
    {
      value: "Monthly",
      label: lang == "en" ? "Monthly" : "شهري",
    },
    {
      value: "Bi-Weekly",
      label: lang == "en" ? "Bi-Weekly" : "نصف أسبوعي",
    },
    {
      value: "Bi-Monthly",
      label: lang == "en" ? "Bi-Monthly" : "نصف شهري",
    },
    {
      value: "One Time Only",
      label: lang == "en" ? "One Time Only" : "مرة واحدة فقط",
    },
  ];


  const handleCheck = (e, isRadio) => {
    const value = parseInt(e.target.id, 10);
    setOtherValue('');
    if (isRadio) {
      setText(true);
      setAvailability([value]); // Set availability as an array with 'Other'
    } else {

      // Handle other checkboxes
      if (e.target.checked) {
        // Append the value to the availability array
        setAvailability((prevAvailability) =>
          prevAvailability.filter((value) => value !== 7)
        );
        setText(false);
        setAvailability((prevAvailability) => [...prevAvailability, value]);
      } else {
        // Remove the value from the availability array
        setAvailability((prevAvailability) =>
          prevAvailability.filter((item) => item !== value)
        );
      }

    }
  };

  useEffect(() => {
    dispatch(getuserinfo(token));
    dispatch(getAvailibilities());
  }, []);


  useEffect(() => {

    if (!availability.includes(7)) {
      setText(false);
    } else {
      setText(true);
      setOtherValue(userinfo?.other_availability);
    }
  }, [availability]);

  useEffect(() => {
    if (userinfo.frequency) {
      const newFreq = allFrequencies.filter(
        (s) => s.value == userinfo.frequency
      );
      setFrequency(newFreq[0]);
    }
    console.log("first",availability,"first")

    if (userinfo?.volunteer_availability) {
      userinfo?.volunteer_availability?.map((availability) => {
        setAvailability((prevAvailability) => [...prevAvailability, availability.availability_id]);
      });
    }
    
  }, [userinfo]);

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }
  return (
    <div className="complete_profile_step1_main_cont">
      <CompleteProfile
        header={t("CompleteProf.Join_the_Circle_3_Title")}
        body={
          loading ? (
            <Loading />
          ) : (
            <form
              className="complete_step3_main_body"
              onSubmit={(e) => handleSubmit(e)}
            >
              <div>
                <div className="complete_step3_header">
                  <p
                    className="complete_input_header"
                    style={{ marginBottom: "1rem" }}
                  >
                    {t("CompleteProf.complete_your_profile_20")}
                  </p>{" "}
                  <Select
                    value={frequency && frequency}
                    className="complete_profile_select"
                    name="nationality"
                    onChange={setFrequency}
                    placeholder={t("CompleteProf.select_Freq")}
                    required
                    options={allFrequencies}
                    isSearchable={false}
                  />
                </div>
                <div className="complete_step3_header">
                  <p
                    className="complete_input_header"
                    style={{ marginBottom: "1rem" }}
                  >
                    {t("CompleteProf.complete_your_profile_22")}
                  </p>{" "}
                  <div className="complete_step6_all_avail">
                    {allAv &&
                      allAv?.map((avail) => {
                        return (
                          <div className="complete_step6_avail">
                            <input
                              value={avail.id}
                              type="checkbox"
                              id={avail.id}
                              name="availability"
                              className="avail_radio"
                              onChange={(e) => handleCheck(e, avail.id == 7 ? true : false)}
                              checked={
                                availability.includes(avail.id) ||
                                  (text && avail.id == 7)
                                  ? "checked"
                                  : ""
                              }
                            />
                            <input
                                      type="hidden"
                                      value={availability.includes(avail.id) || (text && avail.id === 7)}
                                      required
                                    />
                            <div className="complete_step4_sector_info">
                              <label
                                className="complete_step4_avail_name"
                                for={"avail_radio" + avail.id}
                              >
                                {" "}
                                {lang == "en" ? avail.name : avail.name_ar}
                              </label>
                            </div>
                          </div>
                        );
                      })}

                    {text && (
                      <textarea
                        className="complete_profile_6_textarea"
                        required
                        placeholder={t("CompleteProf.complete_your_profile_36")}
                        value={otherValue}
                        onChange={(e) => setOtherValue(e.target.value)}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="complete_step1_footer">
                <div className="divider_line"></div>
                <div className="complete_step_footer_button">
                  <button
                    className={
                      size.width > 768
                        ? "primary_button complete_step_1_next_button"
                        : "primary_button complete_step_2_next_button_resp"
                    }
                    type="button"
                    onClick={() => navigate("/completeProfile/step5")}
                  >
                    {size.width > 768 ? (
                      t("CompleteProf.complete_your_profile_btn_5")
                    ) : lang == "en" ? (
                      <AiOutlineArrowLeft size="1.5rem" />
                    ) : (
                      <AiOutlineArrowRight size="1.5rem" />
                    )}
                  </button>

                  <div
                    className={
                      size.width > 768
                        ? "complete_step_right_footer"
                        : "complete_step_right_footer_resp"
                    }
                  >
                    <button
                      className={
                        size.width > 500
                          ? "pink_button complete_step_2_complete_button"
                          : "pink_button complete_step_2_next_button_resp"
                      }
                      onClick={() => setIsNext(false)}
                    >
                      {size.width > 500 ? (
                        t("CompleteProf.complete_your_profile_btn_3")
                      ) : (
                        <BiSave size="1.5rem" />
                      )}
                    </button>
                    <button
                      className={
                        size.width > 768
                          ? "primary_button complete_step_2_next_button"
                          : "primary_button complete_step_2_next_button_resp"
                      }
                      onClick={() => setIsNext(true)}
                    >
                      {size.width > 768 ? (
                        t("CompleteProf.complete_your_profile_btn_4")
                      ) : lang == "en" ? (
                        <AiOutlineArrowRight size="1.5rem" />
                      ) : (
                        <AiOutlineArrowLeft size="1.5rem" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )
        }
        footer={
          <div
            className={
              lang == "en"
                ? "complete_profile_footer_slider_green"
                : " complete_profile_footer_slider_green complete_profile_footer_slider_green_ar"
            }
            style={{ width: "60%" }}
          ></div>
        }
      />
    </div>
  );
}

export default Complete_Step6;
