import React, { useEffect, useState } from "react";
import "./Filters.css";
import { FaFilter } from "react-icons/fa";
import { VscDebugRestart } from "react-icons/vsc";
import { IoIosArrowDown } from "react-icons/io";
import { AiFillCloseCircle } from "react-icons/ai";
//tranlsation
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

//date picker
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import { useDispatch } from "react-redux";
import { getOpportunities } from "../../Redux/opportunities/opportunityActions";
import isAuth from "../../Utils/isAuth";
import axios from "axios";

function Filters(props) {
  //translation
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [lang, setLang] = useState(i18n.language);

  const today = new Date();
  const date = new Date(today);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const defaultValue = {
    year: year,
    month: month + 1,
    day: day,
  };

  const [startDate, setStartDate] = useState(null);
  const [selectedStart, setSelectedStart] = useState(null);
  const [selectedEnd, setSelectedEnd] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showRec, setShowRec] = useState(false);
  const [showLoc, setShowLoc] = useState(false);
  const [showSec, setShowSec] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [showOrg, setShowOrg] = useState(false);
  const [showImp, setShowImp] = useState(false);
  const [districts, setDistricts] = useState();
  const [selectedDis, setSelectedDis] = useState(null);
  const [sectors, setSectors] = useState();
  const [selectedSec, setSelectedSec] = useState(null);
  const [allOrg, setAllOrg] = useState();
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [allImpact, setAllImpact] = useState();
  const [selectedImp, setSelectedImp] = useState(null);
  const [selectedRecomendation, setselectedRecomendation] = useState(0);
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (startDate)
      setSelectedStart(
        new Date(startDate.year, startDate.month - 1, startDate.day)
      );
  }, [startDate]);
  useEffect(() => {
    if (endDate)
      setSelectedEnd(new Date(endDate.year, endDate.month - 1, endDate.day));
  }, [endDate]);

  useEffect(() => {
    dispatch(
      getOpportunities({
        selectedEnd,
        selectedStart,
        selectedDis,
        selectedSec,
        selectedOrg,
        selectedImp,
        selectedRecomendation,
      })
    );
  }, [
    selectedEnd,
    selectedStart,
    selectedDis,
    selectedSec,
    selectedOrg,
    selectedImp,
    selectedRecomendation,
  ]);

  const renderCustomInput = ({ ref }) => (
    <button readOnly ref={ref} className="filter_select_date">
      {t("opportunity.filter.start_date")}
      {startDate &&
        ": " + startDate.day + "/" + startDate.month + "/" + startDate.year}
    </button>
  );
  const renderCustomInput2 = ({ ref }) => (
    <button readOnly ref={ref} className="filter_select_date">
      {t("opportunity.filter.end_date")}
      {endDate && ": " + endDate.day + "/" + endDate.month + "/" + endDate.year}
    </button>
  );

  const getDistricts = async () => {
    const response = await axios.get(process.env.REACT_APP_API + "/districts");
    setDistricts(response.data.data);
  };

  const getSectors = async () => {
    const response = await axios.get(process.env.REACT_APP_API + "/sectors");
    setSectors(response.data.data);
  };

  const getImpacts = async () => {
    const response = await axios.get(
      process.env.REACT_APP_API + "/impacts/getAll"
    );
    setAllImpact(response.data.Impacts);
  };

  const getOrg = async () => {
    const response = await axios.get(
      process.env.REACT_APP_API + "/organizations/getAll"
    );
    setAllOrg(response.data.Organizations);
  };

  useEffect(() => {
    getDistricts();
    getOrg();
    getSectors();
    getImpacts();
  }, []);

  const updateDis = (dis, i) => {
    if (document.getElementById("loc" + i).checked) {
      if (selectedDis && selectedDis.length > 0) {
        if (!selectedDis.includes(dis.id)) setSelectedDis([...selectedDis, dis.id]);
      } else setSelectedDis([dis.id]);
    } else {
      if (selectedDis && selectedDis.length > 0) {
        if (selectedDis.includes(dis.id)) {
          const newDis = selectedDis.filter((id) => id !== dis.id);
          setSelectedDis(newDis);
        }
      }
    }
  };

  const updateSec = (sec, i) => {
    if (document.getElementById("sec" + i).checked) {
      if (selectedSec && selectedSec.length > 0) {
        if (!selectedSec.includes(sec.id)) setSelectedSec([sec.id]);
      } else setSelectedSec([sec.id]);
    } else {
      if (selectedSec && selectedSec.length > 0) {
        if (selectedSec.includes(sec.id)) {
          const newSec = selectedSec.splice(0, i);
          setSelectedDis(newSec);
        }
      }
    }
  };

  const updateOrg = (org, i) => {
    if (document.getElementById("org" + i).checked) {
      if (selectedOrg && selectedOrg.length > 0) {
        if (!selectedOrg.includes(org.id)) setSelectedOrg([...selectedOrg, org.id]);
      } else setSelectedOrg([org.id]);
    } else {
      if (selectedOrg && selectedOrg.length > 0) {
        if (selectedOrg.includes(org.id)) {
          const newOrg = selectedOrg.filter((id) => id !== org.id); // Remove org.id
          setSelectedOrg(newOrg);
        }
      }
    }
  };

  const updateImp = (imp, i) => {
    if (document.getElementById("imp" + i).checked) {
      if (selectedImp && selectedImp.length > 0) {
        if (!selectedImp.includes(imp.id)) setSelectedImp([...selectedImp, imp.id]);;
      } else setSelectedImp([imp.id]);
    } else {
      if (selectedImp && selectedImp.length > 0) {
        if (selectedImp.includes(imp.id)) {
          const newImp = selectedImp.filter((id) => id !== imp.id); // Remove imp.id
          setSelectedImp(newImp);
        }
      }
    }
  };
  const clearAll = () => {
    setSelectedDis(null);
    setSelectedSec(null);
    setSelectedOrg(null);
    setStartDate(null);
    setSelectedStart(null);
    setSelectedEnd(null);
    setEndDate(null);
    setShowRec(false);
    setShowLoc(false);
    setShowSec(false);
    setShowDate(false);
    setShowOrg(false);
    setShowImp(false);
  };
  return (
    <div className="filters_main_cont">
      <div className="filters_header">
        <div className="filter_title">
          <FaFilter className="filter_main_icon" />
          {t("opportunity.filter.title")}
        </div>
        <div className="filters_clear" onClick={() => clearAll()}>
          <VscDebugRestart className="filter_clear_icon" />{" "}
          {t("opportunity.filter.clear")}
        </div>
        <div className="filters_close" onClick={props.close}>
          <AiFillCloseCircle size="2rem" className="filter_clear_icon" />
        </div>
      </div>
      <div className="filers_main_underline"></div>

      <div className="filters_container">
        {localStorage.getItem("token") && (
          <div className="filter_sub_cont">
            <div
              className="filter_sub_cont_header"
              onClick={() => setShowRec(!showRec)}
            >
              <div className="filter_sub_cont_title">
                {t("opportunity.filter.rec")}
              </div>
              <IoIosArrowDown
                size="1.2rem"
                className={
                  !showRec
                    ? "filter_icon_arrow"
                    : "filter_icon_arrow rotate_arrow"
                }
              />
            </div>
            {showRec && (
              <div className="filter_sub_cont_list">
                <div className="filter_list_check">
                  <input
                    type="checkbox"
                    id="rec"
                    name="rec"
                    value="recommended"
                    className="filter_checkbox"
                    onClick={(e) => {
                      if (e.target.checked) setselectedRecomendation(1);
                      else setselectedRecomendation(0);
                    }}
                  />

                  <label for="rec" className="filter_list_comp">
                    {" "}
                    {t("opportunity.filter.show_only_recommended")}
                  </label>
                </div>
              </div>
            )}
          </div>
        )}

        <div className="filter_sub_cont">
          <div
            className="filter_sub_cont_header"
            onClick={() => setShowLoc(!showLoc)}
          >
            <div className="filter_sub_cont_title">
              {t("opportunity.filter.location")}
            </div>
            <IoIosArrowDown
              size="1.2rem"
              className={
                !showLoc
                  ? "filter_icon_arrow"
                  : "filter_icon_arrow rotate_arrow"
              }
            />
          </div>
          {showLoc && (
            <div className="filter_sub_cont_list">
              {districts &&
                districts?.map((dis, i) => {
                  return (
                    <div className="filter_list_check">
                      <input
                        type="checkbox"
                        id={"loc" + i}
                        name="loc"
                        value="loc"
                        className="filter_checkbox"
                        onClick={(e) => {
                          updateDis(dis, i);
                        }}

                      />
                      <label
                        for={"loc" + i}
                        className="filter_list_comp filter_radio"
                      >
                        {lang == "en" ? dis.name : dis.name_ar}
                      </label>
                    </div>
                  );
                })}
            </div>
          )}
        </div>

        <div className="filter_sub_cont">
          <div
            className="filter_sub_cont_header"
            onClick={() => setShowSec(!showSec)}
          >
            <div className="filter_sub_cont_title">
              {t("opportunity.filter.sector")}
            </div>
            <IoIosArrowDown
              size="1.2rem"
              className={
                !showSec
                  ? "filter_icon_arrow"
                  : "filter_icon_arrow rotate_arrow"
              }
            />
          </div>
          {showSec && (
            <div className="filter_sub_cont_list">
              {sectors &&
                sectors?.map((sec, i) => {
                  return (
                    <div className="filter_list_check">
                      <input
                        type="radio"
                        id={"sec" + i}
                        name="sec"
                        value="sec"
                        className="filter_checkbox"
                        onChange={(e) => {
                          updateSec(sec, i);
                        }}
                      />
                      <label
                        for={"sec" + i}
                        className="filter_list_comp filter_radio"
                      >
                        {lang == "en" ? sec.name : sec.name_ar}
                      </label>
                    </div>
                  );
                })}
            </div>
          )}
        </div>

        <div className="filter_sub_cont">
          <div
            className="filter_sub_cont_header"
            onClick={() => setShowDate(!showDate)}
          >
            <div className="filter_sub_cont_title">
              {t("opportunity.filter.date")}
            </div>
            <IoIosArrowDown
              size="1.2rem"
              className={
                !showDate
                  ? "filter_icon_arrow"
                  : "filter_icon_arrow rotate_arrow"
              }
            />
          </div>
          {showDate && (
            <div className="filter_sub_cont_list">
              <div className="filter_list_check">
                <DatePicker
                  colorPrimary="var(--primary)"
                  value={startDate}
                  onChange={setStartDate}
                  renderInput={renderCustomInput}
                  shouldHighlightWeekends
                  calendarClassName="responsive-calendar datepicker_cont "
                />
                <div className="filter_selected_date"></div>
              </div>
              <div className="filter_list_check">
                <DatePicker
                  colorPrimary="var(--primary)"
                  value={endDate}
                  onChange={setEndDate}
                  renderInput={renderCustomInput2}
                  shouldHighlightWeekends
                  calendarClassName="responsive-calendar datepicker_cont"
                  minimumDate={defaultValue}
                />
              </div>
            </div>
          )}
        </div>
        <div className="filter_sub_cont">
          <div
            className="filter_sub_cont_header"
            onClick={() => setShowOrg(!showOrg)}
          >
            <div className="filter_sub_cont_title">
              {t("opportunity.filter.org")}
            </div>
            <IoIosArrowDown
              size="1.2rem"
              className={
                !showOrg
                  ? "filter_icon_arrow"
                  : "filter_icon_arrow rotate_arrow"
              }
            />
          </div>
          {showOrg && (
            <div className="filter_sub_cont_list">
              {allOrg &&
                allOrg?.map((org, i) => {
                  return (
                    <div className="filter_list_check">
                      <input
                        type="checkbox"
                        id={"org" + i}
                        name="org"
                        value="org"
                        className="filter_checkbox"
                        onClick={(e) => {
                          updateOrg(org, i);
                        }}
                      />
                      <label
                        for={"org" + i}
                        className="filter_list_comp filter_radio"
                      >
                        {lang == "en" ? org.name : org.name_ar}
                      </label>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        <div className="filter_sub_cont">
          <div
            className="filter_sub_cont_header"
            onClick={() => setShowImp(!showImp)}
          >
            <div className="filter_sub_cont_title">
              {t("opportunity.filter.impact")}
            </div>
            <IoIosArrowDown
              size="1.2rem"
              className={
                !showImp
                  ? "filter_icon_arrow"
                  : "filter_icon_arrow rotate_arrow"
              }
            />
          </div>
          {showImp && (
            <div className="filter_sub_cont_list">
              {allImpact &&
                allImpact?.map((imp, i) => {
                  return (
                    <div className="filter_list_check">
                      <input
                        type="checkbox"
                        id={"imp" + i}
                        name="imp"
                        value="imp"
                        className="filter_checkbox"
                        onClick={(e) => {
                          updateImp(imp, i);
                        }}
                      />
                      <label
                        for={"imp" + i}
                        className="filter_list_comp filter_radio"
                      >
                        {lang == "en" ? imp.title : imp.name_ar}
                      </label>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Filters;
