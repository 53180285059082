import axios from "axios";
import { Checkin_outActions } from "./checkin_outReducers";

export const getCheckin = () => async (dispatch) => {
  try {
    dispatch(Checkin_outActions.getCheckin());
    let response = await axios.get(
      process.env.REACT_APP_API + "/checkins/organization/self?type=checkin",
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    dispatch(Checkin_outActions.getCheckinSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    dispatch(Checkin_outActions.getCheckinError(error.message));
  }
};
export const getCheckout = () => async (dispatch) => {
  try {
    dispatch(Checkin_outActions.getCheckout());
    let response = await axios.get(
      process.env.REACT_APP_API + "/checkins/organization/self?type=checkout",
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    dispatch(Checkin_outActions.getCheckoutSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    dispatch(Checkin_outActions.getCheckoutError(error.message));
  }
};

export const AcceptCheckin = (id) => async (dispatch) => {
  try {
    dispatch(Checkin_outActions.acceptCheckin());
    let response = await axios.post(
      process.env.REACT_APP_API + "/checkins/checkin/accept/" + id,

      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    dispatch(Checkin_outActions.acceptCheckinSuccess(response.data.data));
    dispatch(getCountCheckInOutRequest());
    dispatch(getCheckin());
  } catch (error) {
    dispatch(Checkin_outActions.acceptCheckinError(error.response.data.message));
  }
};

export const RejectCheckin = (id) => async (dispatch) => {
  try {
    dispatch(Checkin_outActions.RejectCheckin());
    let response = await axios.post(
      process.env.REACT_APP_API + "/checkins/checkin/reject/" + id,

      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    dispatch(Checkin_outActions.RejectCheckinSuccess(response.data.data));
    dispatch(getCheckin());
  } catch (error) {
    console.log(error);
    dispatch(Checkin_outActions.RejectCheckinError(error.message));
  }
};

export const AcceptCheckout = (id) => async (dispatch) => {
  try {
    dispatch(Checkin_outActions.acceptCheckout());
    let response = await axios.post(
      process.env.REACT_APP_API + "/checkins/checkout/accept",
      { ids: id },

      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    dispatch(Checkin_outActions.acceptCheckoutSuccess(response.data.data));
    dispatch(getCountCheckInOutRequest());
    dispatch(getCheckout());
  } catch (error) {
    console.log(error);
    dispatch(Checkin_outActions.acceptCheckoutError(error.message));
  }
};

export const RejectCheckout = (id) => async (dispatch) => {
  try {
    dispatch(Checkin_outActions.RejectCheckout());
    let response = await axios.post(
      process.env.REACT_APP_API + "/checkins/checkout/reject/" + id,

      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    dispatch(Checkin_outActions.RejectCheckoutSuccess(response.data.data));
    dispatch(getCheckout());
  } catch (error) {
    console.log(error);
    dispatch(Checkin_outActions.RejectCheckoutError(error.message));
  }
};
export const SendReport = (id) => async (dispatch) => {
  try {
    dispatch(Checkin_outActions.Reportvolunteer());
    let response = await axios.post(
      process.env.REACT_APP_API + "/Organization_feedback",
      { request_id: id },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    dispatch(Checkin_outActions.ReportvolunteerSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    dispatch(Checkin_outActions.ReportvolunteerError(error.message));
  }
};
export const calculateCheckinOutHours = (id) => async (dispatch) => {
  try {
    dispatch(Checkin_outActions.getCheckinOutHours());
    let response = await axios.get(
      process.env.REACT_APP_API + "/checkins/cal/" + id,

      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    dispatch(Checkin_outActions.getCheckinOutHoursSuccess(response.data));
  } catch (error) {
    console.log(error);
    dispatch(Checkin_outActions.getCheckinOutHoursError(error.message));
  }
};

export const getCountCheckInOutRequest = () => async (dispatch) => {
  try {
    dispatch(Checkin_outActions.getCountCheckInOutRequestRequest());
    let response = await axios.get(
      process.env.REACT_APP_API + "/checkins/getCountCheckInOutRequest",

      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    dispatch(Checkin_outActions.getCountCheckInOutRequestSuccess(response.data));
  } catch (error) {
    console.log(error);
    dispatch(Checkin_outActions.getCountCheckInOutRequestError(error.message));
  }
};