import axios from "axios";
import { RequestQuestionsAnswersAction } from "./RequestQuestionsAnswersReducers";

export const createRequestQuestionsAnswers =
  (requestQuestionsAnswers) => async (dispatch) => {
    dispatch(RequestQuestionsAnswersAction.createRequestQuestionsAnswers());
    try {
      let response = await axios.post(
        process.env.REACT_APP_API + "/request_question_answers",
        { answers: requestQuestionsAnswers }
      );
      dispatch(
        RequestQuestionsAnswersAction.createRequestQuestionsAnswersSuccess(
          response.data
        )
      );
    } catch (error) {
      dispatch(
        RequestQuestionsAnswersAction.createRequestQuestionsAnswersFail(
          error.message
        )
      );
    }
  };
export const getAnswer = (id) => async (dispatch) => {
  try {
    dispatch(RequestQuestionsAnswersAction.getanswer());
    const token = localStorage.getItem("token");
    let response = await axios.get(
      process.env.REACT_APP_API + "/request_question_answers/request/" + id,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(
      RequestQuestionsAnswersAction.getanswerSuccess(response.data.data)
    );
  } catch (error) {
    dispatch(RequestQuestionsAnswersAction.fetchOppByStatusFail(error.message));
  }
};
