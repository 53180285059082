import { React, useEffect, useState } from "react";
import "./Newsletter.css";
//redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { subscribeNewsLetter } from "../../Redux/NewsLetter/NewsLetterActions";
import Loading from "../Loading/Loading";

function Newsletter({ lang, t }) {
  const dispatch = useDispatch();
  const [email, setemail] = useState("");
  const [mess, setMess] = useState("");

  const { loading, error, success } = useSelector(
    (store) => store.newsLetterReducer
  );

  const subscribeHandler = () => {
    dispatch(subscribeNewsLetter(email));
    setemail("");
  };

  useEffect(() => {
    if (success) {
      setMess("Subscribed successfully");
    } else {
      setMess(error);
    }
  }, [success]);

  return (
    <div
      className={lang === "en" ? "newsletter_cont" : "newsletter_cont_arabic"}
    >
      <div>
        <h3
          className={
            lang === "en" ? "newsletter-title" : "newsletter-title-arabic"
          }
        >
          {t("Footer.newsletter-title")}
        </h3>
        <p
          className={
            lang === "en" ? "newsletter_subtitle" : "newsletter_subtitle_arabic"
          }
        >
          {t("Footer.newsletter-subtitle")}
        </p>
      </div>
      <div className="home_subscription">
        <form
          className={lang === "en" ? "newsletter_form" : "newsletter_form_arabic"}
        >
          <label>
            <input
              autoComplete={false}
              className={lang === "en" ? "input-form" : "input-form-arabic"}
              type="text"
              value={email}
              name="email"
              onChange={(e) => setemail(e.target.value)}
              placeholder={t("Footer.email")}
            />
          </label>
        </form>
        {mess && <span style={{ color: "white" }}>{mess}</span>}
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="footer_btn_cont">
          <button
            className={
              lang === "en" ? "footer-button-form" : "footer-button-form-arabic"
            }
            onClick={subscribeHandler}
          >
            {/* <span>{t("Footer.subscribe")}</span> */}
            {t("Footer.subscribe")}
          </button>
        </div>
      )}
    </div>
  );
}

export default Newsletter;
