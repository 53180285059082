import "./DonatePopup.css";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import PopUp from "../../components/PopUp/PopUp";
import { useState } from "react";
import { useEffect } from "react";
//redux
import { useSelector } from "react-redux";
import { createdonation,onSuccessDonation } from "../../Redux/Donate/DonateActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getConfig,
} from "../../Redux/Pay/PayActions";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../components/Stripe/CheckoutForm";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

function DonatePopup(props) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [ammount, setammount] = useState(0);
  const [currency, setcurrency] = useState("");
  const [openStripPopUpForm, setOpenStripPopUpForm] = useState(false);
  const dispatch = useDispatch();
  const [clientSecret, setClientSecret] = useState("");
  const { donations, success ,loading,donationError } = useSelector((store) => store.DonateReducer);
  const [openSuccessMsg, setOpenSuccessMsg] = useState(false);
  const {config } = useSelector(
    (store) => store.PayReducer
  );
  const [donationId, setDonationId] = useState(null);
  const navigate = useNavigate();
  const [stripePromise, setStripePromise] = useState(null);
  
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const handleDonate = () => {

    setOpenStripPopUpForm(true);
    dispatch(getConfig());
    dispatch(createdonation(ammount, currency));
  };

  useEffect(() => {
    if (openSuccessMsg) {
      if (donationError.length == 0) {
        NotificationManager.success("Your donation is completed successfully!", "", 3000);
      } else {
        NotificationManager.error(donationError, "", 3000);
      }
    }
  }, [openSuccessMsg]);

  useEffect(() => {
    if (donations?.clientSecret) {
      setDonationId(donations?.payment?.id);
      setClientSecret(donations?.clientSecret);
    }
  }, [donations]);

  useEffect(() => {
    if (config?.publishableKey) {
      setStripePromise(loadStripe(config?.publishableKey));
    }
  }, [config]);

  return (
    <div className="donate_main_con">
      <NotificationContainer />
      <PopUp
        setOpen={props.setOpen}
        cont={
          <div className="create_pop_main_container">
            <div className="donate_pop_cont">
              <div>
                <h2 className="donate_popup_header">{t("donate.donate")}</h2>

                <div className="donate_popup_body">
                  <form>
                    <input
                      required
                      className="amount_input input_field"
                      placeholder={t("donate.amount")}
                      onChange={(e) => setammount(e.target.value)}
                    />

                    <select
                      name="currency"
                      required
                      className={
                        lang === "ar"
                          ? "currency_dropdown arabic-select"
                          : "currency_dropdown"
                      }
                      onChange={(e) => setcurrency(e.target.value)}
                    >
                      <option selected disabled value="">
                        {t("donate.currency")}
                      </option>
                      <option value="USD">USD</option>
                      {/* <option value="LBP">LBP</option> */}
                    </select>
                  </form>
                </div>
                <div className="donate_footer_slider">
                  <div className="donate_button_container">

                    <button
                      className="donate_button_acess"
                      onClick={handleDonate}
                    >
                      {" "}
                      {t("donate.donate")}{" "}
                    </button>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        }
      />
         {openStripPopUpForm && clientSecret && stripePromise && (
          <PopUp
            setOpen={setOpenStripPopUpForm}
            body={
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm
                  stripePromise={stripePromise}
                  clientSecret={clientSecret}
                  paymentId={donationId}
                  setOpenSuccessMsg={setOpenSuccessMsg}
                  setOpenStripPopUpForm={setOpenStripPopUpForm}
                  onSuccessPayment={onSuccessDonation}
                />
              </Elements>
            }
          />
        )}

    </div>
  );
}

export default DonatePopup;
