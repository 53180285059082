import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  volunteerSpotlights: [],
  volunteerSpotlightDetail:[],
  spotLoading: false,
  error: ""
};
const volunteerSpotlightSlice = createSlice({
  name: "VolunteerSpotlight",
  initialState,
  reducers: {
    fetchvolunteerSpotlightRequest(state) {
      state.spotLoading = true;
      state.volunteerSpotlights = [];
      state.error = "";
    },

    fetchvolunteerSpotlightSuccess(state, action) {
      state.spotLoading = false;
      state.volunteerSpotlights = action.payload;
      state.error = "";
    },

    fetchvolunteerSpotlightFail(state, action) {
      state.spotLoading = false;
      state.volunteerSpotlights = [];
      state.error = action.payload;
    },

    fetchVolunteerSpotlightDetailRequest(state) {
      state.spotLoading = true;
      state.volunteerSpotlightDetail = [];
      state.error = "";
    },

    fetchVolunteerSpotlightDetailSuccess(state, action) {
      state.spotLoading = false;
      state.volunteerSpotlightDetail = action.payload;
      state.error = "";
    },

    fetchVolunteerSpotlightDetailFail(state, action) {
      state.spotLoading = false;
      state.volunteerSpotlightDetail = [];
      state.error = action.payload;
    },

  },
});

export const volunteerSpotlightAction = volunteerSpotlightSlice.actions;
export default volunteerSpotlightSlice;
