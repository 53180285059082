import axios from "axios";
import { getvolCommunityAct } from "../Community_Action/CommunityActActions";

import { opportunityActions } from "../opportunities/opportunityReducer";

export const getOpportunities =
  ({
    selectedStart,
    selectedEnd,
    selectedDis,
    selectedSec,
    selectedOrg,
    selectedImp,
    selectedRecomendation,
  }) =>
    async (dispatch) => {
      dispatch(opportunityActions.fetchOpportunitiesSuccess());
      let url;
      if (localStorage.getItem("token")) {
        url = process.env.REACT_APP_API + "/opportunities?";
      } else url = process.env.REACT_APP_API + "/opportunities/All?";

      if (selectedStart)
        url += "start_date=" + new Date(selectedStart).toDateString() + "&";
      if (selectedEnd)
        url += "end_date=" + new Date(selectedEnd).toDateString() + "&";
      if (selectedDis) url += "location=" + selectedDis + "&";
      if (selectedSec) url += "sector=" + selectedSec + "&";
      if (selectedOrg) url += "organization=" + selectedOrg + "&";
      if (selectedImp) url += "impact=" + selectedImp + "&";
      if (selectedRecomendation)
        url += "recommendation=" + selectedRecomendation + "&";
      try {
        let response;
        if (localStorage.getItem("token")) {
          const token = localStorage.getItem("token");
          response = await axios.get(url, { Authorization: "Bearer " + token });
        } else response = await axios.get(url);
        dispatch(
          opportunityActions.fetchOpportunitiesSuccess(response.data.data)
        );
      } catch (error) {
        dispatch(opportunityActions.fetchOpportunitiesFail(error.message));
      }
    };

export const getRecOpportunities =
  (selectedRecomendation) => async (dispatch) => {
    dispatch(opportunityActions.fetchRecOppRequest());
    let url = process.env.REACT_APP_API + "/opportunities?";

    if (selectedRecomendation)
      url += "recommendation=" + selectedRecomendation + "&";
    try {
      let response = await axios.get(url);
      let rec = [];
      for (let i = 0; i < response.data.data.length(); i++) {
        if (i < 5) rec.push(response.data.data);
      }
      dispatch(opportunityActions.fetchRecOppSuccess(rec));
    } catch (error) {
      dispatch(opportunityActions.fetchRecOppFail(error.message));
    }
  };

export const getOpportunitiesByStatus = (status) => async (dispatch) => {
  try {
    dispatch(opportunityActions.fetchOppByStatusRequest());
    let response = await axios.get(
      process.env.REACT_APP_API +
      "/opportunities/my-opportunities?status=" +
      status
    );

    dispatch(opportunityActions.fetchOppByStatusSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    dispatch(opportunityActions.fetchOppByStatusFail(error.message));
  }
};

export const getFavoritesOpportunities = () => async (dispatch) => {
  try {
    dispatch(opportunityActions.fetchFavOppRequest());
    let response = await axios.get(
      process.env.REACT_APP_API + "/favorites/opportunities/self"
    );

    dispatch(opportunityActions.fetchFavOppSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    dispatch(opportunityActions.fetchOppByStatusFail(error.message));
  }
};

export const checkOut = (request_id, is_last_checkout) => async (dispatch) => {
  try {
    dispatch(opportunityActions.updateRequestStatus());
    let response = await axios.post(
      process.env.REACT_APP_API + "/checkins/checkout/" + request_id,
      { is_final: is_last_checkout }
    );
    dispatch(opportunityActions.updateRequestStatusSuccess(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const checkIn = (request_id) => async (dispatch) => {
  try {
    dispatch(opportunityActions.updateRequestStatus());
    let response = await axios.post(
      process.env.REACT_APP_API + "/checkins/checkin/" + request_id
    );
    dispatch(opportunityActions.updateRequestStatusSuccess(response));
  } catch (error) {
    console.log(error);
  }
};

export const getOrgOpp = (token) => async (dispatch) => {
  try {
    dispatch(opportunityActions.fetchOrgOppRequest());
    let response = await axios.get(
      process.env.REACT_APP_API + "/opportunities/organization/own",
      {
        token,
      }
    );
    dispatch(opportunityActions.fetchOrgOppSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    dispatch(opportunityActions.fetchOrgOppFail(error.message));
  }
};

export const getOpportunitiesById = (id) => async (dispatch) => {
  try {
    dispatch(opportunityActions.fetchOppDetailsRequest());
    let response = await axios.get(
      process.env.REACT_APP_API + "/opportunities/" + id
    );

    dispatch(opportunityActions.fetchOppDetailsSuccess(response.data.data));
  } catch (error) {
    dispatch(opportunityActions.fetchOppDetailsFail(error.message));
  }
};

export const updateOpportunity =
  (id, status, community) => async (dispatch) => {
    try {
      dispatch(opportunityActions.updateOppRequest());
      await axios.put(
        process.env.REACT_APP_API + `/opportunities/${id}`,

        { status: status }
      );

      if (!community) {
        dispatch(getOrgOpp());
      } else {
        dispatch(getvolCommunityAct());
      }
    } catch (error) {
      dispatch(opportunityActions.updateOppFail(error.message));
    }
  };

export const createOpportunity = (data) => async (dispatch) => {
  try {
    dispatch(opportunityActions.createOppRequest());
    const response = await axios.post(
      process.env.REACT_APP_API + `/opportunities/`,

      data
    );
    dispatch(opportunityActions.createOppSuccess(response.data.data));
    dispatch(getOrgOpp());
  } catch (error) {
    console.log(error);
    dispatch(opportunityActions.createOppFail(error.response.data.message));
  }
};

export const editOpportunity = (id, data, community) => async (dispatch) => {
  try {
    dispatch(opportunityActions.updateOppRequest());

    await axios.put(
      process.env.REACT_APP_API + `/opportunities/${id}`,

      data
    );
    dispatch(opportunityActions.updateOppSucess());

    if (!community) {
      dispatch(getOrgOpp());
    } else {
      // dispatch(getvolCommunityAct());
    }
  } catch (error) {
    dispatch(opportunityActions.updateOppFail(error.message));
  }
};

export const getdisabilitiesbyid = (id) => async (dispatch) => {
  try {
    dispatch(opportunityActions.fetchdisablitiesRequest());
    let response = await axios.get(
      process.env.REACT_APP_API + "/opportunity_disabilities/" + id
    );
    dispatch(opportunityActions.fetchdisablitiesSuccess(response.data.data));
  } catch (error) {
    dispatch(opportunityActions.fetchdisablitiesFail(error.message));
  }
};
export const getimpactsbyorgid = (id) => async (dispatch) => {
  try {
    dispatch(opportunityActions.fetchimpactsRequest());
    let response = await axios.get(
      process.env.REACT_APP_API + "/ImpactOpportunity/getByOpp/" + id
    );
    dispatch(opportunityActions.fetchimpactsSuccess(response.data.data));
  } catch (error) {
    dispatch(opportunityActions.fetchimpactsFail(error.message));
  }
};

export const sendreminderbyid = (id) => async (dispatch) => {
  try {
    dispatch(opportunityActions.fetchreminderRequest());
    let response = await axios.get(
      process.env.REACT_APP_API +
      "/notifications/NotificaionLessThanThree/" +
      id
    );
    dispatch(opportunityActions.fetchreminderSuccess(response.data.data));
  } catch (error) {
    dispatch(opportunityActions.fetchreminderFail(error.message));
  }
};


export const getFeedbackQuestion = () => async (dispatch) => {
  try {
    dispatch(opportunityActions.volunteerFeedbackRequest());
    let response = await axios.get(
      process.env.REACT_APP_API + "/feedbackQuestion/getAll/"
    );

    dispatch(opportunityActions.volunteerFeedbackSuccess(response.data.data));
  } catch (error) {
    dispatch(opportunityActions.volunteerFeedbackFail(error.message));
  }
};

export const getVolunteerConnectQuestion = () => async (dispatch) => {
  try {
    dispatch(opportunityActions.volunteerConnectQuestionRequest());
    let response = await axios.get(
      process.env.REACT_APP_API + "/volunteerConnectQuestion/getAll"
    );

    dispatch(opportunityActions.volunteerConnectQuestionSuccess(response.data.data));
  } catch (error) {
    dispatch(opportunityActions.volunteerConnectQuestionFail(error.message));
  }
};

export const getVolunteerInfluenceQuestion = () => async (dispatch) => {
  try {
    dispatch(opportunityActions.volunteerInfluenceRequest());
    let response = await axios.get(
      process.env.REACT_APP_API + "/volunteerInfluenceQuestion/getAll"
    );

    dispatch(opportunityActions.volunteerInfluenceSuccess(response.data.data));
  } catch (error) {
    dispatch(opportunityActions.volunteerInfluenceFail(error.message));
  }
};