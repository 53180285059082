import axios from "axios";
import { EvalRequestsActions } from "./EvalRequestsReducers";

export const getEvalRequests = () => async (dispatch) => {
  try {
    dispatch(EvalRequestsActions.getEvalRequests());
    let response = await axios.get(
      process.env.REACT_APP_API + "/evaluations/self/"
      //   {
      //     token,
      //   }
    );
    dispatch(EvalRequestsActions.getEvalRequestsSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    dispatch(EvalRequestsActions.getEvalRequestsFailure(error.message));
  }
};

export const getOrgEvalRequests = () => async (dispatch) => {
  try {
    dispatch(EvalRequestsActions.getEvalRequests());
    let response = await axios.get(
      process.env.REACT_APP_API + "/evaluations/selforg/"
      //   {
      //     token,
      //   }
    );
    dispatch(EvalRequestsActions.getEvalRequestsSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    dispatch(EvalRequestsActions.getEvalRequestsFailure(error.message));
  }
};

export const getUserEval = (id) => async (dispatch) => {
  try {
    dispatch(EvalRequestsActions.getUserEval());
    let response = await axios.get(
      process.env.REACT_APP_API + "/evaluations/" + id
    );
    dispatch(EvalRequestsActions.getUserEvalSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    dispatch(EvalRequestsActions.getUserEvalFailure(error.message));
  }
};

export const edituserEval = (data) => async (dispatch) => {
  try {
    dispatch(EvalRequestsActions.getUserEval());
    let response = await axios.put(
      process.env.REACT_APP_API + "/evaluations/" + data.id,
      data
    );
    dispatch(EvalRequestsActions.getUserEvalSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    dispatch(EvalRequestsActions.getUserEvalFailure(error.message));
  }
};

export const fetchcirtificate = () => async (dispatch) => {
  try {
    dispatch(EvalRequestsActions.fetchcirtificate());
    let response = await axios.get(
      process.env.REACT_APP_API + "/checkins/certificate",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    dispatch(
      EvalRequestsActions.fetchcirtificateSuccess(response.data.certeficates)
    );
  } catch (error) {
    console.log(error);
    dispatch(EvalRequestsActions.fetchcirtificateFailure(error.message));
  }
};
