import { React, useState, useEffect } from "react";
import "./ViewRequests.css";
import { useSelector, useDispatch } from "react-redux";
import star from "../../../assets/images/OppRequests/Group 1232.png";
import bookmark from "../../../assets/images/OppRequests/Group 1532.png";
import check from "../../../assets/images/OppRequests/Group 1534.png";
import view from "../../../assets/images/OppRequests/Group 1531.png";
import decline from "../../../assets/images/OppRequests/Group 1533.png";
import { useSSR, useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { useNavigate } from "react-router-dom";
import heart_hand from "../../../assets/images/icons/heart-hand.png";

import { useParams } from "react-router-dom";
//icons
import achievement from "../../../assets/images/viewreq/achievement.png";
import lastname from "../../../assets/images/viewreq/email (2).png";
import email from "../../../assets/images/viewreq/mobile-phone.png";
import { MdLocalPhone } from "react-icons/md";
import suitcase from "../../../assets/images/viewreq/suitcase (1).png";
import confetti from "../../../assets/images/viewreq/confetti.png";
import worldwide from "../../../assets/images/viewreq/worldwide.png";
import pinmap from "../../../assets/images/viewreq/pin-map.png";
import { MdEventAvailable } from "react-icons/md";
import appointment from "../../../assets/images/viewreq/appointment.png";
import heartbeat from "../../../assets/images/viewreq/heartbeat.png";
import volunteer from "../../../assets/images/viewreq/volunteer.png";
import heart from "../../../assets/images/viewreq/heart (3).png";

//redux
import {
  getOppRequests,
  acceptOppRequest,
  rejectOppRequest,
  getOppRequestsbyid,
} from "../../../Redux/OppRequests/OppRequestsActions";
import { getuserinfobyid } from "../../../Redux/user/userActions";
import { getvolreqCommunityAct } from "../../../Redux/Community_Action/CommunityActActions";
import Loading from "../../../components/Loading/Loading";
import axios from "axios";
import { getuserpointsbyid } from "../../../Redux/user/userActions";
import { getAnswer } from "../../../Redux/RequestQuestionsAnswers/RequestQuestionsAnswersActions";

function ViewRequests() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [Age, setAge] = useState();
  const [lang, setLang] = useState(i18n.language);
  const [volExp, setVolExp] = useState();
  const [volSec, setVolSec] = useState();
  const { oppRequests, opps, ReqOpp } = useSelector(
    (store) => store.oppRequestsReducer
  );
  const { idinfo, useridinfo, dataidinfo, loading, points } = useSelector(
    (store) => store.userReducer
  );
  const { reqsvolcommunityAct } = useSelector(
    (store) => store.communityActReducer
  );
  const { answer } = useSelector(
    (store) => store.RequestQuestionsAnswersReducer
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("role") === "3") {
      dispatch(getvolreqCommunityAct());
    } else {
      dispatch(getOppRequests());
    }

    dispatch(getOppRequestsbyid(id));
  }, []);
  useEffect(() => {
    if (ReqOpp.volunteer_id) {
      dispatch(getuserpointsbyid(ReqOpp.volunteer_id));
    }
  }, [ReqOpp]);
  useEffect(() => {
    dispatch(getAnswer(id));
  }, []);
  console.log("answer", answer);
  const getVolExp = async () => {
    const resp = await axios.get(
      process.env.REACT_APP_API +
        `/volunteer_expertises/${ReqOpp.volunteer_id}`,

      { Authorization: "Bearer " + localStorage.getItem("token") }
    );
    setVolExp(resp.data.data);
  };

  const getvolSec = async () => {
    const resp = await axios.get(
      process.env.REACT_APP_API +
        `/volunteersector/volunteer/${ReqOpp.volunteer_id}`,

      { Authorization: "Bearer " + localStorage.getItem("token") }
    );
    setVolSec(resp.data.data);
  };

  useEffect(() => {
    if (ReqOpp.volunteer_id) {
      dispatch(getuserinfobyid(ReqOpp.volunteer_id));
      getVolExp();
      getvolSec();
    }
  }, [ReqOpp]);
  const dispatch = useDispatch();
  const handleAccept = () => {
    if (oppRequests) {
      dispatch(acceptOppRequest(id, navigate));
    }
  };
  const handleReject = () => {
    if (oppRequests) {
      dispatch(rejectOppRequest(id, navigate));
    }
  };

  useEffect(() => {
    if (idinfo[0]?.date_of_birth) {
      Agenow();
    }
  }, [idinfo[0]]);
  const Agenow = () => {
    var today = new Date();
    var birthDate = idinfo[0]?.date_of_birth;
    var age = today.getFullYear() - birthDate?.slice(0, 4);
    var m = today.getMonth() - birthDate;
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    setAge(age);
  };
  return (
    <div className="Main_view_div">
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="cards_main_div">
            <div className="opp_requests_cards_container">
              {/* {oppRequests?.opportunities?.map((opp) => {
            return opp?.requests?.map((item) => {
              return ( */}
              <div className="opp_requests_card">
                <div className="opp_requests_card_header">
                  {idinfo[0]?.points >= 10000 ? (
                    <img src={star} className="opp_requests_star" />
                  ) : (
                    <img src={heart_hand} className="opp_requests_star" />
                  )}
                  <div className="opp_requests_card_text">
                    <div className="opp_requests_card_text_title">
                      {" "}
                      {idinfo[0]?.first_name} {idinfo[0]?.last_name}
                    </div>
                    <div className="opp_requests_card_text_subtitle">
                      {" "}
                      Is interested in your volunteer opportunity
                    </div>
                  </div>
                </div>
                <div className="opp_requests_card_body">
                  <div
                    className="opp_requests_card_body_action_container"
                    onClick={() => handleReject()}
                  >
                    <img src={decline} className="action_logo" />{" "}
                    <div className="opp_requests_card_body_action_text">
                      {t("opprequests.opprequests_4")}
                    </div>
                  </div>
                  <div
                    className="opp_requests_card_body_action_container"
                    onClick={() => handleAccept()}
                  >
                    <img src={check} className="action_logo" />{" "}
                    <div className="opp_requests_card_body_action_text">
                      {t("opprequests.opprequests_3")}
                    </div>
                  </div>
                </div>
              </div>
              {/* );
            });
          })} */}
            </div>
          </div>
          <div className="opp_view_vol_info">
            <div className="opp_view_vol_info_container">
              <div className="opp_view_vol_info_container_Row_1">
                <div className="opp_view_vol_info_container_col1">
                  <div className="opp_view_vol_info_container_title">
                    <div className="view_icon_cont">
                      <img src={achievement} className="view_icon" />
                    </div>
                    {t("viewrequest.viewrequest_1")}
                  </div>
                  <div className="opp_view_vol_info_container_subtitle">
                    {idinfo[0]?.first_name}
                  </div>
                </div>
                <div className="opp_view_vol_info_container_col1">
                  <div className="opp_view_vol_info_container_title">
                    <div className="view_icon_cont">
                      <img src={lastname} className="view_icon" />
                    </div>
                    {t("viewrequest.viewrequest_2")}
                  </div>
                  <div className="opp_view_vol_info_container_subtitle">
                    {idinfo[0]?.last_name}
                  </div>
                </div>
                <div className="opp_view_vol_info_container_col1">
                  <div className="opp_view_vol_info_container_title">
                    <div className="view_icon_cont">
                      <img src={email} className="view_icon" />
                    </div>
                    {t("viewrequest.viewrequest_3")}
                  </div>
                  <div className="opp_view_vol_info_container_subtitle">
                    {dataidinfo?.email}
                  </div>
                </div>
                <div className="opp_view_vol_info_container_col1">
                  <div className="opp_view_vol_info_container_title">
                    <div className="view_icon_cont">
                      <MdLocalPhone className="view_icon" />
                    </div>
                    {t("viewrequest.viewrequest_4")}
                  </div>
                  <div className="opp_view_vol_info_container_subtitle">
                    {" "}
                    {idinfo[0]?.phone_number}
                  </div>
                </div>
              </div>
              <div className="opp_view_vol_info_container_Row_1">
                <div className="opp_view_vol_info_container_col1">
                  <div className="opp_view_vol_info_container_title">
                    <div className="view_icon_cont">
                      <img src={confetti} className="view_icon" />
                    </div>
                    {t("viewrequest.viewrequest_5")}
                  </div>
                  <div className="opp_view_vol_info_container_subtitle">
                    {Age}
                  </div>
                </div>
                <div className="opp_view_vol_info_container_col1">
                  <div className="opp_view_vol_info_container_title">
                    <div className="view_icon_cont">
                      <img src={worldwide} className="view_icon" />
                    </div>
                    {t("viewrequest.viewrequest_6")}
                  </div>
                  <div className="opp_view_vol_info_container_subtitle">
                    {lang === "en"
                      ? idinfo[0]?.nationality?.name
                      : idinfo[0]?.nationality?.name_ar}
                  </div>
                </div>
                <div className="opp_view_vol_info_container_col1">
                  <div className="opp_view_vol_info_container_title">
                    <div className="view_icon_cont">
                      <img src={pinmap} className="view_icon" />
                    </div>
                    {t("viewrequest.viewrequest_7")}
                  </div>
                  <div className="opp_view_vol_info_container_subtitle">
                    {lang === "en"
                      ? idinfo[0]?.district?.name
                      : idinfo[0]?.district?.name_ar}
                  </div>
                </div>
                <div className="opp_view_vol_info_container_col1">
                  <div className="opp_view_vol_info_container_title">
                    <div className="view_icon_cont">
                      <img src={suitcase} className="view_icon" />
                    </div>
                    {t("viewrequest.viewrequest_8")}
                  </div>
                  <div className="opp_view_vol_info_container_subtitle">
                    {idinfo[0]?.occupation}
                  </div>
                </div>
              </div>
              <div className="opp_view_vol_info_container_Row_1">
                {/* <div className="opp_view_vol_info_container_col1">
                  <div className="opp_view_vol_info_container_title">
                    <div className="view_icon_cont">
                      <img src={achievement} className="view_icon" />
                    </div>
                    {t("viewrequest.viewrequest_9")}
                  </div>
                  {volExp?.length > 0
                    ? volExp?.map((vol, i) => {
                        return (
                          <div className="opp_view_vol_info_container_subtitle">
                            {lang == "en" ? vol.skill.name : vol.skill.name_ar}
                          </div>
                        );
                      })
                    : "None"}
                </div> */}
                <div className="opp_view_vol_info_container_col1">
                  <div className="opp_view_vol_info_container_title">
                    <div className="view_icon_cont">
                      <MdEventAvailable className="view_icon" />
                    </div>
                    {t("viewrequest.viewrequest_16")}
                  </div>
                  <div className="opp_view_vol_info_container_subtitle">
                    {idinfo[0]?.any_date
                      ? "Any"
                      : idinfo[0]?.date_from + "to" + idinfo[0]?.date_to}
                  </div>
                </div>
                {/* <div className="opp_view_vol_info_container_col1">
                  <div className="opp_view_vol_info_container_title">
                    <div className="view_icon_cont">
                      <img src={achievement} className="view_icon" />
                    </div>
                    {t("viewrequest.viewrequest_10")}
                  </div>
                  <div className="opp_view_vol_info_container_subtitle">
                    {idinfo[0]?.volunteered_before === 1 ? "No" : "Yes"}
                  </div>
                </div> */}
                <div className="opp_view_vol_info_container_col1">
                  <div className="opp_view_vol_info_container_title">
                    <div className="view_icon_cont">
                      <img src={appointment} className="view_icon" />
                    </div>
                    {t("viewrequest.viewrequest_11")}
                  </div>
                  <div className="opp_view_vol_info_container_subtitle">
                    {idinfo[0]?.availability}
                  </div>
                </div>
                {/* <div className="opp_view_vol_info_container_col1">
                  <div className="opp_view_vol_info_container_title">
                    <div className="view_icon_cont">
                      <img src={achievement} className="view_icon" />
                    </div>
                    {t("viewrequest.viewrequest_12")}
                  </div>
                  {volSec?.length > 0
                    ? volSec?.map((vol, i) => {
                        return (
                          <div className="opp_view_vol_info_container_subtitle">
                            {lang == "en"
                              ? vol?.sector?.name
                              : vol?.sector?.name_ar}
                          </div>
                        );
                      })
                    : "None"}
                </div> */}
                <div className="opp_view_vol_info_container_col1">
                  <div className="opp_view_vol_info_container_title">
                    <div className="view_icon_cont">
                      <img src={heartbeat} className="view_icon" />
                    </div>
                    {t("viewrequest.viewrequest_13")}
                  </div>
                  <div className="opp_view_vol_info_container_subtitle">
                    {idinfo[0]?.frequency}
                  </div>
                </div>
                <div className="opp_view_vol_info_container_col1">
                  <div className="opp_view_vol_info_container_title">
                    <div className="view_icon_cont">
                      <img src={volunteer} className="view_icon" />
                    </div>
                    {t("viewrequest.viewrequest_14")}
                  </div>
                  <div className="opp_view_vol_info_container_subtitle">
                    {idinfo[0]?.reason}
                  </div>
                </div>
              </div>
              <div className="opp_view_vol_info_container_Row_3">
                <div className="opp_view_vol_info_container_col1">
                  <div className="opp_view_vol_info_container_title">
                    <div className="view_icon_cont">
                      <img src={heart} className="view_icon" />
                    </div>
                    {t("viewrequest.viewrequest_15")}
                  </div>
                  <div className="opp_view_vol_info_container_subtitle">
                    {lang === "en"
                      ? idinfo[0]?.district?.name
                      : idinfo[0]?.district?.name_ar}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="Previous_opp_section">
        <div className="opps_title">{t("viewrequest.viewrequest_17")}</div>
        <div className="opps_container">
          {points.map((opp, i) => {
            return (
              <div className="opps_container_row">
                <div className="opp_conts_container">
                  <div className="opp_img_container">
                    <img
                      src={
                        process.env.REACT_APP_API +
                        "/Opportunity/" +
                        opp.opportunity.img_url
                      }
                      className="opp_img"
                    />
                  </div>
                  <div className="opp_info">
                    <div className="opp_name">{opp.opportunity.title}</div>
                    <div className="opp_desc">
                      {" "}
                      Hosted By {opp.opportunity.organization.name}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="Previous_opp_section">
        <div className="opps_title">{t("viewrequest.viewrequest_18")}</div>
        <div className="objective_container">
          <div className="objective_title">
            {" "}
            {t("viewrequest.viewrequest_19")}{" "}
          </div>
          <div className="objective_desc">{idinfo[0]?.objective} </div>
        </div>
        <div className="objective_container">
          <div className="objective_title">
            {" "}
            {t("viewrequest.viewrequest_20")}{" "}
          </div>
          <div className="objective_desc_1">
            {idinfo[0]?.volunteer_skills.map((skill, i) => {
              return (
                <div className="objective_desc_cont">{skill.skill.name}</div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="Previous_opp_section">
        <div className="opps_title">{t("viewrequest.viewrequest_21")}</div>
        {answer?.map((number, i) => (
          <div className="objective_container" key={i}>
            <div className="objective_title">
              {number?.opportunity_question?.question}
            </div>
            <div className="objective_desc_1">
              <div className="objective_desc_cont">{number?.answer}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ViewRequests;
