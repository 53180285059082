import { React, useState, useEffect } from "react";
import UpperSection from "../../components/UpperSection/UpperSection";
import "./Checkin_out.css";
import { useTranslation } from "react-i18next";
import check from "../../assets/images/OppRequests/Group 1534.png";
import view from "../../assets/images/OppRequests/Group 1531.png";
import decline from "../../assets/images/OppRequests/Group 1533.png";
import star from "../../assets/images/OppRequests/Group 1232.png";
import report from "../../assets/images/Group1561.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Feedbacks from "../Opportunities/Feedbacks/Feedbacks";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

//redux
import { useSelector } from "react-redux";
import {
  getCheckin,
  getCheckout,
  AcceptCheckin,
  RejectCheckin,
  AcceptCheckout,
  RejectCheckout,
  SendReport,
  getCountCheckInOutRequest
} from "../../Redux/checkin_out/checkin_outActions";
import { checkOut } from "../../Redux/opportunities/opportunityActions";
import Loading from "../../components/Loading/Loading";
function Checkin_out() {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [openFeedbacksPopup, setOpenFeedbacksPopup] = useState(false);
  const [openFeedbackForm, setOpenFeedbackForm] = useState(false);
  const { Checkin, Checkout, CheckinLoading, CheckoutLoading, CountCheckInOutRequest, accept, CheckinError } = useSelector(
    (store) => store.checkin_outReducer
  );
  const [allCheckout, setAllCheckout] = useState([]);
  const [selectedOpportunityCheckoutId, setSelectedOpportunityCheckoutId] = useState(null);
  const [selectedOpportunityCheckout, setSelectedOpportunityCheckout] = useState([]);

  useEffect(() => {
    if (CheckinError) {
      NotificationManager.error(t("checkin_out.check_in_error"), "", 3000);
    }
  }, [CheckinError]);

  const handleAccept = (id) => {
    if (Checkin) {
      dispatch(AcceptCheckin(id));
    }
  };

  const handleReject = (id) => {
    if (Checkin) {
      dispatch(RejectCheckin(id));
    }
  };
  const handleReport = (id) => {
    dispatch(SendReport(id));
  };
  const handleAcceptCheckout = (id) => {
    if (Checkout) {
      // dispatch(AcceptCheckout(id));
      if (openFeedbacksPopup === false) {
        // setOpenFeedbacksPopup(true);
        console.log(id)
        dispatch(AcceptCheckout(id));
      }
    }
  };


  // useEffect(() => {
  //  if(accept){
  //   if (accept[0]?.nb_of_spots && accept[0]?.nb_of_volunteers_checkout && openFeedbackForm) {
  //     if (accept[0]?.nb_of_spots == accept[0]?.nb_of_volunteers_checkout) {
  //       navigate("/evaluation_feedback/" + accept[0]?.request_id);
  //     }
  //   }}
  // }, [CheckinLoading, accept]);


  const handleRejectCheckout = (id) => {
    if (Checkout) {
      dispatch(RejectCheckout(id));
    }
  };

  useEffect(() => {
    dispatch(getCheckin());
    dispatch(getCheckout());
    dispatch(getCountCheckInOutRequest());
  }, [page]);

  useEffect(() => {
    const array = [];
    const uniqueOpportunityIds = new Set();

    Checkout?.forEach((item) => {
      const opportunityId = item.opportunity_id;

      // Check if the opportunityId is not already in the set
      if (!uniqueOpportunityIds.has(opportunityId)) {
        // Add the opportunityId to the array and set
        array.push(item);
        uniqueOpportunityIds.add(opportunityId);
      }
    });
    setAllCheckout(array)
    // Now, the `array` contains unique opportunity_ids
    console.log(array);

  }, [Checkout]);



  useEffect(() => {

    if (selectedOpportunityCheckoutId != null) {
      const array = [];

      Checkout?.forEach((item) => {

        if (selectedOpportunityCheckoutId == item.opportunity_id) {
          array.push(item.id);
        }
      });
      setSelectedOpportunityCheckout(array)
    }
  }, [selectedOpportunityCheckoutId]);


  return (
    <div className="Main_check_div">
      <UpperSection
        title={t("checkin_out.title")}
        subtitle={(
          <>
            {t("checkin_out.checkin_out_1")}
            <span className="notification-badge">
              {CountCheckInOutRequest?.Count?.countCheckIn}
            </span>
          </>
        )}
        subtitle2={(
          <>
            {t("checkin_out.checkin_out_2")}
            <span className="notification-badge">
              {CountCheckInOutRequest?.Count?.countCheckOut}
            </span>
          </>
        )}
        page={page}
        setPage={setPage}
      />
      {page === 1 && (
        <div className="checkin_out_div">
          {CheckinLoading ? (
            <Loading />
          ) : (
            <div className="opp_requests_cards_container">
              {Checkin?.map((item) => {
                // return item?.map((reqss) => {
                return (
                  <div className="opp_requests_card">
                    <div className="opp_requests_card_header">
                      {/* {props.points >= 8000 ? (
                  <img src={star} className="opp_requests_star" />
                ) : (
                  <div className="opp_requests_card_level_logo" />
                )} */}
                      <div className="opp_requests_card_text">
                        <div className="opp_requests_card_text_title">
                          {item.first_name}
                          {""} {item.last_name}
                        </div>
                        <div className="opp_requests_card_text_subtitle">
                          {" "}
                          {item.title}
                        </div>
                      </div>
                    </div>
                    <div className="opp_requests_card_body">
                      <div
                        className="opp_requests_card_body_action_container"
                        onClick={() => handleReject(item.id)}
                      >
                        <img src={decline} className="action_logo" />{" "}
                        <div className="opp_requests_card_body_action_text">
                          {t("opprequests.opprequests_4")}
                        </div>
                      </div>

                      <div
                        className="opp_requests_card_body_action_container"
                        onClick={() => handleAccept(item.id)}
                      >
                        <img src={check} className="action_logo" />{" "}
                        <div className="opp_requests_card_body_action_text">
                          {t("opprequests.opprequests_3")}
                        </div>
                      </div>
                      <div
                        className="opp_requests_card_body_action_container"
                        onClick={() => handleReport(item.id)}
                      >
                        <img src={report} className="action_logo" />{" "}
                        <div className="opp_requests_card_body_action_text">
                          {t("checkin_out.checkin_out_3")}
                        </div>
                      </div>
                    </div>
                  </div>
                );
                // });
              })}
              <NotificationContainer />
            </div>
          )}
        </div>
      )}
      {page === 2 && (
        <div className="checkin_out_div">
          {CheckoutLoading ? (
            <Loading />
          ) : (
            <>
              <div className="opp_requests_cards_container">
                {allCheckout?.map((item) => {
                  // return item?.map((reqss) => {
                  return (
                    <div style={{ width: 'auto' ,cursor:"pointer"}} className="opp_requests_card" onClick={() => setSelectedOpportunityCheckoutId(item.opportunity_id)}>
                      <div className="opp_requests_card_header">
                        <div className="opp_requests_card_text" style={{ margin: '0px' }}>
                          <div className="opp_requests_card_text_title">
                            {item.title}
                          </div>
                          <div className="opp_requests_card_text_subtitle">
                            {" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              {selectedOpportunityCheckoutId != null && Checkout.length > 0 &&
                <div className="opp_requests_cards_container">
                  <button
                    className="primary_button"
                    style={{ marginBottom: '30px' }}
                    onClick={() => handleAcceptCheckout(selectedOpportunityCheckout)}
                  >
                    {" "}
                    {t("opprequests.accept_all")}{" "}
                  </button>
                </div>
              }
              <div className="opp_requests_cards_container">
                {selectedOpportunityCheckoutId != null && Checkout?.map((item) => {
                  // return item?.map((reqss) => {
                  return (
                    ((selectedOpportunityCheckoutId == item.opportunity_id) && <div className="opp_requests_card">
                      <div className="opp_requests_card_header">
                        {/* {props.points >= 8000 ? (
                  <img src={star} className="opp_requests_star" />
                ) : (
                  <div className="opp_requests_card_level_logo" />
                )} */}
                        <div className="opp_requests_card_text">
                          <div className="opp_requests_card_text_title">
                            {item.first_name}
                            {""} {item.last_name}
                          </div>
                          <div className="opp_requests_card_text_subtitle">
                            {" "}
                            {item.title}
                          </div>
                        </div>
                      </div>
                      <div className="opp_requests_card_body">
                        <div
                          className="opp_requests_card_body_action_container"
                          onClick={() => handleRejectCheckout(item.id)}
                        >
                          <img src={decline} className="action_logo" />{" "}
                          <div className="opp_requests_card_body_action_text">
                            {t("opprequests.opprequests_4")}
                          </div>
                        </div>

                        <div
                          className="opp_requests_card_body_action_container"
                          onClick={() => {
                            setOpenFeedbackForm(true);
                            handleAcceptCheckout([item.id])
                          }
                          }
                        >
                          <img src={check} className="action_logo" />{" "}
                          <div className="opp_requests_card_body_action_text">
                            {t("opprequests.opprequests_3")}
                          </div>
                        </div>
                        {openFeedbacksPopup && (
                          <Feedbacks
                            requestId={item.id}
                            setOpen={setOpenFeedbacksPopup}
                          />
                        )}

                        <div
                          className="opp_requests_card_body_action_container"
                          onClick={() => handleReport(item.id)}
                        >
                          <img src={report} className="action_logo" />{" "}
                          <div className="opp_requests_card_body_action_text">
                            {t("checkin_out.checkin_out_3")}
                          </div>
                        </div>
                      </div>
                    </div>)
                  );
                  // });
                })}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default Checkin_out;
