import axios from "axios";
import { DonationsActions } from "../../Redux/Donate/DonateReducers";

export const createdonation = (ammount, currency) => async (dispatch) => {
  try {
    dispatch(DonationsActions.createdonationrequest());
    const response = await axios.post(
      process.env.REACT_APP_API + "/donations/createDonationPayment",
      {
        amount: ammount,
        currency: currency,
      }
    );
    dispatch(DonationsActions.createdonationrequestSuccess(response.data));
  } catch (error) {
    dispatch(DonationsActions.createdonationrequestFailure(error.message));
  }
};


export const onSuccessDonation = (donationId) => async (dispatch) => {
  dispatch(DonationsActions.successRequest());
  try {
    let response = await axios.put(
      process.env.REACT_APP_API + "/donations/payment/status/donation/" + donationId,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
    dispatch(DonationsActions.successSuccess(response.data));
  } catch (error) {
    dispatch(DonationsActions.successFail(error.response.data.message));
  }
};


export const createmasterclasspayment =
  (ammount, currency) => async (dispatch) => {
    try {
      dispatch(DonationsActions.createmasterclasspaymentrequest());
      const response = await axios.post(
        process.env.REACT_APP_API + "/donations/payment",
        {
          amount: ammount,
          currency: currency,
        }
      );
      dispatch(
        DonationsActions.createmasterclasspaymentrequestSuccess(response.data)
      );
    } catch (error) {
      dispatch(
        DonationsActions.createmasterclasspaymentrequestFailure(error.message)
      );
    }
  };
