import axios from "axios";
import { EvaluationsActions } from "./EvaluationsReducers";

export const addEvaluation = (request_id) => async (dispatch) => {
  try {
    dispatch(EvaluationsActions.AddEvaluationRequest());
    let response = await axios.post(
      process.env.REACT_APP_API + "/evaluations/",
      {
        request_id: request_id,
      }
    );
   
    dispatch(EvaluationsActions.AddEvaluationRequestSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    dispatch(EvaluationsActions.AddEvaluationRequestFail(error.message));
  }
};

export const getEvaluationsTotal = (vol_id) => async (dispatch) => {
  try {
    dispatch(EvaluationsActions.AddEvaluationRequest());
    let response = await axios.get(
      process.env.REACT_APP_API + "/evaluations/getEvalTotal/" + vol_id,
    );
   
    dispatch(EvaluationsActions.AddEvaluationRequestSuccess(response.data));
  } catch (error) {
    console.log(error);
    dispatch(EvaluationsActions.AddEvaluationRequestFail(error.message));
  }
};

