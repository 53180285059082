import axios from "axios";
import { QuizAction } from "./QuizReducers";

export const getQuizes = () => async (dispatch) => {
  dispatch(QuizAction.fetchQuizRequest());
  try {
    let response = await axios.get(process.env.REACT_APP_API + "/quizes");
    dispatch(QuizAction.fetchQuizSuccess(response.data.data));
  } catch (error) {
    dispatch(QuizAction.fetchQuizFail(error.message));
  }
};
export const getanswersbyquizid = (id) => async (dispatch) => {
  dispatch(QuizAction.fetchQuiz_answersbyid());
  try {
    let response = await axios.get(
      process.env.REACT_APP_API + "/quizes_answers/" + id
    );
    dispatch(QuizAction.fetchQuiz_answersbyidSucess(response.data.data));
  } catch (error) {
    dispatch(QuizAction.fetchQuiz_answersbyidFail(error.message));
  }
};
export const getanswersbyquizidandtype = (data) => async (dispatch) => {
  dispatch(QuizAction.fetchQuiz_answersbyidandtype());

  try {
    let response = await axios.post(
      process.env.REACT_APP_API + "/quizes_answers/getOppQuize",
      { answer: data }
    );
    dispatch(QuizAction.fetchQuiz_answersbyidandtypeSucess(response.data.data));
  } catch (error) {
    dispatch(QuizAction.fetchQuiz_answersbyidandtypeFail(error.message));
  }
};
