import "./DonatePopup.css";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import PopUp from "../../components/PopUp/PopUp";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
//redux
import { useSelector } from "react-redux";
import { createdonation } from "../../Redux/Donate/DonateActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
//images
import sad from "../../assets/images/feedbacks/Group 1879.png";
import happy from "../../assets/images/feedbacks/Group 1876.png";

function DonationStatuspop(props) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [Statuses, setStatuses] = useState(props.Statuses);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="donate_main_con">
      <PopUp
        setOpen={props.setOpen}
        cont={
          <div className="create_pop_main_container">
            <div className="donate_pop_cont">
              <div>
                <h2 className="donate_popup_header">{t("donate.status")}</h2>
              </div>{" "}
              <div className="donate_popup_body">
                {Statuses === "success" ? (
                  <div className="donate_success">
                    <h2>{t("donate.success")}</h2>
                    <div className="donate_failed_img">
                      <img src={happy} alt="sad" className="sad_img" />
                    </div>
                  </div>
                ) : (
                  <div className="donate_failed">
                    <h2>{t("donate.error")}</h2>
                    <div className="donate_failed_img">
                      <img src={sad} alt="sad" className="sad_img" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default DonationStatuspop;
