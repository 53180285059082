import axios from "axios";
import { OppRequestsActions } from "./OppRequestsReducers";
import { getvolreqCommunityAct } from "../Community_Action/CommunityActActions";
import { getCountCheckInOutRequest } from "../checkin_out/checkin_outActions";
export const getOppRequests = (token) => async (dispatch) => {
  try {
    dispatch(OppRequestsActions.fetchOppRequestsRequest());
    let response = await axios.get(
      process.env.REACT_APP_API + "/organizations/getRequestByOrg",
      {
        token,
      }
    );
    dispatch(OppRequestsActions.fetchOppRequestsSuccess(response.data.data1));
  } catch (error) {
    dispatch(OppRequestsActions.fetchOppRequestsFail(error.message));
  }
};

export const acceptOppRequest = (id, navigate) => async (dispatch) => {
  try {
    dispatch(OppRequestsActions.acceptedOppRequest());
    let response = await axios.post(
      process.env.REACT_APP_API + "/organizations/request/accept/" + id
    );
    dispatch(OppRequestsActions.acceptedOppRequestSucess(response.data.data));
    dispatch(getOppRequests());
    dispatch(getCountCheckInOutRequest());
    if (navigate) navigate("/oppRequests");
  } catch (error) {
    dispatch(OppRequestsActions.acceptedOppRequestFail(error.message));
  }
};
export const acceptcommRequest = (id, navigate) => async (dispatch) => {
  try {
    dispatch(OppRequestsActions.acceptedOppRequest());
    let response = await axios.post(
      process.env.REACT_APP_API + "/organizations/request/accept/" + id
    );
    dispatch(OppRequestsActions.acceptedOppRequestSucess(response.data.data));
    dispatch(getvolreqCommunityAct());
    if (navigate) navigate("/oppRequests");
  } catch (error) {
    dispatch(OppRequestsActions.acceptedOppRequestFail(error.message));
  }
};
export const rejectcommRequest = (id, navigate) => async (dispatch) => {
  try {
    dispatch(OppRequestsActions.rejectedOppRequest());
    let response = await axios.post(
      process.env.REACT_APP_API + "/organizations/request/reject/" + id
    );
    dispatch(OppRequestsActions.rejectedOppRequestSucess(response.data.data));
    dispatch(getvolreqCommunityAct());
    if (navigate) navigate("/oppRequests");
  } catch (error) {
    dispatch(OppRequestsActions.rejectedOppRequestFail(error.message));
  }
};

export const rejectOppRequest = (id, navigate) => async (dispatch) => {
  try {
    dispatch(OppRequestsActions.rejectedOppRequest());
    let response = await axios.post(
      process.env.REACT_APP_API + "/organizations/request/reject/" + id
    );
    dispatch(OppRequestsActions.rejectedOppRequestSucess(response.data.data));
    dispatch(getOppRequests());
    if (navigate) navigate("/oppRequests");
  } catch (error) {
    dispatch(OppRequestsActions.rejectedOppRequestFail(error.message));
  }
};

export const getOppRequestsbyfilters =
  ({
    selectedStatus,
    selectedOppTitle,
    selectedAge,
    selectedNationality,
    selectedcountry,
    selectedLocation,
    selectedType,
    // selectedstatus,
  }) =>
  async (dispatch) => {
    dispatch(OppRequestsActions.fetchOppRequests());
    let url = process.env.REACT_APP_API + "/organizations/getRequestByOrg?";

    if (selectedStatus) url += "status=" + selectedStatus + "&";
    if (selectedOppTitle) url += "title=" + selectedOppTitle + "&";
    if (selectedAge) url += "age=" + selectedAge + "&";
    if (selectedNationality) url += "nationality=" + selectedNationality + "&";
    if (selectedcountry) url += "country=" + selectedcountry + "&";
    if (selectedLocation) url += "location=" + selectedLocation + "&";
    if (selectedType) url += "type=" + selectedType + "&";
    try {
      let response = await axios.get(url);
      dispatch(OppRequestsActions.fetchOppRequestsSuccess(response.data.data1));
    } catch (error) {
      dispatch(OppRequestsActions.fetchOppRequestsFail(error.message));
    }
  };

export const getOppRequestsbyid = (id) => async (dispatch) => {
  dispatch(OppRequestsActions.fetchOppRequestsByid());
  try {
    let response = await axios.get(
      process.env.REACT_APP_API + "/requests/" + id
    );
    dispatch(OppRequestsActions.fetchOppRequestsByidSuccess(response.data));
  } catch (error) {
    dispatch(OppRequestsActions.fetchOppRequestsByidFail(error.message));
  }
};
export const getmatchingscores =
  (volunteer_id, opportunity_id) => async (dispatch) => {
    dispatch(OppRequestsActions.fetchmatchingscores());
    try {
      let response = await axios.post(
        process.env.REACT_APP_API + "/requests/matchingScoreCriteria",
        {
          volunteer_id: volunteer_id,
          opportunity_id: opportunity_id,
        }
      );

      dispatch(
        OppRequestsActions.fetchmatchingscoresSuccess(response.data.score)
      );
    } catch (error) {
      dispatch(OppRequestsActions.fetchmatchingscoresFail(error.message));
    }
  };
