import axios from "axios";
import { statisticsAction } from "./statisticsReducer";

export const getStatistics = () => async (dispatch) => {
  dispatch(statisticsAction.fetchstatisticRequest());
  try {
    let response = await axios.get(process.env.REACT_APP_API + "/statistics");
    dispatch(statisticsAction.fetchstatisticSuccess(response.data.data));
  } catch (error) {
    dispatch(statisticsAction.fetchstatisticFail(error.message));
  }
};
