import "./Privacy.css";

import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import concerns from "../../assets/images/main-banner.jpg";
import UpperSection from "../../components/UpperSection/UpperSection";

function Privacy() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const navigate = useNavigate();

  const PrivacyAndPolicy = [
    {
      id: 1,
      title: t("Privacy.title1"),
      description: t("Privacy.description1"),
    },
    {
      id: 2,
      title: t("Privacy.title2"),
      description: t("Privacy.description2"),
    },
    {
      id: 3,
      title: t("Privacy.title3"),
      description: t("Privacy.description3"),
    },
    {
      id: 4,
      title: t("Privacy.title4"),
      description: t("Privacy.description4"),
    },
    {
      id: 5,
      title: t("Privacy.title5"),
      description: t("Privacy.description5"),
    },
    {
      id: 6,
      title: t("Privacy.title6"),
      description: t("Privacy.description6"),
    },
  ];

  return (
    <div className="privacy-page">
      {/* <div
        className={
          lang === "en" ? "privacy-green-block" : "privacy-green-block-arabic"
        }
      >
        <p>{t("Privacy.title")}</p>
      </div> */}
      <UpperSection title={t("Privacy.title")} />
      <div className="privacy-container">
        <div className="privacy-img">
          <img src={concerns} className="privacy-big-img" alt="privacy" />
        </div>

        {PrivacyAndPolicy.map((terms) => {
          return (
            <div
              className={
                lang === "en"
                  ? "privacy-mapping-box"
                  : "privacy-mapping-box-arabic"
              }
            >
              <div className="block">
                <p
                  className={
                    lang === "ar" ? "text_privacy_ar" : "text_privacy_en"
                  }
                >
                  {t(terms.title)}
                </p>
                <div className="pink_underline" />
              </div>
              <div className="text">
                <p
                  className={
                    lang === "ar" ? "privacy_texts_ar" : "text_privacy_english"
                  }
                >
                  {t(terms.description)}
                </p>
              </div>
            </div>
          );
        })}
        <div className="privacy-pink-block">
          <div className="pink-content">
            {/* <h2>Contacting us</h2> */}
            <h2>{t("Privacy.contactingUS")}</h2>
            <div
              className={lang === "en" ? "sub-content" : "sub-content-arabic"}
            >
              <p>{t("Privacy.question")}</p>
              <button
                className={
                  lang === "en" ? "contact-button" : "contact-button-arabic"
                }
                onClick={() => navigate("/contactUsPage")}
              >
                {t("Privacy.contactUs")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
