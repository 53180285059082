import axios from "axios";
import { payAction } from "./PayReducer";

export const payCash =
  (amount, subscription_id, priceType) => async (dispatch) => {
    dispatch(payAction.cashsRequest());
    try {
      let response = await axios.post(
        process.env.REACT_APP_API + "/cash",
        {
          amount: amount,
          subscription_id: subscription_id,
          type: priceType,
          currency: "USD",
        },
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      dispatch(payAction.cashsSuccess(response.data));
    } catch (error) {
      dispatch(payAction.cashsFail(error.message));
    }
  };
export const payWish =
  (amount, subscription_id, priceType) => async (dispatch) => {
    dispatch(payAction.wishRequest());
    try {
      let response = await axios.post(
        process.env.REACT_APP_API + "/donations/createSubscriptionPayment",
        {
          amount: amount,
          subscription_id: subscription_id,
          type: priceType,
          currency: "USD",
        },
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      dispatch(payAction.wishSuccess(response.data));
    } catch (error) {
      dispatch(payAction.wishFail(error.message));
    }
  };

export const payStrip =
  (amount, subscription_id, priceType) => async (dispatch) => {
    dispatch(payAction.stripRequest());
    try {
      let response = await axios.post(
        process.env.REACT_APP_API + "/create-payment-intent",
        {
          amount: amount,
          subscription_id: subscription_id,
          type: priceType,
          currency: "USD",
        },
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      dispatch(payAction.stripSuccess(response.data));
    } catch (error) {
      dispatch(payAction.stripFail(error.message));
    }
  };

export const getConfig = () => async (dispatch) => {
  dispatch(payAction.configRequest());
  try {
    let response = await axios.get(process.env.REACT_APP_API + "/config", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
    dispatch(payAction.configSuccess(response.data));
  } catch (error) {
    dispatch(payAction.configFail(error.message));
  }
};

export const onSuccessPayment = (paymentId) => async (dispatch) => {
  dispatch(payAction.successRequest());
  try {
    let response = await axios.post(
      process.env.REACT_APP_API + "/successPay/" + paymentId,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
    dispatch(payAction.successSuccess(response.data));
  } catch (error) {
    dispatch(payAction.successFail(error.response.data.message));
  }
};


export const masterClassPayCash =
  (is_group) => async (dispatch) => {
    dispatch(payAction.masterClassCashsRequest());
    try {
      let response = await axios.post(
        process.env.REACT_APP_API + "/cash/PayMasterclassCash",
        {
          is_group: is_group
        },
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      dispatch(payAction.masterClassCashsSuccess(response.data));
    } catch (error) {
      dispatch(payAction.masterClassCashsFail(error.message));
    }
  };


export const masterClassPayStrip =
  (is_group) => async (dispatch) => {
    dispatch(payAction.masterClassStripRequest());
    try {
      let response = await axios.post(
        process.env.REACT_APP_API + "/donations/payment",
        {
          is_group: is_group
        },
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      dispatch(payAction.masterClassStripSuccess(response.data));
    } catch (error) {
      dispatch(payAction.masterClassStripFail(error.message));
    }
  };

export const onSuccessMasterClassStrip = (paymentId, materClassIds) => async (dispatch) => {
  dispatch(payAction.successRequest());
  try {
    let response = await axios.put(
      process.env.REACT_APP_API + "/donations/payment/status/masterclass/" + paymentId,
      {
        masterClassId: materClassIds
      },
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
    dispatch(payAction.successSuccess(response.data));
  } catch (error) {
    dispatch(payAction.successFail(error.response.data.message));
  }
};

export const onSuccessMasterClassCash = (paymentId , materClassIds) => async (dispatch) => {
  dispatch(payAction.successRequest());
  try {
    let response = await axios.put(
      process.env.REACT_APP_API + "/cash/ChangeStatusMasterclass/" + paymentId,
      {
        masterClassId: materClassIds
      },
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
    dispatch(payAction.successSuccess(response.data));
  } catch (error) {
    dispatch(payAction.successFail(error.response.data.message));
  }
};