import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requestGroupMembers: [],
  loading: false,
  error: "",
};

const requestGroupMembersSlice = createSlice({
  name: "requestGroupMembers",
  initialState,
  reducers: {
    createRequestGroupMembers(state) {
        state.loading = true;
        state.requestGroupMembers = [];
        state.error = "";
        state.success = false;
      },
      createRequestGroupMembersSuccess(state, action) {
        state.loading = false;
        state.requestGroupMembers = action.payload;
        state.error = "";
        state.success = true;
      },
      createRequestGroupMembersFail(state, action) {
        state.loading = false;
        state.requestGroupMembers = [];
        state.error = action.payload;
        state.success = false;
      },
  },
});

export const requestGroupMembersAction = requestGroupMembersSlice.actions;
export default requestGroupMembersSlice;
