import React from "react";
import { useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import "./CreateOpp_Step7.css";

import i18n from "../../../../i18n";
import { useSSR, useTranslation } from "react-i18next";

import { useEffect } from "react";

import { BiSave } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import CreateOpp_Step6 from "../Step6/CreateOpp_Step6";
import CreateOpp_Step8 from "../Step8/CreateOpp_Step8";
import { editOpportunity, getOpportunitiesById } from "../../../../Redux/opportunities/opportunityActions";

function CreateOpp_Step7({
  id,
  setOpen,
  setFooterWidth,
  community,
}) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [openStep8, setOpenStep8] = useState(false);
  const [previousPage, setPreviousPage] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [exprience, setExprience] = useState();
  const [desc, setDesc] = useState();

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const size = useWindowSize();
  const dispatch = useDispatch();

  const [isNext, setIsNext] = useState(true);
  const {
    oppDetails,
  } = useSelector((store) => store.opportunitiesReducer);

  const handlechange = (e) => {
    const lines = e.target.value.split("\n");
    setExprience(lines.map((line) => line.replace("• ", "")).join("\n"));
  };
  const handlechange_desc = (e) => {
    const lines = e.target.value.split("\n");
    setDesc(lines.map((line) => line.replace("• ", "")).join("\n"));
  };
useEffect(() => {
    if (id) {
      dispatch(getOpportunitiesById(id));
    }
  }, []);
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  useEffect(() => {
    setFooterWidth("58.3%");
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    if (exprience && desc) {
      const opp = {
        experience_required: exprience,
        tasks_required: desc,
      };
      if (community) {
        dispatch(editOpportunity(id, opp, community));
      } else dispatch(editOpportunity(id, opp));

      if (isNext) {
        setOpenStep8(true);
      } else {
        setOpen(false);
      }
    } else {
      setError(t("opportunity.create.error"));
    }
  };

  useEffect(() => {
    if (Object.keys(oppDetails).length > 0) {
      if(oppDetails.experience_required!== null)
      setExprience(oppDetails.experience_required);
      if(oppDetails.tasks_required!== null)
      setDesc(oppDetails.tasks_required);
    }
  }, [oppDetails]);

  return (
    <div>
      {
        //  (loading || editLoading)  ? <Loading/> :
        previousPage ? (
          <CreateOpp_Step6
              id={id}
              community={community}
              setOpen={setOpen}
              setFooterWidth={setFooterWidth}
              previous={true}
            />
        ):(  openStep8 ? (
          <CreateOpp_Step8
            id={id}
            community={community}
            setOpen={setOpen}
            setFooterWidth={setFooterWidth}
          />
        ) : (
          <form className="create_opp_step1" onSubmit={(e) => onSubmit(e)}>
            <div className="create_opp_step6_main_cont">
              <div className="create_opp_step1_cont">
                <div className="step1_cont_header">
                  {t("opportunity.create.exp")}*
                </div>

                <textarea
                  className="create_opp_textarea"
                  placeholder={t("opportunity.create.exp_text")}
                  required
                  // value={exprience}
                  value={exprience
                    ?.split("\n")
                    .map((line) => `• ${line}`)
                    .join("\n")}
                  onChange={handlechange}
                />
                {/* <BulletedTextArea
                  className="create_opp_textarea"
                  value={exprience}
                  onChange={handlechange}
                /> */}
              </div>

              <div className="create_opp_step1_cont">
                <div className="step1_cont_header">
                  {t("opportunity.create.desc")}*
                </div>

                <textarea
                  className="create_opp_textarea"
                  placeholder={t("opportunity.create.desc_text")}
                  required
                  value={desc
                    ?.split("\n")
                    .map((line) => `• ${line}`)
                    .join("\n")}
                  onChange={handlechange_desc}
                />
              </div>
            </div>

            {error && <div className="error_msg">{error}</div>}

            <div className="complete_step1_footer">
              <div className="divider_line"></div>
              <div className="complete_step_footer_button">
                <button
                  className={
                    size.width > 768
                      ? "primary_button complete_step_1_next_button"
                      : "primary_button complete_step_2_next_button_resp"
                  }
                  onClick={(e) => { e.preventDefault(); setPreviousPage(true); }}
                >
                  {size.width > 768 ? (
                    t("CompleteProf.complete_your_profile_btn_5")
                  ) : lang == "en" ? (
                    <AiOutlineArrowLeft size="1.5rem" />
                  ) : (
                    <AiOutlineArrowRight size="1.5rem" />
                  )}
                </button>

                <div
                  className={
                    size.width > 768
                      ? "complete_step_right_footer"
                      : "complete_step_right_footer_resp"
                  }
                >
                  <button
                    className={
                      size.width > 500
                        ? "pink_button complete_step_2_complete_button"
                        : "pink_button complete_step_2_next_button_resp"
                    }
                    onClick={() => setIsNext(false)}
                  >
                    {size.width > 500 ? (
                      t("CompleteProf.complete_your_profile_btn_3")
                    ) : (
                      <BiSave size="1.5rem" />
                    )}
                  </button>
                  <button
                    className={
                      size.width > 768
                        ? "primary_button complete_step_2_next_button"
                        : "primary_button complete_step_2_next_button_resp"
                    }
                    onClick={() => setIsNext(true)}
                  >
                    {size.width > 768 ? (
                      t("CompleteProf.complete_your_profile_btn_4")
                    ) : lang == "en" ? (
                      <AiOutlineArrowRight size="1.5rem" />
                    ) : (
                      <AiOutlineArrowLeft size="1.5rem" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        ))
      }
    </div>
  );
}

export default CreateOpp_Step7;
