import axios from "axios";
import { AvailibilitiesAction } from "./AvailibilitiesReducer";

export const getAvailibilities =
  () => async (dispatch) => {
    dispatch(AvailibilitiesAction.availibilitiesRequest());
    try {
      let response = await axios.get(
        process.env.REACT_APP_API + "/getAvailibilities",
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      dispatch(AvailibilitiesAction.availibilitiesSuccess(response.data.data));
    } catch (error) {
      dispatch(AvailibilitiesAction.availibilitiesFail(error.message));
    }
  };