import "./Donate.css";
import { useDispatch } from "react-redux";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import parallax from "../../assets/images/Home/parallax-image.jpg";
import contribution from "../../assets/images/Home/contribution.png";
import fromabroad from "../../assets/images/donate/Fromabroad.svg";
import fromlebanon from "../../assets/images/donate/fromlebanon.svg";
import DonatePopup from "./DonatePopup";
import { useParams } from "react-router-dom";
import DonationStatuspop from "./DonationStatuspop";
function Donate() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [donate, setDonate] = useState(false);
  const [Donstatus, setDonstatus] = useState(false);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams.get("status");

  useEffect(() => {
    if ((status && status === "success") || status === "error") {
      setDonstatus(true);
    } else {
      setDonstatus(false);
    }
  }, [status]);

  return (
    <>
      <div className="green-block">
        <p>{t("donate.donate")}</p>
      </div>
      <div className="donate-page">
        <div className="donate-container">
          <div className="donate-info">
            <div className="donate-info-title">{t("donate.donate_title")}</div>
            <div className="horizontal_line"></div>
            <p>{t("donate.donate_definition")}</p>
            <button className="donate_now_btn" onClick={() => setDonate(true)}>
              {t("donate.donate_now")}
            </button>
          </div>
          <div className="donate-image">
            <img src={parallax} alt="" />
          </div>
        </div>
      </div>
      {Donstatus && (
        <DonationStatuspop setOpen={setDonstatus} Statuses={status} />
      )}

      <div className="more-donations">
        <div className="more-donation-content">
          <div className="donate_more_about_section">
            <div className="more-title">{t("donate.more_donation")}</div>
            <div className="horizontal_line horizontal_line_pink"></div>
            <div className="more-body">
              <div> {t("donate.more_donation_p1")}</div>
              <div> {t("donate.more_donation_p2")}</div>
            </div>
            <button className="donate_now_btn" onClick={() => setDonate(true)}>
              {t("donate.donate_now")}
            </button>
          </div>
          <div className="donate_img_section">
            <img src={contribution} className="donate_img_1" />
          </div>
        </div>
      </div>

      <div className="donate_info_container">
        <div className="left_donate_info">
          <img src={fromabroad} alt="fromabroad" />
          <h4 className="font-30">{t("donate.donate_from_abroad")}</h4>
          <ul className="donate_div_info_list">
            <li className="donate_list_info_item">
              <span className="donate_list_info_title font-16">
                {t("donate.bank_transfer_to")}
              </span>{" "}
              The Volunteer Circle
            </li>
            <li className="donate_list_info_item">
              <span className="donate_list_info_title font-16">
                {t("donate.bank")}
              </span>{" "}
              BBAC
            </li>
            <li className="donate_list_info_item">
              <span className="donate_list_info_title font-16">
                Swift code:{" "}
              </span>
              BBACLBBX
            </li>
            <li className="donate_list_info_item">
              <span className="donate_list_info_title font-16">
                {t("donate.currency")}
              </span>{" "}
              USD
            </li>
            <li className="donate_list_info_item">
              <span className="donate_list_info_title font-16">IBAN: </span>
              LB18 0028 0000 0000 0356 3500 5001
            </li>
            <li className="donate_list_info_item">
              <span className="donate_list_info_title font-16">
                {t("donate.currency")}
              </span>
              EUR
            </li>
            <li className="donate_list_info_item">
              <span className="donate_list_info_title font-16">IBAN: </span>
              LB88 0028 0000 0000 0356 3500 5002
            </li>
          </ul>
        </div>

        <div className="right_donate_info">
          <img src={fromlebanon} alt="fromlebanon" />
          <h4 className="font-30">{t("donate.donate_from_lebanon")}</h4>
          <ul className="donate_div_info_list">
            <li className="donate_list_info_item">
              <span className="donate_list_info_title font-16">
                {t("donate.bank_transfer_to")}
              </span>
              The Volunteer Circle
            </li>
            <li className="donate_list_info_item">
              <span className="donate_list_info_title font-16">
                {t("donate.bank")}
              </span>
              BBAC (Istiklal Branch)
            </li>
            <li className="donate_list_info_item">
              <span className="donate_list_info_title font-16">
                Swift code:{" "}
              </span>
              BBACLBBX
            </li>
            <li className="donate_list_info_item">
              <span className="donate_list_info_title font-16">
                {t("donate.currency")}
              </span>{" "}
              USD
            </li>
            <li className="donate_list_info_item">
              <span className="donate_list_info_title font-16">IBAN: </span>
              LB18 0028 0000 0000 0356 3500 5001
            </li>
            <li className="donate_list_info_item">
              <span className="donate_list_info_title font-16">
                {t("donate.currency")}
              </span>
              EUR
            </li>
            <li className="donate_list_info_item">
              <span className="donate_list_info_title font-16">IBAN: </span>
              LB88 0028 0000 0000 0356 3500 5002
            </li>
          </ul>
        </div>
      </div>
      {donate && <DonatePopup setOpen={setDonate} />}
    </>
  );
}
export default Donate;
