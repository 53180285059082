import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  RequestQuestionsAnswers: [],
  answer: [],
  loading: false,
  error: "",
};

const RequestQuestionsAnswersSlice = createSlice({
  name: "requestQuestionsAnswers",
  initialState,
  reducers: {
    createRequestQuestionsAnswers(state) {
      state.loading = true;
      state.RequestQuestionsAnswers = [];
      state.error = "";
    },
    createRequestQuestionsAnswersSuccess(state, action) {
      state.loading = false;
      state.RequestQuestionsAnswers = action.payload;
      state.error = "";
    },
    createRequestQuestionsAnswersFail(state, action) {
      state.loading = false;
      state.RequestQuestionsAnswers = [];
      state.error = action.payload;
    },
    getanswer(state) {
      state.loading = true;
      state.answer = [];
      state.error = "";
    },
    getanswerSuccess(state, action) {
      state.loading = false;
      state.answer = action.payload;
      state.error = "";
    },
    getanswerFail(state, action) {
      state.loading = false;
      state.answer = [];
      state.error = action.payload;
    },
  },
});

export const RequestQuestionsAnswersAction =
  RequestQuestionsAnswersSlice.actions;
export default RequestQuestionsAnswersSlice;
