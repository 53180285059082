import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  carousel: [],
  loading: false,
  error: "",
};
const carouselSlice = createSlice({
  name: "carousel",
  initialState,
  reducers: {
    fetchCarouselRequest(state) {
      state.loading = true;
      state.carousel = [];
      state.error = "";
    },

    fetchCarouselSuccess(state, action) {
      state.loading = false;
      state.carousel = action.payload;
      state.error = "";
    },

    fetchCarouselFail(state, action) {
      state.loading = false;
      state.carousel = [];
      state.error = action.payload;
    },
    createCarouselRequest(state) {
      state.loading = true;
      state.carousel = [];
      state.error = "";
    },
    createCarouselSucess(state, action) {
      state.loading = false;
      state.carousel = action.payload;
      state.error = "";
    },
    createCarouselFail(state, action) {
      state.loading = false;
      state.carousel = [];
      state.error = action.payload;
    },
  },
});

export const carouselAction = carouselSlice.actions;
export default carouselSlice;
