import axios from "axios";
import { ReasonsAction } from "./ReasonsReducer";

export const getReasons =
  () => async (dispatch) => {
    dispatch(ReasonsAction.reasonsRequest());
    try {
      let response = await axios.get(
        process.env.REACT_APP_API + "/reasons",
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      dispatch(ReasonsAction.reasonsSuccess(response.data.data));
    } catch (error) {
      dispatch(ReasonsAction.reasonsFail(error.message));
    }
  };