import { combineReducers } from "redux";

import authSlice from "./auth/authreducers";
import usersSlice from "./user/userReducers";

import opportunitySlice from "./opportunities/opportunityReducer";
import statisticsSlice from "./statisitcs/statisticsReducer";
import carouselSlice from "./carousel/carouselReducers";
import contactSlice from "./contactus/contactsusReducers";
import FaqsSlice from "./faqs/FaqsReducer";
import testimonialSlice from "./testimonials/testimonialsReducers";
import partnersSlice from "./partners/PartnersReducers";
import organizationSlice from "./organizations/OrganizationsReducers";
import favoritesSlice from "./favorites/FavoritesReducers";
import evaluationsSlice from "./evaluations/EvaluationsReducers";
import feedbacksSlice from "./feedbacks/FeedbacksReducers";
import oppRequestsSlice from "./OppRequests/OppRequestsReducers";
import oppRequestsFiltersSlice from "./OppRequestsFilters/OppRequestsFiltersReducers";
import evalRequestsSlice from "./EvalRequests/EvalRequestsReducers";
import communityActSlice from "./Community_Action/CommunityActReducers";
import requestSlice from "./requests/requestsReducers";
import newsLetterSlice from "./NewsLetter/NewsLetterReducers";
import masterClassSlice from "./MasterClass/MasterClassReducers";
import Checkin_outSlice from "./checkin_out/checkin_outReducers";
import blogsSlice from "./Blogs/BlogsReducers";
import volunteerSpotlightSlice from "./VolunteerSpotlight/VolunteerSpotlightReducers";
import volunteerSpotlightsQaSlice from "./VolunteerSpotlightQa/VolunteerSpotlightQaReducers";
import videoBlogSlice from "./videoBlog/videoBlogReducers";
import QuizSlice from "./Quiz/QuizReducers";
import NotificationsSlice from "./Notifications/NotificationsReducers";
import teamsSlice from "./Teams/TeamsReducers";
import donationsSlice from "./Donate/DonateReducers";
import RecommendationsSlice from "./Recommendation/RecommendationReducer";
import trusted_partnersSlice from "./Trusted_partners/Trusted_partnersReducers";
import organizationEvaluationsSlice from "./OrganizationEvaluations/OrganizationEvaluationsReducers";
import subscriptionsSlice from "./Subscriptions/SubscriptionsReducer";
import paySlice from "./Pay/PayReducer";
import AvailibilitiesSlice from "./Availibilities/AvailibilitiesReducer";
import ReasonsSlice from "./Reasons/ReasonsReducer";
import RequestQuestionsAnswersSlice from "./RequestQuestionsAnswers/RequestQuestionsAnswersReducers";
import OpportunityQuestionsSlice from "./OpportunityQuestions/OpportunityQuestionsReducers";
import subscriptionDescriptionSlice from "./SubscriptionDescription/SubscriptionDescriptionReducer";
import SubscriptionValuesSlice from "./SubscriptionValues/SubscriptionValuesReducer";
import hearingAboutTvcSlice from "./Hearing_about_tvc/HearingAboutTvcReducers";

const RootReducer = combineReducers({
  authReducer: authSlice.reducer,
  userReducer: usersSlice.reducer,
  opportunitiesReducer: opportunitySlice.reducer,
  statisticsReducer: statisticsSlice.reducer,
  carouselReducer: carouselSlice.reducer,
  contactReducer: contactSlice.reducer,
  faqsReducer: FaqsSlice.reducer,
  testimonialReducer: testimonialSlice.reducer,
  partnersReducer: partnersSlice.reducer,
  organizationReducer: organizationSlice.reducer,
  favoritesReducer: favoritesSlice.reducer,
  evaluationsReducer: evaluationsSlice.reducer,
  feedbacksReducer: feedbacksSlice.reducer,
  oppRequestsReducer: oppRequestsSlice.reducer,
  oppRequestsFiltersReducer: oppRequestsFiltersSlice.reducer,
  evalRequestsReducer: evalRequestsSlice.reducer,
  communityActReducer: communityActSlice.reducer,
  requestReducer: requestSlice.reducer,
  masterClassReducer: masterClassSlice.reducer,
  newsLetterReducer: newsLetterSlice.reducer,
  checkin_outReducer: Checkin_outSlice.reducer,
  blogsReducer: blogsSlice.reducer,
  volunteerSpotlightReducer: volunteerSpotlightSlice.reducer,
  volunteerSpotlightQaReducer: volunteerSpotlightsQaSlice.reducer,
  videoBlogReducer: videoBlogSlice.reducer,
  QuizReducer: QuizSlice.reducer,
  NotificationsReducer: NotificationsSlice.reducer,
  TeamsReducer: teamsSlice.reducer,
  DonateReducer: donationsSlice.reducer,
  RecommendationReducer: RecommendationsSlice.reducer,
  Trusted_partnersReducer: trusted_partnersSlice.reducer,
  OrganizationEvaluations: organizationEvaluationsSlice.reducer,
  SubscriptionsReducer: subscriptionsSlice.reducer,
  PayReducer: paySlice.reducer,
  AvailibilitiesReducer: AvailibilitiesSlice.reducer,
  ReasonsReducer: ReasonsSlice.reducer,
  RequestQuestionsAnswersReducer: RequestQuestionsAnswersSlice.reducer,
  OpportunityQuestionsReducer: OpportunityQuestionsSlice.reducer,
  SubscriptionDescriptionReducer:subscriptionDescriptionSlice.reducer,
  SubscriptionValuesReducer:SubscriptionValuesSlice.reducer,
  HearingAboutTvcReducer:hearingAboutTvcSlice.reducer
});

export default RootReducer;
