import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contacts: [],
  contLoading: false,
  error: "",
  success: false,
};

const contactSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    fetchContactsRequest(state) {
      state.contLoading = true;
      state.contacts = [];
      state.error = "";
    },
    fetchContactsSuccess(state, action) {
      state.contLoading = false;
      state.contacts = action.payload;
      state.error = "";
    },
    fetchContactsFail(state, action) {
      state.contLoading = false;
      state.contacts = [];
      state.error = action.payload;
    },
    createContactRequest(state) {
      state.contLoading = true;
      state.contacts = [];
      state.error = "";
      state.success = false;
    },
    createContactSuccess(state, action) {
      state.contLoading = false;
      state.contacts = action.payload;
      state.error = "";
      state.success = true;
    },
    createContactFail(state, action) {
      state.contLoading = false;
      state.contacts = [];
      state.error = action.payload;
      state.success = false;
    },
  },
});

export const contactAction = contactSlice.actions;
export default contactSlice;
