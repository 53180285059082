import React, { useState, useEffect, useRef } from "react";
import { useHistory, NavLink, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import setAuthToken from "../../Utils/setAuthToken";
import isAuth from "../../Utils/isAuth";
import { getuserinfo } from "../../Redux/user/userActions";
import { authActions } from "../../Redux/auth/authreducers";
import { logout } from "../../Redux/auth/authactions";
import { IoIosNotificationsOutline } from "react-icons/io";
//redux
import { getTotalNotifications } from "../../Redux/Notifications/NotificationsActions";
import { getCountCheckInOutRequest } from "../../Redux/checkin_out/checkin_outActions";

//css
import "../Header/Header.css";

//images
import logo from "../../assets/images/header/logo-black.svg";
import heart from "../../assets/images/header/heart-shape.svg";
import userlogo from "../../assets/images/header/register-login.svg";
//components
import Mobileheader from "./Mobileheader";
//translation
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { NotificationContainer } from "react-notifications";

function Header() {
  const ref = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const navigate = useNavigate();

  const [isShown, setIsShown] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [dropDown1, setDropDown1] = useState(false);
  const [dropDown2, setDropDown2] = useState(false);
  const [dropDown3, setDropDown3] = useState(false);
  const [dropDown4, setDropDown4] = useState(false);
  const [role, setRole] = useState("");

  const logoutuser = () => {
    dispatch(logout(navigate));

    // navigate("/");
    // window.location.reload();
  };
  const { notifications_total } = useSelector(
    (store) => store.NotificationsReducer
  );
  const { CountCheckInOutRequest } = useSelector(
    (store) => store.checkin_outReducer
  );

  useEffect(() => {
    if (isAuth() && role == 3) {
      dispatch(getTotalNotifications());
    }

    if (isAuth() && role == 4) {
      dispatch(getCountCheckInOutRequest());
    }
  }, [role]);

  const handleClick = (event) => {
    setIsShown((current) => !current);
  };
  const getrole = () => {
    const role = localStorage.getItem("role");
    setRole(role);
  };

  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const [islogedin, setislogedin] = useState(false);
  useEffect(() => {
    if (!isAuth()) {
      setislogedin(false);
    } else {
      setislogedin(true);
      getrole();
    }
  }, [isAuth()]);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (dropDown && ref.current && !ref.current.contains(e.target)) {
        setDropDown(!dropDown);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [dropDown]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (dropDown3 && ref3.current && !ref3.current.contains(e.target)) {
        setDropDown3(!dropDown3);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [dropDown3]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (dropDown4 && ref4.current && !ref4.current.contains(e.target)) {
        setDropDown4(!dropDown4);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [dropDown4]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (dropDown1 && ref1.current && !ref1.current.contains(e.target)) {
        setDropDown1(!dropDown1);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [dropDown1]);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (dropDown2 && ref2.current && !ref2.current.contains(e.target)) {
        setDropDown2(!dropDown2);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [dropDown2]);
  const handleLanguageChangear = () => {
    if (i18n.language === "en") {
      i18n.changeLanguage("ar");
      document.body.dir = "rtl";
      document.documentElement.setAttribute("lang", "ar");
      setLang("ar");
    } else {
      i18n.changeLanguage("en");
      document.body.dir = "ltr";
      document.documentElement.setAttribute("lang", "en");
      setLang("en");
    }
  };
  const handleLanguageChangeen = () => {
    if (i18n.language === "ar") {
      i18n.changeLanguage("en");
      document.body.dir = "rtl";
      document.documentElement.setAttribute("lang", "en");
      setLang("en");
    }
  };
  return (
    <div
      className={`Main_header_section globalContainer ${lang === "ar" && "main_header_arabic"
        }`}
    >
      <NotificationContainer />
      <div
        className={lang === "en" ? "logo_main_header" : "logo_main_header_ar"}
      >
        <img
          src={logo}
          alt="the vulunteer circle"
          onClick={() => navigate("/")}
        />
      </div>
      <div className="controls">
        <div className="header_section2">
          {role !== "4" ? (
            <>
              <div className="header_section2_1">
                <div className="header_link_main" ref={ref}>
                  <p
                    className={lang == "ar" ? "arabic_titles" : ""}
                    onClick={() => setDropDown(!dropDown)}
                  >
                    {!islogedin ? t("header.title1") : t("header.title11")}
                  </p>
                  {dropDown && (
                    <div className="hedar_drop_down">
                      <NavLink
                        to={"/opportunities/1"}
                        activeClassName="active-link"
                        className="header_link"
                        // style={{
                        //   fontSize: lang === 0 ? "14px" : "1.5rem",
                        // }}
                        // key={0}
                        onClick={() => setDropDown(false)}
                      >
                        <p className={lang == "ar" ? "arabic_titles" : ""}>
                          {t("header.subs1")}
                        </p>
                      </NavLink>

                    </div>
                  )}
                </div>

                {islogedin && 
                <div className="header_link_main" >
                <p
                  className={lang == "ar" ? "arabic_titles" : ""}
                  onClick={() => navigate("/communityAction")}
                >
                  {t("header.subs11")}
                </p>
                </div>
                }


                <div className="header_link_main" ref={ref1}>
                  <p
                    className={lang == "ar" ? "arabic_titles" : ""}
                    onClick={() => setDropDown1(!dropDown1)}
                  >
                    {t("header.title2")}
                  </p>
                  {dropDown1 && (
                    <div className="hedar_drop_down">
                      <NavLink
                        to={"/mayors-of-impact"}
                        activeClassName="active-link"
                        className="header_link"
                        onClick={() => setDropDown1(false)}
                      >
                        <p className={lang == "ar" ? "arabic_titles" : ""}>
                          {!islogedin ? t("header.subs3") : t("header.subs33")}
                        </p>
                      </NavLink>
                      {!islogedin && <NavLink
                        to={"/companies-and-institutions/1"}
                        activeClassName="active-link"
                        className="header_link"
                        // style={{
                        //   fontSize: lang === 0 ? "14px" : "1.5rem",
                        // }}
                        // key={6}
                        onClick={() => setDropDown1(false)}
                      >
                        <p className={lang == "ar" ? "arabic_titles" : ""}>
                          {t("header.subs4")}
                        </p>
                      </NavLink>
                      }
                    </div>
                  )}
                </div>
             {!islogedin  &&   <div className="header_link_main" ref={ref2}>
                  <p
                    className={lang == "ar" ? "arabic_titles" : ""}
                    onClick={() => setDropDown2(!dropDown2)}
                  >
                    {t("header.title3")}
                  </p>
                  {dropDown2 && (
                    <div className="hedar_drop_down">
                      {role == 4 && (
                        <NavLink
                          to={"/masterClass"}
                          activeClassName="active-link"
                          className="header_link"
                          onClick={() => setDropDown2(false)}
                        >
                          <p className={lang == "ar" ? "arabic_titles" : ""}>
                            {t("header.subs5")}
                          </p>
                        </NavLink>
                      )}
                      <NavLink
                        to={"/volunteer-managers"}
                        activeClassName="active-link"
                        className="header_link"
                        onClick={() => setDropDown2(false)}
                      >
                        <p className={lang == "ar" ? "arabic_titles" : ""}>
                          {t("header.subs6")}
                        </p>
                      </NavLink>

                      <NavLink
                        to={"/companies-and-institutions/2"}
                        activeClassName="active-link"
                        className="header_link"
                        onClick={() => setDropDown2(false)}
                      >
                        <p className={lang == "ar" ? "arabic_titles" : ""}>
                          {t("header.subs9")}
                        </p>
                      </NavLink>


                      <NavLink
                        to={"/companies-and-institutions/1"}
                        activeClassName="active-link"
                        className="header_link"
                        onClick={() => setDropDown2(false)}
                      >
                        <p className={lang == "ar" ? "arabic_titles" : ""}>
                          {t("header.subs10")}
                        </p>
                      </NavLink>

                    </div>
                  )}
                </div>
          }
                <div className="header_link_main" ref={ref3}>
                  <p
                    className={lang == "ar" ? "arabic_titles" : ""}
                    onClick={() => navigate("/about-us")}
                  >
                    {t("header.subs7")}
                  </p>
                  {/* {dropDown3 && (
                    <div className="hedar_drop_down">
                      <NavLink
                        to={"/about-us"}
                        activeClassName="active-link"
                        className="header_link"
                        onClick={() => setDropDown3(false)}
                      >
                        <p className={lang == "ar" ? "arabic_titles" : ""}>
                          {t("header.subs7")}
                        </p>
                      </NavLink>
                      <NavLink
                        to={"/our-partners"}
                        activeClassName="active-link"
                        className="header_link"
                        // style={{
                        //   fontSize: lang === 0 ? "14px" : "1.5rem",
                        // }}
                        // key={6}
                        onClick={() => setDropDown3(false)}
                      >
                        <p className={lang == "ar" ? "arabic_titles" : ""}>
                          {t("header.subs8")}
                        </p>
                      </NavLink>
                    </div>
                  )} */}
                </div>
              </div>
            </>
          ) : (
            <div className="header_section2_1">
              <div className="header_link_main">
                <p
                  onClick={() => navigate("/opportunities/1")}
                  className={lang == "ar" ? "arabic_titles" : "titles_org"}
                >
                  {t("header.org_title1")}
                </p>
              </div>
             
              <div className="header_link_main">
                <p
                  onClick={() => navigate("/oppRequests")}
                  className={lang == "ar" ? "arabic_titles" : "titles_org"}
                >
                  {t("header.org_title4")}
                </p>
                <span class="notification-badge">
                  {CountCheckInOutRequest?.Count?.countRequest >= 0 &&
                    CountCheckInOutRequest?.Count?.countEval >= 0
                    ? CountCheckInOutRequest?.Count?.countRequest +
                    CountCheckInOutRequest?.Count?.countEval
                    : 0}
                </span>
              </div>
              <div className="header_link_main">
                <p
                  onClick={() => navigate("/checkin_out")}
                  className={lang == "ar" ? "arabic_titles" : "titles_org"}
                >
                  {t("header.org_title2")}
                </p>
                <span class="notification-badge">
                  {CountCheckInOutRequest?.Count?.countCheckIn >= 0 &&
                    CountCheckInOutRequest?.Count?.countCheckOut >= 0
                    ? CountCheckInOutRequest?.Count?.countCheckIn +
                    CountCheckInOutRequest?.Count?.countCheckOut
                    : 0}
                </span>
              </div>
              <div className="header_link_main">
                <p
                  onClick={() => navigate("/masterClass")}
                  className={lang == "ar" ? "arabic_titles" : "titles_org"}
                >
                  {t("header.org_title3")}
                </p>
              </div>
            </div>
          )}

          <button
            className={"login_button_header pink"}
            onClick={() => navigate("/donate")}
          >
            <img className="logo" src={heart} />{" "}
            <p className={lang == "ar" ? "arabic_titles" : ""}>
              {t("header.donate")}
            </p>
          </button>
          {!islogedin ? (
            <>
              <button
                className="login_button1_header green"
                onClick={() => navigate("/auth/login")}
              >
                <img src={userlogo} className="logo" />
                <p className={lang == "ar" ? "arabic_titles" : ""}>
                  {" "}
                  {t("header.login")}
                </p>
              </button>
            </>
          ) : (
            <>
              <div
                className="header_link_main_login"
                onClick={() => setDropDown4(!dropDown4)}
                ref={ref4}
              >
                <button className="login_button1_header green">
                  <img src={userlogo} className="logo" />
                  <p className={lang == "ar" ? "arabic_titles" : ""}>
                    {" "}
                    {t("header.Account")}
                  </p>
                </button>
                {dropDown4 && (
                  <div className="header_drop_down_login">
                    <NavLink
                      to={"/profile-status"}
                      activeClassName="active-link"
                      className="header_link_login"
                      onClick={() => setDropDown4(false)}
                    >
                      <p className={lang == "ar" ? "arabic_titles" : ""}>
                        {t("header.status")}
                      </p>
                    </NavLink>
                    <div className="horizontal_rw_black_login"></div>
                    <NavLink
                      to={"/profile-info"}
                      activeClassName="active-link"
                      className="header_link_login"
                      onClick={() => setDropDown4(false)}
                    >
                      <p className={lang == "ar" ? "arabic_titles" : ""}>
                        {t("header.myProfile")}
                      </p>
                    </NavLink>
                    <div className="horizontal_rw_black_login"></div>
                    <NavLink
                      to={"/evaluation-certificates"}
                      activeClassName="active-link"
                      className="header_link_login"
                      onClick={() => setDropDown4(false)}
                    >
                      <p className={lang == "ar" ? "arabic_titles" : ""}>
                        {t("header.eval")}
                      </p>
                    </NavLink>
                    <div className="horizontal_rw_black_login"></div>

                    <NavLink
                      to={"/notifications"}
                      activeClassName="active-link"
                      className="header_link_login"
                      onClick={() => setDropDown4(false)}
                    >
                      <p className={lang == "ar" ? "arabic_titles" : ""}>
                        {t("header.subs_m8")}
                      </p>
                    </NavLink>
                    <div className="horizontal_rw_black_login"></div>

                    <NavLink
                      activeClassName="active-link"
                      className="header_link_login"
                      onClick={logoutuser}

                    // onClick={() => setDropDown4(false)}
                    >
                      <p className={lang == "ar" ? "arabic_titles" : ""}>
                        {t("header.logout")}
                      </p>
                    </NavLink>
                  </div>
                )}
                {islogedin && role == 3 && (
                  <div class="notification">
                    <IoIosNotificationsOutline size={30} />
                    <span class="notification-badge">
                      {notifications_total?.Check +
                        notifications_total?.Motivation +
                        notifications_total?.Reminders +
                        notifications_total?.Updates}
                    </span>
                  </div>
                )}
              </div>
            </>
          )}
          <div
            className={
              lang == "ar"
                ? "translation_section translation_section_arabic"
                : "translation_section"
            }
          >
            <span
              className="language-selected active-lang"
              onClick={handleLanguageChangeen}
            >
              {lang === "ar" ? "ع" : "EN"}
            </span>
            |
            <span
              className="language-selected "
              onClick={handleLanguageChangear}
            >
              {i18n.language === "en" ? "ع" : "EN"}
            </span>
          </div>

          <div
            className={
              lang === "ar"
                ? "header_burger_menu header_burger_menu_ar"
                : "header_burger_menu"
            }
          >
            <GiHamburgerMenu size={35} onClick={handleClick} />
          </div>
        </div>
      </div>

      <div className="mobile_menu">
        {isShown && <Mobileheader isShown={isShown} setIsShown={setIsShown} />}
      </div>
    </div>
  );
}

export default Header;
