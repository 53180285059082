import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  testimonials: [],
  loading: false,
  error: "",
};

const testimonialSlice = createSlice({
  name: "testimonials",
  initialState,
  reducers: {
    fetchTestimonialsRequest(state) {
      state.loading = true;
      state.testimonials = [];
      state.error = "";
    },

    fetchTestimonialsSuccess(state, action) {
      state.loading = false;
      state.testimonials = action.payload;
      state.error = "";
    },

    fetchTestimonialsFail(state, action) {
      state.loading = false;
      state.testimonials = [];
      state.error = action.payload;
    },
    createTestimonialRequest(state) {
      state.loading = true;
      state.testimonials = [];
      state.error = "";
    },
    createTestimonialSucess(state, action) {
      state.loading = false;
      state.testimonials = action.payload;
      state.error = "";
    },
    createTestimonialFail(state, action) {
      state.loading = false;
      state.testimonials = [];
      state.error = action.payload;
    },
  },
});

export const testimonialAction = testimonialSlice.actions;
export default testimonialSlice;
