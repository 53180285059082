import { React, useEffect, useState } from "react";
import "./Quiz.css";
import UpperSection from "../../components/UpperSection/UpperSection";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";

//images
import banner from "../../assets/images/quiz/Banner_main.png";
import curve from "../../assets/images/Home/curve.png";
//redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getQuizes,
  getanswersbyquizid,
  getanswersbyquizidandtype,
} from "../../Redux/Quiz/QuizActions";

function Quiz() {
  const { t } = useTranslation();
  const [Lang, setLang] = useState(i18n.language);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Quizz, Answers, opprec } = useSelector((store) => store.QuizReducer);
  const [quizes, setquizes] = useState([]);
  const [counter, setcounter] = useState(0);
  const [answersarr, setanswersarr] = useState([]);
  const [next, setnext] = useState({});
  const [data, setdata] = useState([]);
  const [finished, setfinished] = useState(false);

  const scrollToSection = (classname) => {
    scroller.scrollTo(classname, {
      duration: 800,
      delay: 0,
      offset: -100,
      smooth: "easeInOutQuart",
    });
  };
  useEffect(() => {
    dispatch(getQuizes());
  }, []);

  useEffect(() => {
    if (Quizz) {
      setquizes(Quizz[counter]);
    }
  }, [Quizz, counter]);
  const handleNext = () => {
    setcounter(counter + 1);
    data.push(next);
  };

  useEffect(() => {
    if (Quizz) {
      dispatch(getanswersbyquizid(quizes?.id));
    }
  }, [quizes]);
  const handlefinish = () => {
    data.push(next);
    dispatch(getanswersbyquizidandtype(data));
    setfinished(true);
  };
  const handleReadmore = (id) => {
    navigate("/oppDetail/" + id);
  };

  return (
    <div className="Main_quizz_div">
      <UpperSection title={t("quiz.title")} />
      <div className="quizz_div_1">
        <div className="quiz_text_container">
          <div className="title_container">{t("quiz.quiz_1")}</div>
          <div className="text_subtext">{t("quiz.quiz_2")} </div>
          <div
            className="text_button_container"
            onClick={() => scrollToSection("quizz_div_3")}
          >
            <button className="primary_button"> {t("quiz.quiz_3")}</button>
          </div>
        </div>
        <div className="quiz_image_container">
          <img src={banner} alt="banner" className="banner_image" />
        </div>
      </div>
      <div className="quizz_div_2">
        <div className="quiz_text_container_1">
          <div className="title_container_1">{t("quiz.quiz_4")}</div>
          <div className="pink_underline_quiz" />
          <div className="text_subtext_1">{t("quiz.quiz_5")}</div>
          <div className="text_subtext_1">{t("quiz.quiz_6")}</div>
          <div className="text_subtext_1">{t("quiz.quiz_7")}</div>
        </div>
      </div>
      <div className="curve_section">
        <img src={curve} className="curve_section_img" draggable={false} />
      </div>
      {(Quizz !== undefined && Quizz.length > 0) ? ((!finished)? (
        <div className="quizz_div_3">
          <div className="quiz_text_container_1">
            <div className="title_container_1">{t("quiz.quiz_8")}</div>
            <div className="pink_underline_quiz" />
          </div>

          <div className="quiz_container">
            <div className="quiz_title">
              {Lang == "en" ? quizes?.question : quizes?.question_ar}?{" "}
            </div>
            {Answers?.map((item) => {
              return (
                <div className="quiz_answers">
                  <input
                    type="radio"
                    name="answer"
                    value={item.type + " " + item.quize_id}
                    className="quiz_radio"
                    onChange={(e) => {
                      setnext(item.id);
                    }}
                  />

                  <label for="answer" className="label_radio">
                    {Lang == "en" ? item.answer : item.answer_ar}
                  </label>
                </div>
              );
            })}
            { (counter <= Quizz.length - 2) ? (
              <div className="quiz_next_button">
                <button
                  className="primary_button"
                  onClick={() => {
                    handleNext();
                  }}
                >
                  {t("quiz.quiz_9")}
                </button>
              </div>
            ) : (
              <div className="quiz_next_button">
                <button
                  className="primary_button"
                  onClick={() => {
                    handlefinish();
                  }}
                >
                  {t("quiz.quiz_10")}
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="quizz_div_3">
          <div className="quiz_text_container_1">
            <div className="title_container_2">{t("quiz.quiz_11")}</div>
            <div className="quiz_subtext"> {t("quiz.quiz_12")}</div>
          </div>
          <div className="quiz_opp_cont">
            {opprec?.map((item) => {
              return (
                <div className="opp_quizz_cards_container">
                  <div className="opp_quizz_card">
                    <div className="opp_quizz_card_header">
                      <div className="opp_requests_card_text">
                        <div className="opp_quizz_card_text_subtitle">
                          <img
                            src={
                              process.env.REACT_APP_API +
                              "/Opportunity/" +
                              item.img_url
                            }
                            className="opp_quizz_card_img"
                          />
                        </div>
                        <div className="opp_quizz_card_text_title">
                          {item.title}
                        </div>
                      </div>
                    </div>
                    <div className="opp_quiz_card_body">
                      <div
                        className="opp_quiz_card_body_action_container"
                        onClick={() => handleReadmore(item.id)}
                      >
                        <button className="quiz_btn">
                          {t("quiz.quiz_13")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )) : (<div></div>)}
    </div>
  );
}

export default Quiz;
