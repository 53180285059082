import axios from "axios";
import { requestGroupMembersAction } from "./requestGroupMembersReducers";

export const createRequestGroupMembers =
  (id, requestGroupMembers) => async (dispatch) => {
    dispatch(requestGroupMembersAction.createRequestGroupMembers());
    try {
      let response = await axios.post(
        process.env.REACT_APP_API + "/request_group_members/" + id,
        { data: requestGroupMembers }
      );
      dispatch(
        requestGroupMembersAction.createRequestGroupMembersSuccess(
          response.data
        )
      );
    } catch (error) {
      dispatch(
        requestGroupMembersAction.createRequestGroupMembersFail(error.message)
      );
    }
  };
