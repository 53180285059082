import axios from "axios";
import { OpportunityQuestionsAction } from "./OpportunityQuestionsReducers";

export const getOpportunityQuestions =
  (opportunity_id) => async (dispatch) => {
    dispatch(OpportunityQuestionsAction.getOpportunityQuestions());
    try {
      let response = await axios.get(
        process.env.REACT_APP_API + "/opportunity_questions/opportunity/" + opportunity_id
      );
      dispatch(
        OpportunityQuestionsAction.getOpportunityQuestionsSuccess(
          response.data.data
        )
      );
    } catch (error) {
      dispatch(
        OpportunityQuestionsAction.getOpportunityQuestionsFail(error.message)
      );
    }
  };
