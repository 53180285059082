import axios from "axios";
import { favoritesActions } from "./FavoritesReducers";

export const addtoFavorites = (request_id, token) => async (dispatch) => {
  try {
    dispatch(favoritesActions.FavoritesAddRequest());
    let response = await axios.post(
      process.env.REACT_APP_API + "/favorites/",
      {
        opportunity_id: request_id,
      },
      { Authorization: "Bearer " + token }
    );
    dispatch(favoritesActions.FavoritesAddSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    dispatch(favoritesActions.FavoritesAddFail(error.message));
  }
};

export const removeFromFavorites = (request_id, token) => async (dispatch) => {
  try {
    dispatch(favoritesActions.fetchFavoritesDeleteRequest());
    let response = await axios.delete(
      process.env.REACT_APP_API + "/favorites/" + request_id,

      { Authorization: "Bearer " + token }
    );
    dispatch(favoritesActions.fetchFavoritesDeleteSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    dispatch(favoritesActions.fetchFavoritesDeleteFail(error.message));
  }
};

export const getFavoritesbytoken = (token) => async (dispatch) => {
  try {
    dispatch(favoritesActions.fetchFavoritesRequest());
    let response = await axios.get(
      process.env.REACT_APP_API + "/favorites/opportunities/self/",

      { Authorization: "Bearer " + token }
    );
    dispatch(favoritesActions.fetchFavoritesSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    dispatch(favoritesActions.fetchFavoritesFail(error.message));
  }
};

export const getAllFavorites = () => async (dispatch) => {
  try {
    dispatch(favoritesActions.fetchFavAllRequest());
    let response = await axios.get(
      process.env.REACT_APP_API + "/favorites/self"
    );

    dispatch(favoritesActions.fetchFavAllSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    dispatch(favoritesActions.fetchFavAllFail(error.message));
  }
};
