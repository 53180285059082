import React from "react";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Register.css";
import volunteer from "../../assets/images/auth/volunteer.png";
import register from "../../assets/images/auth/lock.png";
import pass from "../../assets/images/auth/pass.png";

import { useDispatch, useSelector } from "react-redux";
import {
  signupVolunteer,
  signupOrg,
  signupVerifiedVolunteer,
  signupVerifiedOrg,
  login,
  loginVerifyOtp,
} from "../../Redux/auth/authactions";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import OTPInput, { ResendOTP } from "otp-input-react";
import Loading from "../../components/Loading/Loading";

function Register() {
  const dispatch = useDispatch();

  const {
    id,
    errorLogin,
    errorSignUp,
    loginLoading,
    OrgLoading,
    VolLoading,
    OTPLoading,
    response,
    OTPError,
  } = useSelector((store) => store.authReducer);

  const [email, setEmail] = useState("");
  const [isVol, setIsVol] = useState(true);
  const { page } = useParams();
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [otpVal, setOtpVal] = useState(0);
  const navigate = useNavigate();

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  //handle submit sign up/in
  const handleSubmit = (e) => {
    e.preventDefault();
    //if email exists
    if (email) {
      //if in the signup page
      if (page == "signUp") {
        //if the sign up as volunteer send email and navigate to otp verification for volunteer
        if (isVol) dispatch(signupVolunteer(email, navigate));
        //if the sign up as organization send email and navigate to otp verification for organization
        else dispatch(signupOrg(email, navigate));

        //if in the signin page  send email and navigate to otp verification
      } else if (page == "login") {
        dispatch(login(email, navigate));
      }
    }
  };

  //checking changes in the otp vlaue
  useEffect(() => {
    //if the otp value is equal to 4 digits
    if (otpVal.toString().length == 4) {
      //if the page params is volunteer, dispatch the signup verification for volunteer
      if (page.includes("vol"))
        dispatch(signupVerifiedVolunteer(id, otpVal, navigate));
      //if the page params is organization, dispatch the signup verification for organization
      else if (page.includes("org"))
        dispatch(signupVerifiedOrg(id, otpVal, navigate));
      //verfication after login, dispatch the signin verification
      else dispatch(loginVerifyOtp(id, otpVal, navigate));
    }
  }, [otpVal]);

  return (
    <div className="register_main_container">
      <div className="register_card_main">
        <div className="register_card">
          <img
            className={
              page.includes("verification")
                ? "register_image2"
                : "register_image"
            }
            src={page.includes("verification") ? pass : register}
          />

          {/*if login page*/}
          {page == "login" ? (
            <div className="register_input_cont">
              <h1 className="register_title">{t("Register.signIn")}</h1>
              <p className="register_subtitle">
                {t("Register.receive")}{" "}
                <span className="green_color">{t("Register.email")}</span>
              </p>
              <form onSubmit={(e) => handleSubmit(e)}>
                <input
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="input_field"
                  placeholder={t("Register.email_add")}
                />
                {errorLogin && (
                  <div className="register_error">{errorLogin}</div>
                )}
                <div className="register_button_login_cont">
                  <button className="primary_button">
                    {loginLoading ? (
                      <>
                        <AiOutlineLoading3Quarters className="fa-spin" />{" "}
                        {t("Register.logging")}
                      </>
                    ) : (
                      t("Register.signIn")
                    )}
                  </button>
                </div>{" "}
              </form>
              <div className="divider_line"></div>
              <p className="register_note">{t("Register.account")}</p>
              <div className="register_button_create_cont">
                <button
                  className="pink_button"
                  onClick={() => navigate("/auth/signUp")}
                >
                  {t("Register.new")}
                </button>
              </div>
            </div>
          ) : page == "signUp" ? (
            <div className="register_input_cont">
              {/*if signup page*/}

              <h1 className="register_title">{t("Register.signUp")}</h1>
              <p className="register_subtitle">
                {t("Register.choose")}{" "}
                <span className="green_color">{t("Register.create")}</span>
              </p>
              <form onSubmit={(e) => handleSubmit(e)}>
                <input
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="input_field"
                  placeholder={t("Register.email_add")}
                />
                {errorSignUp && (
                  <div className="register_error">{errorSignUp}</div>
                )}

                <div className="register_button_cont">
                  <button
                    className="primary_button"
                    onClick={() => setIsVol(true)}
                  >
                    {VolLoading ? (
                      <>
                        <AiOutlineLoading3Quarters className="fa-spin" />{" "}
                        {t("Register.signing")}
                      </>
                    ) : (
                      t("Register.volunteer")
                    )}
                  </button>
                  <button
                    className="pink_button"
                    onClick={() => setIsVol(false)}
                  >
                    {OrgLoading ? (
                      <>
                        <AiOutlineLoading3Quarters className="fa-spin" />{" "}
                        {t("Register.signing")}
                      </>
                    ) : (
                      t("Register.organization")
                    )}
                  </button>
                </div>
              </form>
            </div>
          ) : (
            page.includes("verification") && (
              <>
                {/*if otp verification page*/}
                <div className="register_input_cont">
                  <h1 className="register_title">{t("Register.signIn")}</h1>
                  <p className="register_subtitle">
                    {t("Register.otpMsg")}{" "}
                    <span className="green_color">{t("Register.email")}</span>
                  </p>
                  <div className="register_otp_cont">
                    <OTPInput
                      value={otpVal}
                      onChange={setOtpVal}
                      OTPLength={4}
                      otpType="number"
                      inputStyles={{
                        width: "20%",
                        textAlign: "center",
                        marginRight: "20px",
                        borderWidth: "0px 0px 1.5px 0px",
                        fontSize: "1.5rem",
                        borderBottomColor: "var(--bottom-grey)",
                      }}
                    />
                    {(OTPError || errorLogin) && (
                      <div
                        className="register_error"
                        style={{ marginTop: "1rem", marginBottom: "-1rem" }}
                      >
                        {OTPError ? OTPError : errorLogin}
                      </div>
                    )}
                  </div>

                  {(OTPLoading || loginLoading) && (
                    <AiOutlineLoading3Quarters
                      style={{
                        margin: "auto",
                        color: "var(--primary)",
                        justifyContent: "center",
                        display: "flex",
                        marginBottom: "1rem",
                      }}
                      className="fa-spin"
                    />
                  )}

                  <div className="divider_line"></div>
                  <p className="register_footer_title">
                    {t("Register.otpQst")}{" "}
                  </p>
                  <button
                    className="register_footer_subtitle green_color"
                    onClick={() => {
                      dispatch(login(email, navigate));
                      setOtpVal("");
                    }}
                  >
                    {t("Register.otpResend")}{" "}
                  </button>
                  {response && (
                    <div className="register_sent">
                      {" "}
                      {t("Register.otpIsRes")}{" "}
                    </div>
                  )}
                </div>
              </>
            )
          )}
        </div>
        <img className="register_astro" src={volunteer} />
      </div>
    </div>
  );
}

export default Register;
