import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requests: [],
  loading: false,
  error: "",

  reportedRequests: [],
  reportedLoading: false,
  reportedError: "",
};

const requestSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    createRequest(state) {
        state.loading = true;
        state.requests = [];
        state.error = "";
        state.success = false;
      },
      createRequestSuccess(state, action) {
        state.loading = false;
        state.requests = action.payload;
        state.error = "";
        state.success = true;
      },
      createRequestFail(state, action) {
        state.loading = false;
        state.requests = [];
        state.error = action.payload;
        state.success = false;
      },


      checkIfVolunteerReported(state, action) {
        state.reportedLoading = true;
        state.reportedRequests = [];
        state.reportedError = "";
      },
      checkIfVolunteerReportedSuccess(state, action) {
        state.reportedLoading = false;
        state.reportedRequests = action.payload;
        state.reportedError = "";
      },
      checkIfVolunteerReportedFail(state, action) {
        state.reportedLoading = false;
        state.reportedRequests = [];
        state.reportedError = action.payload;
      },

  },
});

export const requestAction = requestSlice.actions;
export default requestSlice;
