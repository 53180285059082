import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  Requests: [],
  Oppbytitle: [],
  oppByStatus: [],
  Age: [],
  sectors: [],
  nationality: [],
  country: [],
  location: [],
  createLoading: false,
  createError: "",
  createSucess: false,
};

const OppRequestsFiltersSlice = createSlice({
  name: "opportunities",
  initialState,
  reducers: {
    fetchOpportunitiesRequest(state) {
      state.loading = true;
      state.Requests = [];
      state.error = "";
    },
    fetchOpportunitiesSuccess(state, action) {
      state.loading = false;
      state.Requests = action.payload;
      state.error = "";
    },
    fetchOpportunitiesFail(state, action) {
      state.loading = false;
      state.Requests = [];
      state.error = action.payload;
    },
  },
});

export const OppRequestsFiltersActions = OppRequestsFiltersSlice.actions;
export default OppRequestsFiltersSlice;
