import { React, useEffect, useState } from "react";
import "./MasterClass.css";
import UpperSection from "../../components/UpperSection/UpperSection";
import i18n from "../../i18n";
import { useSSR, useTranslation } from "react-i18next";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import CarouselMulti from "react-multi-carousel";
//images
import photo1 from "../../assets/images/masterClass/illustration.b5c979e3 (1).png";
//popup
import AcessMaster_Pop from "./AcessMaster_Pop/AcessMaster_Pop";
//redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  sendMasterClassReqs,
  getMasterClassVerification,
  getallmastertestimonials,
} from "../../Redux/MasterClass/MasterClassActions";

import { masterClassPayCash, masterClassPayStrip, getConfig, onSuccessMasterClassStrip, onSuccessMasterClassCash } from "../../Redux/Pay/PayActions";
import CheckoutForm from "../../components/Stripe/CheckoutForm";
import Popup from "../../components/PopUp/PopUp.js";
import { Elements } from "@stripe/react-stripe-js";
import MasterClass_Det from "./MasterClass_Det/MasterClass_Det";
import Greencarousell from "../Home/Greencarousell";
//
import Emailinput from "./Emailinput";
import { GrGroup } from "react-icons/gr";
import { FaUserAlt } from "react-icons/fa";
import VideoPlayer from "./VideoPlayer/VideoPlayer";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";

function MasterClass() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [lang, setLang] = useState(i18n.language);
  const dispatch = useDispatch();
  const [showPop, setShowPop] = useState(false);
  const [IsGroup, setIsGroup] = useState(false);
  const [isVerified, setisVerified] = useState(false);
  const { Verified, mastertestimonials, reqMasterClass, reqMasterClassError, reqMasterClassLoading } = useSelector((store) => store.masterClassReducer);
  const [cashSubmit, setCashSubmit] = useState(false);
  const [stripSubmit, setStripSubmit] = useState(false);
  const [openPaymentPopUp, setOpenPaymentPopUp] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [Emails, setEmails] = useState([]);
  const [masterClassIds, setMasterClassIds] = useState([]);
  const [PaymentPop, setPaymentPop] = useState(false);
  const [groupop, setgroupop] = useState(false);
  const [openSuccessMsg, setOpenSuccessMsg] = useState(false);
  const [openMasterClassSuccessMsg, setOpenMasterClassSuccessMsg] = useState(false);
  const [openStripPopUpForm, setOpenStripPopUpForm] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentId, setPaymentId] = useState(null);

  const responsive3 = {
    desktop: {
      breakpoint: { max: 3000, min: 1400 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1400, min: 940 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 940, min: 0 },
      items: 1,
    },
  };
  const { masterClassCashs, masterClassCachLoading, config, masterClassStrip, successError, successLoading } = useSelector(
    (store) => store.PayReducer
  );

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);


  useEffect(() => {
    if (!successLoading && openMasterClassSuccessMsg) {
      if (successError.length == 0) {
        NotificationManager.success("Thank you! 🎉", "", 3000);
        window.location.href = "/masterClass";
      } else {
        NotificationManager.error(successError, "", 3000);
      }
    }
  }, [successLoading, openMasterClassSuccessMsg, successError]);

  useEffect(() => {
    if (!reqMasterClassLoading && openSuccessMsg) {
      if (reqMasterClass?.status) {
        setMasterClassIds(reqMasterClass?.data);
        NotificationManager.success(t("masterclass.masterclass_23"), "", 3000);
        setOpenPaymentPopUp(true);
      } else {
        NotificationManager.error(reqMasterClass?.message, "", 3000);
      }
    }
  }, [reqMasterClassLoading, openSuccessMsg, reqMasterClassError]);


  useEffect(() => {
    dispatch(getMasterClassVerification());
    setOpenSuccessMsg(false);
  }, []);


  const handleSubmit = () => {
    if (IsGroup !== undefined) {
      if (IsGroup) {
        const data = {
          emails: Emails,
          is_group: 1,
        };
        dispatch(sendMasterClassReqs(data));
        setOpenSuccessMsg(true);
      } else {
        const data = {
          is_group: 0
        };
        dispatch(sendMasterClassReqs(data));
        setOpenSuccessMsg(true);
      }
    }
  };
  //new workflow
  const addEmail = (newEmail) => {
    setEmails([...Emails, newEmail]);
  };
  const handleAddEmail = (newEmail) => {
    addEmail(newEmail);
  };
  const handleRemoveSpot = () => {
    const newStoredSpots = [...Emails];
    newStoredSpots.pop();
    setEmails(newStoredSpots);
  };
  const handlepayment = () => {
    if (!IsGroup) {
      setgroupop(false);
      setShowPop(true);
      setIsGroup(false);
      handleSubmit();
    } else {
      setgroupop(true);
      setShowPop(true);
      setIsGroup(true);
      setOpenPaymentPopUp(false);
    }

  };

  useEffect(() => {
    dispatch(getallmastertestimonials());
  }, []);



  useEffect(() => {
    if (cashSubmit) {
      if (masterClassCashs?.data !== undefined) {
        if (!masterClassCashs?.data?.status) {
          NotificationManager.warning(t("Subscription.pay_message"), "", 3000);
          //dispatch(onSuccessMasterClassCash(masterClassCashs?.data?.id , masterClassIds))
        }
      }
    }
  }, [cashSubmit, masterClassCashs, masterClassCachLoading]);





  useEffect(() => {
    if (stripSubmit) {
      setShowPop(false);
      setOpenStripPopUpForm(true);
      dispatch(getConfig());
    }
  }, [stripSubmit]);



  useEffect(() => {
    if (!openStripPopUpForm) {
      setStripSubmit(false);
    }
  }, [openStripPopUpForm]);


  useEffect(() => {
    if (config?.publishableKey) {
      setStripePromise(loadStripe(config?.publishableKey));
    }
  }, [config]);

  useEffect(() => {
    if (masterClassStrip?.clientSecret) {
      setPaymentId(masterClassStrip?.payment?.id);
      setClientSecret(masterClassStrip?.clientSecret);
    }
  }, [masterClassStrip]);

  const submitAction = () => {
    if (paymentMethod == "cash") {
      setCashSubmit(true);
      dispatch(masterClassPayCash(IsGroup ? 1 : 0));
      dispatch(getMasterClassVerification());
      setShowPop(false);
    } else if (paymentMethod == "strip") {
      setStripSubmit(true);
      dispatch(masterClassPayStrip(IsGroup ? 1 : 0));
    }
  };


  useEffect(() => {
    if (!Verified || Verified.length == 0) {
      setisVerified(false);
    } else if (Verified != "Pending") setisVerified(true);
    else setisVerified(false);
  }, [Verified]);

  return (
    <div className="Main_masteclass_div">
      <NotificationContainer />
      {isVerified ? (
        <MasterClass_Det />
      ) : (
        <>
          <div className="UpperSection">
            <UpperSection title={t("masterclass.title")} />
          </div>
          <div className="masterclass_body_div">
            <div className="masterclass_body_video_cont">
              <VideoPlayer videoId="GE8hPRuaQ4w" />
            </div>
            <div className="masterclass_body_second_div">
              <div className="masterclass_body_second_image_container">
                <img src={photo1} className="masterclass_image" />
              </div>
              <div className="masterclass_body_second_text_container">
                <div className="masterclass_body_second_text">
                  {t("masterclass.masterclass_4")}{" "}
                </div>
                <div className="masterclass_body_second_text_2">
                  {" "}
                  {t("masterclass.masterclass_5")}{" "}
                </div>
                <div className="masterclass_body_second_button_cont">
                  {Verified == "Pending" ? (
                    <button className="masterclass_button">
                      {t("masterclass.master_req")}
                    </button>
                  ) : (
                    <button
                      className="masterclass_button"
                      // onClick={() => setShowPop(true)}
                      onClick={() => setPaymentPop(true)}
                    >
                      {t("masterclass.button")}
                    </button>
                  )}
                </div>
                {PaymentPop && (
                  <AcessMaster_Pop
                    setOpen={setPaymentPop}
                    header={t("masterclass.masterclass_20")}
                    body={
                      <div className="masterclass_Acess_pop">
                        <div className="masterclass_Acess_pop_container_2">
                          <div className="masterclass_Acess_pop1">
                            <div className="masterclass_Acess_icon">
                              <GrGroup
                                size={50}
                                className="master_icon_bundle"
                              />
                            </div>
                            <div className="masterclass_Acess_pop_input">
                              <input
                                type="radio"
                                id="group"
                                name="group"
                                value="group"
                                className="masterclass_Acess_pop_input"
                                checked={IsGroup ? true : false}
                                onClick={() => {
                                  setIsGroup(true);
                                }}
                              />
                              <label
                                for="group"
                                className="masterclass_Acess_pop_label"
                              >
                                {t("masterclass.masterclass_21")}
                              </label>
                            </div>
                          </div>
                          <div className="masterclass_Acess_pop1">
                            <div className="masterclass_Acess_icon">
                              <FaUserAlt
                                size={50}
                                className="master_icon_bundle"
                              />
                            </div>
                            <div className="masterclass_Acess_pop_input">
                              <input
                                type="radio"
                                id="individual"
                                name="group"
                                value="individual"
                                checked={!IsGroup ? true : false}
                                className="masterclass_Acess_pop_input"
                                onClick={() => {
                                  setIsGroup(false);
                                }}
                              />
                              <label
                                for="group"
                                className="masterclass_Acess_pop_label"
                              >
                                {t("masterclass.masterclass_22")}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="masterclass_Acess_pop_container_2">
                          <div className="masterclass_Acess_pop1">
                            <a
                              target="_blank"
                              href={lang === "en" ? " https://drive.google.com/file/d/1vrPlutbc-XDVA4lQjnZRwzhmLnhCk-Gk/view?usp=drive_link" : " https://drive.google.com/file/d/1pYpA7fLSPs9KdeJHIbgMJD8sD2zBxETW/view?usp=drive_link"}
                            >
                              {t("Home.demo_6")}
                            </a>
                          </div>
                        </div>

                        <div className="masterclass_button_container">
                          <button
                            className="masterclass_button_acess"
                            onClick={() => {
                              setPaymentPop(false);
                              handlepayment();
                            }}
                          >
                            {" "}
                            {t("masterclass.button_2")}{" "}
                          </button>
                        </div>
                      </div>
                    }
                  />
                )}


                {showPop && (
                  <AcessMaster_Pop
                    setOpen={setShowPop}
                    header={t("masterclass.masterclass_8")}
                    body={
                      !openPaymentPopUp && groupop ? (<div className="masterclass_Acess_pop">

                        <div className="masterclass_Acess_step1">
                          {groupop && (
                            <div className="masterclass_Acess_step1_container">
                              <div className="masterclass_Acess_step1_container_tooltip">
                                {t("masterclass.masterclass_tooltip")}
                                <a
                                  className="tooltip-icon"
                                  id="global"
                                  data-tooltip-content={t(
                                    "masterclass.masterclass_tooltip_message"
                                  )}
                                >
                                  <BsFillQuestionCircleFill />
                                </a>
                                <Tooltip
                                  anchorId="global"
                                  title={t(
                                    "masterclass.masterclass_tooltip_message"
                                  )}
                                >
                                  <span>
                                    {t(
                                      "masterclass.masterclass_tooltip_message"
                                    )}
                                  </span>
                                </Tooltip>
                              </div>
                              <div className="master_group_emails">
                                <Emailinput
                                  onAdd={handleAddEmail}
                                  ondel={handleRemoveSpot}
                                />
                                <div className="emails_container">
                                  {Emails.map((email) => (
                                    <div key={email} className="emails_div">
                                      {email}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="masterclass_button_container">
                            <button
                              className="masterclass_button_acess"
                              onClick={handleSubmit}
                            >
                              {" "}
                              {t("masterclass.button_2")}{" "}
                            </button>
                          </div>
                        </div>
                      </div>) : (
                        <div className="subscription_card" style={{ width: '70%' }}>
                          <form>
                            <div className="subscription_card_body">
                              <h3 className="subscription_card_name green_color">
                                {t("Subscription.select_title")}
                              </h3>
                              <select
                                name="payment"
                                onChange={(event) => setPaymentMethod(event.target.value)}
                                required
                                className={
                                  lang === "ar"
                                    ? "currency_dropdown arabic-select"
                                    : "currency_dropdown"
                                }
                              >
                                <option selected disabled value=""></option>
                                <option value="cash">{t("Subscription.cash")}</option>
                                <option value="strip">{t("Subscription.credit_card")}</option>
                              </select>
                            </div>
                            <button className="button" type="button" onClick={submitAction}>
                              {t("Subscription.submit")}
                            </button>
                          </form>
                        </div>
                      )
                    }
                  />
                )}
              </div>
            </div>
            <div className="masterclass_body_third_div">
              <div className="masterclass_body_third_text">
                {t("masterclass.masterclass_6")}
                <div className="masterclass_body_third_pink_underline" />
              </div>
              <div className="masterclass_body_third_texts_container">
                <div className="masterclass_body_third_container">
                  <div className="masterclass_body_number_cont"> 1 </div>
                  <div className="masterclass_body_third_text_cont">
                    {" "}
                    {t("masterclass.masterclass_7_1")}{" "}
                  </div>
                </div>
                <div className="masterclass_body_third_container">
                  <div className="masterclass_body_number_cont"> 2 </div>
                  <div className="masterclass_body_third_text_cont">
                    {" "}
                    {t("masterclass.masterclass_7_2")}{" "}
                  </div>
                </div>
                <div className="masterclass_body_third_container">
                  <div className="masterclass_body_number_cont"> 3 </div>
                  <div className="masterclass_body_third_text_cont">
                    {" "}
                    {t("masterclass.masterclass_7_3")}{" "}
                  </div>
                </div>
                <div className="masterclass_body_third_container">
                  <div className="masterclass_body_number_cont"> 4 </div>
                  <div className="masterclass_body_third_text_cont">
                    {" "}
                    {t("masterclass.masterclass_7_4")}{" "}
                  </div>
                </div>
                <div className="masterclass_body_third_container">
                  <div className="masterclass_body_number_cont"> 5 </div>
                  <div className="masterclass_body_third_text_cont">
                    {" "}
                    {t("masterclass.masterclass_7_5")}{" "}
                  </div>
                </div>
                <div className="masterclass_body_third_container">
                  <div className="masterclass_body_number_cont"> 6</div>
                  <div className="masterclass_body_third_text_cont">
                    {" "}
                    {t("masterclass.masterclass_7_6")}{" "}
                  </div>
                </div>
                <div className="masterclass_body_third_container">
                  <div className="masterclass_body_number_cont"> 7</div>
                  <div className="masterclass_body_third_text_cont">
                    {" "}
                    {t("masterclass.masterclass_7_7")}{" "}
                  </div>
                </div>
                <div className="masterclass_body_third_container">
                  <div className="masterclass_body_number_cont"> 8</div>
                  <div className="masterclass_body_third_text_cont">
                    {" "}
                    {t("masterclass.masterclass_7_8")}{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="masterclass_body_fourth_div">
              <div className="green_carousell">
                <div className="green_carousell_title">
                  {t("Home.topic_12")}
                </div>
                <div className="green_carousell_hr" />

                <CarouselMulti
                  swipeable={true}
                  draggable={true}
                  showDots={
                    mastertestimonials &&
                    (mastertestimonials.length < 3 ? false : true)
                  }
                  arrows={true}
                  responsive={responsive3}
                >
                  {mastertestimonials.map((testimonial) => (
                    <Greencarousell
                      greensubs={
                        lang === "ar" ? testimonial.name_ar : testimonial.name
                      }
                      greentexts={
                        lang === "ar" ? testimonial.text_ar : testimonial.text
                      }
                    />
                  ))}
                </CarouselMulti>
              </div>
            </div>
          </div>
        </>
      )}



      {openStripPopUpForm && clientSecret && stripePromise && (
        <Popup
          setOpen={setOpenStripPopUpForm}
          body={
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <CheckoutForm
                stripePromise={stripePromise}
                clientSecret={clientSecret}
                paymentId={paymentId}
                setOpenSuccessMsg={setOpenMasterClassSuccessMsg}
                setOpenStripPopUpForm={setOpenStripPopUpForm}
                onSuccessPayment={onSuccessMasterClassStrip}
                masterClassIds={masterClassIds}
              />
            </Elements>
          }
        />
      )}

    </div>
  );
}

export default MasterClass;
