import React, { useEffect, useState } from "react";
import "./SubscriptionCards.css";
import { GiCheckMark } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import Popup from "../PopUp/PopUp.js";
import i18n from "../../i18n";
import { Elements } from "@stripe/react-stripe-js";
import {
  payCash,
  payStrip,
  getConfig,
} from "../../Redux/Pay/PayActions";
import { useDispatch, useSelector } from "react-redux";
import { isString } from "formik";
import CheckoutForm from "../Stripe/CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { onSuccessPayment } from "../../Redux/Pay/PayActions";



function SubscriptionCards(props) {
  const [checkMarkColor, setCheckMarkColor] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const priceType = props.paymentMethodeVal;
  const [cashSubmit, setCashSubmit] = useState(false);
  const [tripSubmit, setStripSubmit] = useState(false);
  const [pricePerMonthValue, setPricePerMonthValue] = useState("price");
  const { t } = useTranslation();
  const [openSubscribePopUp, setOpenSubscribePopUp] = useState(false);
  const [openStripPopUpForm, setOpenStripPopUpForm] = useState(false);
  const [lang, setLang] = useState(i18n.language);
  const dispatch = useDispatch();
  const { cashs, cachLoading, config, strip } = useSelector(
    (store) => store.PayReducer
  );
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentId, setPaymentId] = useState(null);


  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (tripSubmit) {
      setOpenSubscribePopUp(false);
      setOpenStripPopUpForm(true);
      dispatch(getConfig());
    }
  }, [tripSubmit]);

  useEffect(() => {
    if (!openStripPopUpForm) {
      setStripSubmit(false);
    }
  }, [openStripPopUpForm]);

  useEffect(() => {
    if (cashSubmit) {
      if (cashs?.data !== undefined) {
        if (!cashs?.data?.status) {
          props.setOpenWarningMsg(true);
          setOpenSubscribePopUp(false);
        }
      }
    }
  }, [cashSubmit, cashs, cachLoading]);

  useEffect(() => {
    if (priceType == "annual") {
      setPricePerMonthValue(props.year_price / 12);
    }
    if (priceType == "six_month") {
      setPricePerMonthValue(props.six_month_price / 6);
    }
  }, [priceType]);

  useEffect(() => {
    if (props.color === "#72c095") {
      setCheckMarkColor("#f06a80");
    } else {
      setCheckMarkColor("#72c095");
    }
  }, [props.color]);


  const submitAction = () => {
    let amount;
    if (props.cost_per_month == null) {
      if (priceType == "annual") {
        amount = props.year_price;
      }
      if (priceType == "six_month") {
        amount = props.six_month_price;
      }
    } else {
      amount = props.cost_per_month;
    }
    if (paymentMethod == "cash") {
      setCashSubmit(true);
      dispatch(payCash(amount, props.subscription_id, props.cost_per_month == null ? priceType : "per_month"));
    } else if (paymentMethod == "strip") {
      setStripSubmit(true);
      dispatch(payStrip(amount, props.subscription_id, props.cost_per_month == null ? priceType : "per_month"));
    }
  };

  useEffect(() => {
    if (config?.publishableKey) {
      setStripePromise(loadStripe(config?.publishableKey));
    }
  }, [config]);

  useEffect(() => {
    if (strip?.clientSecret) {
      setPaymentId(strip?.payment?.id);
      setClientSecret(strip?.clientSecret);
    }
  }, [strip]);



  return (
    <>
      <div className="Main_sub_card_div Main_sub_card_div_cards">
        <div className="sub_card_div" onClick={() => setOpenSubscribePopUp(true)}>
          <div className="sub_card" style={{ backgroundColor: props.color }}>
            <div className="sub_card_title">{props.title}</div>
            <div className="sub_card_subtitle">{props.subtitle}</div>
            {/* <div className="sub_card_heading">{props.cost_per_month ? '$' + props.cost_per_month + " USD" : props.heading}</div> */}


            {/* <button
              style={{ float: "right" }}
              className="footer-button-form subscribe_btn"
              type="button"
              onClick={() => setOpenSubscribePopUp(true)}
            >
              {

                priceType == "six_month" && t("Subscription.subscribe")
                //  (!isString(pricePerMonthValue)
                //   ? Math.round(pricePerMonthValue * 100) / 100 +
                //   " " +
                //   t("Subscription.per_month")
                //   : pricePerMonthValue + " " + t("Subscription.per_month"))
                  }

              {priceType == "annual" && (t("Subscription.save") + " " + (((props.six_month_price * 2) - props.year_price)).toFixed(2) + "%")}
            </button> */}

          </div>
          <div className="sub_card_content">
            {(props.content).map((val, index) => {
              return (
                <div className="content_sub" style={{ backgroundColor: index % 2 == 0 ? "#f9f9f9" : "" }}>
                  <div className="icon_div">
                    {val.value == 'true' ? <GiCheckMark
                      className="check_mark"
                      size={20}
                      style={{ color: "#72c095" }}
                    /> :
                      (val.value == 'false' ?
                        <AiOutlineClose
                          className="check_mark"
                          size={20}
                          style={{ color: "#f06a80" }}
                        />
                        : val.value
                      )
                    }
                  </div>
                </div>
              )
            })
            }
            {/* {props.content0 && (
              <div className="content_sub" style={{ backgroundColor: "#f9f9f9" }}>
                <div className="icon_div">
                  <GiCheckMark
                    className="check_mark"
                    size={20}
                    style={{ color: checkMarkColor }}
                  />
                </div>
              </div>
            )}

            {!props.content0 && (
              <div className="content_sub" style={{ backgroundColor: "#f9f9f9" }}>
                <div className="icon_div">
                  <AiOutlineClose
                    className="check_mark"
                    size={20}
                    style={{ color: checkMarkColor }}
                  />
                </div>
              </div>
            )}

            {props.content1 && (
              <div className="content_sub">
                <div className="content_sub_text">{props.content1}</div>
              </div>
            )}
            {props.content2 && (
              <div className="content_sub" style={{ backgroundColor: "#f9f9f9" }}>
                <div className="content_sub_text">{props.content2}</div>
              </div>
            )}
            {props.content3 && (
              <div className="content_sub">
                <div className="content_sub_text">{props.content3}</div>
              </div>
            )}
            {props.content4 && (
              <div className="content_sub" style={{ backgroundColor: "#f9f9f9" }}>
                <div className="icon_div">
                  <GiCheckMark
                    className="check_mark"
                    size={20}
                    style={{ color: checkMarkColor }}
                  />
                </div>
              </div>
            )}

            {!props.content4 && (
              <div className="content_sub" style={{ backgroundColor: "#f9f9f9" }}>
                <div className="icon_div">
                  <AiOutlineClose
                    className="check_mark"
                    size={20}
                    style={{ color: checkMarkColor }}
                  />
                </div>
              </div>
            )}

            {props.content5 && (
              <div className="content_sub">
                <div className="icon_div">
                  <GiCheckMark
                    className="check_mark"
                    size={20}
                    style={{ color: checkMarkColor }}
                  />
                </div>
              </div>
            )}
            {!props.content5 && (
              <div className="content_sub">
                <div className="icon_div">
                  <AiOutlineClose
                    className="check_mark"
                    size={20}
                    style={{ color: checkMarkColor }}
                  />
                </div>
              </div>
            )}

            {props.content6 && (
              <div className="content_sub" style={{ backgroundColor: "#f9f9f9" }}>
                <div className="icon_div">
                  <GiCheckMark
                    className="check_mark"
                    size={20}
                    style={{ color: checkMarkColor }}
                  />
                </div>
              </div>
            )}
            {!props.content6 && (
              <div className="content_sub" style={{ backgroundColor: "#f9f9f9" }}>
                <div className="icon_div">
                  <AiOutlineClose
                    className="check_mark"
                    size={20}
                    style={{ color: checkMarkColor }}
                  />
                </div>
              </div>
            )}

            {props.content7 && (
              <div className="content_sub">
                <div className="icon_div">
                  <GiCheckMark
                    className="check_mark"
                    size={20}
                    style={{ color: checkMarkColor }}
                  />
                </div>
                <div className="content_sub_text">{props.content7}</div>
              </div>
            )}
            {props.content8 && (
              <div className="content_sub" style={{ backgroundColor: "#f9f9f9" }}>
                <div className="icon_div">
                  <GiCheckMark
                    className="check_mark"
                    size={20}
                    style={{ color: checkMarkColor }}
                  />
                </div>
                <div className="content_sub_text">{props.content8}</div>
              </div>
            )}
            {props.content9 && (
              <div className="content_sub">
                <div className="icon_div">
                  <GiCheckMark
                    className="check_mark"
                    size={20}
                    style={{ color: checkMarkColor }}
                  />
                </div>
                <div className="content_sub_text">{props.content9}</div>
              </div>
            )} */}

            <div className="content_sub" style={{ border: "2px solid " + props.color, borderRadius: '0 0  16px  16px' }}>
              <div className="icon_div">
                <div className="sub_card_heading" style={{ color: props.color }}>
                  {props.cost_per_month ? (
                    <span>
                      {`$${props.cost_per_month} `}
                      <sub className="currenry_style">USD</sub>
                      {` / ${t("Subscription.month")}`}
                    </span>
                  ) : (
                    props.heading
                  )}
                </div>
              </div>
            </div>

          </div>

        </div>
        {openSubscribePopUp && priceType !== "" && (
          <Popup
            setOpen={setOpenSubscribePopUp}
            body={
              <div className="subscription_card">
                <form>
                  <h2 className="subscription_card_header">{props.title}</h2>
                  <div className="subscription_card_body">
                    <h3 className="subscription_card_name green_color">
                      {t("Subscription.select_title")}
                    </h3>
                    <select
                      name="payment"
                      onChange={(event) => setPaymentMethod(event.target.value)}
                      required
                      className={
                        lang === "ar"
                          ? "currency_dropdown arabic-select"
                          : "currency_dropdown"
                      }
                    >
                      <option selected disabled value=""></option>
                      <option value="cash">{t("Subscription.cash")}</option>
                      <option value="strip">{t("Subscription.credit_card")}</option>
                    </select>
                  </div>
                  <button className="button" type="button" onClick={submitAction}>
                    {t("Subscription.submit")}
                  </button>
                </form>
              </div>
            }
          />
        )}



        {openStripPopUpForm && clientSecret && stripePromise && (
          <Popup
            setOpen={setOpenStripPopUpForm}
            body={
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm
                  stripePromise={stripePromise}
                  clientSecret={clientSecret}
                  paymentId={paymentId}
                  setOpenSuccessMsg={props.setOpenSuccessMsg}
                  setOpenStripPopUpForm={setOpenStripPopUpForm}
                  onSuccessPayment={onSuccessPayment}
                />
              </Elements>
            }
          />
        )}
      </div>
    </>
  );
}

export default SubscriptionCards;
