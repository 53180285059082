import React, { useEffect, useState } from "react";
import "./OppCard.css";
import handicap from "../../assets/images/flags/wheelchair.png";
import food from "../../assets/images/flags/food.png";
import transport from "../../assets/images/flags/transport.png";
import calltoaction from "../../assets/images/flags/calltoaction.png";
import check from "../../assets/images/check.png";
import { GoLocation } from "react-icons/go";
import { BiTimeFive } from "react-icons/bi";
import { AiOutlineUser } from "react-icons/ai";
import Calculationpop from "../Calculationpop/Calculationpop";
import { BsPatchQuestion } from "react-icons/bs";
//translation
import { useSSR, useTranslation } from "react-i18next";
import i18n from "../../i18n";
import Popup from "../PopUp/PopUp.js";
import {
  checkOut,
  checkIn,
  getOpportunitiesByStatus,
  updateOpportunity,
  sendreminderbyid,
} from "../../Redux/opportunities/opportunityActions";
import { addEvaluation } from "../../Redux/evaluations/EvaluationsActions";
import { getEvalRequests } from "../../Redux/EvalRequests/EvalRequestsActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Feedbacks from "../../pages/Opportunities/Feedbacks/Feedbacks";
import Apply_Step1 from "../../pages/Opportunities/Applies/Apply_Step1/Apply_Step1";
import CreateOpp_Step1 from "../../pages/CreateOpp/CreateOpp_steps/Step1/CreateOpp_Step1";
import { IoMdHeartEmpty } from "react-icons/io";
import {
  addtoFavorites,
  getAllFavorites,
  removeFromFavorites,
} from "../../Redux/favorites/FavoritesActions";
import { FaHeart } from "react-icons/fa";
import PopUp from "../PopUp/PopUp.js";
import CreateOpp_Pop from "../../pages/CreateOpp/CreateOpp_Pop/CreateOpp_Pop";
import { FiPause } from "react-icons/fi";
import { IoMdRefresh, IoIosMore, IoIosClose } from 'react-icons/io';


function OppCard(props) {
  //translation
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [lang, setLang] = useState(i18n.language);
  const [openCheckoutPopUp, setOpenCheckoutPopUp] = useState(false);
  const [openCheckinPopUp, setOpenCheckinPopUp] = useState(false);
  const [openEvaluationPopUp, setOpenEvaluationPopUp] = useState(false);
  const [openFeedbacksPopup, setOpenFeedbacksPopup] = useState(false);
  const [openApplyPopUp, setOpenApplyPopUp] = useState(false);
  const { checkInOutLoading } = useSelector(
    (store) => store.opportunitiesReducer
  );
  const loadingFeed = useSelector((state) => state.evaluationsReducer.loading);
  const [footerWidth, setFooterWidth] = useState("8.33%");
  const { success } = useSelector((state) => state.evaluationsReducer);
  const { evalRequests } = useSelector((state) => state.evalRequestsReducer);
  const [loading, setLoading] = useState(false);
  const [isFinal, setIsFinal] = useState(false);
  const [applyAccessDeniedNotification, setApplyAccessDeniedNotification] =
    useState(false);
  const [pauseOpportunity, setPauseOpportunity] = useState(false);
  const [closeOpportunity, setCloseOpportunity] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [Saved, setSaved] = useState(props?.isSaved);
  const [evalRequestsData, setEvalRequestsData] = useState([]);
  const [progresshovered, setprogresshovered] = useState(false);
  const [loadingRequestEvaluation, setLoadingRequestEvaluation] =
    useState(false);
  const dispatch = useDispatch();
  const { allFav, favoritesAdd } = useSelector(
    (state) => state.favoritesReducer
  );
  useEffect(() => {
    dispatch(getEvalRequests());
    dispatch(getAllFavorites());
  }, []);

  useEffect(() => {
    setEvalRequestsData(evalRequests);
  }, [evalRequests]);

  useEffect(() => { }, [evalRequestsData]);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (loading && !checkInOutLoading) {
      setLoading(false);
      dispatch(
        getOpportunitiesByStatus(
          props.status == "Accepted" ? props.status : "InOut"
        )
      );
      window.scrollTo(0, 0);
    }
  }, [loading, checkInOutLoading, loadingFeed]);
  const [ongoingedit, setongoingedit] = useState(false);

  const [opencalculation, setopencalculation] = useState(false);
  useEffect(() => {
    if (loadingRequestEvaluation && success) {
      setOpenEvaluationPopUp(true);
      setLoadingRequestEvaluation(false);
      dispatch(getEvalRequests());
      setopencalculation(true);
    }
  }, [success, loadingRequestEvaluation]);

  const checkoutAction = () => {
    dispatch(checkOut(props.requestId, isFinal));
    setLoading(true);
  };

  const save = (e) => {
    e.stopPropagation()
    if (Saved) {
      dispatch(removeFromFavorites(props.id));
      setSaved(false);
    } else {
      dispatch(addtoFavorites(props.id));
      setSaved(true);
    }
  };
  useEffect(() => {
    if (
      allFav &&
      allFav.length > 0 &&
      localStorage.getItem("token") &&
      localStorage.getItem("role" == 3)
    )
      if (allFav?.find((s) => s.opportunity_id == props.id)) {
        setSaved(true);
      } else {
        setSaved(false);
      }
  }, [allFav]);

  return (
    <div className="opp_card_main_cont" onClick={(e) => {
      const isInsideOppCard = e.target.closest('.opp_card_main_cont');
      const isInput = e.target.closest('.create_pop_main_container');
      const feedbacks_page = e.target.closest('.feedbacks_page');
      if (!feedbacks_page && !isInput && !e.target.closest('button') && isInsideOppCard) {
        navigate(`/oppDetail/${props.id}`, {
          state: props.status && props.status !== "Saved" && 2,
        });
      }
    }}>
      <div className="opp_card_flags_cont">
        {props?.flags?.map((flag, i) => {
          return (
            <>
              {" "}
              {flag == "handicap" && (
                <img className="opp_card_flag_comp" src={handicap} />
              )}
              {flag == "food" && (
                <img className="opp_card_flag_comp" src={food} />
              )}
              {flag == "transport" && (
                <img className="opp_card_flag_comp" src={transport} />
              )}
              {flag == "calltoaction" && (
                <img className="opp_card_flag_comp" src={calltoaction} />
              )}
            </>
          );
        })}
      </div>

      <div className="opp_card_header_cont">
        <img
          src={process.env.REACT_APP_API + "/Organization/" + props.image}
          className="opp_card_header_image"
        />
        <div className="opp_card_header_info">
          <div className="opp_card_header_title">{props.title}</div>
        </div>
      </div>

      <div className="opp_card_header_respond">
            {t("opportunity.card.respond")}{" "}
            {props.respond ? props.respond : "24 Hrs"}
          </div>
      <div className="opp_card_info_cont">
        <div className="opp_card_info_main">
          <GoLocation size="2rem" className="opp_card_info_icon" />
          <div className="opp_card_info_header">
            <div className="opp_card_info_title">
              {" "}
              {t("opportunity.card.location")}
            </div>
            <div className="opp_card_info_info">
              {props.location ? props.location : "To be added"}
            </div>{" "}
          </div>
        </div>
        <div className="opp_card_info_main_div2">
          <div className="opp_card_info_main_div_item">
            <BiTimeFive size="2rem" className="opp_card_info_icon" />
            <div className="opp_card_info_header">
              <div className="opp_card_info_title">
                {" "}
                {t("opportunity.card.deadline")}
              </div>
              <div className="opp_card_info_text">
                {props.deadline ? props.deadline : "To be added"}
              </div>{" "}
            </div>
          </div>
          <div className="opp_card_info_main_div_item">
            <AiOutlineUser size={35} className="opp_card_logo greeny" />
            <div className="opp_card_info_header">
              <div className="opp_card_info_title">
                {" "}
                {t("opportunity.card.spots")}
              </div>
              <div className="opp_card_info_text">{props.spots}</div>{" "}
            </div>{" "}
          </div>
        </div>
      </div>
      {progresshovered && (
        <PopUp
          setOpen={setprogresshovered}
          body={
            <div className="ProgressBar_popUp">
              <div className="progressBar_popUp_header">
                <div className="ProgressBar_popUp_title">Progress Bar Info </div>
              </div>
              <div className="ProgressBar_popUp_body">
                <div className="ProgressBar_popUp_body_color_cont">
                  <div className=" Progresscont">
                    <div className="ProgressBardivs_color1 c1"></div>
                    <div className="ProgressBar_popUp_body_color_text">
                      Pending
                    </div>
                  </div>
                  <div className=" Progresscont">
                    <div className="ProgressBardivs_color1 c3"></div>
                    <div className="ProgressBar_popUp_body_color_text">
                      Confirmed
                    </div>
                  </div>
                  <div className=" Progresscont">
                    <div className="ProgressBardivs_color1 c5"></div>
                    <div className="ProgressBar_popUp_body_color_text">
                      Pending Check In
                    </div>
                  </div>

                  <div className=" Progresscont">
                    <div className="ProgressBardivs_color1 c4"></div>
                    <div className="ProgressBar_popUp_body_color_text">
                      Checked In
                    </div>
                  </div>
                  <div className=" Progresscont">
                    <div className="ProgressBardivs_color1 c7"></div>
                    <div className="ProgressBar_popUp_body_color_text">
                      Pending Check Out
                    </div>
                  </div>
                  <div className=" Progresscont">
                    <div className="ProgressBardivs_color1 c6"></div>
                    <div className="ProgressBar_popUp_body_color_text">
                      Checked Out
                    </div>
                  </div>
                  <div className=" Progresscont">
                    <div className="ProgressBardivs_color1 c8"></div>
                    <div className="ProgressBar_popUp_body_color_text">
                      History
                    </div>
                  </div>
                  <div className=" Progresscont">
                    <div className="ProgressBardivs_color1 c9"></div>
                    <div className="ProgressBar_popUp_body_color_text">
                      Evaluated
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      )}
      {(localStorage.getItem("role") != 4) && <div className="ProgressBar">
        <div
          className="ProgressBar_title"
          onClick={(e) => {
            e.stopPropagation()
            setprogresshovered(true);
          }}
        >
          {props.status ? (
            <>
              <span>{props.status} </span><BsPatchQuestion size={20} className="popicon" />
            </>
          ) : (
            t("opportunity.volunteer_application_progress")
          )}

        </div>

        {props.status && <div className="ProgressBar_container">
          {/* <div className="ProgressBardivs"></div> */}
          {props.status == "Pending" ? (
            <div className="ProgressBardivs_color c1 ">
              <div className="check_cont">
                <img src={check} className="check" />
              </div>
            </div>
          ) : (
            <div className="ProgressBardivs_color1 c1 "></div>
          )}
          <div className="ProgressBardivs "></div>
          {props.status == "Accepted" ? (
            <div className="ProgressBardivs_color c2 ">
              <div className="check_cont">
                <img src={check} className="check" />
              </div>
            </div>
          ) : (
            <div className="ProgressBardivs_color1 c2 "></div>
          )}
          <div className="ProgressBardivs"></div>
          {props.status == "Pending-Checked-in" ? (
            <div className="ProgressBardivs_color c5 ">
              <div className="check_cont">
                <img src={check} className="check" />
              </div>
            </div>
          ) : (
            <div className="ProgressBardivs_color1 c5 "></div>
          )}
          <div className="ProgressBardivs"></div>
          {/* {props.status == "Checked-in" ? (
            <div className="ProgressBardivs_color c4 ">
              <div className="check_cont">
                <img src={check} className="check" />
              </div>
            </div>
          ) : (
            <div className="ProgressBardivs_color1 c4 "></div>
          )} */}

          {props.status == "Checked-in" ? (
            <div className="ProgressBardivs_color c4 ">
              <div className="check_cont">
                <img src={check} className="check" />
              </div>
            </div>
          ) : (
            <div className="ProgressBardivs_color1 c4 "></div>
          )}
          <div className="ProgressBardivs"></div>

          {props.status == "Pending-Last-Checked-out" || props.status == "Pending-Checked-out" ? (
            <div className="ProgressBardivs_color c7 ">
              <div className="check_cont">
                <img src={check} className="check" />
              </div>
            </div>
          ) : (
            <div className="ProgressBardivs_color1 c7 "></div>
          )}
          <div className="ProgressBardivs"></div>

          {props.status == "Checked-out" ? (
            <div className="ProgressBardivs_color c6 ">
              <div className="check_cont">
                <img src={check} className="check" />
              </div>
            </div>
          ) : (
            <div className="ProgressBardivs_color1 c6 "></div>
          )}


          <div className="ProgressBardivs"></div>
          {props.status == "History" ? (
            <div className="ProgressBardivs_color c8 ">
              <div className="check_cont">
                <img src={check} className="check" />
              </div>
            </div>
          ) : (
            <div className="ProgressBardivs_color1 c8 "></div>
          )}
          <div className="ProgressBardivs"></div>

          {props.status == "Evaluated" ? (
            <div className="ProgressBardivs_color c9 ">
              <div className="check_cont">
                <img src={check} className="check" />
              </div>
            </div>
          ) : (
            <div className="ProgressBardivs_color1 c9 "></div>
          )}
        </div>}
      </div>}
      {/* <div className="divider_line"></div> */}

      <div className="opp_card_points">
        <div className="points_value">
          <div className="opp_card_points_title">
            {" "}
            {t("opportunity.card.points")}
          </div>
          <div className="opp_card_points_text">
            {props.points} {t("opportunity.card.pts_hour")}
          </div>{" "}
        </div>
        <div
          className="grey_vertical_border"
          style={{ margin: "0 10px" }}
        ></div>
        {(localStorage.getItem("role") != 4) && <><div className="dotter_spacer"></div>
          <div className="opp_card_save_btn_cont_cm" onClick={save}>
            {Saved ? (
              <>
                <FaHeart size={25} className="opp_card_logo" />
                <div
                  className={
                    lang === "en"
                      ? "opp_card_save_text"
                      : "opp_card_save_text_ar"
                  }
                >
                  {t("oppdetails.oppdetails_3")}
                </div>
              </>
            ) : (
              <>
                <IoMdHeartEmpty size={30} className="opp_card_logo" />
                <div
                  className={
                    lang === "en"
                      ? "opp_card_save_text"
                      : "opp_card_save_text_ar"
                  }
                >
                  {t("oppdetails.oppdetails_3")}
                </div>
              </>
            )}
          </div></>}

        {(localStorage.getItem("role") != 4) && props.status && (
          <>
            <div className={props.status + "-circle_shape circle_shape"}></div>
            <div className="opp_status">
              {props.status == "Pending"
                ? t("opportunity.Pending_confirmation")
                : t("opportunity." + props.status)}{" "}
            </div>{" "}
          </>
        )}
      </div>
      <div className="readMore_container">
        {props.status != "Drafts" && (
          // <button
          //   className={
          //     props.status && props.status != "Past"
          //       ? "opp_card_read_more"
          //       : "opp_card_read_more button_full_width"
          //   }
          //   onClick={() =>
          //     navigate(`/oppDetail/${props.id}`, {
          //       state: props.status && props.status != "Saved" && 2,
          //     })
          //   }
          // >
          //   {" "}
          //   {t("opportunity.card.read_more")}
          // </button>
          <>
            <div className="pause-circle-icon" onClick={() =>
              navigate(`/oppDetail/${props.id}`, {
                state: props.status && props.status != "Saved" && 2,
              })
            }>
              <IoIosMore title={t("opportunity.card.read_more")} />
            </div>
            {props.status != "Closed" && (localStorage.getItem("role") != 3) && <div className="pause-circle-icon" onClick={(e) => {e.stopPropagation() ; setCloseOpportunity(true)} }>
              <IoIosClose title={t("opportunity.close")} />
            </div>}
          </>

        )}
        {props.status == "Accepted" && (
          <button
            className="opp_card_check_in_btn button_full_width"
            onClick={() => {
              dispatch(checkIn(props.requestId));
              setOpenCheckinPopUp(true);
            }}
          >
            {" "}
            {t("opportunity.check_in")}
          </button>
        )}
        {props.status == "History" && (
          <button
            className={
              evalRequestsData?.some((evalRequests) => evalRequests?.request?.id === props.requestId)
                ? "opp_card_history_btn opp_card_check_out_btn_disabled button_full_width button_full_width"
                : "opp_card_history_btn button_full_width button_full_width"
            }
            onClick={() => {
              dispatch(addEvaluation(props.requestId));
              setLoadingRequestEvaluation(true);
            }}
            disabled={
              evalRequestsData?.some((evalRequests) => evalRequests?.request?.id === props.requestId)
                ? true
                : false
            }

          >
            {" "}
            {t("opportunity.request_evaluation")}
          </button>
        )}
        {props.status == "Checked-in" && (
          <button
            className="opp_card_check_out_btn button_full_width"
            onClick={() => {
              setOpenCheckoutPopUp(true);
            }}
          >
            {" "}
            {t("opportunity.check_out")}
          </button>
        )}

        {props.status == "Checked-out" && (
          <button
            className="opp_card_check_in_btn button_full_width"
            onClick={() => {
              dispatch(checkIn(props.requestId));
              setOpenCheckinPopUp(true);
            }}
          >
            {" "}
            {t("opportunity.check_in")}
          </button>
        )}

        {[
          "Pending-Checked-in",
          "Pending-Checked-out",
          "Pending-Last-Checked-out",
        ].includes(props.status) && (
            <button
              className="opp_card_check_out_btn opp_card_check_out_btn_disabled button_full_width"
              disabled
            >
              {" "}
              {t("opportunity.check_out")}
            </button>
          )}
        {props.status == "Saved" && (
          <button
            className="opp_card_check_out_btn button_full_width"
            onClick={() => {
              navigate(`/oppDetail/${props.id}`, { state: 3 });
            }}
          >
            {" "}
            {t("opportunity.apply")}
          </button>
        )}
        {props.status == "Ongoing" && (
          <>
            {/* <button
              className="opp_card_check_out_btn"
              onClick={() => dispatch(updateOpportunity(props.id, "paused", props?.community))}
            >
              {" "}
              {t("opportunity.pause")}
            </button> */}

            <div className="pause-circle-icon" onClick={(e) => {e.stopPropagation() ; setPauseOpportunity(true)}}>
              <FiPause title={t("opportunity.pause")} />
            </div>

            <button
              className="opp_card_check_out_btn button_full_width"
              onClick={() => setOpenEdit(true)}
            >
              {" "}
              {t("opportunity.continue")}
            </button>

            {openEdit && (
              <CreateOpp_Pop
                setOpen={setOpenEdit}
                header={
                  t("opportunity.create_vol_opp")
                }
                body={
                  <CreateOpp_Step1
                    id={props.id}
                    setOpen={setOpenEdit}
                    edit={true}
                    status={props.status}
                    setFooterWidth={setFooterWidth}
                  />
                }
                footer={
                  <div
                    className={
                      lang == "en"
                        ? "create_opp_footer_slider_green"
                        : "create_opp_footer_slider_green create_opp_footer_slider_green_ar"
                    }
                    style={{ width: footerWidth }}
                  ></div>
                }
              />
            )}
          </>
        )}


        {props.status == "Drafts" && (
          <>
            <button
              className="opp_card_check_out_btn button_full_width"
              onClick={() => setOpenEdit(true)}
            >
              {" "}
              {t("opportunity.continue_edit")}
            </button>

            {openEdit && (

              <CreateOpp_Pop
                setOpen={setOpenEdit}
                header={
                  t("opportunity.create_vol_opp")
                }
                body={
                  <CreateOpp_Step1
                    id={props.id}
                    setOpen={setOpenEdit}
                    edit={true}
                    setFooterWidth={setFooterWidth}
                  />
                }
                footer={
                  <div
                    className={
                      lang == "en"
                        ? "create_opp_footer_slider_green"
                        : "create_opp_footer_slider_green create_opp_footer_slider_green_ar"
                    }
                    style={{ width: footerWidth }}
                  ></div>
                }
              />
            )}
          </>
        )}
        {props.status == "Paused" && (
          <>
            <div className="refresh-icon" onClick={(e) => {e.stopPropagation() ; dispatch(updateOpportunity(props.id, "Ongoing", props?.community))}}>
              <IoMdRefresh title={t("opportunity.reactivate")} />
            </div>

          </>
          // <button
          //   className="opp_card_check_out_btn"
          //   onClick={() => dispatch(updateOpportunity(props.id, "Ongoing", props?.community))}
          // >
          //   {" "}
          //   {t("opportunity.reactivate")}
          // </button>
        )}
      </div>

      {openCheckoutPopUp && (
        <Popup
          setOpen={setOpenCheckoutPopUp}
          body={
            <div className="checkout_popup">
              <div className="checkout_header">
                <div className="checkout_header_title1">
                  {t("opportunity.card.checkout_popUp_notification")}
                </div>
                <div className="checkout_header_title2">
                  {t("opportunity.card.checkout_popUp_notification1")}
                </div>
              </div>
              <div className="checkout_popup_body">
                <button
                  className="last_checkout"
                  onClick={() => {
                    setOpenCheckoutPopUp(false);
                    setOpenFeedbacksPopup(true);
                    setIsFinal(true);
                  }}
                >
                  {" "}
                  {t("opportunity.card.lastChechout")}
                </button>
                <button
                  className="not_last_checkout"
                  onClick={() => {
                    setOpenCheckoutPopUp(false);
                    setOpenFeedbacksPopup(true);
                    setIsFinal(false);
                  }}
                >
                  {" "}
                  {t("opportunity.card.notLastCheckOut")}
                </button>
              </div>
            </div>
          }
        />
      )}

      {openCheckinPopUp && (
        <Popup
          setOpen={setOpenCheckinPopUp}
          body={
            <div className="checkin_popup">
              <div className="checkin_header">
                <div className="checkin_header_title">
                  {t("opportunity.card.checkin_popUp_notification")}
                </div>
              </div>
              <div className="checkin_popup_body">
                <button
                  className="checkin_ok"
                  onClick={() => {
                    setOpenCheckinPopUp(false);
                    setLoading(true);
                  }}
                >
                  {" "}
                  {t("opportunity.card.got_it")}
                </button>
              </div>
            </div>
          }
        />
      )}

      {openEvaluationPopUp && (
        <Popup
          setOpen={setOpenEvaluationPopUp}
          body={
            <div className="evaluation_popup_title">
              {t("evaluations.evaluation_sent_successfully")}
            </div>
          }
        />
      )}

      {openFeedbacksPopup && (
        <Feedbacks
          requestId={props.requestId}
          setOpen={setOpenFeedbacksPopup}
          onSubmitAction={checkoutAction}
        />
      )}
      {/* {openApplyPopUp && (
        <Apply_Step1
          setOpen={setOpenApplyPopUp}
          open={openApplyPopUp}
          opportunityId={props.opportunityId}
        />
      )} */}

      {applyAccessDeniedNotification && (
        <Popup
          setOpen={setApplyAccessDeniedNotification}
          body={
            <div className="popup_body">
              <div className="home_popup_body">
                {t("CompleteProf.complete_your_profile_to_apply")}
              </div>
            </div>
          }
        />
      )}
       {pauseOpportunity && (
        <Popup
          setOpen={setPauseOpportunity}
          body={
            <div className="checkin_popup">
              <div className="checkin_header">
                <div className="checkin_header_title">
                  {t("opportunity.card.pause_accept_message")}
                </div>
              </div>
              <div className="checkin_popup_body">
                <button
                  className="checkin_ok"
                  onClick={() =>  dispatch(updateOpportunity(props.id, "paused", props?.community))}
                >
                  {" "}
                  {t("opportunity.pause")}
                </button>
                <button
                  className="checkin_ok"
                  onClick={() =>  setPauseOpportunity(false)}
                >
                  {" "}
                  {t("opportunity.card.cancel")}
                </button>
              </div>
            </div>
          }
        />
      )}


{closeOpportunity && (
        <Popup
          setOpen={setCloseOpportunity}
          body={
            <div className="checkin_popup">
              <div className="checkin_header">
                <div className="checkin_header_title">
                  {t("opportunity.card.close_accept_message")}
                </div>
              </div>
              <div className="checkin_popup_body">
                <button
                  className="checkin_ok"
                  onClick={() =>  navigate("/evaluation_feedback/" + props.id)}
                >
                  {" "}
                  {t("opportunity.close")}
                </button>
                <button
                  className="checkin_ok"
                  onClick={() =>  setCloseOpportunity(false)}
                >
                  {" "}
                  {t("opportunity.card.cancel")}
                </button>
              </div>
            </div>
          }
        />
      )}


    </div>
  );
}

export default OppCard;
