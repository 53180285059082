import { React, useState } from "react";
import "./Emailinput.css";

function Emailinput({ onAdd, ondel }) {
  const [email, setEmail] = useState("");
  const [numEmails, setNumEmails] = useState(0);

  const handleAdd = () => {
    if (!email || numEmails >= 4) {
      return;
    }
    onAdd(email);
    setEmail("");
    setNumEmails(numEmails + 1);
  };

  const handledel = () => {
    ondel();
    setEmail("");
    setNumEmails(numEmails - 1);
  };

  return (
    <div>
      {" "}
      <div className="Main_emailinput_master">
        <input
          className="emailinput_master"
          type="email"
          placeholder="Please Enter Your Team Emails"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <button onClick={handleAdd} className="button_add_Master">
          +
        </button>
        <button onClick={handledel} className="button_add_Master">
          -
        </button>
      </div>
      {numEmails >= 4 && (
        <div className="emailinput_master_error">
          You can only add up to 4 emails
        </div>
      )}
    </div>
  );
}

export default Emailinput;
