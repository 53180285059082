import axios from "axios";
import { RecommendationsAction } from "./RecommendationReducer";

export const getRecommendations = () => async (dispatch) => {
  dispatch(RecommendationsAction.fetchRecommendationsRequest());
  try {
    let response = await axios.get(process.env.REACT_APP_API + "/opportunities/getRecommendation");
    dispatch(RecommendationsAction.fetchRecommendationsSuccess(response.data.data));
  } catch (error) {
    dispatch(RecommendationsAction.fetchRecommendationsFail(error.message));
  }
};
