import axios from "axios";
import { Navigate } from "react-router-dom";
import { authActions } from "./authreducers";
import setAuthToken from "../../Utils/setAuthToken";
import { compose } from "redux";
import { userAction } from "../user/userReducers";

export const login = (email, navigate) => async (dispatch) => {
  try {
    dispatch(authActions.loginRequest());
    const response = await axios.post(process.env.REACT_APP_API + "/login", {
      email,
    });
    dispatch(authActions.loginSuccess(response.data));
    navigate("/auth/verification");
  } catch (e) {
    dispatch(authActions.loginFail(e.response.data.message));
  }
};

export const signupVolunteer = (email, navigate) => async (dispatch) => {
  try {
    dispatch(authActions.signupVolRequest());
    const { data } = await axios.post(
      process.env.REACT_APP_API + "/signup/volunteer",
      {
        email,
      }
    );

    dispatch(authActions.signupVolSuccess(data));
    navigate("/auth/verification_vol");
  } catch (e) {
    dispatch(authActions.signupVolFail(e.response.data.message));
  }
};

export const signupOrg = (email, navigate) => async (dispatch) => {
  try {
    dispatch(authActions.signupOrgRequest());
    const { data } = await axios.post(
      process.env.REACT_APP_API + "/signup/organization",
      {
        email,
      }
    );

    dispatch(authActions.signupOrgSuccess(data));
    navigate("/auth/verification_org");
  } catch (e) {
    dispatch(authActions.signupOrgFail(e.response.data.message));
  }
};
export const signupVerifiedVolunteer =
  (id, code, navigate) => async (dispatch) => {
    try {
      code = parseInt(code);
      dispatch(authActions.verifyRequest());
      const { data } = await axios.post(
        process.env.REACT_APP_API + "/verifiedSignUpVolunteer/" + id,
        {
          code,
        }
      );

      const token = data.token;

      const step = data.user.step;
      const role = data.user.role;

      localStorage.setItem("token", token);
      localStorage.setItem("role", role);
      localStorage.setItem("id", data.user.id);

      localStorage.setItem("step", step);

      setAuthToken(token);
      dispatch(authActions.verifySuccess(data.message));
       if (step !== 9){
        if (role == 3) navigate("/afterauth");
        else navigate("/completeProfile/org/step1");
      }
      else navigate("/");
    } catch (e) {
      dispatch(authActions.verifyFail(e.response.data.message));
    }
  };

export const signupVerifiedOrg = (id, code, navigate) => async (dispatch) => {
  try {
    code = parseInt(code);
    dispatch(authActions.verifyRequest());
    const { data } = await axios.post(
      process.env.REACT_APP_API + "/verifiedSignUpOrganization/" + id,
      {
        code,
      }
    );
    const token = data.token;
    const step = data.user.step;
    const role = data.user.role;
    const email = data.user.email;
    localStorage.setItem("token", token);
    localStorage.setItem("role", role);
    localStorage.setItem("email", email);
    localStorage.setItem("id", data.user.id);

    localStorage.setItem("step", step);

    setAuthToken(token);
    dispatch(authActions.verifySuccess(data.message));
    navigate("/completeProfile/org/step1");
  } catch (e) {
    dispatch(authActions.verifyFail(e.response.data.message));
  }
};

export const loginVerifyOtp = (id, code, navigate) => async (dispatch) => {
  try {
    code = parseInt(code);
    dispatch(authActions.verifyLoginRequest());
    const response = await axios.post(
      process.env.REACT_APP_API + "/verify/" + id,
      {
        code,
      }
    );
    const token = response.data.token;
    const step = response.data.user.step;
    const role = response.data.user.role;
    const email = response.data.user.email;
    localStorage.setItem("token", token);
    localStorage.setItem("role", response.data.user.role);
    localStorage.setItem("step", step);
    localStorage.setItem("email", email);
    localStorage.setItem("id", response.data.user.id);

    setAuthToken(token);
    dispatch(authActions.verifyLoginSuccess(response.data));
    if(role == 3 && step == 10){
      navigate("/");
    }
    else if(role == 4 && step == 5 ){
      navigate("/");
    }
    else if (step !== 9){
      if (role == 3) navigate("/afterauth");
      else navigate("/completeProfile/org/step1");
    }
    else navigate("/");
  } catch (e) {
    console.log(e);
    dispatch(authActions.verifyLoginFail(e.response.data.message));
  }
};

export const logout = () => async (dispatch) => {
  try {
    const id = localStorage.getItem("id");

    let response = await axios.put(
      process.env.REACT_APP_API + "/users/editUser/" + id,
      {
        step: localStorage.getItem("step"),
      }
    );
    localStorage.clear();
    setAuthToken(false);
    dispatch(authActions.logoutSuccess());
    window.location.href = "/";
  } catch (error) {
    console.log(error);
  }
};
