import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import i18n from "../../../../i18n";
import CompleteProfile from "../../CompleteProfile";
import "./Complete_Step5.css";
import Select from "react-select";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import axios from "axios";
import {
  getuserinfo,
  updateUserinfo,
} from "../../../../Redux/user/userActions";
import Loading from "../../../../components/Loading/Loading";

function Complete_Step5() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const navigate = useNavigate();
  const size = useWindowSize();
  const dispatch = useDispatch();
  const [isNext, setIsNext] = useState(true);
  const token = localStorage.getItem("token");
  const { userinfo, loading } = useSelector((store) => store.userReducer);
  const [allOrg, setAllOrg] = useState([]);
  const [found, setFound] = useState(true);
  const [newInst, setNewInst] = useState("");

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (organization) {
      var user = {};
      if (organization.label != "Others") {
        user = {
          institution: organization.label,
        };
      } else {
        user = {
          institution: "Others: " + newInst,
        };
      }
      const step = localStorage.getItem("step");
      if (step == 4) {
        localStorage.setItem("step", 5);
      }
      if (isNext) dispatch(updateUserinfo(token, user, navigate, 6));
      else dispatch(updateUserinfo(token, user, navigate, 0));
    }
  };

  const getInst = async () => {
    const response = await axios.get(
      process.env.REACT_APP_API + "/institutions"
    );

    const newOrg = response.data.data.map((d, i) => {
      return { value: d.name, label: lang == "en" ? d.name : d.name_ar };
    });
    newOrg.push({ value: "Others", label: lang == "en" ? "Others" : "اخرون" });
    setAllOrg(newOrg);
  };

  useEffect(() => {
    dispatch(getuserinfo(token));

    getInst();
  }, []);

  useEffect(() => {
    if (userinfo && allOrg) {
      if (userinfo.institution) {
        if (allOrg.find((s) => s.value == userinfo.institution)) {
          var newO = allOrg.filter((s) => s.value == userinfo.institution);
          setOrganization(newO);
          setFound(true);
          setNewInst("");
        } else {
          setOrganization({
            value: "Others",
            label: "Others",
          });
          setFound(false);
          const inst = userinfo.institution.split("Others: ").pop();
          setNewInst(inst);
        }
      }
    }
  }, [userinfo, allOrg]);

  const [organization, setOrganization] = useState("");

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }
  return (
    <div className="complete_profile_step1_main_cont">
      <CompleteProfile
        header={t("CompleteProf.Join_the_Circle_3_Title")}
        body={
          loading ? (
            <Loading />
          ) : (
            <form
              className="complete_step3_main_body"
              onSubmit={(e) => handleSubmit(e)}
            >
              <div>
                <div className="complete_step3_header">
                  <p
                    className="complete_input_header"
                    style={{ marginBottom: "1rem" }}
                  >
                    {t("CompleteProf.complete_your_profile_34")}
                    <br />
                    <span className="complete_step3_subheader">
                      {t("CompleteProf.complete_your_profile_35")}
                    </span>
                  </p>
                </div>
                <Select
                  value={organization && organization}
                  className="complete_profile_select"
                  name="nationality"
                  onChange={setOrganization}
                  placeholder={t("CompleteProf.Join_the_Circle_1_Org")}
                  required
                  options={allOrg}
                  isSearchable={true}
                />
                {organization.value == "Others" && (
                  <textarea
                    required
                    className="complete_profile_6_textarea"
                    placeholder={t("CompleteProf.complete_your_profile_37")}
                    value={newInst}
                    onChange={(e) => setNewInst(e.target.value)}
                  />
                )}
              </div>
              <div className="complete_step1_footer">
                <div className="divider_line"></div>
                <div className="complete_step_footer_button">
                  <button
                    className={
                      size.width > 768
                        ? "primary_button complete_step_1_next_button"
                        : "primary_button complete_step_2_next_button_resp"
                    }
                    type="button"
                    onClick={() => navigate("/completeProfile/step4")}
                  >
                    {size.width > 768 ? (
                      t("CompleteProf.complete_your_profile_btn_5")
                    ) : lang == "en" ? (
                      <AiOutlineArrowLeft size="1.5rem" />
                    ) : (
                      <AiOutlineArrowRight size="1.5rem" />
                    )}
                  </button>

                  <div
                    className={
                      size.width > 768
                        ? "complete_step_right_footer"
                        : "complete_step_right_footer_resp"
                    }
                  >
                    <button
                      className={
                        size.width > 500
                          ? "pink_button complete_step_2_complete_button"
                          : "pink_button complete_step_2_next_button_resp"
                      }
                      onClick={() => setIsNext(false)}
                    >
                      {size.width > 500 ? (
                        t("CompleteProf.complete_your_profile_btn_3")
                      ) : (
                        <BiSave size="1.5rem" />
                      )}
                    </button>
                    <button
                      className={
                        size.width > 768
                          ? "primary_button complete_step_2_next_button"
                          : "primary_button complete_step_2_next_button_resp"
                      }
                      onClick={() => setIsNext(true)}
                    >
                      {size.width > 768 ? (
                        t("CompleteProf.complete_your_profile_btn_4")
                      ) : lang == "en" ? (
                        <AiOutlineArrowRight size="1.5rem" />
                      ) : (
                        <AiOutlineArrowLeft size="1.5rem" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )
        }
        footer={
          <div
            className={
              lang == "en"
                ? "complete_profile_footer_slider_green"
                : " complete_profile_footer_slider_green complete_profile_footer_slider_green_ar"
            }
            style={{ width: "50%" }}
          ></div>
        }
      />
    </div>
  );
}

export default Complete_Step5;
