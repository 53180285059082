import React, { useEffect, useRef, useState } from "react";
import i18n from "../../../../i18n";
import { useTranslation } from "react-i18next";
import "./CreateOpp_Step1.css";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import {
  createOpportunity,
  getOpportunitiesById,
  editOpportunity,
} from "../../../../Redux/opportunities/opportunityActions";
import { useDispatch, useSelector } from "react-redux";
import PopUp from "../../../../components/PopUp/PopUp";
import Loading from "../../../../components/Loading/Loading";
import CreateOpp_Step2 from "../../CreateOpp_steps/Step2/CreateOpp_Step2";
import { createCallAction } from "../../../../Redux/Community_Action/CommunityActActions";
function CreateOpp_Step1({ setOpen, id, edit, community, status, previous, setFooterWidth }) {
  const { t } = useTranslation();

  const [lang, setLang] = useState(i18n.language);
  const [previousPage, setPreviousPage] = useState(previous);
  const {
    oppId,
    oppDetails,
    age,
    createSucess,
    createError,
    editSucess,
    loading
  } = useSelector((store) => store.opportunitiesReducer);

  const [image, setImage] = useState();
  const { commId, createCommSucess, createCommError, createCommLoading } =
    useSelector((store) => store.communityActReducer);
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    if (edit) {
      if (editSucess && !previousPage) setOpenStep2(true);
    } else {
      if (createSucess && !community && !previousPage) setOpenStep2(true);
      if (createCommSucess && community && !previousPage) setOpenStep2(true);
    }
  }, [createSucess, createCommSucess, editSucess]);

  useEffect(() => {
    if (createError && !community) setError(createError);
    if (createCommError && community) setError(createCommError);
  }, [createError, createCommError]);
  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);


  const inputFile = useRef(null);

  const [type, setType] = useState();
  const [title, setTitle] = useState();
  const [isGrp, setIsGrp] = useState(null);
  const [error, setError] = useState(null);

  const size = useWindowSize();
  const dispatch = useDispatch();

  const [selectedAge, setSelectedAge] = useState([]);
  const [showNote, setShowNote] = useState(false);
  const [openStep2, setOpenStep2] = useState(false);
  const [showongnote, setshowongnote] = useState(false);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  var allAges = [
    {
      id: 1,
      value: "Under 18",
      label: lang === "en" ? "Under 18" : "أقل من 18 سنة",
    },
    {
      id: 2,
      value: "18-25",
      label: "18-25",
    },

    {
      id: 3,
      value: "25-35",
      label: "25-35",
    },
    {
      id: 4,
      value: "Above 35 years old",
      label: lang === "en" ? "Above 35 years old" : "أكبر من 35 سنة",
    },
  ];
  const onSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    var Vage = [];
    selectedAge.map((element) => {
      Vage.push(element.value);
    });
    if (type && title && isGrp != null && Vage.length > 0) {
      const opp = {
        title: title,
        type: type,
        is_group: isGrp,
        ages_id: Vage,
      };
      if (community) {
        if (!edit) { dispatch(createCallAction(opp)); setPreviousPage(false) }
        else { dispatch(editOpportunity(id, opp, community)); setPreviousPage(false) }
      } else {
        if (!edit) { dispatch(createOpportunity(opp)); setPreviousPage(false) }
        else { dispatch(editOpportunity(id, opp)); setPreviousPage(false) }
      }
    } else {
      setError(t("opportunity.create.error"));
    }
  };
  const handleEvent = (e, age) => {
    if (e.target.checked) {
      setSelectedAge((element) => [...element, age]);

      if (selectedAge.length + 1 == allAges.length) {
        document.getElementsByClassName("create_opp_check")[4].checked = true;
      }
    } else {
      setSelectedAge(selectedAge.filter((s) => s.id != age.id));
      document.getElementsByClassName("create_opp_check")[4].checked = false;
    }
  };

  const selectAll = (e) => {
    if (e.target.checked) {
      setSelectedAge(allAges);

      var checked = document.getElementsByClassName("create_opp_check");
      for (let item of checked) {
        item.checked = true;
      }
    } else {
      setSelectedAge([]);
      var checked = document.getElementsByClassName("create_opp_check");
      for (let item of checked) {
        item.checked = false;
      }
    }
  };

  useEffect(() => {
    setOpenStep2(false)
    if (edit) {
      dispatch(getOpportunitiesById(id));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(oppDetails).length > 0 && id > 0) {
      setTitle(oppDetails.title);
      setIsGrp(oppDetails.is_group);
      setType(oppDetails.type);

      if (
        oppDetails.type == "Skill-based" &&
        document.getElementById("skill")
      ) {
        document.getElementById("skill").checked = true;
      } else if (
        oppDetails.type == "Time-based" &&
        document.getElementById("time")
      ) {
        document.getElementById("time").checked = true;
      }

      if (oppDetails.is_group && document.getElementById("group")) {
        document.getElementById("group").checked = true;
      } else {
        if (document.getElementById("indv"))
          document.getElementById("indv").checked = true;
      }

      if (age.length > 0) {
        var newA = [];
        age.map((a, i) => {
          newA.push(allAges.filter((s) => s.label == a?.age)[0]);
        });

        setSelectedAge(newA);

        newA.map((el, i) => {
          if (document.getElementById("create_opp_check" + el.id))
            return (document.getElementById(
              "create_opp_check" + el.id
            ).checked = true);
        });
      }
    }
  }, [oppDetails]);
  return (
    <div>
      {
        (openStep2 ? (
          <CreateOpp_Step2
            community={community}
            id={edit ? id : community ? commId : oppId}
            setOpen={setOpen}
            setFooterWidth={setFooterWidth}
            status={status}
          />
        ) : (
          <form
            className="create_opp_step1"
            id="form"
            onSubmit={(e) => onSubmit(e)}
          >
            <div className="create_opp_body_step">
              <div className="create_opp_step1_cont1">
                <div className="create_opp_step1_cont">
                  <div className="step1_cont_header">
                    {t("opportunity.create.title")}*
                  </div>

                  <input
                    type="text"
                    className="create_opp_input"
                    required
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder={t("opportunity.create.title")}
                  />
                </div>

                <div className="create_opp_step1_cont">
                  <div className="step1_cont_header">
                    {community
                      ? t("opportunity.create.type_comm")
                      : t("opportunity.create.type")}
                    *
                    <p
                      className="create_opp_note"
                      onClick={() => setShowNote(true)}
                    >
                      {" "}
                      {t("CompleteProf.complete_your_profile_what")}
                    </p>
                  </div>
                  <div className="create_opp_radio_main">
                    <div className="create_opp_radio_comp">
                      <input
                        onChange={(e) => setType(e.target.value)}
                        className={
                          lang == "en"
                            ? "create_opp_radio_btn"
                            : "create_opp_radio_btn_ar"
                        }
                        type="radio"
                        id="skill"
                        name="type"
                        value="Skill-based"
                      />
                      <label
                        className="create_opp_radio_label"
                        for="skill"
                        style={{ cursor: "pointer" }}
                      >
                        {t("opportunity.create.type1")}
                      </label>
                    </div>
                    <div className="create_opp_radio_comp">
                      <input
                        onChange={(e) => setType(e.target.value)}
                        className={
                          lang == "en"
                            ? "create_opp_radio_btn"
                            : "create_opp_radio_btn_ar"
                        }
                        type="radio"
                        id="time"
                        name="type"
                        value="Time-based"
                      />
                      <label
                        className="create_opp_radio_label"
                        for="skill"
                        style={{ cursor: "pointer" }}
                      >
                        {t("opportunity.create.type2")}
                      </label>
                    </div>
                  </div>
                </div>
                {showNote && (
                  <PopUp
                    setOpen={setShowNote}
                    body={
                      <div className="complete_step4_popup">
                        <h2 className="complete_step4_popup_header green_color">
                          {t("CompleteProf.complete_your_profile_what")}
                        </h2>
                        <div className="complete_step4_popup_body">
                          <h4 className="complete_step4_popup_desc">
                            {t(
                              "opportunity.opportunity_type_opp_meaning_msg1"
                            )}
                            <br />
                            <br />
                            {t(
                              "opportunity.opportunity_type_opp_meaning_msg2"
                            )}
                          </h4>
                        </div>
                      </div>
                    }
                  />
                )}

                <div className="create_opp_step1_cont">
                  <div className="step1_cont_header">
                    {community
                      ? t("opportunity.create.isgrp_comm")
                      : t("opportunity.create.isgrp")}
                    *
                  </div>
                  <div className="create_opp_radio_main">
                    <div className="create_opp_radio_comp">
                      <input
                        onChange={(e) => setIsGrp(true)}
                        className={
                          lang == "en"
                            ? "create_opp_radio_btn"
                            : "create_opp_radio_btn_ar"
                        }
                        type="radio"
                        id="group"
                        name="isgrp"
                        value="group"
                      />
                      <label
                        className="create_opp_radio_label"
                        for="group"
                        style={{ cursor: "pointer" }}
                      >
                        {t("opportunity.create.yes")}
                      </label>
                    </div>
                    <div className="create_opp_radio_comp">
                      <input
                        onChange={(e) => setIsGrp(false)}
                        className={
                          lang == "en"
                            ? "create_opp_radio_btn"
                            : "create_opp_radio_btn_ar"
                        }
                        type="radio"
                        id="indv"
                        name="isgrp"
                        value="indv"
                      />
                      <label
                        className="create_opp_radio_label"
                        for="indv"
                        style={{ cursor: "pointer" }}
                      >
                        {t("opportunity.create.no")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="create_opp_step1_cont">
                <div className="step1_cont_header">
                  {t("opportunity.create.age")}*
                  <p className="create_opp_note_dark">
                    {" "}
                    {t("opportunity.create.multiple")}
                  </p>
                </div>
                <div className="create_opp_checkbox_main">
                  {allAges.map((age, index) => {
                    return (
                      <div className="step2_create_opp">
                        <input
                          type="checkbox"
                          id={"create_opp_check" + age.id}
                          name={age.value}
                          value={age.value}
                          className="create_opp_check"
                          onChange={(e) => handleEvent(e, age)}
                        />
                        <div className="step2_create_opp_info">
                          <label
                            className="step2_create_opp_name"
                            for={age.value}
                          >
                            {age.label}
                          </label>
                        </div>
                      </div>
                    );
                  })}

                  <div className="step2_create_opp">
                    <input
                      type="checkbox"
                      id="create_opp_check5"
                      name="All"
                      value="All"
                      className="create_opp_check"
                      onChange={(e) => selectAll(e)}
                    />
                    <div className="step2_create_opp_info">
                      <label className="step2_create_opp_name" for="All">
                        {t("opportunity.create.all")}
                      </label>
                    </div>
                  </div>


                </div>
              </div>
            </div>
            {error && <div className="error_msg">{error}</div>}
            <div className="complete_step1_footer">
              <div className="divider_line"></div>
              {status === "Ongoing" && (
                <div className="text_notes">
                  {" "}
                  Some fields wont be available, because we believe that they
                  will affect the matching scores of the Opportunity.
                </div>
              )}

              <button
                className={
                  size.width > 768
                    ? "primary_button complete_step_2_next_button"
                    : "primary_button complete_step_2_next_button_resp"
                }
              >
                {size.width > 768 ? (
                  t("CompleteProf.complete_your_profile_btn_4")
                ) : lang == "en" ? (
                  <AiOutlineArrowRight size="1.5rem" />
                ) : (
                  <AiOutlineArrowLeft size="1.5rem" />
                )}
              </button>
            </div>
          </form>
        ))
      }
    </div>
  );
}

export default CreateOpp_Step1;
