import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  videos: [],
  blogLoading: false,
  error: ""
};

const videoBlogSlice = createSlice({
  name: "videoBlog",
  initialState,
  reducers: {
    fetchVideoBlogInfoRequest(state) {
      state.blogLoading = true;
      state.videos = [];
      state.error = "";
    },

    fetchVideoBlogInfoSuccess(state, action) {
      state.blogLoading = false;
      state.videos = action.payload;
      state.error = "";
    },

    fetchVideoBlogInfoFail(state, action) {
      state.blogLoading = false;
      state.videos = [];
      state.error = action.payload;
    },
  },
});

export const videoBlogAction = videoBlogSlice.actions;
export default videoBlogSlice;
