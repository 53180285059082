import axios from "axios";
import { trusted_partnersAction } from "./Trusted_partnersReducers";

export const fetchTrusted_partners = () => {
  return async (dispatch) => {
    try {
      dispatch(trusted_partnersAction.fetchTrusted_partnersRequest());
      const { data } = await axios.get(
        process.env.REACT_APP_API + "/trusted_partners"
      );
      dispatch(trusted_partnersAction.fetchTrusted_partnersSuccess(data.data));
    } catch (error) {
      dispatch(trusted_partnersAction.fetchTrusted_partnersFail(error.message));
    }
  };
};
