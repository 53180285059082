import React, { useEffect, useState } from "react";
import "./ProfileStatus.css";
import "./slider.css";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import SilverLevel from "../../../assets/images/icons/silver-card.svg";
import BronzeLevel from "../../../assets/images/icons/bronzecard.png"; //needed
import GoldLevel from "../../../assets/images/icons/gold-card.svg";
import PlatinumLevel from "../../../assets/images/icons/platinum-card.svg";
import StarApplicantLevel from "../../../assets/images/icons/platinum-card.svg";
import star from "../../../assets/images/icons/vol-star.png";
import star_card from "../../../assets/images/icons/card-star.png";
import heart_hand from "../../../assets/images/icons/heart-hand.png";
import world from "../../../assets/images/icons/world.jpg";
import raya from "../../../assets/images/raya-ghanem.png";
import ReactSlider from "react-slider";
import Switch from "react-switch";
import clock from "../../../assets/images/clock.png";
import heart from "../../../assets/images/heart.png";
import ProfileHeader from "../ProfileHeader/ProfileHeader";
import PopUp from "../../../components/PopUp/PopUp";
import cost from "../../../assets/images/cost.webp";
import beneficiaries from "../../../assets/images/Home/beneficiaries-touched.svg";
import costsaved from "../../../assets/images/Home/cost-saved.svg";
import { getEvaluationsTotal } from "../../../Redux/evaluations/EvaluationsActions";
import {
  getuserinfo,
  changeVolunteerWorkStatus,
  getuserpointsbytoken,
} from "../../../Redux/user/userActions";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/Loading/Loading";
import { MdOutlineVerified } from "react-icons/md";

function Profile_status() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [switchValue, setSwitchValue] = useState();
  const [isChecked, setIsChecked] = React.useState(false);
  const [pointshow, setpointshow] = useState(false);
  const MAX_POINTS_NUM = 10000;
  const { userinfo, user, status, loading, points } = useSelector(
    (store) => store.userReducer
  );
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [profileData, setProfileData] = useState({});
  const [userData, setUserData] = useState({});
  const [evaluationTotalData, setEvaluationTotalData] = useState({});
  const [userType, setUserType] = useState("");
  const { evaluations } = useSelector((state) => state.evaluationsReducer);

  useEffect(() => {
    dispatch(getuserinfo());
    dispatch(getuserpointsbytoken());
  }, []);

  useEffect(() => {
    if (evaluations !== undefined && evaluations.length != 0) {
      setEvaluationTotalData(evaluations);
    }
  }, [evaluations]);

  const getLevelImage = (points) => {
    let Bronze = 2000;
    let Silver = 4000;
    let Gold = 6000;
    let Platinum = 8000;
    let StarApplicant = 10000;
    if (points < Silver) {
      return ` ${BronzeLevel}`;
    } else if (Silver <= points && points < Gold) {
      return ` ${SilverLevel}`;
    } else if (Gold <= points && points < Platinum) {
      return ` ${GoldLevel}`;
    } else if (Platinum <= points && points < StarApplicant) {
      return ` ${PlatinumLevel}`;
    } else if (points >= StarApplicant) {
      return ` ${StarApplicantLevel}`;
    }
  };

  const calculateLevel = (points) => {
    let Bronze = 2000;
    let Silver = 4000;
    let Gold = 6000;
    let Platinum = 8000;
    let StarApplicant = 10000;
    if (points < Bronze) {
      const BronzeLevel = Bronze - points;
      return `${t("Home.points_needed", { BronzeLevel })}`; //done
    } else if (points < Silver) {
      const SilverLevel = Silver - points;
      return `${t("Home.points_needed_2", { SilverLevel })}`; //done
    } else if (points < Gold) {
      const GoldLevel = Gold - points;
      return `${t("Home.points_needed_3", { GoldLevel })}`; //done
    } else if (points < Platinum) {
      const PlatinumLevel = Platinum - points;
      return `${t("Home.points_needed_4", { PlatinumLevel })}`; //done
    } else if (points < StarApplicant) {
      return ` ${t("Home.points_needed_5")}`;
    }
  };

  useEffect(() => {
    if (userinfo != undefined && userinfo.length != 0) {
      if (userinfo?.volunteer_id !== undefined) {
        dispatch(getEvaluationsTotal(userinfo?.volunteer_id));
      }
      setIsChecked(userinfo.open_to_work == 1 ? true : false);
      setSwitchValue(userinfo.open_to_work == 1 ? true : false);
      setProfileData(userinfo);
      setUserData(user);
      setUserType(user.role == 4 ? "ngo" : "volounteer");
    }
  }, [userinfo]);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const handleStatusChange = async (e) => {
    setSwitchValue(e);
    dispatch(changeVolunteerWorkStatus(token, e));
  };

  useEffect(() => {
    if (status == 200 && switchValue != undefined) {
      setIsChecked(switchValue);
    }
  }, [loading, status]);

  return (
    <>
      {profileData != {} && userinfo != {} && profileData.length !== 0 && (
        <>
          <div className="profie_status_main_container">
            {<ProfileHeader />}
            {loading ? (
              <Loading />
            ) : (
              <>
                {userType !== "ngo" && (
                  <div className="cards_row1">
                    <div
                      className={
                        lang == "ar"
                          ? "top-left-profilecard left-profilecard"
                          : "top-left-profilecard right-profilecard"
                      }
                    >
                      <div className="card-info-hold-w">
                        <div>
                          <div className="profile_name_style">
                            <img
                              src={
                                profileData?.points < 10000 ? heart_hand : star
                              }
                              alt=""
                              className="profile-status-card-icon"
                            />
                            <div className="profile_name">
                              {profileData?.first_name !== null
                                ? profileData?.first_name
                                : ""}{" "}
                              {profileData?.last_name !== null
                                ? profileData?.last_name
                                : ""}
                            </div>
                          </div>
                          <div className="font-16">
                            {t("myProfile.Profile_stat")}
                          </div>
                          <div className="profile_points_style">

                            <div>
                              {" "}
                              {t("myProfile.Profile_bal")} {profileData?.points}{" "}
                            </div>
                            {t("myProfile.Profile_bala")}
                          </div>
                          <div className="cards_desc_points_next_level">
                            {/* {profileData?.pointsToNextLevel}{' '} */}
                            {calculateLevel(profileData?.points)}
                          </div>
                          <div className="font-27">
                            <span className="bold-font">
                              {profileData?.points}
                            </span>{" "}
                            {t("myProfile.myPointsHistory_points")}
                          </div>
                        </div>
                        <img
                          src={getLevelImage(profileData?.points)}
                          alt=""
                          className="profile-status-card"
                        />
                      </div>
                      <div>
                        <ReactSlider
                          className="horizontal-slider"
                          thumbClassName="example-thumb"
                          trackClassName="example-track"
                          value={profileData?.points}
                          min={0}
                          max={MAX_POINTS_NUM}
                          disabled={true}
                          renderTrack={(props, state) => <div {...props} />} //custom track
                        />
                      </div>
                    </div>

                    <div className="open_to_work_container">
                      <div
                        className="open_to_work"
                        style={{
                          backgroundColor: isChecked
                            ? "var(--primary)"
                            : "#fff",
                          color: isChecked
                            ? "var(--white)"
                            : "var(--secondary)",
                        }}
                      >
                        <div style={{ fontWeight: "bold" }}>
                          {" "}
                          {t("myProfile.open_to_work")}
                        </div>
                        <p>{t("myProfile.open_to_work_desc")}</p>
                        <div style={{ fontWeight: "bold" }}>
                          {" "}
                          {t("myProfile.on_off")}
                        </div>
                        <Switch
                          className={
                            !isChecked ? "checked-switch" : "unchecked-switch"
                          }
                          checked={isChecked}
                          uncheckedIcon={true}
                          checkedIcon={true}
                          onChange={(e) => {
                            handleStatusChange(e);
                          }}
                          disabled={profileData?.hours >= 40 ? false : true}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {pointshow && (
                  <PopUp
                    setOpen={setpointshow}
                    body={
                      <div className="popup_container">
                        <div className="popup_container_row_left_date">
                          Points History
                        </div>
                        {points.map((item) => {
                          return (
                            <div className="popup_container_row">
                              <div className="popup_container_row_left">
                                <div className="popup_container_row_left_desc">
                                  {item.point_after - item.point_before}
                                </div>
                              </div>
                              <div className="popup_container_row_right">
                                <div className="popup_container_row_right_points">
                                  &nbsp; Points From opportunity{" "}
                                  {item.opportunity.title}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    }
                  />
                )}
                {userType !== "ngo" && (
                  <>
                    <div className="cards_container status_cards_container">
                      <div
                        className={lang == "ar" ? "cards2 right-card" : "cards2 "}
                      >
                        {/* <div className="flip-card-inner"> */}
                        <div className="flip-card-front">
                          <img src={raya} alt="" className="cards_img" />
                          <div className="cards_desc">
                            {profileData?.points} {t("myProfile.points")}
                          </div>
                          <div className="cards_desc_points_next_level">
                            {/* {profileData?.pointsToNextLevel}{' '} */}
                            {calculateLevel(profileData?.points)}
                          </div>
                        </div>
                        <div class="flip-card-back">
                          <h1 className="title_points">Points</h1>
                          {points[0] && (
                            <p>
                              {points[0]?.point_after - points[0]?.point_before}{" "}
                              From opportunity {points[0]?.opportunity.title}
                            </p>
                          )}
                          {points[1] && (
                            <p>
                              {points[1]?.point_after - points[1]?.point_before}{" "}
                              From opportunity {points[1]?.opportunity.title}
                            </p>
                          )}
                          {points[2] && (
                            <p>
                              {points[2]?.point_after - points[2]?.point_before}{" "}
                              From opportunity {points[2]?.opportunity.title}
                            </p>
                          )}
                          <button
                            className="primary_button"
                            onClick={() => setpointshow(true)}
                          >
                            {" "}
                            View More{" "}
                          </button>
                        </div>
                      </div>

                      <div className="cards">
                        <img src={clock} alt="" className="cards_img" />
                        <div className="cards_desc">
                          {profileData?.hours}
                          {""} {t("myProfile.hours")}
                        </div>
                        <div className="cards_desc_points_next_level">
                          {t("myProfile.Profile_125a")} {profileData?.hours}{" "}
                          {t("myProfile.Profile_125")}
                        </div>
                      </div>

                      <div className={
                        lang == "ar" ? "cards left-card" : "cards right-card"
                      }>
                        <img src={heart} alt="" className="cards_img" />
                        <div className="cards_desc">
                          {profileData?.total_opportunities}
                        </div>
                        <div className="cards_desc_points_next_level">
                          {t("myProfile.Profile_28a")}{" "}
                          {profileData?.total_opportunities}{" "}
                          {t("myProfile.Profile_28")}
                        </div>
                      </div>
                    </div>
                    <div className="cards_container status_cards_container">

                      <div className="cards">
                        <img src={beneficiaries} alt="" className="cards_img" />
                        <div className="cards_desc">
                          {evaluationTotalData?.totalBeneficiaries}
                        </div>
                        <div className="cards_desc_points_next_level">
                          {t("evaluation_feedback.numberOfBeneficiaries")}{" "}
                          {evaluationTotalData?.totalBeneficiaries}{" "}
                        </div>
                      </div>

                      <div className="cards">
                        <img src={costsaved} alt="" className="cards_img" />
                        <div className="cards_desc">
                          {evaluationTotalData?.totalCoasts}
                        </div>
                        <div className="cards_desc_points_next_level">
                          {t("evaluation_feedback.costSaved")}{" "}
                          {evaluationTotalData?.totalCoasts}{" "}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {userType === "ngo" && (
                  <div className="cards_row1">
                    <div className="organization_status_div">
                      <div className="ngo_name">
                        {profileData?.organization_name}
                        <div className="ngo_verified">
                          {profileData?.verified == "1" && (
                            <MdOutlineVerified
                              className="opp_card_bottom_verified_icon"
                              size={30}
                            />
                          )}
                        </div>
                      </div>
                      <div className="word_div">
                        <img
                          src={world}
                          alt=""
                          className="profile-status-card-icon"
                        />
                        <div className="volunteer_circle_div">
                          {t("myProfile.your")}
                          {"  "}
                          <span className="org_name">{profileData?.organization_name}{" "}</span>
                          {t("myProfile.volunteer_circle_make_impact")}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {userType === "ngo" && (
                  <div className="cards_container_ngo ngo_cards_container">
                    <div
                      className={lang == "ar" ? "cards right-card" : "cards "}
                    >
                      <img src={star_card} alt="" className="cards_img" />
                      <div className="cards_desc">{t("opportunity.title")}</div>
                      <div>{profileData?.numbre_of_opportunity}</div>
                    </div>
                    <div
                      className={
                        lang == "ar" ? "cards left-card" : "cards right-card"
                      }
                    >
                      <img src={heart} alt="" className="cards_img" />
                      <div className="cards_desc">
                        {t("myProfile.beneficiaries_touched")}
                      </div>
                      <div>{profileData?.numbre_of_request}</div>
                    </div>
                    <div
                      className={
                        lang == "ar" ? "cards left-card" : "cards right-card"
                      }
                    >
                      <img src={cost} alt="" className="cards_img" />
                      <div className="cards_desc">{t("myProfile.cost")}</div>
                      <div>{profileData?.coast_opp}</div>
                    </div>
                  </div>
                )}{" "}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}
export default Profile_status;
