import React from "react";
import { useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import "./CreateOpp_Step11.css";

import i18n from "../../../../i18n";
import { useSSR, useTranslation } from "react-i18next";

import { useEffect } from "react";

import { BiSave } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import CreateOpp_Step10 from "../Step10/CreateOpp_Step10";
import CreateOpp_Step12 from "../Step12/CreateOpp_Step12";
import { editOpportunity , getOpportunitiesById } from "../../../../Redux/opportunities/opportunityActions";
import Loading from "../../../../components/Loading/Loading";
function CreateOpp_Step11({
  id,
  setOpen,
  setFooterWidth,
  community,
}) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [openStep12, setOpenStep12] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [cost, setCost] = useState();
  const [livesTouched, setLivesTouched] = useState(0);
  const [previousPage, setPreviousPage] = useState(false);
  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const size = useWindowSize();
  const dispatch = useDispatch();

  const [isNext, setIsNext] = useState(true);
  const {
    oppDetails
  } = useSelector((store) => store.opportunitiesReducer);
  useEffect(() => {
    if (id) {
      dispatch(getOpportunitiesById(id));
    }
  }, []);

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  useEffect(() => {
    setFooterWidth(" 91.63%");
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    if (cost) {
      const opp = {
        cost: cost,
        lives_touched : livesTouched
      };
      if (community) {
        dispatch(editOpportunity(id, opp, community));
      } else dispatch(editOpportunity(id, opp));

      if (isNext) {
        setOpenStep12(true);
      } else {
        setOpen(false);
      }
    } else {
      setError(t("opportunity.create.error"));
    }
  };
  useEffect(() => {
    if (Object.keys(oppDetails).length > 0) {
      setCost(oppDetails.cost);
      setLivesTouched(oppDetails.lives_touched)
    }
  }, [oppDetails]);


  const handleBeforeInput = (e) => {
    // Perform actions or validation here before the input changes
    // For example, you can prevent certain characters or values.
    const proposedValue = e; // The proposed new value
    // Check if the proposed value is allowed (e.g., not negative)
    if (parseFloat(proposedValue) <= 0) {
      e.preventDefault(); // Prevent the input change
    }
  };

  return (
    <div>
      {
        // (loading || editLoading)  ? <Loading/> :
        previousPage ? (
          <CreateOpp_Step10
            id={id}
            community={community}
            setOpen={setOpen}
            setFooterWidth={setFooterWidth}
            previous={true}
          />
        ):(openStep12 ? (
          <CreateOpp_Step12
            id={id}
            oppDetails={oppDetails}
            community={community}
            setOpen={setOpen}
            setFooterWidth={setFooterWidth}
          />
        ) : (
          <form className="create_opp_step1" onSubmit={(e) => onSubmit(e)}>
            <div className="create_opp_step7_main_cont">
              <div className="create_opp_step10_cont">
                <div className="step1_cont_header">
                  {t("opportunity.create.cost_text")}*
                </div>
                <div className="step10_content">
                  <p className="create_opp_note_dark">
                    {" "}
                    {t("opportunity.create.cost_note")}
                  </p>{" "}
                  <input
                    type="number"
                    value={cost}
                    min={1}
                    step={1}
                    className="create_opp_input"
                    placeholder="$"
                    required
                    onBeforeInput={(e) => {handleBeforeInput(e.target.value)}}
                    onChange={(e) => {
                      if (parseInt(e.target.value) > 0) {
                        setCost(parseInt(e.target.value));
                      }
                    }}
                  />
                </div>

                <div className="step10_content">
                  <p className="step1_cont_header maringQA">
                    {" "}
                    {t("opportunity.create.numberOfBeneficiariesExpected")}
                  </p>{" "}
                  <input
                    type="number"
                    min={1}
                    step={1}
                    value={livesTouched}
                    className="create_opp_input"
                    placeholder={t("opportunity.create.nbrOfBeneficiaries")}
                    required
                    onBeforeInput={(e) => {handleBeforeInput(e.target.value)}}
                    onChange={(e) => {
                      if (parseInt(e.target.value) > 0) {
                        setLivesTouched(parseInt(e.target.value));
                      }
                    }}
                  />
                </div>


              </div>
            </div>

            {error && <div className="error_msg">{error}</div>}

            <div className="complete_step1_footer">
              <div className="divider_line"></div>
              <div className="complete_step_footer_button">
                <button
                  className={
                    size.width > 768
                      ? "primary_button complete_step_1_next_button"
                      : "primary_button complete_step_2_next_button_resp"
                  }
                  onClick={(e) => { e.preventDefault(); setPreviousPage(true); }}
                >
                  {size.width > 768 ? (
                    t("CompleteProf.complete_your_profile_btn_5")
                  ) : lang == "en" ? (
                    <AiOutlineArrowLeft size="1.5rem" />
                  ) : (
                    <AiOutlineArrowRight size="1.5rem" />
                  )}
                </button>

                <div
                  className={
                    size.width > 768
                      ? "complete_step_right_footer"
                      : "complete_step_right_footer_resp"
                  }
                >
                  <button
                    className={
                      size.width > 500
                        ? "pink_button complete_step_2_complete_button"
                        : "pink_button complete_step_2_next_button_resp"
                    }
                    onClick={() => setIsNext(false)}
                  >
                    {size.width > 500 ? (
                      t("CompleteProf.complete_your_profile_btn_3")
                    ) : (
                      <BiSave size="1.5rem" />
                    )}
                  </button>
                  <button
                    className={
                      size.width > 768
                        ? "primary_button complete_step_2_next_button"
                        : "primary_button complete_step_2_next_button_resp"
                    }
                    onClick={() => setIsNext(true)}
                  >
                    {size.width > 768 ? (
                      t("CompleteProf.complete_your_profile_btn_4")
                    ) : lang == "en" ? (
                      <AiOutlineArrowRight size="1.5rem" />
                    ) : (
                      <AiOutlineArrowLeft size="1.5rem" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        ))
      }
    </div>
  );
}

export default CreateOpp_Step11;
