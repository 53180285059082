import axios from "axios";
import { FaqsAction } from "./FaqsReducer";

export const getFaqs = () => async (dispatch) => {
  dispatch(FaqsAction.fetchFaqsRequest());
  try {
    let response = await axios.get(process.env.REACT_APP_API + "/faqs");
    dispatch(FaqsAction.fetchFaqsSuccess(response.data));
  } catch (error) {
    dispatch(FaqsAction.fetchFaqsFail(error.message));
  }
};
