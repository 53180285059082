import axios from "axios";
import { carouselAction } from "./carouselReducers";

export const getCarousel = () => async (dispatch) => {
  dispatch(carouselAction.fetchCarouselRequest());
  try {
    let response = await axios.get(
      process.env.REACT_APP_API + "/carousel/getall"
    );
    dispatch(carouselAction.fetchCarouselSuccess(response.data));
  } catch (error) {
    dispatch(carouselAction.fetchCarouselFail(error.message));
  }
};
