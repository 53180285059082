import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  availibilities: [],
};
const AvailibilitiesSlice = createSlice({
  name: "Availibilities",
  initialState,
  reducers: {
    availibilitiesRequest(state) {
      state.loading = true;
      state.availibilities = [];
      state.error = "";
    },
    availibilitiesSuccess(state, action) {
      state.loading = false;
      state.availibilities = action.payload;
      state.error = "";
    },
    availibilitiesFail(state, action) {
      state.loading = false;
      state.availibilities = [];
      state.error = action.payload;
    },

  },
});

export const AvailibilitiesAction = AvailibilitiesSlice.actions;
export default AvailibilitiesSlice;
