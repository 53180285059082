import React, { useEffect, useState } from "react";
import i18n from "../../../../i18n";
import { useTranslation } from "react-i18next";
import "./Apply_Step8.css";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import apply_img_step8 from "../../../../assets/images/opportunity/apply/apply_step8.png";
import happy from "../../../../assets/images/icons/happy.svg";
import { createRequest } from "../../../../Redux/requests/requestsActions";
import { getOpportunityQuestions } from "../../../../Redux/OpportunityQuestions/OpportunityQuestionsActions";
import { createRequestQuestionsAnswers } from "../../../../Redux/RequestQuestionsAnswers/RequestQuestionsAnswersActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Popup from "../../../../components/PopUp/PopUp.js";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
// Import other JavaScript components if needed
import Apply_Step7 from "../Apply_Step7/Apply_Step7";
import "react-notifications/lib/notifications.css";

function Apply_Step8({ setOpen, open, data, setFooterWidth , opportunityId , previousFirstPage ,openOutOfArea}) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [oppData, setOppData] = useState("");
  const size = useWindowSize();
  const [finished, setFinished] = useState(false);
  const dispatch = useDispatch();
  const { error, loading, requests } = useSelector((state) => state.requestReducer);
  const { OpportunityQuestions } = useSelector((state) => state.OpportunityQuestionsReducer);
  const [errorMessage, setErrorMessage] = useState("");
  const [opportunityQuestionsData, setOpportunityQuestionsData] = useState([]);
  const [fields, setFields] = useState([]);
  const [previous, setPrevious] = useState(false);
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (error != "") {
      setErrorMessage(error);
    }
  }, [error]);

  useEffect(() => {
    setFinished(true);
    if (loading == false && error == "" & requests?.data?.id > 0) {

      const newArray = fields.map(item => ({
        ...item,
        request_id: requests?.data?.id
      })
      );
      dispatch(createRequestQuestionsAnswers(newArray))
    }
  }, [requests, loading, error]);


  useEffect(() => {
    if (OpportunityQuestions) {
      setOpportunityQuestionsData(OpportunityQuestions);
      setFields([])
      OpportunityQuestions?.map((option, index) => {
        setFields((fields) => [...fields, ""]);
      })
    }
  }, [OpportunityQuestions]);

  useEffect(() => {
    setPrevious(false);
    setOppData({ ...data });
    setFooterWidth("100%");
    dispatch(getOpportunityQuestions(data?.opportunityId));
    setFinished(false);
    setErrorMessage("");
  }, []);

  useEffect(() => { }, [oppData]);

  const onSubmit = (e) => {
    e.preventDefault();
    let values = {};
    if (oppData.is_group) {
      values = {
        opportunity_id: oppData.opportunityId,
        is_group: oppData.is_group,
        association: oppData.association,
        phone_number: oppData.PhoneNumb != undefined ? oppData.PhoneNumb : "",
        association_name:
          oppData.associationName != undefined ? oppData.associationName : "",
        full_name: oppData.fullName != undefined ? oppData.fullName : "",
        occupation:
          oppData.occupation?.value != undefined
            ? oppData.occupation?.value
            : "",
      };
    } else {
      values = {
        opportunity_id: oppData.opportunityId,
        association: oppData.association,
        is_group: oppData.is_group,
      };
    }
    dispatch(createRequest(values, oppData.group_members));
  };


  const updateInput = (e, index, question_id) => {
    const value = e.target.value;
    const newState = fields.map((obj, i) => {
      // 👇️ if id equals 2, update country property
      if (i === index) {
        return {
          "opportunity_question_id": question_id,
          "answer": value
        };
      }

      // 👇️ otherwise return object as is
      return obj;
    });
    setFields(newState);
  };


  useEffect(() => {
    if (loading == false && finished) {
      if (error != "") {
        NotificationManager.error(<sapn>{error}</sapn>, "", 3000);
      } else {
        const notificationMessage = (
          <div className="apply_notification_message">
            <sapn>{t("opportunity.apply_popup.apply_notification")}</sapn>
            <span className="pop_up_sub">
              <img src={happy} alt="happy" className="popup_icon" />
            </span>

          </div>
        )
        NotificationManager.success(notificationMessage, "", 3000);
      }
      setOpen(false);
    }

  }, [finished, loading, error]);


  return (
    <>
      {previous && (
        <Apply_Step7
          setOpen={setOpen}
          open={open}
          data={data}
          previousFirstPage = {previousFirstPage}
          opportunityId = {opportunityId}
          openOutOfArea={openOutOfArea}
          setFooterWidth={setFooterWidth}
        />
      )}


      {!previous && !finished && (
        <form className="apply_step8" onSubmit={(e) => onSubmit(e)}>
          <img className="apply_img" src={apply_img_step8} />
          {t("opportunity.apply_popup.transportation_discount")}

          <div className="questions_container">
            {opportunityQuestionsData.length > 0 && opportunityQuestionsData?.map((option, index) => {
              return (
                <div className="questions_div">
                  <label
                    key={`question-${index}`}
                    value={option.value}
                    className='main-selectOption'
                  >
                    {option.question}
                  </label>
                  <input
                    placeholder={t("opportunity.create.answer")}
                    type="text"
                    className="create_opp_input"
                    required
                    key={index}
                    onChange={(e) => updateInput(e, index, option.id)}
                  ></input>
                </div>
              );
            })
            }
          </div>
          <div className="dotter_spacer" />
          <div class="complete_step_footer_button">
            <div className="div_buttons div_buttons_left">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setPrevious(true);
                }}
                className={
                  size.width > 768
                    ? "apply_primary_button app_step2_apply_later_button gray-btn"
                    : "apply_primary_button app_step2_apply_later_button_resp gray-btn"
                }
              >
                {t("opportunity.apply_popup.previous")}
              </button>
            </div>
            <div className="div_buttons">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(false);
                }}
                className={
                  size.width > 768
                    ? "apply_primary_button app_step8_apply_later_button"
                    : "apply_primary_button app_step8_apply_later_button_resp"
                }
              >
                {t("opportunity.apply_popup.apply_later")}
              </button>
              <button
                type="submit"
                className={
                  size.width > 768
                    ? "primary_button app_step8_next_button"
                    : "primary_button app_step8_next_button_resp"
                }
              >
                {size.width > 768 ? (
                  t("opportunity.apply_popup.finish")
                ) : lang == "en" ? (
                  <AiOutlineArrowRight size="1.5rem" />
                ) : (
                  <AiOutlineArrowLeft size="1.5rem" />
                )}
              </button>
            </div>
          </div>
        </form>
      )}

    </>
  );
}

export default Apply_Step8;
