import axios from "axios";
import { contactAction } from "./contactsusReducers";

export const getContactus = () => async (dispatch) => {
  dispatch(contactAction.fetchContactsRequest());
  try {
    let response = await axios.get(process.env.REACT_APP_API + "/contacts");
    dispatch(contactAction.fetchContactsSuccess(response.data));
  } catch (error) {
    dispatch(contactAction.fetchContactsFail(error.message));
  }
};

export const createContactus = (contact) => async (dispatch) => {
  dispatch(contactAction.createContactRequest());
  try {
    let response = await axios.post(
      process.env.REACT_APP_API + "/contacts",
      contact
    );
    dispatch(contactAction.createContactSuccess(response.data));
  } catch (error) {
    dispatch(contactAction.createContactFail(error.message));
  }
};
