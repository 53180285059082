import React from 'react'
import { useNavigate } from 'react-router-dom'
import found from "../../assets/images/notFound.png"
import "./notFound.css"
function NotFound() {
    const navigate =  useNavigate()
  return (
    
    <div className='not_found_div'><img className='not_found_image' src={found}/>
    <button className='primary_button' onClick={()=>navigate("/")}>Go to home</button></div>
  )
}

export default NotFound