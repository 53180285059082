import React from "react";
import { useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import "./CreateOpp_Step5.css";
import i18n from "../../../../i18n";
import {  useTranslation } from "react-i18next";
import { useEffect } from "react";
import { BiSave } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import CreateOpp_Step4 from "../Step4/CreateOpp_Step4";
import CreateOpp_Step6 from "../Step6/CreateOpp_Step6";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import { editOpportunity, getOpportunitiesById } from "../../../../Redux/opportunities/opportunityActions";

function CreateOpp_Step5({
  id,
  setOpen,
  setFooterWidth,
  community,
}) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [openStep6, setOpenStep6] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [hoursNb, setHoursNb] = useState();
  const [defaultDate, setDefaultDate] = useState();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [deadline, setDeadline] = useState();
  const [language, setLanguage] = useState();

  const {
    oppDetails,
  } = useSelector((store) => store.opportunitiesReducer);
  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const size = useWindowSize();
  const dispatch = useDispatch();
  const [previousPage, setPreviousPage] = useState(false);
  const [isNext, setIsNext] = useState(true);
  useEffect(() => {
    if (id) {
      dispatch(getOpportunitiesById(id));
    }
  }, []);
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  useEffect(() => {
    setFooterWidth("42%");
    setDefaultDate({
      day: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    });
  }, []);

  const renderCustomInput1 = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      className="complete_profile_input complete_date_input"
      placeholder={
        new Date().getDate() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getFullYear()
      }
      value={
        startDate &&
        startDate.day + "-" + startDate.month + "-" + startDate.year
      }
      onChange={setStartDate}
    />
  );

  const renderCustomInput2 = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      className="complete_profile_input complete_date_input"
      placeholder={
        new Date().getDate() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getFullYear()
      }
      value={endDate && endDate.day + "-" + endDate.month + "-" + endDate.year}
      onChange={setEndDate}
    />
  );

  const renderCustomInput3 = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      className="complete_profile_input complete_date_input"
      placeholder={
        new Date().getDate() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getFullYear()
      }
      value={
        deadline && deadline.day + "-" + deadline.month + "-" + deadline.year
      }
      onChange={setDeadline}
    />
  );
  const onSubmit = (e) => {
    e.preventDefault();
    if (hoursNb && startDate && endDate && language && deadline) {
      const starting_date = new Date();
      starting_date.setDate(startDate.day);
      starting_date.setMonth(startDate.month - 1);
      starting_date.setFullYear(startDate.year);
      const ending_date = new Date();
      ending_date.setDate(endDate.day);
      ending_date.setMonth(endDate.month - 1);
      ending_date.setFullYear(endDate.year);

      const deadline_date = new Date();
      deadline_date.setDate(deadline.day);
      deadline_date.setMonth(deadline.month - 1);
      deadline_date.setFullYear(deadline.year);
      const opp = {
        languages: language,
        hours: hoursNb,
        start_date: starting_date,
        end_date: ending_date,
        deadline_date: deadline_date,
      };
      if (community) {
        dispatch(editOpportunity(id, opp, community));
      } else dispatch(editOpportunity(id, opp));

      if (isNext) {
        setOpenStep6(true);
      } else {
        setOpen(false);
      }
    } else {
      setError(t("opportunity.create.error"));
    }
  };

  useEffect(() => {
    if (Object.keys(oppDetails).length > 0) {
      setLanguage(oppDetails.languages);
      if(oppDetails.hours !== null)
      setHoursNb(oppDetails.hours);
      if (
        oppDetails.start_date &&
        oppDetails.end_date &&
        oppDetails.deadline_date
      ) {
        let start = new Date(oppDetails?.start_date);
        let day = start.getDate();
        let month = start.getMonth() + 1;
        let year = start.getFullYear();
        let startDay = {
          day,
          month,
          year,
        };
        setStartDate(startDay);

        let end = new Date(oppDetails?.end_date);
        day = end.getDate();
        month = end.getMonth() + 1;
        year = end.getFullYear();
        let endDay = {
          day,
          month,
          year,
        };
        setEndDate(endDay);

        let deadline = new Date(oppDetails?.deadline_date);
        day = deadline.getDate();
        month = deadline.getMonth() + 1;
        year = start.getFullYear();
        let deadlineDay = {
          day,
          month,
          year,
        };
        setDeadline(deadlineDay);
      }
    }
  }, [oppDetails]);

  return (
    <div>
      {
        // (loading || editLoading)  ? <Loading/> :
        previousPage ?
          (
            <CreateOpp_Step4
              id={id}
              community={community}
              setOpen={setOpen}
              setFooterWidth={setFooterWidth}
              previous={true}
            />
          ) : (openStep6 ? (
            <CreateOpp_Step6
              id={id}
              community={community}
              setOpen={setOpen}
              setFooterWidth={setFooterWidth}
            />
          ) : (
            <form className="create_opp_step1" onSubmit={(e) => onSubmit(e)}>
              <div className="create_opp_step5_main_cont">
                <div className="create_opp_step1_cont">
                  <div className="step1_cont_header">
                    {t("opportunity.create.hours")}*
                  </div>
                  <input
                    type="number"
                    className="create_opp_input"
                    min="1"
                    required
                    value={hoursNb}
                    onChange={(e) => setHoursNb(e.target.value)}
                    placeholder={t("opportunity.create.hours")}
                  />
                </div>
                <div className="create_opp_step1_cont">
                  <div className="step1_cont_header">
                    {t("opportunity.create.long")}*
                  </div>
                  <div className="create_opp_step5_header">
                    <div
                      style={{
                        width: "100%",
                        zIndex: "10000000",
                        position: "relative",
                      }}
                    >
                      <DatePicker
                        id="start_date"
                        colorPrimary="var(--primary)"
                        value={startDate}
                        onChange={setStartDate}
                        renderInput={renderCustomInput1}
                        shouldHighlightWeekends
                        calendarClassName="responsive-calendar datepicker_cont "
                        minimumDate={defaultDate}
                      />
                    </div>
                    <br />
                    <div
                      style={{
                        width: "100%",
                        zIndex: "9000000",
                        position: "relative",
                      }}
                    >
                      <DatePicker
                        id="end_date"
                        colorPrimary="var(--primary)"
                        value={endDate}
                        onChange={setEndDate}
                        renderInput={renderCustomInput2}
                        shouldHighlightWeekends
                        calendarClassName="responsive-calendar datepicker_cont2"
                        minimumDate={startDate}
                      />
                    </div>
                  </div>
                </div>
                <div className="create_opp_step1_cont">
                  <div className="step1_cont_header">
                    {t("opportunity.create.languages")}*
                  </div>
                  <input
                    type="text"
                    className="create_opp_input"
                    required
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                    placeholder={t("opportunity.create.example")}
                  />
                </div>{" "}
                <div className="create_opp_step1_cont">
                  <div className="step1_cont_header">
                    {t("opportunity.create.deadline")}*
                  </div>
                  <div className="create_opp_step5_header">
                    <div
                      style={{
                        width: "100%",
                        zIndex: "10000000 !important",
                        position: "relative",
                      }}
                    >
                      <DatePicker
                        id="start_date"
                        colorPrimary="var(--primary)"
                        value={deadline}
                        onChange={setDeadline}
                        renderInput={renderCustomInput3}
                        shouldHighlightWeekends
                        calendarClassName="responsive-calendar datepicker_cont"
                        minimumDate={startDate}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {error && <div className="error_msg">{error}</div>}

              <div className="complete_step1_footer">
                <div className="divider_line"></div>
                <div className="complete_step_footer_button">
                  <button
                    className={
                      size.width > 768
                        ? "primary_button complete_step_1_next_button"
                        : "primary_button complete_step_2_next_button_resp"
                    }
                    onClick={(e) => { e.preventDefault(); setPreviousPage(true); }}
                  >
                    {size.width > 768 ? (
                      t("CompleteProf.complete_your_profile_btn_5")
                    ) : lang == "en" ? (
                      <AiOutlineArrowLeft size="1.5rem" />
                    ) : (
                      <AiOutlineArrowRight size="1.5rem" />
                    )}
                  </button>

                  <div
                    className={
                      size.width > 768
                        ? "complete_step_right_footer"
                        : "complete_step_right_footer_resp"
                    }
                  >
                    <button
                      className={
                        size.width > 500
                          ? "pink_button complete_step_2_complete_button"
                          : "pink_button complete_step_2_next_button_resp"
                      }
                      onClick={() => setIsNext(false)}
                    >
                      {size.width > 500 ? (
                        t("CompleteProf.complete_your_profile_btn_3")
                      ) : (
                        <BiSave size="1.5rem" />
                      )}
                    </button>
                    <button
                      className={
                        size.width > 768
                          ? "primary_button complete_step_2_next_button"
                          : "primary_button complete_step_2_next_button_resp"
                      }
                      onClick={() => setIsNext(true)}
                    >
                      {size.width > 768 ? (
                        t("CompleteProf.complete_your_profile_btn_4")
                      ) : lang == "en" ? (
                        <AiOutlineArrowRight size="1.5rem" />
                      ) : (
                        <AiOutlineArrowLeft size="1.5rem" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          ))
      }
    </div>
  );
}

export default CreateOpp_Step5;
