import axios from "axios";
import { requestAction } from "./requestsReducers";
import { createRequestGroupMembers } from "../request_group_members/requestGroupMembersActions";
import {getFavoritesOpportunities} from "../opportunities/opportunityActions";
  

export const createRequest = (request , group_members) => async (dispatch) => {
    dispatch(requestAction.createRequest());
    try {
        let response = await axios.post(
            process.env.REACT_APP_API + "/requests",
            request
        );
        if(response.data.data !== undefined){
        if (response.data.data && response.data.data.id && group_members!= undefined) {
            if(group_members.length > 0){
                group_members.map((value, index) => {
                    group_members[index]['occupation'] = group_members[index]['occupation'].value;
                })
            }
            dispatch(createRequestGroupMembers(response.data.data.id,group_members));
        }
        
        dispatch(requestAction.createRequestSuccess(response.data));
    }else{
        dispatch(requestAction.createRequestFail(response.data.message));
    }
    } catch (error) {
        dispatch(requestAction.createRequestFail(error.message));
    }
    dispatch(getFavoritesOpportunities());
};


export const checkIfVolunteerReported = () => async (dispatch) => {
    try {
      dispatch(requestAction.checkIfVolunteerReported());
      const token = localStorage.getItem("token");
      let response = await axios.get(
        process.env.REACT_APP_API + "/requests/checkReported",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      dispatch(
        requestAction.checkIfVolunteerReportedSuccess(response.data)
      );
    } catch (error) {
      dispatch(requestAction.checkIfVolunteerReportedFail(error.message));
    }
  };