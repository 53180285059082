import React from "react";
import PopUp from "../../../components/PopUp/PopUp";
import "./CreateOpp_Pop.css"
function CreateOpp_Pop(props) {
  return (
    <div className="create_opp_main_con">
      <PopUp
      setOpen={props.setOpen}
        cont={
          <div className="create_pop_main_container">
            <div className="create_opp_pop_cont">
              <div className="create_opp_header_cont">
                <h2 className="create_opp_header">{props.header}</h2>
                {props.subtitle && (
                  <p className="create_opp_subtitle">{props.subtitle}*</p>
                )}
                <div className="create_opp_body">{props.body}</div>
              </div>{" "}
            </div>
            <div className="create_opp_footer_slider">{props.footer}</div>
          </div>
        }
      />
    </div>
  );
}

export default CreateOpp_Pop;
