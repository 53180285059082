import React from "react";
import "./Apply.css";
import PopUp from "../../../components/PopUp/PopUp.js";

function Apply(props) {

    return (
        <div className="apply_main_con">
            <PopUp
                setOpen={props.setOpen}
                cont={
                    <>
                        <div className="apply_pop_cont">
                            <div className="apply_header_cont">
                                <h2 className="apply_header">{props.header}</h2>
                                {props.subtitle && (
                                    <p className="apply_subtitle">{props.subtitle}*</p>
                                )}
                                <div className="apply_body">{props.body}</div>
                            </div>{" "}
                        </div>
                        <div className="apply_footer_slider">{props.footer}</div>
                    </>
                }
            />
        </div>
    );
}

export default Apply;