import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  blogs: [],
  loading: false,
  error: "",
  blogDetail:[],
  firstBlogs:[]
};
const blogsSlice = createSlice({
  name: "Blogs",
  initialState,
  reducers: {
    fetchBlogsRequest(state) {
      state.loading = true;
      state.blogs = [];
      state.error = "";
    },

    fetchBlogsSuccess(state, action) {
      state.loading = false;
      state.blogs = action.payload;
      state.error = "";
    },

    fetchBlogsFail(state, action) {
      state.loading = false;
      state.blogs = [];
      state.error = action.payload;
    },
    
    fetchFirstBlogsRequest(state) {
      state.loading = true;
      state.firstBlogs = [];
      state.error = "";
    },

    fetchFirstBlogsSuccess(state, action) {
      state.loading = false;
      state.firstBlogs = action.payload;
      state.error = "";
    },

    fetchFirstBlogsFail(state, action) {
      state.loading = false;
      state.firstBlogs = [];
      state.error = action.payload;
    },
    

    fetchBlogIdRequest(state) {
      state.loading = true;
      state.blogDetail = [];
      state.error = "";
    },

    fetchBlogIdSuccess(state, action) {
      state.loading = false;
      state.blogDetail = action.payload;
      state.error = "";
    },

    fetchBlogIdFail(state, action) {
      state.loading = false;
      state.blogDetail = [];
      state.error = action.payload;
    },
    
  },
});

export const blogsAction = blogsSlice.actions;
export default blogsSlice;
