import { React, useEffect, useState, useRef } from "react";
import "./Feedbacks.css";
import { useSSR, useTranslation } from "react-i18next";
import i18n from "../../../i18n";

//emojies imports
import notapp from "../../../assets/images/feedbacks/Group 1879.png";
import insuff from "../../../assets/images/feedbacks/Group 1878.png";
import suff from "../../../assets/images/feedbacks/Group 1877.png";
import good from "../../../assets/images/feedbacks/Group 1876.png";
import excellent from "../../../assets/images/feedbacks/Group 1875.png";
import PopUp from "../../../components/PopUp/PopUp";
import { addFeedback } from "../../../Redux/feedbacks/FeedbacksActions";
import { getFeedbackQuestion } from "../../../Redux/opportunities/opportunityActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AiOutlineClose
} from "react-icons/ai";
import Select from "react-select";
import axios from "axios";

function Feedbacks(props) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [Emojie, setEmojie] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [support, setSupport] = useState(null);
  const [improveAspects, setImproveAspects] = useState("");
  const [involvement, setInvolvement] = useState("");
  const [overallImpact, setOverallImpact] = useState(null);
  const [personalGoals, setPersonalGoals] = useState(null);
  const [allSkills, setAllSkills] = useState([]);
  const [skills, setSkills] = useState([]);
  const [entrepreneurialIdeas, setEntrepreneurialIdeas] = useState();
  const [socialCommercial, setSocialCommercial] = useState([]);
  const [socialCommercialExplanation, setSocialCommercialExplanation] = useState("");
  const [volunteerConnect, setVolunteerConnect] = useState([]);
  const [volunteerInfluence, setVolunteerInfluence] = useState([]);
  const [displayOtherInput, setDisplayOtherInput] = useState(false);
  const [otherInfluence, setOtherVolunteerInfluence] = useState("");
  const [volunteerServices, setVolunteerServices] = useState(null);
  const [improveExperience, setImproveExperience] = useState("");

  const { volunteerFeedbackQuestion } = useSelector(
    (store) => store.opportunitiesReducer
  );

  const getSkills = async () => {
    const response = await axios.get(process.env.REACT_APP_API + "/skills");
    const newS = [];
    response.data.data.map((d, i) => {
      if (d.type == "Soft skills") newS.push(d);
    });
    const newSkills = newS.map((d, i) => {
      return { value: d.id, label: lang == "en" ? d.name : d.name_ar, color: d.type == "Soft skills" ? "blue" : 'red' };
    });

    setAllSkills(newSkills);
  };




  useEffect(() => {
    console.log('overallImpact : ', overallImpact)
  }, [overallImpact]);


  useEffect(() => {
    console.log("personalGoals : ", personalGoals)
  }, [personalGoals]);

  useEffect(() => {
    getSkills();
    dispatch(getFeedbackQuestion());
  }, []);

  const setSelectedSkills = (event) => {
    if (event.length > 0) {
      var found = false;
      if (skills.length > 0)
        for (var i = 0; i < skills.length; i++) {
          if (event[0].value == skills[i].value) {
            found = true;
          }
        }
      if (!found) setSkills((skill) => [...skill, event[0]]);
    }
  };



  const removeSkill = (index) => {
    const newfields = [...skills];
    newfields.splice(index, 1);
    setSkills(newfields);
  };

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);


  const handleSubmit = (e) => {
    e.preventDefault();
    let skillsData = [];
    if (skills.length > 0) {
      skillsData = skills.map((item) => {
        if (typeof item === 'object') {
          // If the item is an object, map its properties
          return item.value

        }
      });
    }
    const feedback_data = {
      request_id: props.requestId,
      support: support,
      ImproveAspects: improveAspects,
      involvement: involvement,
      overallImpact: overallImpact,
      personalGoals: personalGoals,
      soft_skills_id: skillsData,
      entrepreneurialIdeas: entrepreneurialIdeas,
      socialCommercial: socialCommercial,
      socialCommercialExplanation: socialCommercialExplanation,
      volunteerConnect: volunteerConnect,
      volunteerInfluence: volunteerInfluence,
      otherInfluence: otherInfluence,
      volunteerExperience: Emojie,
      VolunteerServices: volunteerServices,
      ImproveExperience: improveExperience
    };
    console.log('feedback_data', feedback_data)
    dispatch(addFeedback(feedback_data));
    props.setOpen(false);
    props.onSubmitAction();
    navigate(`/checkin_out_evaluation/${props.requestId}`);
  };

  return (
    <>
      <PopUp
        setOpen={props.setOpen}
        body={
          <div className="feedbacks_page">
            <div className="feedbacks_page_title_container">
              {t("feedbacks.title")}
            </div>
            <form onSubmit={(e) => handleSubmit(e)}>

              <div className="feedbacks_page_title_container2">
                {t("feedbacks.feedback_section1")}
              </div>

              <div className="feedbacks_page_emojies_container">
                <div className="feedbacks_page_subtitle">
                  {t("feedbacks.feedback_question1")}
                </div>
                <div className="radio_buttons_container_feedbacks radio_buttons_container_numbers">
                  <div className="radio_buttons_container">
                    <input
                      type="radio"
                      name="scale1"
                      className="feedbacks_page_radio"
                      onChange={(e) => setSupport(1)}
                    />
                    <div className="feedbacks_page_radio_text">
                      {t("feedbacks.very_poor")}{" "}
                    </div>
                  </div>
                  <div className="radio_buttons_container">
                    <input
                      type="radio"
                      name="scale1"
                      className="feedbacks_page_radio"
                      onChange={(e) => setSupport(2)}
                    />
                    <div className="feedbacks_page_radio_text">
                      {t("feedbacks.poor")}{" "}
                    </div>
                  </div>
                  <div className="radio_buttons_container">
                    <input
                      type="radio"
                      name="scale1"
                      className="feedbacks_page_radio"
                      onChange={(e) => setSupport(3)}
                    />
                    <div className="feedbacks_page_radio_text">
                      {t("feedbacks.average")}{" "}
                    </div>
                  </div>
                  <div className="radio_buttons_container">
                    <input
                      type="radio"
                      name="scale1"
                      className="feedbacks_page_radio"
                      onChange={(e) => setSupport(4)}
                    />
                    <div className="feedbacks_page_radio_text">
                      {t("feedbacks.good")}{" "}
                    </div>
                  </div>
                  <div className="radio_buttons_container">
                    <input
                      type="radio"
                      name="scale1"
                      className="feedbacks_page_radio"
                      onChange={(e) => setSupport(5)}
                    />
                    <div className="feedbacks_page_radio_text">
                      {t("feedbacks.excellent")}{" "}
                    </div>
                  </div>
                </div>
              </div>


              <div className="feedbacks_page_skill_developement_comments">
                <div className="feedbacks_page_title_comment">
                  {" "}
                  {t("feedbacks.feedback_question2")}{" "}
                </div>
                <textarea
                  className="feedbacks_page_input_comment_2"
                  placeholder={t("feedbacks.feedbacks_3")}
                  onChange={(e) => setImproveAspects(e.target.value)}
                />
              </div>



              <div className="feedbacks_page_emojies_container">
                <div className="feedbacks_page_subtitle">
                  {t("feedbacks.feedback_question3")}
                </div>
                <div className="radio_buttons_container_feedbacks">
                  <div className="radio_buttons_container">
                    <input
                      type="radio"
                      name="involvement"
                      className="feedbacks_page_radio"
                      onChange={(e) => setInvolvement("Very involved")}
                    />
                    <div className="feedbacks_page_radio_text">
                      {t("feedbacks.very_involved")}{" "}
                    </div>
                  </div>

                  <div className="radio_buttons_container">
                    <input
                      type="radio"
                      name="involvement"
                      className="feedbacks_page_radio"
                      onChange={(e) => setInvolvement("Involved occasionally")}
                    />
                    <div className="feedbacks_page_radio_text">
                      {t("feedbacks.involved_occasionally")}{" "}
                    </div>
                  </div>

                  <div className="radio_buttons_container">
                    <input
                      type="radio"
                      name="involvement"
                      className="feedbacks_page_radio"
                      onChange={(e) => setInvolvement("Not involved")}
                    />
                    <div className="feedbacks_page_radio_text">
                      {t("feedbacks.not_involved")}{" "}
                    </div>
                  </div>

                </div>
              </div>

              <div className="feedbacks_page_title_container2">
                {t("feedbacks.feedback_section2")}
              </div>


              <div className="feedbacks_page_emojies_container">
                <div className="feedbacks_page_subtitle">
                  {t("feedbacks.feedback_question4")}
                </div>
                <div className="radio_buttons_container_feedbacks radio_buttons_container_numbers">

                  {volunteerFeedbackQuestion?.Personal_WellBeing &&
                    volunteerFeedbackQuestion?.Personal_WellBeing?.map((VSCQ, i) => {
                      return (

                        <div className="radio_buttons_container">
                          <input
                            key={VSCQ?.id}
                            type="radio"
                            name="scale2"
                            className="feedbacks_page_radio"
                            onChange={(e) => {
                              const itemId = VSCQ?.id;
                              setOverallImpact(itemId);
                            }} />
                          <div className="feedbacks_page_radio_text">
                            {lang == "en" ? VSCQ?.question : VSCQ?.question_ar}{" "}
                          </div>
                        </div>
                      )
                    }
                    )
                  }

                </div>
              </div>

              <div className="feedbacks_page_skill_developement_comments">
                <div className="feedbacks_page_title_comment">
                  {" "}
                  {t("feedbacks.feedback_question5")}{" "}
                </div>
                <div className="radio_buttons_container_feedbacks radio_buttons_container_numbers">

                  {volunteerFeedbackQuestion?.Personal_Goals &&
                    volunteerFeedbackQuestion?.Personal_Goals?.map((VSCQ, i) => {
                      return (

                        <div className="radio_buttons_container">
                          <input
                            key={VSCQ?.id}
                            type="radio"
                            name="PersonalGoals"
                            className="feedbacks_page_radio"
                            onChange={(e) => {
                              const itemId = VSCQ?.id;
                              setPersonalGoals(itemId);
                            }} />
                          <div className="feedbacks_page_radio_text">
                            {lang == "en" ? VSCQ?.question : VSCQ?.question_ar}{" "}
                          </div>
                        </div>
                      )
                    }
                    )
                  }

                  {/* <div className="radio_buttons_container">
                    <input
                      type="radio"
                      name="PersonalGoals"
                      className="feedbacks_page_radio"
                      onChange={(e) => setPersonalGoals(1)}
                    />
                    <div className="feedbacks_page_radio_text">
                      {t("feedbacks.affected_positively")}{" "}
                    </div>
                  </div>
                  <div className="radio_buttons_container">
                    <input
                      type="radio"
                      name="PersonalGoals"
                      className="feedbacks_page_radio"
                      onChange={(e) => setPersonalGoals(2)}
                    />
                    <div className="feedbacks_page_radio_text">
                      {t("feedbacks.affected_negatively")}{" "}{" "}
                    </div>
                  </div>
                  <div className="radio_buttons_container">
                    <input
                      type="radio"
                      name="PersonalGoals"
                      className="feedbacks_page_radio"
                      onChange={(e) => setPersonalGoals(3)}
                    />
                    <div className="feedbacks_page_radio_text">
                      {t("feedbacks.didnt_affect")}{" "}{" "}
                    </div>
                  </div> */}


                </div>
              </div>


              <div className="feedbacks_page_skill_developement_comments">
                <div className="feedbacks_page_title_comment">
                  {" "}
                  {t("feedbacks.feedback_question6")}{" "}
                </div>
                <Select
                  value={null}
                  className="feedback_select"
                  name="skills"
                  onChange={(e) => setSelectedSkills(e)}
                  placeholder={t("CompleteProf.search")}
                  isMulti={true}
                  options={allSkills}
                />

                <div className="complete_step3_skills_cont">
                  {skills &&
                    skills.map((skill, index) => {
                      return (
                        <div className="complete_step3_skills_input_cont">
                          <div className="complete_step3_skills_input" style={{ borderColor: skill.color }}>
                            {" "}
                            {skill.label}{" "}
                          </div>{" "}
                          <AiOutlineClose
                            className="complete_step2_close"
                            size="1.2rem"
                            onClick={() => removeSkill(index)}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>


              <div className="feedbacks_page_title_container2">
                {t("feedbacks.feedback_section3")}
              </div>


              <div className="feedbacks_page_emojies_container">
                <div className="feedbacks_page_subtitle">
                  {t("feedbacks.feedback_question7")}
                </div>
                <div className="radio_buttons_container_feedbacks">
                  <div className="radio_buttons_container">
                    <input
                      type="radio"
                      name="entrepreneurialIdeas"
                      className="feedbacks_page_radio"
                      onChange={(e) => setEntrepreneurialIdeas(true)}
                    />
                    <div className="feedbacks_page_radio_text">
                      {t("feedbacks.feedbacks_5")}{" "}
                    </div>
                  </div>
                  <div className="radio_buttons_container">
                    <input
                      type="radio"
                      name="entrepreneurialIdeas"
                      className="feedbacks_page_radio"
                      onChange={(e) => setEntrepreneurialIdeas(false)}
                    />
                    <div className="feedbacks_page_radio_text">
                      {t("feedbacks.feedbacks_6")}{" "}
                    </div>
                  </div>
                </div>
              </div>


              {entrepreneurialIdeas && <div className="feedbacks_page_emojies_container">
                <div className="feedbacks_page_subtitle">
                  {t("feedbacks.feedback_question8")}
                </div>
                <div className="radio_buttons_container_feedbacks">
                  {volunteerFeedbackQuestion?.Social_Commercial &&
                    volunteerFeedbackQuestion?.Social_Commercial?.map((VSCQ, i) => {
                      return (

                        <div className="radio_buttons_container">
                          <input
                            type="checkbox"
                            name="socialCommercial"
                            className="feedbacks_page_radio"
                            onChange={(e) => {
                              const checked = e.target.checked;
                              const itemId = VSCQ?.id;

                              setSocialCommercial((socialCommercial) => {
                                if (checked) {
                                  // If checked, add the ID to the array if it doesn't already exist
                                  return socialCommercial.includes(itemId)
                                    ? socialCommercial
                                    : [...socialCommercial, itemId];
                                } else {
                                  // If unchecked, remove the ID from the array if it exists
                                  return socialCommercial.filter((id) => id !== itemId);
                                }
                              });
                            }} />
                          <div className="feedbacks_page_radio_text">
                            {lang == "en" ? VSCQ?.question : VSCQ?.question_ar}{" "}
                          </div>
                        </div>
                      )
                    }
                    )
                  }

                  <div className="feedbacks_page_experience_comments">
                    <div className="feedbacks_page_title_comment">
                      {t("feedbacks.explain_briefly")}{" "}
                    </div>

                    <textarea
                      className="feedbacks_page_input_comment_2"
                      placeholder={t("feedbacks.explain_briefly")}
                      onChange={(e) => setSocialCommercialExplanation(e.target.value)}
                    />


                  </div>

                </div>
              </div>}


              <div className="feedbacks_page_subtitle">
                {t("feedbacks.feedback_question9")}
              </div>
              <div className="radio_buttons_container_feedbacks">
                {volunteerFeedbackQuestion?.Volunteer_Connect &&
                  volunteerFeedbackQuestion?.Volunteer_Connect?.map((VSCQ, i) => {
                    return (

                      <div className="radio_buttons_container">
                        <input
                          type="checkbox"
                          name="volunteerConnect"
                          className="feedbacks_page_radio"
                          onChange={(e) => {
                            const checked = e.target.checked;
                            const itemId = VSCQ?.id;

                            setVolunteerConnect((volunteerConnect) => {
                              if (checked) {
                                // If checked, add the ID to the array if it doesn't already exist
                                return volunteerConnect.includes(itemId)
                                  ? volunteerConnect
                                  : [...volunteerConnect, itemId];
                              } else {
                                // If unchecked, remove the ID from the array if it exists
                                return volunteerConnect.filter((id) => id !== itemId);
                              }
                            });
                          }} />
                        <div className="feedbacks_page_radio_text">
                          {lang == "en" ? VSCQ?.question : VSCQ?.question_ar}{" "}
                        </div>
                      </div>
                    )
                  }
                  )
                }

              </div>

              <div className="feedbacks_page_subtitle">
                {t("feedbacks.feedback_question10")}
              </div>
              <div className="radio_buttons_container_feedbacks">
                {volunteerFeedbackQuestion?.Volunteer_Influence &&
                  volunteerFeedbackQuestion?.Volunteer_Influence?.map((VSCQ, i) => {
                    return (

                      <div className="radio_buttons_container">
                        <input
                          type="checkbox"
                          name="volunteerInfluence"
                          className="feedbacks_page_radio"
                          onChange={(e) => {
                            const checked = e.target.checked;
                            const itemId = VSCQ?.id;

                            setVolunteerInfluence((volunteerInfluence) => {
                              if (checked) {
                                // If checked, add the ID to the array if it doesn't already exist
                                return volunteerInfluence.includes(itemId)
                                  ? volunteerInfluence
                                  : [...volunteerInfluence, itemId];
                              } else {
                                // If unchecked, remove the ID from the array if it exists
                                return volunteerInfluence.filter((id) => id !== itemId);
                              }
                            });
                          }} />
                        <div className="feedbacks_page_radio_text">
                          {lang == "en" ? VSCQ?.question : VSCQ?.question_ar}{" "}
                        </div>
                      </div>
                    )
                  }
                  )
                }
                <div className="radio_buttons_container">
                  <input
                    type="checkbox"
                    name="volunteerInfluence"
                    className="feedbacks_page_radio"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      if (checked) {
                        setDisplayOtherInput(true)
                      } else {
                        setDisplayOtherInput(false)
                      }
                    }} />
                  <div className="feedbacks_page_radio_text">
                    {t("evaluation_feedback.other")}{"  "}
                  </div>
                  {displayOtherInput
                    &&
                    <input
                      type="text"
                      name="OtherVolunteerInfluence"
                      onChange={(e) => { setOtherVolunteerInfluence(e.target.value) }} />
                  }
                </div>
              </div>

              <div className="feedbacks_page_title_container2">
                {t("feedbacks.feedback_section4_title")}
              </div>
              <div className="feedbacks_page_title_container2">
                {t("feedbacks.feedback_section4")}
              </div>
              <div className="feedbacks_page_subtitle">
                {t("feedbacks.feedback_question11")}
              </div>
              <div className="feedbacks_page_emojies">
                <div className="feedbacks_page_emojies_row">
                  <div className="feedbacks_page_emojies_row_1">
                    <img
                      src={notapp}
                      alt="notapp"
                      className={
                        Emojie == "1"
                          ? "active_emojie emojie_logo"
                          : "emojie_logo"
                      }
                      onClick={() => setEmojie("1")}
                    />
                    <div className="feedbacks_page_emojies_row_1_text">
                      {t("feedbacks.emoji_1")}
                    </div>
                  </div>
                </div>
                <div className="feedbacks_page_emojies_row">
                  <div className="feedbacks_page_emojies_row_1">
                    <img
                      src={insuff}
                      alt="notapp"
                      className={
                        Emojie == "2"
                          ? "active_emojie emojie_logo"
                          : "emojie_logo"
                      }
                      onClick={() => setEmojie("2")}
                    />
                    <div className="feedbacks_page_emojies_row_1_text">
                      {t("feedbacks.emoji_2")}
                    </div>
                  </div>
                </div>
                <div className="feedbacks_page_emojies_row">
                  <div className="feedbacks_page_emojies_row_1">
                    <img
                      src={suff}
                      alt="notapp"
                      className={
                        Emojie == "3"
                          ? "active_emojie emojie_logo"
                          : "emojie_logo"
                      }
                      onClick={() => setEmojie("3")}
                    />
                    <div className="feedbacks_page_emojies_row_1_text">
                      {t("feedbacks.emoji_3")}
                    </div>
                  </div>
                </div>
                <div className="feedbacks_page_emojies_row">
                  <div className="feedbacks_page_emojies_row_1">
                    <img
                      src={good}
                      alt="notapp"
                      className={
                        Emojie == "4"
                          ? "active_emojie emojie_logo"
                          : "emojie_logo"
                      }
                      onClick={() => setEmojie("4")}
                    />
                    <div className="feedbacks_page_emojies_row_1_text">
                      {t("feedbacks.emoji_4")}
                    </div>
                  </div>
                </div>
                <div className="feedbacks_page_emojies_row">
                  <div className="feedbacks_page_emojies_row_1">
                    <img
                      src={excellent}
                      alt="notapp"
                      className={
                        Emojie == "5"
                          ? "active_emojie emojie_logo"
                          : "emojie_logo"
                      }
                      onClick={() => setEmojie("5")}
                    />
                    <div className="feedbacks_page_emojies_row_1_text">
                      {t("feedbacks.emoji_5")}
                    </div>
                  </div>
                </div>
              </div>



              <div className="feedbacks_page_emojies_container">
                <div className="feedbacks_page_subtitle">
                  {t("feedbacks.feedback_question12")}
                </div>
                <div className="radio_buttons_container_feedbacks radio_buttons_container_numbers">
                  <div className="radio_buttons_container">
                    <input
                      type="radio"
                      name="VolunteerServices"
                      className="feedbacks_page_radio"
                      onChange={(e) => setVolunteerServices(1)}
                    />
                    <div className="feedbacks_page_radio_text">
                      {t("feedbacks.very_difficult")}
                    </div>
                  </div>
                  <div className="radio_buttons_container">
                    <input
                      type="radio"
                      name="VolunteerServices"
                      className="feedbacks_page_radio"
                      onChange={(e) => setVolunteerServices(2)}
                    />
                    <div className="feedbacks_page_radio_text">
                      {t("feedbacks.difficult")}
                    </div>
                  </div>
                  <div className="radio_buttons_container">
                    <input
                      type="radio"
                      name="VolunteerServices"
                      className="feedbacks_page_radio"
                      onChange={(e) => setVolunteerServices(3)}
                    />
                    <div className="feedbacks_page_radio_text">
                      {t("feedbacks.neutral")}
                    </div>
                  </div>
                  <div className="radio_buttons_container">
                    <input
                      type="radio"
                      name="VolunteerServices"
                      className="feedbacks_page_radio"
                      onChange={(e) => setVolunteerServices(4)}
                    />
                    <div className="feedbacks_page_radio_text">
                      {t("feedbacks.easy")}
                    </div>
                  </div>
                  <div className="radio_buttons_container">
                    <input
                      type="radio"
                      name="VolunteerServices"
                      className="feedbacks_page_radio"
                      onChange={(e) => setVolunteerServices(5)}
                    />
                    <div className="feedbacks_page_radio_text">
                      {t("feedbacks.very_easy")}
                    </div>
                  </div>
                </div>
              </div>

              <div className="feedbacks_page_skill_developement_comments">
                <div className="feedbacks_page_title_comment">
                  {" "}
                  {t("feedbacks.feedback_question13")}{" "}
                </div>
                <textarea
                  className="feedbacks_page_input_comment_2"
                  placeholder={t("feedbacks.feedbacks_3")}
                  onChange={(e) => setImproveExperience(e.target.value)}
                />
              </div>

              <div className="feedbacks_page_submit_button">
                <button
                  type="submit"
                  className="feedbacks_page_submit_button_text"
                >
                  {t("feedbacks.feedbacks_8")}
                </button>
              </div>
            </form>
          </div>
        }
      />
    </>
  );
}

export default Feedbacks;
