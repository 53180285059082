import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Checkin: [],
  CheckinLoading: false,
  CheckinError: null,
  Checkout: [],
  CheckoutLoading: false,
  CheckoutError: null,
  accept: [],
  report: [],
  CheckInOutHoursLoading:false,
  CheckInOutHoursError: null,
  CheckinOutHours:[],
  CountCheckInOutRequest: [],
  CountCheckInOutRequestError: null,
  CountCheckInOutRequestLoading:false,

};

const Checkin_outSlice = createSlice({
  name: "Checkin_out",
  initialState,
  reducers: {
    getCheckin: (state) => {
      state.CheckinLoading = true;
      state.CheckinError = null;
      state.Checkin = [];
    },
    getCheckinSuccess: (state, action) => {
      state.CheckinLoading = false;
      state.Checkin = action.payload;
    },
    getCheckinError: (state, action) => {
      state.CheckinLoading = false;
      state.CheckinError = action.payload;
    },
    getCheckout: (state) => {
      state.CheckoutLoading = true;
      state.CheckoutError = null;
      state.Checkout = [];
    },
    getCheckoutSuccess: (state, action) => {
      state.CheckoutLoading = false;
      state.Checkout = action.payload;
    },
    getCheckoutError: (state, action) => {
      state.CheckoutLoading = false;
      state.CheckoutError = action.payload;
    },
    acceptCheckin: (state, action) => {
      state.CheckinLoading = true;
      state.CheckinError = null;
      state.accept = [];
    },
    acceptCheckinSuccess: (state, action) => {
      state.CheckinLoading = false;
      state.accept = action.payload;
    },
    acceptCheckinError: (state, action) => {
      state.CheckinLoading = false;
      state.CheckinError = action.payload;
      state.accept = action.payload;
    },
    RejectCheckin: (state, action) => {
      state.CheckinLoading = true;
      state.CheckinError = null;
      state.accept = [];
    },
    RejectCheckinSuccess: (state, action) => {
      state.CheckinLoading = false;
      state.accept = action.payload;
    },
    RejectCheckinError: (state, action) => {
      state.CheckinLoading = false;
      state.CheckinError = action.payload;
      state.accept = action.payload;
    },
    acceptCheckout: (state, action) => {
      state.CheckoutLoading = true;
      state.CheckoutError = null;
      state.accept = [];
    },
    acceptCheckoutSuccess: (state, action) => {
      state.CheckoutLoading = false;
      state.accept = action.payload;
    },
    acceptCheckoutError: (state, action) => {
      state.CheckoutLoading = false;
      state.CheckoutError = action.payload;
      state.accept = action.payload;
    },
    RejectCheckout: (state, action) => {
      state.CheckoutLoading = true;
      state.CheckoutError = null;
      state.accept = [];
    },
    RejectCheckoutSuccess: (state, action) => {
      state.CheckoutLoading = false;
      state.accept = action.payload;
    },
    RejectCheckoutError: (state, action) => {
      state.CheckoutLoading = false;
      state.CheckoutError = action.payload;
      state.accept = action.payload;
    },
    Reportvolunteer: (state, action) => {
      state.CheckoutLoading = true;
      state.CheckoutError = null;
      state.report = [];
    },
    ReportvolunteerSuccess: (state, action) => {
      state.CheckoutLoading = false;
      state.report = action.payload;
    },
    ReportvolunteerError: (state, action) => {
      state.CheckoutLoading = false;
      state.CheckoutError = action.payload;
      state.report = action.payload;
    },

    getCheckinOutHours: (state) => {
      state.CheckInOutHoursLoading = true;
      state.CheckInOutHoursError = null;
      state.CheckinOutHours = [];
    },
    getCheckinOutHoursSuccess: (state, action) => {
      state.CheckInOutHoursLoading = false;
      state.CheckinOutHours = action.payload;
    },
    getCheckinOutHoursError: (state, action) => {
      state.CheckInOutHoursLoading = false;
      state.CheckInOutHoursError = action.payload;
    },

    getCountCheckInOutRequestRequest: (state) => {
      state.CountCheckInOutRequestLoading = true;
      state.CountCheckInOutRequestError = null;
      state.CountCheckInOutRequest = [];
    },
    getCountCheckInOutRequestSuccess: (state, action) => {
      state.CountCheckInOutRequestLoading = false;
      state.CountCheckInOutRequestError = null;
      state.CountCheckInOutRequest =  action.payload;
    },
    getCountCheckInOutRequestError: (state, action) => {
      state.CountCheckInOutRequestLoading = false;
      state.CountCheckInOutRequestError =  action.payload;
      state.CountCheckInOutRequest =  [];
    },
  },
});

export const Checkin_outActions = Checkin_outSlice.actions;
export default Checkin_outSlice;
