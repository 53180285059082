import React, { useEffect, useState } from "react";
import Apply from "../Apply";
import i18n from "../../../../i18n";
import { useTranslation } from "react-i18next";
import "./Apply_Step2.css";
import apply_img_step2 from "../../../../assets/images/opportunity/apply/apply_step1.png";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import Apply_Step3 from "../Apply_Step3/Apply_Step3";
import Apply_Step1 from "../Apply_Step1/Apply_Step1";
import { useNavigate } from "react-router-dom";

function Apply_Step2({ setOpen, open, data, setFooterWidth ,opportunityId ,openOutOfArea}) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [hasAssociationValue, setHasAssociation] = useState(false);
  const [openApplyPopUpStep2, setOpenApplyPopUpStep2] = useState(false);
  const [previous, setPrevious] = useState(false);
  const [oppData, setOppData] = useState("");
  const size = useWindowSize();
  const navigate = useNavigate();
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  useEffect(() => {console.log(data)
    setPrevious(false);
    setOppData({ ...data });
    setFooterWidth("25%");
  }, []);

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const onSubmit = (e) => {
    e.preventDefault();
    setOppData({ ...oppData, association: hasAssociationValue });
    setOpenApplyPopUpStep2(true);
  };

  return (
    <>

      {previous && (
        <Apply_Step1
          setOpen={setOpen}
          open={open}
          opportunityId={opportunityId}
        />
      )}

      {!previous &&!openApplyPopUpStep2 ? (
        <form className="apply_step2" onSubmit={(e) => onSubmit(e)}>
          <img className="apply_img" src={apply_img_step2} />
          <div>{t("opportunity.apply_popup.apply_assosciation_question")}</div>
          <div className="complete_radio_container">
            <div className="complete_radio_comp">
              <input
                onChange={(e) => setHasAssociation(true)}
                type="radio"
                id="yes"
                name="association"
                value="Yes"
                class="association_radio"
                required
              />
              <label for="yes" style={{ cursor: "pointer" }}>
                {" "}
                {t("opportunity.apply_popup.yes")}
              </label>
            </div>
            <div className="complete_radio_comp">
              <input
                onChange={(e) => setHasAssociation(false)}
                type="radio"
                id="no"
                name="association"
                value="No"
                class="association_radio"
                required
              />
              <label for="no" style={{ cursor: "pointer" }}>
                {" "}
                {t("opportunity.apply_popup.no")}
              </label>
            </div>
          </div>
          {hasAssociationValue && (
            <>
              <div>
                {t("opportunity.apply_popup.apply_assosciation_name_question")}
              </div>
              <input
                className="association_name_input"
                type="text"
                style={{ cursor: "pointer" }}
                onChange={(e) =>
                  setOppData({ ...oppData, associationName: e.target.value })
                }
                placeholder={t("opportunity.apply_popup.association_name")}
                required
              />
            </>
          )}
          <div className="dotter_spacer" />
          <div class="complete_step_footer_button">
            <div className="div_buttons div_buttons_left">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setPrevious(true);
                }}
                className={
                  size.width > 768
                    ? "apply_primary_button app_step2_apply_later_button gray-btn"
                    : "apply_primary_button app_step2_apply_later_button_resp gray-btn"
                }
              >
                {t("opportunity.apply_popup.previous")}
              </button>
            </div>
            <div className="div_buttons">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(false);
                }}
                className={
                  size.width > 768
                    ? "apply_primary_button app_step2_apply_later_button"
                    : "apply_primary_button app_step2_apply_later_button_resp"
                }
              >
                {t("opportunity.apply_popup.apply_later")}
              </button>

              <button
                type="submit"
                className={
                  size.width > 768
                    ? "primary_button app_step2_next_button"
                    : "primary_button app_step2_next_button_resp"
                }
              >
                {size.width > 768 ? (
                  t("opportunity.apply_popup.next")
                ) : lang == "en" ? (
                  <AiOutlineArrowRight size="1.5rem" />
                ) : (
                  <AiOutlineArrowLeft size="1.5rem" />
                )}
              </button>
            </div>
          </div>
        </form>
      ) : (
        !previous && <Apply_Step3
          setOpen={setOpen}
          open={open}
          data={oppData}
          opportunityId={opportunityId}
          openOutOfArea={openOutOfArea}
          setFooterWidth={setFooterWidth}
        />
      )}
    </>
  );
}

export default Apply_Step2;
