import React, { useEffect } from "react";
import "./Footer.css";
import logo from "../../assets/images/logo-black.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import i18n from "../../i18n";
import { MdEmail, MdPhone } from "react-icons/md";
import Mailto from "react-protected-mailto";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
  FaWhatsapp,
} from "react-icons/fa";
import Newsletter from "../Newsletter/Newsletter";
//redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { subscribeNewsLetter } from "../../Redux/NewsLetter/NewsLetterActions";

function Footer() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [Role, setRole] = useState("");
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    setRole(role);
  }, [role]);

  return (
    <div
      className={
        lang === "en"
          ? "main-footer globalContainer"
          : "main-footer-arabic globalContainer"
      }
    >
      <Newsletter lang={lang} t={t} />

      <div className="footer_info">
        <div className="footer-logo">
          <img src={logo} className="footer_image" alt="the Volunteer Circle" />
          <div
            className={lang === "en" ? "footer_rights" : "footer_rights_arabic"}
          >
            {t("Footer.rights")}
          </div>
        </div>

        <div className="footer_titles">
          <ul className="footer_list">
            <li>
              <Link
                className={lang === "en" ? "footer_link" : "footer_link_arabic"}
                to="/blogs_media"
              >
                {t("Footer.blogs")}
              </Link>
            </li>

            <li>
              <Link
                className={lang === "en" ? "footer_link" : "footer_link_arabic"}
                to="/contactUsPage"
              >
                {t("Footer.contactUs")}
              </Link>
            </li>
            <li>
              <Link
                className={lang === "en" ? "footer_link" : "footer_link_arabic"}
                to="/faqs"
              >
                {t("Footer.faqs")}
              </Link>
            </li>
            <li>
              <Link
                className={lang === "en" ? "footer_link" : "footer_link_arabic"}
                to="/privacy"
              >
                {t("Footer.privacy")}
              </Link>
            </li>
            {Role == 4 && (
              <li>
                <Link
                  className={
                    lang === "en" ? "footer_link" : "footer_link_arabic"
                  }
                  to="/subscription"
                >
                  {t("Footer.subscription")}
                </Link>
              </li>
            )}

            <li>
              <Link
                className={lang === "en" ? "footer_link" : "footer_link_arabic"}
                to="/termsandconditions"
              >
                {t("Footer.terms")}
              </Link>
            </li>
          </ul>
        </div>

        <div className="footer_titles_2">
          <ul className="footer_list">
            <li>
              <Link
                className={lang === "en" ? "footer_link" : "footer_link_arabic"}
                to="/opportunities"
              >
                {t("Footer.volunteer")}
              </Link>
            </li>
            <li>
              <Link
                className={lang === "en" ? "footer_link" : "footer_link_arabic"}
                to="/mayors-of-impact"
              >
                {t("Footer.programs")}
              </Link>
            </li>
            {role == 4 && <li>
              <Link
                className={lang === "en" ? "footer_link" : "footer_link_arabic"}
                to="/masterclass"
              >
                {t("Footer.masterCurriculum")}
              </Link>
            </li>}
            <li>
              <Link
                className={lang === "en" ? "footer_link" : "footer_link_arabic"}
                to="/volunteer-managers"
              >
                {t("Footer.managers")}
              </Link>
            </li>
            <li>
              <Link
                className={lang === "en" ? "footer_link" : "footer_link_arabic"}
                to="/about-us"
              >
                {t("Footer.aboutUs")}
              </Link>
            </li>
            <li>
              <Link
                className={lang === "en" ? "footer_link" : "footer_link_arabic"}
                to="/our-partners"
              >
                {t("Footer.partners")}
              </Link>
            </li>
          </ul>
        </div>

        <div className="footer_info_3">
          <ul className="footer_list">
            <li>
              <Link className="footer_link_Contact" to="/contactUsPage">
                {t("Footer.contactUs")}
              </Link>
            </li>

            <li className="footer_link_cont">
              <MdEmail size={20} color={"var(--primary)"} />{" "}
              <span className="footer-email">
                <Mailto
                  email="info@thevolunteercircle.com"
                  subject="Inquiries"
                  className="mailto_div"
                >
                  info@thevolunteercircle.com{" "}
                </Mailto>
              </span>
            </li>

            <a href="tel:+96170115999" className="mailto_div">
              {" "}
              <li className="footer_link_cont">
                <MdPhone size={20} color={"var(--primary)"} />{" "}
                <span className="footer-num">+961 70 115 999</span>
              </li>
            </a>

            <a href="tel:+96181736424" className="mailto_div">
              {" "}
              <li className="footer_link_cont">
                <MdPhone size={20} color={"var(--primary)"} />{" "}
                <span className="footer-num">+961 81 736 424</span>
              </li>
            </a>
          </ul>

          <div
            className={lang === "en" ? "footer-social" : "footer-social-arabic"}
          >
            <a
              href="https://www.facebook.com/thevolunteercircle/"
              target="_blank"
            >
              <FaFacebookF size={"1.1rem"} className="footer_icons_active" />
            </a>
            <a
              href="https://www.linkedin.com/company/the-volunteer-circle/"
              target="_blank"
            >
              <FaLinkedinIn size={"1.1rem"} className="footer_icons_active" />
            </a>
            <a
              href="https://www.instagram.com/thevolunteercircle/"
              target="_blank"
            >
              <FaInstagram size={"1.1rem"} className="footer_icons_active" />
            </a>
            {/* <a href="https://www.twitter.com" target="_blank">
              <FaTwitter size={"1.1rem"} className="footer_icons_active" />
            </a> */}
            <a
              href="https://www.youtube.com/channel/UCbBO_117zSc_ycLLjl5RYOA/videos"
              target="_blank"
            >
              <FaYoutube size={"1.1rem"} className="footer_icons_active" />
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=03507177"
              target="_blank"
            >
              <FaWhatsapp size={"1.1rem"} className="footer_icons_active" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
