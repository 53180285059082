import axios from "axios";
import { volunteerSpotlightQaAction } from "./VolunteerSpotlightQaReducers";


export const getQABySpotlightId = (id) => async (dispatch) => {
  dispatch(volunteerSpotlightQaAction.fetchvolunteerSpotlightQaRequest());
  try {
    let response = await axios.get(
      process.env.REACT_APP_API + `/volspotlightqa/spotlight/${id}`
    );
    dispatch(volunteerSpotlightQaAction.fetchvolunteerSpotlightQaSuccess(response.data.data));
  } catch (error) {
    dispatch(volunteerSpotlightQaAction.fetchvolunteerSpotlightQaFail(error.message));
  }
};