import React, { useEffect, useRef } from "react";
import "./ContactUs.css";
import emailjs from "@emailjs/browser";
import { useState } from "react";
import { createContactus } from "../../Redux/contactus/contactusActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import PopUp from "../PopUp/PopUp";

import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import Loading from "../Loading/Loading";

function ContactUs() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const { success, error, contLoading } = useSelector(
    (state) => state.contactReducer
  );

  const [loading, setLoading] = useState(false);
  const [errors, setError] = useState("");
  const [response, setResponse] = useState("");
  const [fullname, setfullname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [organization_name, setorganization_name] = useState("");
  const [position, setposition] = useState("");
  const [topic, settopic] = useState("");
  const [message, setmessage] = useState("");
  const userdata = useSelector((store) => store.userReducer);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const values = {
      type: topic,
      organization: organization_name,
      fullname: fullname,
      email: email,
      phone: phone,
      position: position,
      text: message,
    };
    dispatch(createContactus(values));
    e.target.reset();
  };
  useEffect(() => {
    if (success) {
      setOpen(true);
    }
  }, [success]);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm(
        "service_9jkws6v",
        "template_i14zpgf",
        form.current,
        "kHsoldXcQdaBhiibE"
      )
      .then(
        (result) => {
          setLoading(false);
          setResponse("Successfully Sent!");
          document.getElementById("contact-us").reset();
        },
        (errors) => {
          setLoading(false);
          setError("An error occurred. Please try again later.");
        }
      );
  };

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    setOpen(false);
  }, []);

  const form = useRef();

  return (
    <div className="contact-container">
      <h2 className="title-2">
        {t("contactUs.header1")}{" "}
        <span className="primary-b"> {t("contactUs.header2")}</span>
      </h2>
      <hr className="pink-hr2" />
      <div className="contact-error">{errors}</div>
      <div className="contact-response">{response}</div>
      <form
        onSubmit={handleSubmit && handleFormSubmit}
        ref={form}
        id="contact-us"
      >
        <select
          name="subject"
          onChange={(event) => settopic(event.target.value)}
          required
          className={lang === "ar" ? "arabic-select" : ""}
        >
          <option selected disabled value="">
            {t("contactUs.subject")}
          </option>
          <option value="I want to hire talent!">
            {t("contactUs.option1")}
          </option>
          <option value="Interested in the Impact Entrepreneurship Leadership training">
            {t("contactUs.option2")}
          </option>
          <option value="Outsource my Volunteer Management">
            {t("contactUs.option3")}
          </option>
        </select>
        <input
          type="text"
          placeholder={t("contactUs.organization")}
          required
          name="organization_name"
          onChange={(event) => setorganization_name(event.target.value)}
        />
        <input
          type="text"
          placeholder={t("contactUs.name")}
          required
          name="name"
          onChange={(event) => setfullname(event.target.value)}
        />
        <input
          type="email"
          placeholder={t("contactUs.email")}
          required
          name="business_email"
          onChange={(event) => setemail(event.target.value)}
        />
        <input
          type="text"
          placeholder={t("contactUs.position")}
          required
          name="position"
          onChange={(event) => setposition(event.target.value)}
        />

        {/* <PhoneInput
          country={"lb"}
          required={true}
          name="phone_number"
          onChange={(e) => setphone(e)}
          placeholder={t("contactUs.phone")}
        /> */}
        <input
          type="phone"
          placeholder={t("contactUs.phone")}
          required
          name="phone_number"
          onChange={(event) => setphone(event.target.value)}
        />
        <textarea
          placeholder={t("contactUs.msg")}
          required
          name="message"
          onChange={(event) => setmessage(event.target.value)}
        />
        {contLoading || loading ? (
          <Loading />
        ) : (
          <button
            type="submit"
            disabled={loading}
            className={`${loading ? "disabled_btn" : ""}`}
          >
            {loading ? t("contactUs.submiting") : t("contactUs.submit")}
          </button>
        )}
        {error && <div className="error_msg">{error}</div>}
        {open && (
          <PopUp
            setOpen={setOpen}
            body={
              <div className="home_popup_body">
                Message Received Successfuly <br /> We Will Get Back To You !{" "}
              </div>
            }
          />
        )}
      </form>
    </div>
  );
}

export default ContactUs;
