import React from "react";
import { useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import "./CreateOpp_Step3.css";
import i18n from "../../../../i18n";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { editOpportunity, getOpportunitiesById } from "../../../../Redux/opportunities/opportunityActions";
import Select from "react-select";
import { BiSave } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import CreateOpp_Step4 from "../Step4/CreateOpp_Step4";
import CreateOpp_Step2 from "../Step2/CreateOpp_Step2";

function CreateOpp_Step3({
  id,
  setOpen,
  setFooterWidth,
  community,
  status,
}) {
  const [openStep4, setOpenStep4] = useState(false);
  const [error, setError] = useState(null);
  const [volNb, setVolNb] = useState();

  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);

  const navigate = useNavigate();
  const size = useWindowSize();
  const dispatch = useDispatch();
  const [previousPage, setPreviousPage] = useState(false);
  const [isNext, setIsNext] = useState(true);
  const {
    oppDetails,
  } = useSelector((store) => store.opportunitiesReducer);

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });


    useEffect(() => {
      if (id) {
        dispatch(getOpportunitiesById(id));
      }
    }, []);

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  useEffect(() => {
    setFooterWidth("25%");
  }, []);

  const [occupation, setOccupation] = useState("");
  const [occupation1, setOccupation1] = useState("");
  const [selectedOccupations, setSelectedOccupations] = useState([]);
  const allOcupations = [
    {
      value: "Unoccupied",
      label: lang == "en" ? "Unoccupied" : "شاغر",
    },
    {
      value: "School Student",
      label: lang == "en" ? "School Student" : "طالب مدرسة",
    },

    {
      value: "University Student",
      label: lang == "en" ? "University Student" : "طالب جامعي",
    },
    {
      value: "Employee",
      label: lang == "en" ? "Employee" : "موظف",
    },
    {
      value: "Self Employed",
      label: lang == "en" ? "Self Employed" : "موطف لحسابي الخاص",
    },
  ];

  const [frequency, setFrequency] = useState();

  const allFrequencies = [
    {
      value: "Any",
      label: lang == "en" ? "Any" : "أيما",
    },
    {
      value: "Daily",
      label: lang == "en" ? "Daily" : "يومي",
    },
    {
      value: "Weekly",
      label: lang == "en" ? "Weekly" : "اسبوعي",
    },
    {
      value: "Monthly",
      label: lang == "en" ? "Monthly" : "شهري",
    },
    {
      value: "Bi-Weekly",
      label: lang == "en" ? "Bi-Weekly" : "نصف أسبوعي",
    },
    {
      value: "Bi-Monthly",
      label: lang == "en" ? "Bi-Monthly" : "نصف شهري",
    },
    {
      value: "One Time Only",
      label: lang == "en" ? "One Time Only" : "مرة واحدة فقط",
    },
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    if (occupation && frequency && volNb > 0) {
      const opp = {
        occupation: occupation,
        occupation1: occupation1,
        frequency: frequency.value,
        nb_of_spots: parseInt(volNb),
      };
      if (community) {
        dispatch(editOpportunity(id, opp, community));
      } else dispatch(editOpportunity(id, opp));
      if (isNext) {
        setOpenStep4(true);
      } else {
        setOpen(false);
      }
    } else {
      setError(t("opportunity.create.error"));
    }
  };


  const setOccupationVal = (selectedOccupation) => {
    setOccupation("");
    setOccupation1("");
    setSelectedOccupations(selectedOccupation);
    if (selectedOccupation.length == 1) {
      setOccupation(selectedOccupation[0].value);
    } else {

      selectedOccupation?.forEach((selectedOccupationData, index) => {
        if (index == 0) {
          setOccupation(selectedOccupationData.value);
        } else {
          setOccupation1((prev) => prev + (prev ? ',' : '') + selectedOccupationData.value);
        }
      });

    }


  };


  useEffect(() => {

    if (Object.keys(oppDetails).length > 0) {
      setFrequency(
        allFrequencies.filter((s) => s.value == oppDetails.frequency)[0]
      );

      if (oppDetails.occupation) {
        setSelectedOccupations(allOcupations.filter((s) => s.value == oppDetails.occupation)[0]);

        setOccupation(
          allOcupations.filter((s) => s.value == oppDetails.occupation)[0]['value']
        );
      }

      if (oppDetails.occupation1) {
        const occupation1Array = oppDetails.occupation1.split(",");
        const selectedOptions = allOcupations.filter((occupation) =>
          occupation1Array.includes(occupation.label)
        );
        setSelectedOccupations([allOcupations.filter((s) => s.value == oppDetails.occupation)[0], ...selectedOptions]);
        setOccupation1(selectedOptions)
      }




      if (oppDetails.nb_of_spots !== null)
        setVolNb(oppDetails.nb_of_spots);
    }
  }, [oppDetails]);



  return (
    <div>
      {
        previousPage &&
        <CreateOpp_Step2
          community={community}
          id={id}
          setOpen={setOpen}
          setFooterWidth={setFooterWidth}
          status={status}
          previous={true}
        />
      }
      {openStep4 &&
        <CreateOpp_Step4
          id={id}
          community={community}
          setOpen={setOpen}
          setFooterWidth={setFooterWidth}
        />
      }

      {!previousPage && !openStep4 &&
        <form className="create_opp_step1" onSubmit={(e) => onSubmit(e)}>
          <div className="create_opp_step3_main_cont">
            {status == "Ongoing" ? (
              <></>
            ) : (
              <div className="create_opp_step1_cont">
                <div className="step1_cont_header">
                  {t("opportunity.create.occupation")}*
                </div>
                <Select
                  value={selectedOccupations}
                  className="complete_profile_select"
                  name="nationality"
                  onChange={(e) => {
                    setOccupationVal(e);
                  }}
                  placeholder={t("opportunity.create.occupation")}
                  required
                  options={allOcupations}
                  isMulti={true}
                />
              </div>
            )}
            <div className="create_opp_step1_cont">
              <div className="step1_cont_header">
                {t("opportunity.create.frequency")}*
              </div>
              <Select
                value={frequency && frequency}
                className="complete_profile_select"
                name="nationality"
                onChange={setFrequency}
                placeholder={t("opportunity.create.frequency")}
                required
                options={allFrequencies}
              />
            </div>
            <div className="create_opp_step1_cont">
              <div className="step1_cont_header">
                {t("opportunity.create.number_vol")}*
              </div>
              <input
                type="number"
                className="create_opp_input"
                min="1"
                required
                value={volNb}
                onChange={(e) => { setVolNb(e.target.value) }}
                placeholder={t("opportunity.create.number_of_vol")}
              />
              {error && <div className="error_msg">{error}</div>}
            </div>
          </div>
          <div className="complete_step1_footer">
            <div className="divider_line"></div>
            <div className="complete_step_footer_button">
              <button
                className={
                  size.width > 768
                    ? "primary_button complete_step_1_next_button"
                    : "primary_button complete_step_2_next_button_resp"
                }
                onClick={(e) => { e.preventDefault(); setPreviousPage(true); }}
              >
                {size.width > 768 ? (
                  t("CompleteProf.complete_your_profile_btn_5")
                ) : lang == "en" ? (
                  <AiOutlineArrowLeft size="1.5rem" />
                ) : (
                  <AiOutlineArrowRight size="1.5rem" />
                )}
              </button>

              <div
                className={
                  size.width > 768
                    ? "complete_step_right_footer"
                    : "complete_step_right_footer_resp"
                }
              >
                <button
                  className={
                    size.width > 500
                      ? "pink_button complete_step_2_complete_button"
                      : "pink_button complete_step_2_next_button_resp"
                  }
                  onClick={() => setIsNext(false)}
                >
                  {size.width > 500 ? (
                    t("CompleteProf.complete_your_profile_btn_3")
                  ) : (
                    <BiSave size="1.5rem" />
                  )}
                </button>
                <button
                  className={
                    size.width > 768
                      ? "primary_button complete_step_2_next_button"
                      : "primary_button complete_step_2_next_button_resp"
                  }
                  onClick={() => setIsNext(true)}
                >
                  {size.width > 768 ? (
                    t("CompleteProf.complete_your_profile_btn_4")
                  ) : lang == "en" ? (
                    <AiOutlineArrowRight size="1.5rem" />
                  ) : (
                    <AiOutlineArrowLeft size="1.5rem" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>

      }
    </div>
  );
}

export default CreateOpp_Step3;
