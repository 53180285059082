import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Quizz: [],
  Answers: [],
  loading: false,
  error: "",
  opprec: [],
};

const QuizSlice = createSlice({
  name: "Quiz",
  initialState,
  reducers: {
    fetchQuizRequest(state) {
      state.loading = true;
      state.Quizz = [];
      state.error = "";
    },

    fetchQuizSuccess(state, action) {
      state.loading = false;
      state.Quizz = action.payload;
      state.error = "";
    },

    fetchQuizFail(state, action) {
      state.loading = false;
      state.Quizz = [];
      state.error = action.payload;
    },
    fetchQuiz_answersbyid(state) {
      state.loading = true;
      state.Answers = [];
      state.error = "";
    },
    fetchQuiz_answersbyidSucess(state, action) {
      state.loading = false;
      state.Answers = action.payload;
      state.error = "";
    },
    fetchQuiz_answersbyidFail(state, action) {
      state.loading = false;
      state.Answers = [];
      state.error = action.payload;
    },
    fetchQuiz_answersbyidandtype(state) {
      state.loading = true;
      state.opprec = [];
      state.error = "";
    },
    fetchQuiz_answersbyidandtypeSucess(state, action) {
      state.loading = false;
      state.opprec = action.payload;
      state.error = "";
    },

    fetchQuiz_answersbyidandtypeFail(state, action) {
      state.loading = false;
      state.opprec = [];
      state.error = action.payload;
    },
  },
});

export const QuizAction = QuizSlice.actions;
export default QuizSlice;
