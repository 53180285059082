import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  ImgLoading: false,
  ImgError: "",
  ImgSuccess: "",
  error: "",
  userinfo: [],
  user: [],
  sectorLoading: false,
  sectorError: "",
  sectors: [],
  idinfo: [],
  useridinfo: [],
  dataidinfo: [],
  points: [],
  status: 200,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    fetchuserinfoRequest(state) {
      state.loading = true;
      state.userinfo = [];
      state.user = [];
      state.idinfo = [];
      state.error = "";
    },

    fetchuserinfoSuccess(state, action) {
      state.loading = false;
      state.userinfo = action.payload.info;
      state.user = action.payload.user;
      state.idinfo = action.payload;
      state.error = "";
    },

    fetchuserinfoFail(state, action) {
      state.loading = false;
      state.userinfo = [];
      state.user = [];
      state.idinfo = [];
      state.error = action.payload;
    },

    updateUserRequest(state, action) {
      state.loading = true;
      state.error = "";
    },

    updateUserSuccess(state, action) {
      state.loading = true;
      state.userinfo = action.payload;
      state.error = "";
    },
    updateUserinfoFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updateUserStatus(state, action) {
      state.loading = false;
      state.status = action.payload;
    },
    updateUserImgRequest(state) {
      state.ImgLoading = true;
      state.ImgError = "";
      state.ImgSuccess = "";
    },
    updateUserImgFail(state) {
      state.ImgLoading = false;
      state.ImgError = "";
      state.ImgSuccess = "Success";
    },
    updateUserImgSuccess(state, action) {
      state.ImgLoading = false;
      state.ImgError = action.payload;
      state.ImgSuccess = "";
    },
    getSectorsofOrgRequest(state) {
      state.sectorLoading = true;
    },
    getSectorsofOrgSuccess(state, action) {
      state.sectorLoading = false;
      state.sectorError = "";
      state.sectors = action.payload;
    },
    getSectorsofOrgFail(state, action) {
      state.sectorLoading = false;
      state.sectorError = action.payload;
    },
    fetchuserinfobyidRequest(state) {
      state.loading = true;
      state.idinfo = [];
      state.useridinfo = [];
      state.useridinfo = [];
      state.error = "";
    },
    fetchuserinfobyidSuccess(state, action) {
      state.loading = false;
      state.idinfo = action.payload.data;
      state.useridinfo = action.payload.sector;
      state.dataidinfo = action.payload.user;
      state.error = "";
    },
    fetchuserinfobyidFail(state, action) {
      state.loading = false;
      state.idinfo = [];
      state.useridinfo = [];
      state.dataidinfo = [];
      state.error = action.payload;
    },
    fetchpointsRequest(state) {
      state.loading = true;
      state.points = [];
      state.error = "";
    },
    fetchpointsSuccess(state, action) {
      state.loading = false;
      state.points = action.payload;
      state.error = "";
    },
    fetchpointsFail(state, action) {
      state.loading = false;
      state.points = [];
      state.error = action.payload;
    },
  },
});

export const userAction = userSlice.actions;
export default userSlice;
