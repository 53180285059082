import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  info: [],
  loading: false,
  error: "",
  subscriptions: [],
};
const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    subscriptionsRequest(state) {
      state.loading = true;
      state.subscriptions = [];
      state.error = "";
    },
    subscriptionsSuccess(state, action) {
      state.loading = false;
      state.subscriptions = action.payload;
      state.error = "";
    },
    subscriptionsFail(state, action) {
      state.loading = false;
      state.subscriptions = [];
      state.error = action.payload;
    },
  },
});

export const subscriptionsAction = subscriptionsSlice.actions;
export default subscriptionsSlice;
