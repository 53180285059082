import axios from "axios";
import { partnersAction } from "./PartnersReducers";

export const getPartners = () => async (dispatch) => {
  dispatch(partnersAction.fetchPartnersRequest());
  try {
    let response = await axios.get(process.env.REACT_APP_API + "/partners");
    dispatch(partnersAction.fetchPartnersSuccess(response.data.data));
  } catch (error) {
    dispatch(partnersAction.fetchPartnersFail(error.message));
  }
};
