import React, { useEffect, useState } from "react";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import "./ProfileHeader.css";

function Profile_header() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const navigate = useNavigate();
  const location = useLocation();
  const userType = 0;

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  return (
    <div className="blog_green_header">
      <div className="blog_green_header_container">
        <div>
          <h1 className="profile_title font-bold font-50 white">
            {" "}
            {t("myProfile.My_Profile")}
          </h1>
          <div className="sub_menu">
            {userType !== 1 && (
              <>
                <div
                  className={
                    location.pathname == "/profile-status"
                      ? "uppercase font-bold pointer white"
                      : "uppercase grey font-bold pointer"
                  }
                >
                  <div
                    onClick={() => navigate("/profile-status")}
                    className={
                      location.pathname == "/profile-status"
                        ? ""
                        : "disabled-header-button"
                    }
                  >
                    {t("myProfile.profile_status")}
                  </div>
                </div>
                <div
                  className="grey_vertical_border"
                  style={{ margin: "0 20px" }}
                ></div>
                <div className="dotter_spacer"></div>
              </>
            )}
            <div
              className={
                location.pathname == "/profile-info"
                  ? "uppercase font-bold pointer white"
                  : "uppercase grey font-bold pointer"
              }
            >
              <div
                onClick={() => navigate("/profile-info")}
                className={
                  location.pathname == "/profile-info"
                    ? ""
                    : "disabled-header-button"
                }
              >
                {t("myProfile.Profile_info")}
              </div>
            </div>
            {userType !== 1 && (
              <>
                <div
                  className="grey_vertical_border"
                  style={{ margin: "0 20px" }}
                ></div>
                <div className="dotter_spacer"></div>
                <div
                  className={
                    location.pathname == "/evaluation-certificates"
                      ? "uppercase font-bold pointer white"
                      : "uppercase grey font-bold pointer"
                  }
                >
                  <div
                    onClick={() => navigate("/evaluation-certificates")}
                    className={
                      location.pathname == "/evaluation-certificates"
                        ? ""
                        : "disabled-header-button"
                    }
                  >
                    {t("myProfile.Profile_ev")}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Profile_header;
