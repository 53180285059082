import { PaymentElement } from "@stripe/react-stripe-js";
import React, { useState, useEffect } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/completion`,
        redirect: "if_required",
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      if (props?.masterClassIds) {
        dispatch(
          props.onSuccessPayment(props.paymentId, props?.masterClassIds)
        );
      } else {
        dispatch(props.onSuccessPayment(props.paymentId));
      }
      props.setOpenStripPopUpForm(false);
      // Handle a successful payment
      props.setOpenSuccessMsg(true);
    }

    setIsProcessing(false);
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        <button
          disabled={isProcessing || !stripe || !elements}
          id="submit"
          style={{ marginTop: "10px" }}
        >
          <span id="button-text">
            {isProcessing
              ? "Processing ... "
              : "Proceed to benefit from your subscription!"}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
        {/* {successPopUp && (
          <Popup setOpen={setSuccessPopUp} body={<h1>Thank you! 🎉</h1>} />
        )} */}
      </form>
    </>
  );
}
