import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  reasons: [],
};
const ReasonsSlice = createSlice({
  name: "Reasons",
  initialState,
  reducers: {
    reasonsRequest(state) {
      state.loading = true;
      state.reasons = [];
      state.error = "";
    },
    reasonsSuccess(state, action) {
      state.loading = false;
      state.reasons = action.payload;
      state.error = "";
    },
    reasonsFail(state, action) {
      state.loading = false;
      state.reasons = [];
      state.error = action.payload;
    },

  },
});

export const ReasonsAction = ReasonsSlice.actions;
export default ReasonsSlice;
