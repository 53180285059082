import React from "react";
import { useState } from "react";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineClose,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import "./CreateOpp_Step10.css";
import { getimpactsbyorgid } from "../../../../Redux/opportunities/opportunityActions";

import i18n from "../../../../i18n";
import { useSSR, useTranslation } from "react-i18next";

import { useEffect } from "react";

import { BiSave } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import CreateOpp_Step9 from "../Step9/CreateOpp_Step9";
import CreateOpp_Step11 from "../Step11/CreateOpp_Step11";
import axios from "axios";
import Goals from "../../../../components/Goals/Goals";

function CreateOpp_Step10({
  id,
  setOpen,
  setFooterWidth,
  community,
}) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [openStep11, setOpenStep11] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [allImpacts, setAllImpacts] = useState();
  const [selectedImpacts, setSelectedImpacts] = useState([]);
  const [previousPage, setPreviousPage] = useState(false);
  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const size = useWindowSize();
  const dispatch = useDispatch();
  const { impacts , loading } = useSelector((store) => store.opportunitiesReducer);

  const [isNext, setIsNext] = useState(true);
 

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  const getImpacts = async () => {
    const response = await axios.get(
      process.env.REACT_APP_API + "/impacts/getAll"
    );

    setAllImpacts(response.data.Impacts);
  };

  useEffect(() => {
    getImpacts();
    dispatch(getimpactsbyorgid(id));
    setFooterWidth("83.3%");
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    if (selectedImpacts.length > 0) {
      const opp = {
        opportunity_id: id,
        impact_id: selectedImpacts,
      };
      const response = axios.post(
        process.env.REACT_APP_API + "/ImpactOpportunity/create",
        opp
      );
      if (isNext) {
        setOpenStep11(true);
      } else {
        setOpen(false);
      }
    } else {
      setError(t("opportunity.create.error"));
    }
  };

  const addImpact = (id) => {
    var newIds = [];

    if (selectedImpacts.includes(id)) {
      newIds = [...selectedImpacts];
      newIds = newIds.filter((s) => s !== id);
    } else {
      newIds = [...selectedImpacts];
      newIds.push(id);
    }
    setSelectedImpacts(newIds);
  };
  useEffect(() => {
    if (impacts.length > 0) {
      setSelectedImpacts([])
      impacts.map((impact) => {
        setSelectedImpacts((selectedImpacts) => [
          ...selectedImpacts,
          impact.impact_id,
        ]);
      });
    }
  }, [impacts]);


  return (
    <div>
      {
        previousPage ? (
          <CreateOpp_Step9
            id={id}
            community={community}
            setOpen={setOpen}
            setFooterWidth={setFooterWidth}
            previous={true}
          />
        ):( openStep11 ? (
          <CreateOpp_Step11
            id={id}
            setOpen={setOpen}
            community={community}
            setFooterWidth={setFooterWidth}
          />
        ) : (
          <form className="create_opp_step1" onSubmit={(e) => onSubmit(e)}>
            <div className="create_opp_step7_main_cont">
              <div className="create_opp_step1_cont">
                <div className="step1_cont_header">
                  {t("opportunity.create.impact_header")}*
                </div>
                <div className="goals_list">
                  {allImpacts && 
                    allImpacts?.map((impact, i) => {
                      return (
                        <Goals
                          name={lang == "en" ? impact.title : impact.title_ar}
                          image={impact.img_url}
                          onClick={addImpact}
                          id={impact.id}
                          selectedImpacts={selectedImpacts}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
            {error && <div className="error_msg">{error}</div>}

            <div className="complete_step1_footer">
              <div className="divider_line"></div>
              <div className="complete_step_footer_button">
                <button
                  className={
                    size.width > 768
                      ? "primary_button complete_step_1_next_button"
                      : "primary_button complete_step_2_next_button_resp"
                  }
                  onClick={(e) => { e.preventDefault(); setPreviousPage(true); }}
                >
                  {size.width > 768 ? (
                    t("CompleteProf.complete_your_profile_btn_5")
                  ) : lang == "en" ? (
                    <AiOutlineArrowLeft size="1.5rem" />
                  ) : (
                    <AiOutlineArrowRight size="1.5rem" />
                  )}
                </button>

                <div
                  className={
                    size.width > 768
                      ? "complete_step_right_footer"
                      : "complete_step_right_footer_resp"
                  }
                >
                  <button
                    className={
                      size.width > 500
                        ? "pink_button complete_step_2_complete_button"
                        : "pink_button complete_step_2_next_button_resp"
                    }
                    onClick={() => setIsNext(false)}
                  >
                    {size.width > 500 ? (
                      t("CompleteProf.complete_your_profile_btn_3")
                    ) : (
                      <BiSave size="1.5rem" />
                    )}
                  </button>
                  <button
                    className={
                      size.width > 768
                        ? "primary_button complete_step_2_next_button"
                        : "primary_button complete_step_2_next_button_resp"
                    }
                    onClick={() => setIsNext(true)}
                  >
                    {size.width > 768 ? (
                      t("CompleteProf.complete_your_profile_btn_4")
                    ) : lang == "en" ? (
                      <AiOutlineArrowRight size="1.5rem" />
                    ) : (
                      <AiOutlineArrowLeft size="1.5rem" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        ))
      }
    </div>
  );
}

export default CreateOpp_Step10;
