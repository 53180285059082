import React from "react";
import "../Home/Greencarousell.css";
import CarouselMulti from "react-multi-carousel";
import quote from "../../assets/images/Home/quote.png";

function Greencarousell(props) {
  return (
    <div className="Main_green_carousell">
      <div className="green_carousell_contents">
        <img
          src={quote}
          alt="quote"
          className="quote-img-green"
          draggable="false"
        />
        <div className="green_carousell_texts">{props.greentexts}</div>
        <div className="green_carousell_subs">{props.greensubs}</div>
      </div>
    </div>
  );
}

export default Greencarousell;
