import axios from "axios";
import { testimonialAction } from "../testimonials/testimonialsReducers";

export const getTestimonials = () => async (dispatch) => {
  dispatch(testimonialAction.fetchTestimonialsRequest());
  try {
    let response = await axios.get(process.env.REACT_APP_API + "/testimonial");
    dispatch(testimonialAction.fetchTestimonialsSuccess(response.data));
  } catch (error) {
    dispatch(testimonialAction.fetchTestimonialsFail(error.message));
  }
};
