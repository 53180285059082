import React from "react";
import { useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import "./CreateOpp_Step2.css";
import CreateOpp_Pop from "../../CreateOpp_Pop/CreateOpp_Pop";
import i18n from "../../../../i18n";
import { useTranslation } from "react-i18next";
import PopUp from "../../../../components/PopUp/PopUp";
import { useEffect } from "react";
import {
  createOpportunity,
  editOpportunity,
  getOpportunitiesById
} from "../../../../Redux/opportunities/opportunityActions";
import axios from "axios";
import Select from "react-select";
import { BiSave } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import CreateOpp_Step1 from "../Step1/CreateOpp_Step1";
import CreateOpp_Step3 from "../Step3/CreateOpp_Step3";

function CreateOpp_Step2({
  id,
  setOpen,
  setFooterWidth,
  community,
  status,
  previous
}) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);

  const [country, setCountry] = useState();
  const [districts, setDistricts] = useState();
  const [governate, setGovernate] = useState();

  const [selectedGov, setSelectedGov] = useState();
  const [selectedCount, setSelectedCount] = useState();
  const [selectedDis, setSelectedDis] = useState();
  const [openStep3, setOpenStep3] = useState(false);
  const [error, setError] = useState(null);
  const [counter, setCounter] = useState(0);
  const navigate = useNavigate();
  const [ongoing, setongoing] = useState(false);

  const {
    oppDetails,
    gender,
  } = useSelector((store) => store.opportunitiesReducer);
  const [previousPage, setPreviousPage] = useState(false);
  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);
  useEffect(() => {
    if (status === "Ongoing") {
      setongoing(true);
    } else {
      setongoing(false);
    }
  }, [status]);

  const size = useWindowSize();
  const dispatch = useDispatch();
  const [selectedGender, setSelectedGender] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [isNext, setIsNext] = useState(true);


  useEffect(() => {
    if (id) {
      dispatch(getOpportunitiesById(id));
    }
  }, []);




  const allGender = [
    {
      id: 1,
      value: "Male",
      label: "Male",
    },
    {
      id: 2,
      value: "Female",
      label: "Female",
    },
    {
      id: 3,
      value: "Non-Binary",
      label: "Non-Binary",
    },
  ];
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  const getCountry = async () => {
    const response = await axios.get(process.env.REACT_APP_API + "/countries");

    const newCount = response.data.data.map((d, i) => {
      return { value: d.id, label: lang == "en" ? d.name : d.name_ar };
    });
    setCountry(newCount);
  };
  const getGov = async (id) => {
    const response = await axios.get(process.env.REACT_APP_API + "/governates");

    const newGov = response.data.data.map((d, i) => {
      return (
        d.country_id == id && {
          value: d.id,
          label: lang == "en" ? d.name : d.name_ar,
        }
      );
    });

    setGovernate(newGov.filter((s) => s));
  };

  const getDis = async (id) => {
    const response = await axios.get(process.env.REACT_APP_API + "/districts");

    const newDis = response.data.data.map((d, i) => {
      return (
        d.governate.id == id && {
          value: d.id,
          label: lang == "en" ? d.name : d.name_ar,
        }
      );
    });
    setDistricts(newDis.filter((s) => s));
  };

  useEffect(() => {
    getCountry();
    setSelectedValues([]);
    setFooterWidth("16.66%");
  }, []);

  useEffect(() => {
    setCounter(counter + 1);
    getGov(selectedCount?.value);

    if (
      counter == 1 &&
      oppDetails?.district?.governate?.country?.id != selectedCount?.value
    ) {
      setSelectedGov("");
      setSelectedDis("");
    }

    if (counter > 1) {
      setSelectedGov("");
      setSelectedDis("");
    }
  }, [selectedCount]);

  useEffect(() => {
    getDis(selectedGov?.value);
    if (
      counter == 1 &&
      oppDetails?.district?.governate?.id != selectedGov?.value
    ) {
      setSelectedDis("");
    }

    if (counter > 1) {
      setSelectedDis("");
    }
  }, [selectedGov]);

  const handleEvent = (e, gender) => {
    if (e.target.checked) {
      setSelectedGender((element) => [...element, gender]);
      if (!selectedValues.includes(gender.value)) {
        setSelectedValues((prevSelectedValues) => [...prevSelectedValues, gender.value]);
      }
    } else {
      setSelectedGender((element) => element.filter((item) => item.id !== gender.id));
      setSelectedValues((prevSelectedValues) => prevSelectedValues.filter((id) => id !== gender.value));
    }
  };
  

  const onSubmit = (e) => {
    e.preventDefault();

    var gender = [];
    selectedGender.map((element) => {
      gender.push(element.value);
    });
    if (selectedGov && selectedCount && selectedDis && gender.length > 0) {
      const opp = {
        district_id: selectedDis.value,
        genders_id: gender,
      };
      if (community) {
        dispatch(editOpportunity(id, opp, community));
      } else dispatch(editOpportunity(id, opp));

      if (isNext) {
        setOpenStep3(true);
      } else {
        setOpen(false);
      }
    } else {
      setError(t("opportunity.create.error"));
    }

  };

  useEffect(() => {
    if (
      oppDetails?.district?.id &&
      oppDetails?.district?.governate?.country?.id &&
      oppDetails?.district?.governate?.id
    ) {
      var newDis = {
        value: oppDetails.district.id,
        label:
          lang == "en" ? oppDetails.district.name : oppDetails.district.name_ar,
      };

      setSelectedDis(newDis);

      var newC = {
        value: oppDetails.district.governate.country.id,
        label:
          lang == "en"
            ? oppDetails.district.governate.country.name
            : oppDetails.district.governate.country.name_ar,
      };

      setSelectedCount(newC);

      var newGov = {
        value: oppDetails.district.governate.id,
        label:
          lang == "en"
            ? oppDetails.district.governate.name
            : oppDetails.district.governate.name_ar,
      };

      setSelectedGov(newGov);
      setCounter(1);

      var newG = [];
      
      gender.map((a, i) => {
        newG.push(allGender?.filter((s) => s.value == a.gender)[0]);
        if (a  && !selectedValues.includes(a.gender)) {
          setSelectedValues((prevSelectedValues) => [...prevSelectedValues, a.gender]);
        }
      });
      setSelectedGender(newG.filter((s) => s));
      
    }
  }, [oppDetails]);


  return (
    <div>

      {previousPage &&
        <CreateOpp_Step1
          id={id}
          community={community}
          setOpen={setOpen}
          status={status}
          edit={true}
          previous={true}
          setFooterWidth={setFooterWidth}
        />

      }

      {openStep3 &&
        <CreateOpp_Step3
          id={id}
          community={community}
          setOpen={setOpen}
          setFooterWidth={setFooterWidth}
        />
      }
      {!openStep3 && !previousPage &&
        <form className="create_opp_step1" onSubmit={(e) => onSubmit(e)}>
          <div className="create_opp_step2_main_cont">
            <div className="create_opp_step1_cont">
              <div className="step1_cont_header">
                {t("opportunity.create.gender")}*
                <p className="create_opp_note_dark">
                  {" "}
                  {t("opportunity.create.multiple")}
                </p>
              </div>
              <div className="create_opp_checkbox_main">
                {allGender.map((gender, index) => {
                  return (
                    <div className="step2_create_opp">
                      <input
                        type="checkbox"
                        id={"create_opp_check" + gender.id}
                        name={gender.value}
                        value={gender.value}
                        className="create_opp_check"
                        onChange={(e) => handleEvent(e, gender)}
                        checked={selectedValues.includes(gender.value) ? true :false}
                      />
                      <div className="step2_create_opp_info">
                        <label
                          className="step2_create_opp_name"
                          for={gender.value}
                        >
                          {gender.label}
                        </label>
                      </div>
                    </div>
                  );
                })}


              </div>
            </div>
            {ongoing ? (
              <></>
            ) : (
              <div className="create_opp_step1_cont">
                <div className="step1_cont_header">
                  {t("opportunity.create.location")}*
                  <p className="create_opp_note_dark">
                    {" "}
                    {t("opportunity.create.precise_loc")}
                  </p>
                </div>
                <Select
                  value={selectedCount && selectedCount}
                  className="complete_profile_select"
                  name="nationality"
                  onChange={setSelectedCount}
                  placeholder={t("opportunity.create.country")}
                  required
                  options={country}
                />
                {selectedCount && governate && (
                  <Select
                    value={selectedGov && selectedGov}
                    className="complete_profile_select"
                    name="nationality"
                    onChange={setSelectedGov}
                    placeholder={t("opportunity.create.governate")}
                    required
                    options={governate}
                  />
                )}
                {selectedGov && districts && (
                  <Select
                    value={selectedDis && selectedDis}
                    className="complete_profile_select"
                    name="nationality"
                    onChange={setSelectedDis}
                    placeholder={t("opportunity.create.district")}
                    required
                    options={districts}
                  />
                )}
              </div>
            )}
          </div>
          {error && <div className="error_msg">{error}</div>}

          <div className="complete_step1_footer">
            <div className="divider_line"></div>
            <div className="complete_step_footer_button">
              <button
                className={
                  size.width > 768
                    ? "primary_button complete_step_1_next_button"
                    : "primary_button complete_step_2_next_button_resp"
                }
                onClick={(e) => { e.preventDefault(); setPreviousPage(true); }}
              >
                {size.width > 768 ? (
                  t("CompleteProf.complete_your_profile_btn_5")
                ) : lang == "en" ? (
                  <AiOutlineArrowLeft size="1.5rem" />
                ) : (
                  <AiOutlineArrowRight size="1.5rem" />
                )}
              </button>

              <div
                className={
                  size.width > 768
                    ? "complete_step_right_footer"
                    : "complete_step_right_footer_resp"
                }
              >
                <button
                  className={
                    size.width > 500
                      ? "pink_button complete_step_2_complete_button"
                      : "pink_button complete_step_2_next_button_resp"
                  }
                  onClick={() => setIsNext(false)}
                >
                  {size.width > 500 ? (
                    t("CompleteProf.complete_your_profile_btn_3")
                  ) : (
                    <BiSave size="1.5rem" />
                  )}
                </button>
                <button
                  className={
                    size.width > 768
                      ? "primary_button complete_step_2_next_button"
                      : "primary_button complete_step_2_next_button_resp"
                  }
                  onClick={() => setIsNext(true)}
                >
                  {size.width > 768 ? (
                    t("CompleteProf.complete_your_profile_btn_4")
                  ) : lang == "en" ? (
                    <AiOutlineArrowRight size="1.5rem" />
                  ) : (
                    <AiOutlineArrowLeft size="1.5rem" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>

      }
    </div>
  );
}

export default CreateOpp_Step2;
