import React, { useEffect, useState } from "react";
import "./MyOpportunities.css";
//translation
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getuserinfo } from "../../../Redux/user/userActions";
import {
  getOpportunitiesByStatus,
  getFavoritesOpportunities,
} from "../../../Redux/opportunities/opportunityActions";
import OppCard from "../../../components/OppCard/OppCard";
import org from "../../../assets/images/opportunity/organization.jpg";

function MyOpportunities() {
  //translation
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [activeBtn, setActiveBtn] = useState("pending");
  const { user } = useSelector((store) => store.userReducer);
  const { opportunities } = useSelector((store) => store.opportunitiesReducer);
  const dispatch = useDispatch();
  const [userType, setUserType] = useState(0);
  const flags = ["food", "handicap"];

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    dispatch(getuserinfo());
    dispatch(getOpportunitiesByStatus("pending"));
  }, []);

  useEffect(() => {
    if (user != undefined) {
      setUserType(user.role);
    }
  }, [user]);

  const handleGetOpportunitiesByStatus = (status) => {
    dispatch(getOpportunitiesByStatus(status));
  };

  useEffect(() => {}, [opportunities]);

  return (
    <div className="opp_main_cont">
      <div className="blog_green_header"></div>
      <div
        className={
          lang == "ar"
            ? "my_opportunities_status_container_ar"
            : "my_opportunities_status_container"
        }
      >
        <div className="opportunities_status">
          <button
            className={
              activeBtn == "pending"
                ? "opportunities_status_btn opportunities_status_btn_active"
                : "opportunities_status_btn"
            }
            onClick={() => {
              setActiveBtn("pending");
              handleGetOpportunitiesByStatus("pending");
            }}
          >
            {t("opportunity.pending")}
          </button>
          <button
            className={
              activeBtn == "confirmed"
                ? "opportunities_status_btn opportunities_status_btn_active"
                : "opportunities_status_btn"
            }
            onClick={() => {
              setActiveBtn("confirmed");
              handleGetOpportunitiesByStatus("Accepted");
            }}
          >
            {t("opportunity.confirmed")}
          </button>
          <button
            className={
              activeBtn == "saved"
                ? "opportunities_status_btn opportunities_status_btn_active"
                : "opportunities_status_btn"
            }
            onClick={() => {
              setActiveBtn("saved");
              dispatch(getFavoritesOpportunities());
            }}
          >
            {t("opportunity.saved")}
          </button>
          <button
            className={
              activeBtn == "history"
                ? "opportunities_status_btn opportunities_status_btn_active"
                : "opportunities_status_btn"
            }
            onClick={() => {
              setActiveBtn("history");
              handleGetOpportunitiesByStatus("History");
            }}
          >
            {t("opportunity.history")}
          </button>
          <button
            className={
              activeBtn == "check_in_out"
                ? "opportunities_status_btn opportunities_status_btn_active"
                : "opportunities_status_btn"
            }
            onClick={() => {
              setActiveBtn("check_in_out");
              handleGetOpportunitiesByStatus("Checked-in");
            }}
          >
            {t("opportunity.check_in_out")}
          </button>
        </div>
      </div>

      <div className="my_opportunities_container">
        <div className="all_opportunities">
          {opportunities &&
            opportunities != [] &&
            opportunities?.length > 0 &&
            opportunities?.map((oppprtunityData, index) => {
              if (activeBtn != "saved") {
                return (
                  <OppCard
                    flags={flags}
                    image={
                      oppprtunityData.opportunity?.img_url != undefined
                        ? process.env.REACT_APP_API +
                          "/Organization/" +
                          oppprtunityData.opportunity?.img_url
                        : org
                    }
                    title={oppprtunityData.opportunity.title}
                    respond={oppprtunityData.opportunity.respond_time}
                    location={
                      lang == "ar"
                        ? oppprtunityData.opportunity.district.name_ar
                        : oppprtunityData.opportunity.district.name
                    }
                    deadline={new Date(
                      oppprtunityData.opportunity.deadline_date
                    ).toLocaleDateString()}
                    spots={
                      oppprtunityData.opportunity.nb_of_spots -
                      oppprtunityData.opportunity.nb_of_volunteers
                    }
                    impact={oppprtunityData.opportunity.tasks_required}
                    points={
                      oppprtunityData.opportunity.type === "Time-based"
                        ? 10
                        : 20
                    }
                    status={oppprtunityData.status}
                    key={index}
                    requestId={oppprtunityData.id}
                  />
                );
              } else {
                return (
                  <OppCard
                    flags={flags}
                    image={
                      oppprtunityData?.img_url != undefined
                        ? process.env.REACT_APP_API +
                          "/Organization/" +
                          oppprtunityData?.img_url
                        : org
                    }
                    title={oppprtunityData.title}
                    respond={oppprtunityData.respond_time}
                    location={
                      lang == "ar"
                        ? oppprtunityData.name_ar
                        : oppprtunityData.name
                    }
                    deadline={new Date(
                      oppprtunityData.deadline_date
                    ).toLocaleDateString()}
                    spots={
                      oppprtunityData.nb_of_spots -
                      oppprtunityData.nb_of_volunteers
                    }
                    impact={oppprtunityData.tasks_required}
                    points={oppprtunityData.type === "Time-based" ? 10 : 20}
                    status="Saved"
                    key={index}
                  />
                );
              }
            })}
        </div>
      </div>
    </div>
  );
}
export default MyOpportunities;
