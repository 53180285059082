import React from 'react'
import { useNavigate } from 'react-router-dom'
import "./BlogCard.css"
function BlogCard(props) {
  const navigate =  useNavigate()

  return (
    <div className="blog_card_main_cont">
        <div className='blog_card_header'>
        <img src={props.image} className="blog_card_image"/>
        <div className='blog_card_date'>{props.date}</div>
        </div>

        <div className='blog_card_info'>
            <h3 className='blog_card_title'>{props.title}</h3>
            <button className='blog_card_readmore' onClick={()=>navigate("/blog/details/"+props.id)}>Read More</button>

        </div>
    </div>
  )
}

export default BlogCard