import React, { useState, useEffect, useRef } from "react";
import "../Terms and conditions/Termsandconditions.css";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import UpperSection from "../../components/UpperSection/UpperSection";

function Termsandconditions() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  return (
    <div className="Main_terms_section">
      <div className="top_band">
        {/* <div
          className={
            lang === "ar" ? "terms_title terms_title_ar" : "terms_title"
          }
        >
        </div> */}
        <UpperSection title={t("termsandconditions.termstitle")} />
      </div>
      <div className="terms_container">
        <div
          className={
            lang === "ar"
              ? "parag_container_terms parag_container_terms_ar"
              : "parag_container_terms"
          }
        >
          <div className="number_circle">1</div>
          <div
            className={
              lang === "ar" ? "text_lines text_lines_ar" : "text_lines"
            }
          >
            {t("termsandconditions.termsline1")}
          </div>
        </div>
        <div
          className={
            lang === "ar"
              ? "parag_container_terms parag_container_terms_ar"
              : "parag_container_terms"
          }
        >
          <div className="number_circle">2</div>
          <div
            className={
              lang === "ar" ? "text_lines text_lines_ar" : "text_lines"
            }
          >
            {t("termsandconditions.termsline2")}
          </div>
        </div>
        <div
          className={
            lang === "ar"
              ? "parag_container_terms parag_container_terms_ar"
              : "parag_container_terms"
          }
        >
          <div className="number_circle">3</div>
          <div
            className={
              lang === "ar" ? "text_lines text_lines_ar" : "text_lines"
            }
          >
            {t("termsandconditions.termsline3")}
          </div>
        </div>
        <div
          className={
            lang === "ar"
              ? "parag_container_terms parag_container_terms_ar"
              : "parag_container_terms"
          }
        >
          <div className="number_circle">4</div>
          <div
            className={
              lang === "ar" ? "text_lines text_lines_ar" : "text_lines"
            }
          >
            {t("termsandconditions.termsline4")}
          </div>
        </div>
        <div
          className={
            lang === "ar"
              ? "parag_container_terms parag_container_terms_ar"
              : "parag_container_terms"
          }
        >
          <div className="number_circle">5</div>
          <div
            className={
              lang === "ar" ? "text_lines text_lines_ar" : "text_lines"
            }
          >
            {t("termsandconditions.termsline5")}
          </div>
        </div>
        <div
          className={
            lang === "ar"
              ? "parag_container_terms parag_container_terms_ar"
              : "parag_container_terms"
          }
        >
          <div className="number_circle">6</div>
          <div
            className={
              lang === "ar" ? "text_lines text_lines_ar" : "text_lines"
            }
          >
            {t("termsandconditions.termsline6")}
          </div>
        </div>
        <div
          className={
            lang === "ar"
              ? "parag_container_terms parag_container_terms_ar"
              : "parag_container_terms"
          }
        >
          <div className="number_circle">7</div>
          <div
            className={
              lang === "ar" ? "text_lines text_lines_ar" : "text_lines"
            }
          >
            {t("termsandconditions.termsline7")}
          </div>
        </div>
        <div
          className={
            lang === "ar"
              ? "parag_container_terms_2 parag_container_terms_ar"
              : "parag_container_terms_2"
          }
        >
          <div className="title_terms_8_cont">
            <div className="number_circle">8</div>
            <div
              className={
                lang === "ar" ? "text_lines text_lines_ar" : "text_lines"
              }
            >
              {t("termsandconditions.termsline8")}
            </div>
          </div>
          <div className="subline_container">
            <ul className="list_ofsubs">
              <li className="subs_context">
                {t("termsandconditions.termsline8_1")}
              </li>
              <li className="subs_context">
                {t("termsandconditions.termsline8_2")}
              </li>
              <li className="subs_context">
                {t("termsandconditions.termsline8_3")}
              </li>
            </ul>
          </div>
        </div>
        <div
          className={
            lang === "ar"
              ? "parag_container_terms parag_container_terms_ar"
              : "parag_container_terms"
          }
        >
          <div className="number_circle">9</div>
          <div
            className={
              lang === "ar" ? "text_lines text_lines_ar" : "text_lines"
            }
          >
            {t("termsandconditions.termsline9")}
          </div>
        </div>
        <div
          className={
            lang === "ar"
              ? "parag_container_terms parag_container_terms_ar"
              : "parag_container_terms"
          }
        >
          <div className="number_circle">10</div>
          <div
            className={
              lang === "ar" ? "text_lines text_lines_ar" : "text_lines"
            }
          >
            {t("termsandconditions.termsline10")}
          </div>
        </div>
        <div
          className={
            lang === "ar"
              ? "parag_container_terms parag_container_terms_ar"
              : "parag_container_terms"
          }
        >
          <div className="number_circle">11</div>
          <div
            className={
              lang === "ar" ? "text_lines text_lines_ar" : "text_lines"
            }
          >
            {t("termsandconditions.termsline11")}
          </div>
        </div>

        <div
          className={
            lang === "ar"
              ? "parag_container_terms parag_container_terms_ar"
              : "parag_container_terms"
          }
        >
          <div className="number_circle">12</div>
          <div
            className={
              lang === "ar" ? "text_lines text_lines_ar" : "text_lines"
            }
          >
            {t("termsandconditions.termsline12")}
          </div>
        </div>


      </div>
    </div>
  );
}

export default Termsandconditions;
