import React from "react";
import { useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import "./CreateOpp_Step4.css";
import i18n from "../../../../i18n";
import { useSSR, useTranslation } from "react-i18next";

import { useEffect } from "react";
import { editOpportunity , getOpportunitiesById} from "../../../../Redux/opportunities/opportunityActions";

import { BiSave } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import CreateOpp_Step3 from "../Step3/CreateOpp_Step3";
import CreateOpp_Step5 from "../Step5/CreateOpp_Step5";
import Loading from "../../../../components/Loading/Loading";

function CreateOpp_Step4({
  id,
  setOpen,
  setFooterWidth,
  community,
}) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [openStep5, setOpenStep5] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [previousPage, setPreviousPage] = useState(false);
  const options = [
    {
      value: "Required",
      label: "Required",
    },

    {
      value: "Not Required",
      label: "Not Required",
    },

    {
      value: "Provided",
      label: "Provided",
    },
  ];

  const allOnline = [
    {
      value: "Yes",
      label: "Yes",
    },
    {
      value: "No",
      label: "No",
    },
    {
      value: "Both (Online and on site)",
      label: "Both (Online and on site)",
    },
  ];
  const [transporation, setTransporation] = useState();
  const [fnb, setFnb] = useState();
  const [online, setOnline] = useState();

  const {
    oppDetails,
  } = useSelector((store) => store.opportunitiesReducer);
  useEffect(() => {
    if (id) {
      dispatch(getOpportunitiesById(id));
    }
  }, []);
  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const size = useWindowSize();
  const dispatch = useDispatch();

  const [isNext, setIsNext] = useState(true);

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  useEffect(() => {
    setFooterWidth("33.5%");
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    if (online && fnb && transporation) {
      const opp = {
        transporation: transporation,
        food: fnb,
        online: online,
      };
      if (community) {
        dispatch(editOpportunity(id, opp, community));
      } else dispatch(editOpportunity(id, opp));

      if (isNext) {
        setOpenStep5(true);
      } else {
        setOpen(false);
      }
    } else {
      setError(t("opportunity.create.error"));
    }
  };

  useEffect(() => {
    if (Object.keys(oppDetails).length > 0) {
      var newT = options.filter((s) => s.value == oppDetails.transporation)[0];
      var newF = options.filter((s) => s.value == oppDetails.food)[0];
      var varO = allOnline.filter((s) => s.value == oppDetails.online)[0];

      setTransporation(newT.value);
      setFnb(newF.value);
      setOnline(varO.value);

      // document.getElementById("trans" + newT.value).checked = true;
      // document.getElementById(varO.value).checked = true;
      // document.getElementById(newF.value).checked = true;
    }
  }, [oppDetails]);

  return (
    <div>
      {
        // (loading || editLoading)  ? <Loading /> :
        previousPage ? (
          <CreateOpp_Step3
          id={id}
          community={community}
          setOpen={setOpen}
          setFooterWidth={setFooterWidth}
          previous={true}
        />
         ):( openStep5 ? (
          <CreateOpp_Step5
            id={id}
            setOpen={setOpen}
            community={community}
            setFooterWidth={setFooterWidth}
          />
        ) : (
          <form className="create_opp_step1" onSubmit={(e) => onSubmit(e)}>
            <div className="create_opp_step4_main_cont">
              <div className="create_opp_step1_cont">
                <div className="step1_cont_header">
                  {t("opportunity.create.trans")}*
                </div>
                <div className="create_opp_radio_step4">
                  {options.map((item, i) => {
                    return (
                      <div className="create_opp_radio_comp">
                        <input
                          onChange={(e) => setTransporation(e.target.value)}
                          className={
                            lang == "en"
                              ? "create_opp_radio_btn"
                              : "create_opp_radio_btn_ar"
                          }
                          type="radio"
                          id={"trans" + item.value}
                          name="trans"
                          value={item.value}
                          required
                          checked={transporation == item.value ? true:false}
                        />
                        <label
                          className="create_opp_radio_label"
                          for="trans"
                          style={{ cursor: "pointer" }}
                        >
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="create_opp_step1_cont">
                <div className="step1_cont_header">
                  {t("opportunity.create.online")}*
                </div>
                <div className="create_opp_radio_step4">
                  {allOnline.map((item, i) => {
                    return (
                      <div className="create_opp_radio_comp">
                        <input
                          onChange={(e) => setOnline(e.target.value)}
                          className={
                            lang == "en"
                              ? "create_opp_radio_btn"
                              : "create_opp_radio_btn_ar"
                          }
                          type="radio"
                          id={item.value}
                          name="online"
                          value={item.value}
                          required
                          checked={online == item.value ? true:false}
                        />
                        <label
                          className="create_opp_radio_label"
                          for="online"
                          style={{ cursor: "pointer" }}
                        >
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="create_opp_step1_cont">
                <div className="step1_cont_header">
                  {t("opportunity.create.fnb")}*
                </div>
                <div className="create_opp_radio_step4">
                  {options.map((item, i) => {
                    return (
                      <div className="create_opp_radio_comp">
                        <input
                          onChange={(e) => setFnb(e.target.value)}
                          className={
                            lang == "en"
                              ? "create_opp_radio_btn"
                              : "create_opp_radio_btn_ar"
                          }
                          type="radio"
                          id={item.value}
                          name="fnb"
                          value={item.value}
                          required
                          checked={fnb == item.value ? true:false}
                        />
                        <label
                          className="create_opp_radio_label"
                          for="fnb"
                          style={{ cursor: "pointer" }}
                        >
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {error && <div className="error_msg">{error}</div>}

            <div className="complete_step1_footer">
              <div className="divider_line"></div>
              <div className="complete_step_footer_button">
                <button
                  className={
                    size.width > 768
                      ? "primary_button complete_step_1_next_button"
                      : "primary_button complete_step_2_next_button_resp"
                  }
                  onClick={(e) =>{ e.preventDefault(); setPreviousPage(true);}}
                >
                  {size.width > 768 ? (
                    t("CompleteProf.complete_your_profile_btn_5")
                  ) : lang == "en" ? (
                    <AiOutlineArrowLeft size="1.5rem" />
                  ) : (
                    <AiOutlineArrowRight size="1.5rem" />
                  )}
                </button>

                <div
                  className={
                    size.width > 768
                      ? "complete_step_right_footer"
                      : "complete_step_right_footer_resp"
                  }
                >
                  <button
                    className={
                      size.width > 500
                        ? "pink_button complete_step_2_complete_button"
                        : "pink_button complete_step_2_next_button_resp"
                    }
                    onClick={() => setIsNext(false)}
                  >
                    {size.width > 500 ? (
                      t("CompleteProf.complete_your_profile_btn_3")
                    ) : (
                      <BiSave size="1.5rem" />
                    )}
                  </button>
                  <button
                    className={
                      size.width > 768
                        ? "primary_button complete_step_2_next_button"
                        : "primary_button complete_step_2_next_button_resp"
                    }
                    onClick={() => setIsNext(true)}
                  >
                    {size.width > 768 ? (
                      t("CompleteProf.complete_your_profile_btn_4")
                    ) : lang == "en" ? (
                      <AiOutlineArrowRight size="1.5rem" />
                    ) : (
                      <AiOutlineArrowLeft size="1.5rem" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        ))
      }
    </div>
  );
}

export default CreateOpp_Step4;
