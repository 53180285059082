import axios from "axios";
import { hearingAboutTvcAction } from "./HearingAboutTvcReducers";

export const getAllHearingAbtTvc = () => async (dispatch) => {
  dispatch(hearingAboutTvcAction.hearingAboutTvcRequest());
  try {
    let response = await axios.get(
      process.env.REACT_APP_API + "/hearing_abt_tvc"
    );
    dispatch(hearingAboutTvcAction.hearingAboutTvcSuccess(response.data.data));
  } catch (error) {
    dispatch(hearingAboutTvcAction.hearingAboutTvcFail(error.message));
  }
};

