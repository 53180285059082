import React from "react";
import { useState } from "react";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineClose,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import "./CreateOpp_Step8.css";
import Select from "react-select";

import i18n from "../../../../i18n";
import { useSSR, useTranslation } from "react-i18next";
import { useEffect } from "react";
import { BiSave } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { editOpportunity, getOpportunitiesById } from "../../../../Redux/opportunities/opportunityActions";
import CreateOpp_Step7 from "../Step7/CreateOpp_Step7";
import CreateOpp_Step9 from "../Step9/CreateOpp_Step9";
import axios from "axios";
function CreateOpp_Step8({
  id,
  setOpen,
  setFooterWidth,
  community,
}) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [openStep9, setOpenStep9] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [previousPage, setPreviousPage] = useState(false);
  const [allExp, setAllExp] = useState([]);

  const {
    oppDetails,
  } = useSelector((store) => store.opportunitiesReducer);
  useEffect(() => {
    if (id) {
      dispatch(getOpportunitiesById(id));
    }
  }, []);
  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const size = useWindowSize();
  const dispatch = useDispatch();

  const [isNext, setIsNext] = useState(true);

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }
  const [skills, setSkills] = useState([]);
  const [expertises, setExpertises] = useState([]);
  const [allSkills, setAllSkills] = useState([]);

  const setSelectedSkills = (event) => {
    if (event.length > 0) {
      var found = false;
      if (skills.length > 0)
        for (var i = 0; i < skills.length; i++) {
          if (event[0].value == skills[i].value) {
            found = true;
          }
        }
      if (!found) setSkills((skill) => [...skill, event[0]]);
    }
  };

  const setSelectedExpertises = (event) => {
    if (event.length > 0) {
      var found = false;
      if (expertises.length > 0)
        for (var i = 0; i < expertises.length; i++) {
          if (event[0].value == expertises[i].value) {
            found = true;
          }
        }
      if (!found) setExpertises((expertise) => [...expertise, event[0]]);
    }
  };


  const removeSkill = (index) => {
    const newfields = [...skills];
    newfields.splice(index, 1);
    setSkills(newfields);
  };

  const removeExpertise = (index) => {
    const newfields = [...expertises];
    newfields.splice(index, 1);
    setExpertises(newfields);
  };


  useEffect(() => {
    setFooterWidth("66.64%");
    getSkills();
    getExp();
  }, []);

  const getSkills = async () => {
    const response = await axios.get(process.env.REACT_APP_API + "/skills");
    const newS = [];
    response.data.data.map((d, i) => {
      if (d.type == "Soft skills" || d.type == "technical skills") newS.push(d);
    });
    const newSkills = newS.map((d, i) => {
      return { value: d.id, label: lang == "en" ? d.name : d.name_ar, color: d.type == "Soft skills" ? "blue" : 'red' };
    });

    setAllSkills(newSkills);
  };


  const getExp = async () => {
    const response = await axios.get(process.env.REACT_APP_API + "/skills");
    const newE = [];
    response.data.data.map((d, i) => {
      if (d.type == "E") newE.push(d);
    });
    const newExp = newE.map((d, i) => {
      return { value: d.id, label: lang == "en" ? d.name : d.name_ar };
    });

    setAllExp(newExp);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    var newS = "";
    skills.map((s, i) => {
      newS = newS + "#" + s.value.toString();
    });

    expertises.map((s, i) => {
      newS = newS + "#" + s.value.toString();
    });
    if (skills.length > 0 && expertises.length > 0) {
      const opp = {
        skills: newS,
      };
      if (community) {
        dispatch(editOpportunity(id, opp, community));
      } else dispatch(editOpportunity(id, opp));
      if (isNext) {
        setOpenStep9(true);
      } else {
        setOpen(false);
      }
    } else {
      setError(t("opportunity.create.error"));
    }
  };

  useEffect(() => {
    if (Object.keys(oppDetails).length > 0) {
      if (allSkills.length > 0) {
        let newSkill = oppDetails.skills?.split("#");
        var newS = [];
        var newE = [];
        newSkill?.map((skill, i) => {
          newS.push(
            allSkills.filter((s) => {
              if (skill != "") return s.value == skill;
            })[0]
          );

          newE.push(
            allExp.filter((s) => {
              if (skill != "") return s.value == skill;
            })[0]
          );

        });

        setSkills(newS.filter((s) => s));

        console.log('all extertiseeee' , newE.filter((s) => s));

        console.log('allSkillssssss ' , allSkills)
        
        setExpertises(newE.filter((s) => s));
      }
    }
  }, [oppDetails, allSkills , allExp]);

  return (
    <div>
      {
        // (loading || editLoading)  ? <Loading/> :
        previousPage ? (
          <CreateOpp_Step7
            id={id}
            community={community}
            setOpen={setOpen}
            setFooterWidth={setFooterWidth}
            previous={true}
          />
        ) : (openStep9 ? (
          <CreateOpp_Step9
            id={id}
            community={community}
            setOpen={setOpen}
            setFooterWidth={setFooterWidth}
          />
        ) : (
          <form className="create_opp_step1" onSubmit={(e) => onSubmit(e)}>
            <div className="create_opp_step7_main_cont">
              <div className="create_opp_step1_cont">
                <div className="step1_cont_header">
                  {t("opportunity.create.ideal")}*
                  <p className="create_opp_note_dark">
                    {" "}
                    {t("opportunity.create.ideal_text")}
                  </p>
                </div>

                <Select
                  value={null}
                  className="complete_profile_select  create_opp_select_step7"
                  name="skills"
                  onChange={(e) => setSelectedSkills(e)}
                  placeholder={t("CompleteProf.search")}
                  isMulti={true}
                  options={allSkills}
                />
                <div className="complete_step3_skills_cont marginTop0">
                  {skills &&
                    skills.map((skill, index) => {
                      return (
                        <div className="complete_step3_skills_input_cont">
                          <div className="complete_step3_skills_input" style={{ borderColor: skill.color }}>
                            {" "}
                            {skill.label}{" "}
                          </div>{" "}
                          <AiOutlineClose
                            className="complete_step2_close"
                            size="1.2rem"
                            onClick={() => removeSkill(index)}
                          />
                        </div>
                      );
                    })}
                </div>


                <Select
                  value={null}
                  className="complete_profile_select  create_opp_select_step7"
                  name="expertises"
                  onChange={(e) => setSelectedExpertises(e)}
                  placeholder={t("CompleteProf.searchExp")}
                  isMulti={true}
                  options={allExp}
                />
                <div className="complete_step3_skills_cont marginTop0">
                  {expertises &&
                    expertises.map((expertise, index) => {
                      return (
                        <div className="complete_step3_skills_input_cont">
                          <div className="complete_step3_skills_input" style={{ borderColor: expertise.color }}>
                            {" "}
                            {expertise.label}{" "}
                          </div>{" "}
                          <AiOutlineClose
                            className="complete_step2_close"
                            size="1.2rem"
                            onClick={() => removeExpertise(index)}
                          />
                        </div>
                      );
                    })}
                </div>


              </div>
            </div>

            {error && <div className="error_msg">{error}</div>}

            <div className="complete_step1_footer">
              <div className="divider_line"></div>
              <div className="complete_step_footer_button">
                <button
                  className={
                    size.width > 768
                      ? "primary_button complete_step_1_next_button"
                      : "primary_button complete_step_2_next_button_resp"
                  }
                  onClick={(e) => { e.preventDefault(); setPreviousPage(true); }}
                >
                  {size.width > 768 ? (
                    t("CompleteProf.complete_your_profile_btn_5")
                  ) : lang == "en" ? (
                    <AiOutlineArrowLeft size="1.5rem" />
                  ) : (
                    <AiOutlineArrowRight size="1.5rem" />
                  )}
                </button>

                <div
                  className={
                    size.width > 768
                      ? "complete_step_right_footer"
                      : "complete_step_right_footer_resp"
                  }
                >
                  <button
                    className={
                      size.width > 500
                        ? "pink_button complete_step_2_complete_button"
                        : "pink_button complete_step_2_next_button_resp"
                    }
                    onClick={() => setIsNext(false)}
                  >
                    {size.width > 500 ? (
                      t("CompleteProf.complete_your_profile_btn_3")
                    ) : (
                      <BiSave size="1.5rem" />
                    )}
                  </button>
                  <button
                    className={
                      size.width > 768
                        ? "primary_button complete_step_2_next_button"
                        : "primary_button complete_step_2_next_button_resp"
                    }
                    onClick={() => setIsNext(true)}
                  >
                    {size.width > 768 ? (
                      t("CompleteProf.complete_your_profile_btn_4")
                    ) : lang == "en" ? (
                      <AiOutlineArrowRight size="1.5rem" />
                    ) : (
                      <AiOutlineArrowLeft size="1.5rem" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        ))
      }
    </div>
  );
}

export default CreateOpp_Step8;
