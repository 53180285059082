import axios from "axios";
import { OrganizationEvaluationsActions } from "./OrganizationEvaluationsReducers";

export const addOrganizationEvaluations = (data) => async (dispatch) => {
  try {
    dispatch(OrganizationEvaluationsActions.AddOrganizationEvaluationsRequest());
    let response = await axios.post(
      process.env.REACT_APP_API + "/organizationevaluation/",
      {
        ...data,
      }
    );
   
    dispatch(OrganizationEvaluationsActions.AddOrganizationEvaluationsRequestSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    dispatch(OrganizationEvaluationsActions.AddOrganizationEvaluationsRequestFail(error.message));
  }
};

