import axios from "axios";
import { CommunityActActions } from "./CommunityActReducers";

export const getallCommunityAct = () => async (dispatch) => {
  try {
    dispatch(CommunityActActions.getCommunityAct());
    let response = await axios.get(
      process.env.REACT_APP_API + "/opportunities/community"
    );
    dispatch(CommunityActActions.getCommunityActSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    dispatch(CommunityActActions.getCommunityActError(error.message));
  }
};

export const getCommunityActbyfilters =
  ({
    selectedStatus,
    selectedOppTitle,
    selectedAge,
    selectedNationality,
    selectedcountry,
    selectedLocation,
    selectedType,
    selectedDis,
    selectedRecommendation,
  }) =>
  async (dispatch) => {
    dispatch(CommunityActActions.getCommunityAct());
    let url;
    if (localStorage.getItem("token"))
      url = process.env.REACT_APP_API + "/opportunities/community?";
    else url = process.env.REACT_APP_API + "/opportunities/AllCommunity";

    if (selectedStatus) {
      url = url + "status=" + selectedStatus + "&";
    }
    if (selectedOppTitle) {
      url = url + "title=" + selectedOppTitle + "&";
    }
    if (selectedAge) {
      url = url + "age=" + selectedAge + "&";
    }
    if (selectedNationality) {
      url = url + "nationality=" + selectedNationality + "&";
    }
    if (selectedcountry) {
      url = url + "country=" + selectedcountry + "&";
    }
    if (selectedLocation) {
      url = url + "location=" + selectedLocation + "&";
    }
    if (selectedType) {
      url = url + "type=" + selectedType + "&";
    }
    if (selectedDis) {
      url = url + "district=" + selectedDis + "&";
    }
    if (selectedRecommendation) {
      url = url + "recommendation=" + selectedRecommendation + "&";
    }
    // if (selectedSec) {
    //   url = url + "sector=" + selectedSec + "&";
    // }
    // if (selectedOrg) {
    //   url = url + "organization=" + selectedOrg + "&";
    // }
    // if (selectedImp) {
    //   url = url + "impact=" + selectedImp + "&";
    // }

    try {
      let response;
      if (localStorage.getItem("token"))
        response = await axios.get(url, {
          Authorization: "Bearer " + localStorage.getItem("token"),
        });
      else response = await axios.get(url);

      dispatch(CommunityActActions.getCommunityActSuccess(response.data.data));
    } catch (error) {
      dispatch(CommunityActActions.getCommunityActError(error.message));
    }
  };

export const getvolCommunityAct = () => async (dispatch) => {
  try {
    dispatch(CommunityActActions.fetchvolCommunityAct());
    let response = await axios.get(
      process.env.REACT_APP_API + "/opportunities/volunteer",
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    dispatch(
      CommunityActActions.fetchvolCommunityActSuccess(response.data.data)
    );
  } catch (error) {
    console.log(error);
    dispatch(CommunityActActions.fetchvolCommunityActError(error.message));
  }
};
export const getvolreqCommunityAct = () => async (dispatch) => {
  try {
    dispatch(CommunityActActions.fetchvolreqCommunityAct());
    let response = await axios.get(
      process.env.REACT_APP_API + "/volunteers/getRequestByVolunteer",
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    dispatch(
      CommunityActActions.fetchvolreqCommunityActSuccess(response.data.data)
    );
  } catch (error) {
    console.log(error);
    dispatch(CommunityActActions.fetchvolreqCommunityActError(error.message));
  }
};

export const getCommunityActbyfiltersvolunteer =
  ({
    selectedStatus,
    selectedOppTitle,
    selectedAge,
    selectedNationality,
    selectedcountry,
    selectedLocation,
    selectedType,
    selectedDis,
    selectedRecommendation,
  }) =>
  async (dispatch) => {
    dispatch(CommunityActActions.fetchvolreqCommunityAct());
    let url = process.env.REACT_APP_API + "/volunteers/getRequestByVolunteer?";
    if (selectedStatus) {
      url = url + "status=" + selectedStatus + "&";
    }
    if (selectedOppTitle) {
      url = url + "title=" + selectedOppTitle + "&";
    }
    if (selectedAge) {
      url = url + "age=" + selectedAge + "&";
    }
    if (selectedNationality) {
      url = url + "nationality=" + selectedNationality + "&";
    }
    if (selectedcountry) {
      url = url + "country=" + selectedcountry + "&";
    }
    if (selectedLocation) {
      url = url + "location=" + selectedLocation + "&";
    }
    if (selectedType) {
      url = url + "type=" + selectedType + "&";
    }
    if (selectedDis) {
      url = url + "district=" + selectedDis + "&";
    }
    if (selectedRecommendation) {
      url = url + "recommendation=" + selectedRecommendation + "&";
    }
    try {
      let response = await axios.get(url);
      dispatch(
        CommunityActActions.fetchvolreqCommunityActSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(CommunityActActions.fetchvolreqCommunityActError(error.message));
    }
  };

export const createCallAction = (data) => async (dispatch) => {
  try {
    dispatch(CommunityActActions.createActionRequest());
    const response = await axios.post(
      process.env.REACT_APP_API + `/opportunities/communityaction`,

      data
    );

    dispatch(CommunityActActions.createActionSuccess(response.data.data));
    dispatch(getallCommunityAct());

    dispatch(getvolCommunityAct());
  } catch (error) {
    console.log(error);
    dispatch(CommunityActActions.createActionFail(error.response.data.message));
  }
};

export const getFavoritesComm = () => async (dispatch) => {
  try {
    dispatch(CommunityActActions.fetchFavCommRequest());
    let response = await axios.get(
      process.env.REACT_APP_API + "/favorites/opportunitiesC/self"
    );

    dispatch(CommunityActActions.fetchFavCommSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    dispatch(CommunityActActions.fetchFavCommFail(error.message));
  }
};
