import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  trusted_partners: [],
  loading: false,
  error: "",
};

const trusted_partnersSlice = createSlice({
  name: "trusted_partners",
  initialState,
  reducers: {
    fetchTrusted_partnersRequest(state) {
      state.loading = true;
      state.trusted_partners = [];
      state.error = "";
    },
    fetchTrusted_partnersSuccess(state, action) {
      state.loading = false;
      state.trusted_partners = action.payload;
      state.error = "";
    },
    fetchTrusted_partnersFail(state, action) {
      state.loading = false;
      state.trusted_partners = [];
      state.error = action.payload;
    },
  },
});

export const trusted_partnersAction = trusted_partnersSlice.actions;
export default trusted_partnersSlice;
