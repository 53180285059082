import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  favorites: [],
  favoritesAdd: [],
  loading: false,
  error: "",
  favAll:[]
};

const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {
    fetchFavoritesRequest(state) {
      state.loading = true;
      state.favorites = [];
      state.error = "";
    },
    fetchFavoritesSuccess(state, action) {
      state.loading = false;
      state.favorites = action.payload;
      state.error = "";
    },
    fetchFavoritesFail(state, action) {
      state.loading = false;
      state.favorites = [];
      state.error = action.payload;
    },
    FavoritesAddRequest(state) {
      state.loading = true;
      state.favoritesAdd = [];
      state.error = "";
    },
    FavoritesAddSuccess(state, action) {
      state.loading = false;
      state.favoritesAdd = action.payload;
      state.error = "";
    },
    FavoritesAddFail(state, action) {
      state.loading = false;
      state.favoritesAdd = [];
      state.error = action.payload;
    },

    fetchFavoritesDeleteRequest(state, action) {
      state.loading = false;
      state.favorites = [];
      state.error = action.payload;
    },

    fetchFavoritesDeleteSuccess(state, action) {
      state.loading = false;
      state.favorites = [];
      state.error = action.payload;
    },

    fetchFavoritesDeleteFail(state, action) {
      state.loading = false;
      state.favorites = [];
      state.error = action.payload;
    },


    fetchFavAllRequest(state) {
      state.loading = true;
      state.allFav = [];
      state.error = "";
    },

    fetchFavAllSuccess(state, action) {
      state.loading = false;
      state.allFav = action.payload;

      state.error = "";
    },

    fetchFavAllFail(state, action) {
      state.loading = false;
      state.allFav = action.payload;
      state.error = "";
    },
  },
});

export const favoritesActions = favoritesSlice.actions;
export default favoritesSlice;
