import React from "react";
import PopUp from "../../../components/PopUp/PopUp";
import "./AcessMaster_Pop.css";
function AcessMaster_Pop(props) {
  return (
    <div className="master_cl__main_con">
      <PopUp
        setOpen={props.setOpen}
        cont={
          <div className="create_pop_main_container">
            <div className="master_cl__pop_cont">
              <div className="master_cl__header_cont">
                <h2 className="master_cl__header">{props.header}</h2>
                {props.subtitle && (
                  <p className="master_cl__subtitle">{props.subtitle}</p>
                )}
                <div className="master_cl__body">{props.body}</div>
              </div>{" "}
            </div>
            {/* <div className="master_cl__footer_slider">{props.footer}</div> */}
          </div>
        }
      />
    </div>
  );
}

export default AcessMaster_Pop;
