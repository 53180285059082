import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  subscriptionsDescriptions: [],
};
const subscriptionDescriptionSlice = createSlice({
  name: "subscriptionDescription",
  initialState,
  reducers: {
    subscriptionDescriptionRequest(state) {
      state.loading = true;
      state.subscriptionsDescriptions = [];
      state.error = "";
    },
    subscriptionDescriptionSuccess(state, action) {
      state.loading = false;
      state.subscriptionsDescriptions = action.payload;
      state.error = "";
    },
    subscriptionDescriptionFail(state, action) {
      state.loading = false;
      state.subscriptionsDescriptions = [];
      state.error = action.payload;
    },
  },
});

export const subscriptionDescriptionAction = subscriptionDescriptionSlice.actions;
export default subscriptionDescriptionSlice;
