import axios from "axios";
import { MasterClassActions } from "./MasterClassReducers";

export const sendMasterClassReqs = (data) => async (dispatch) => {
  try {
    dispatch(MasterClassActions.SendMasterClassRequest());
    let response = await axios.post(
      process.env.REACT_APP_API + "/masterclasses",
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    dispatch(MasterClassActions.SendMasterClassSuccess(response.data));
  } catch (error) {
    dispatch(MasterClassActions.SendMasterClassFailure(error.message));
  }
};
export const getAllEpisodes = () => async (dispatch) => {
  try {
    dispatch(MasterClassActions.fetchAllEpisodesRequest());
    let response = await axios.get(process.env.REACT_APP_API + "/episodes");
    dispatch(MasterClassActions.fetchAllEpisodesSuccess(response.data.data));
  } catch (error) {
    dispatch(MasterClassActions.fetchAllEpisodesFailure(error.message));
  }
};
export const getVideosbyid = (id) => async (dispatch) => {
  try {
    dispatch(MasterClassActions.fetchvideosRequest());
    let response = await axios.get(
      process.env.REACT_APP_API + "/episode_videos/" + id
    );
    dispatch(MasterClassActions.fetchvideosSuccess(response.data.data));
  } catch (error) {
    dispatch(MasterClassActions.fetchvideosFailure(error.message));
  }
};
export const getVideoProgress = () => async (dispatch) => {
  try {
    dispatch(MasterClassActions.fetchvideoProgressRequest());
    let response = await axios.get(
      process.env.REACT_APP_API + "/episode_video_progress/getbyToken",
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    dispatch(MasterClassActions.fetchvideoProgressSuccess(response.data.data));
  } catch (error) {
    dispatch(MasterClassActions.fetchvideoProgressFailure(error.message));
  }
};

export const getMasterClassVerification = () => async (dispatch) => {
  try {
    dispatch(MasterClassActions.fetchMasterClassVerificationRequest());
    let response = await axios.get(
      process.env.REACT_APP_API + "/masterclasses/self",
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    dispatch(
      MasterClassActions.fetchMasterClassVerificationSuccess(response.data.data)
    );
  } catch (error) {
    dispatch(
      MasterClassActions.fetchMasterClassVerificationFailure(error.message)
    );
  }
};

export const getAllVideos = () => async (dispatch) => {
  try {
    dispatch(MasterClassActions.fetchAllvideosRequest());
    let response = await axios.get(
      process.env.REACT_APP_API + "/episode_videos/"
    );

    dispatch(MasterClassActions.fetchAllvideosSuccess(response.data.data));
  } catch (error) {
    dispatch(MasterClassActions.fetchAllvideosFailure(error.message));
  }
};

export const createVidProg = (data) => async (dispatch) => {
  try {
    dispatch(MasterClassActions.createVidProgReq());
    let response = await axios.post(
      process.env.REACT_APP_API + "/episode_video_progress",
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    dispatch(MasterClassActions.createVidProgSuccess(response.data.data));
    dispatch(getVideoProgress());
  } catch (error) {
    dispatch(MasterClassActions.createVidProgFail(error.message));
  }
};

export const getQuizUser = () => async (dispatch) => {
  try {
    dispatch(MasterClassActions.getUserQuizesRequest());
    let response = await axios.get(
      process.env.REACT_APP_API + "/episode_quiz_users/token",
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    dispatch(MasterClassActions.getUserQuizesSuccess(response.data.data));
  } catch (error) {
    dispatch(MasterClassActions.getUserQuizesFailure(error.message));
  }
};

export const createUserQuiz = (data) => async (dispatch) => {
  try {
    dispatch(MasterClassActions.createUserQuizesReq());
    let response = await axios.post(
      process.env.REACT_APP_API + "/episode_quiz_users/token",
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    dispatch(
      MasterClassActions.createUserQuizesSuccess(response.data.data.status)
    );
    //dispatch(getQuizUser());
  } catch (error) {
    dispatch(MasterClassActions.createVidProgFail(error.message));
  }
};

export const finishMaster = (data) => async (dispatch) => {
  try {
    var token = localStorage.getItem("token");
    dispatch(MasterClassActions.FinishMasterReq());
    let response = await axios.get(
      process.env.REACT_APP_API + "/masterclasses/accepted",
      { token }
    );
    dispatch(MasterClassActions.FinishMasterSuccess(response.data.data));
    dispatch(getQuizUser());
  } catch (error) {
    dispatch(MasterClassActions.FinishMasterFail(error.message));
  }
};
export const editmasterclass = (id) => async (dispatch) => {
  try {
    dispatch(MasterClassActions.editmasterclassverificationRequest());
    let response = await axios.put(
      process.env.REACT_APP_API + "/masterclasses",
      {
        id,
      }
    );
    dispatch(
      MasterClassActions.editmasterclassverificationSuccess(response.data.data)
    );
  } catch (error) {
    dispatch(
      MasterClassActions.editmasterclassverificationFailure(error.message)
    );
  }
};
export const getallmastertestimonials = () => async (dispatch) => {
  try {
    dispatch(MasterClassActions.fetchMasterTestimonialsRequest());
    let response = await axios.get(
      process.env.REACT_APP_API + "/masterclasses/getmastertestimonials"
    );
    dispatch(
      MasterClassActions.fetchMasterTestimonialsSuccess(response.data.data)
    );
  } catch (error) {
    dispatch(MasterClassActions.fetchMasterTestimonialsFailure(error.message));
  }
};
