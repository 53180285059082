import React, { useEffect, useState } from "react";
import ProfileHeader from "../ProfileHeader/ProfileHeader";
import "./EvalNCert.css";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import excellent from "../../../assets/images/popupImages/excellent.png";
import good from "../../../assets/images/popupImages/good.png";
import sufficient from "../../../assets/images/popupImages/sufficient.png";
import verygood from "../../../assets/images/popupImages/verygood.png";
import insufficient from "../../../assets/images/popupImages/insufficient.png";
import { getEvalRequests } from "../../../Redux/EvalRequests/EvalRequestsActions";
import {
  getOrgEvalRequests,
  fetchcirtificate,
} from "../../../Redux/EvalRequests/EvalRequestsActions";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/Loading/Loading";

function EvalNCert() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [evalRequestsData, setEvalRequestsData] = useState([]);
  const { evalRequests, loading, certificates } = useSelector(
    (state) => state.evalRequestsReducer
  );
  const dispatch = useDispatch();
  const [isEval, setIsEval] = useState(true);
  const role = localStorage.getItem("role");
  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (role == 4) {
      dispatch(getOrgEvalRequests());
    } else {
      dispatch(getEvalRequests());
      dispatch(fetchcirtificate());
    }
  }, []);

  useEffect(() => {
    setEvalRequestsData(evalRequests);
  }, [evalRequests]);

  return (
    <div className="eval_and_cert_main_cont">
      <ProfileHeader />
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="eval_cert_header">
            <button
              className={
                isEval
                  ? "eval_cert_button eval_cert_active_btn"
                  : "eval_cert_button"
              }
              onClick={() => setIsEval(true)}
            >
              {t("EvalNCert.evaluations")}
            </button>
            <button
              className={
                !isEval
                  ? "eval_cert_button eval_cert_active_btn"
                  : "eval_cert_button"
              }
              onClick={() => setIsEval(false)}
            >
              {t("EvalNCert.certificates")}
            </button>
          </div>

          {isEval && evalRequestsData != [] ? (
            <div className="evaluation_container">
              {evalRequestsData.map((element, i) => {
                return (
                  <a className="evaluation_card"
                  href={
                    process.env.REACT_APP_API +
                    "/EvalPdfs/" +
                    element.pdf
                  }
                  target="_blank"
                  >
                    <div className="evaluation_cont1">
                      <h4 className="evaluation_card_title">
                        {element?.request?.opportunity?.title}{" "}
                      </h4>
                      <p className="evaluation_card_org green_color">
                        {" "}
                        {element?.request?.opportunity?.organization.name}
                      </p>
                      <div className="evaluation_card_date">
                        {new Date(element.createdAt).getDate() +
                          "-" +
                          new Date(element.createdAt).toLocaleString(
                            "default",
                            { month: "long" }
                          ) +
                          "-" +
                          new Date(element.createdAt).getFullYear()}{" "}
                      </div>
                    </div>
                    <div className="evaluation_cont2">
                      <img
                        className="evaluation_record_img"
                        src={
                          element.overall_evaluation == 0
                            ? insufficient
                            : element.overall_evaluation == 1
                            ? sufficient
                            : element.overall_evaluation == 2
                            ? good
                            : element.overall_evaluation == 3
                            ? verygood
                            : element.overall_evaluation == 4 && excellent
                        }
                      />
                    </div>
                  </a>
                );
              })}
            </div>
          ) : (
            <div className="evaluation_container">
              {certificates.map((element) => {
                return (
                  <div className="evaluation_card">
                    <a
                      className="evaluation_card_title"
                      href={
                        process.env.REACT_APP_API +
                        "/Certificate/" +
                        element.pdf
                      }
                      target="_blank"
                    >
                      {element.opportunity_name}
                    </a>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default EvalNCert;
