import axios from "axios";
import { volunteerSpotlightAction } from "./VolunteerSpotlightReducers";

export const getVolunteerSpotlights = () => async (dispatch) => {
  dispatch(volunteerSpotlightAction.fetchvolunteerSpotlightRequest());
  try {
    let response = await axios.get(
      process.env.REACT_APP_API + "/volspotlight"
    );
    dispatch(volunteerSpotlightAction.fetchvolunteerSpotlightSuccess(response.data.data));
  } catch (error) {
    dispatch(volunteerSpotlightAction.fetchvolunteerSpotlightFail(error.message));
  }
};


export const getVolunteerSpotlightById = (id) => async (dispatch) => {
  dispatch(volunteerSpotlightAction.fetchVolunteerSpotlightDetailRequest());
  try {
    let response = await axios.get(
      process.env.REACT_APP_API + `/volspotlight/${id}`
    );
    dispatch(volunteerSpotlightAction.fetchVolunteerSpotlightDetailSuccess(response.data.data));
  } catch (error) {
    dispatch(volunteerSpotlightAction.fetchVolunteerSpotlightDetailFail(error.message));
  }
};