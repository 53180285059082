import axios from "axios";
import { subscriptionDescriptionAction } from "./SubscriptionDescriptionReducer";

export const getSubscriptionsDescriptions = () => async (dispatch) => {
  dispatch(subscriptionDescriptionAction.subscriptionDescriptionRequest());
  try {
    let response = await axios.get(process.env.REACT_APP_API + "/subscriptionDescription/all");
    dispatch(subscriptionDescriptionAction.subscriptionDescriptionSuccess(response.data.data));
  } catch (error) {
    dispatch(subscriptionDescriptionAction.subscriptionDescriptionFail(error.message));
  }
};
