import axios from "axios";
import { newsLetterAction } from "./NewsLetterReducers";
import isAuth from "../../Utils/isAuth";

export const subscribeNewsLetter = (email) => async (dispatch) => {
  try {
    dispatch(newsLetterAction.subscribeRequest());
    const response = await axios.post(
      process.env.REACT_APP_API + "/email_subscriptions",
      { email: email }
    );
    dispatch(newsLetterAction.subscribeSuccess(response.data.data));
    if (!isAuth()) {
      window.location.href = "/auth/login";
    }
  } catch (error) {
    dispatch(newsLetterAction.subscribeFail(error.response.data.message));
  }
};
