import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import i18n from "../../i18n";
import "./EvaluationFeedback.css";
//emojies imports
import notapp from "../../assets/images/feedbacks/Group 1879.png";
import insuff from "../../assets/images/feedbacks/Group 1878.png";
import suff from "../../assets/images/feedbacks/Group 1877.png";
import good from "../../assets/images/feedbacks/Group 1876.png";
import excellent from "../../assets/images/feedbacks/Group 1875.png";
import { SlArrowDown } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import { calculateCheckinOutHours } from "../../Redux/checkin_out/checkin_outActions";
import { useParams } from "react-router-dom";
import { addOrganizationEvaluations } from "../../Redux/OrganizationEvaluations/OrganizationEvaluationsActions";

function EvaluationFeedback() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);

  const [underrepresentedIndividuals, setUnderrepresentedIndividuals] = useState("");
  const [underprivilegedIndividuals, setUnderprivilegedIndividuals] = useState("");
  const [others, setOthers] = useState("");
  const [treesSaved, setTreesSaved] = useState("");
  const [wildlifeHabitatsPreserved, setWildlifeHabitatsPreserved] = useState("");
  const [naturalAreasCleaned, setNaturalAreasCleaned] = useState("");
  const [historicalBuildingsRestored, setHistoricalBuildingsRestored] = useState("");
  const [culturalArtifactsConserved, setCulturalArtifactsConserved] = useState("");
  const [localTraditionsPromoted, setLocalTraditionsPromoted] = useState("");
  const [inKindSuppliesDistributed, setInKindSuppliesDistributed] = useState("");
  const [buildingsImproved, setBuildingsImproved] = useState("");
  const [buildingsConstructed, setBuildingsConstructed] = useState("");
  const [publicSpacesBeautified, setPublicSpacesBeautified] = useState("");
  const [showSportsParticipantsNumber, setShowSportsParticipantsNumber] = useState(false);
  const [sportsActivitiesOrganized, setSportsActivitiesOrganized] = useState(false);
  const [sportsParticipantsNumber, setSportsParticipantsNumber] = useState(0);

  const [showEventsParticipantsNumber, setShowEventsParticipantsNumber] = useState(false);
  const [eventsOrganized, setEventsOrganized] = useState(false);
  const [eventsParticipantsNumber, setEventsParticipantsNumber] = useState(0);

  const [help, setHelp] = useState("New Project");
  const [newRole, setNewRole] = useState(false);
  const [showOrganizationRole, setShowOrganizationRole] = useState(false);
  const [organizationRole, setOrganizationRole] = useState("");
  const [livesTouched, setLivesTouched] = useState('');
  const [inputLivesTouched, setInputLivesTouched] = useState('');
  const [CheckinOutHoursData, setCheckinOutHoursData] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { CheckinOutHours } = useSelector((store) => store.checkin_outReducer);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (CheckinOutHours !== undefined) {
      setCheckinOutHoursData(CheckinOutHours?.total);
    }
  }, [CheckinOutHours]);

  useEffect(() => {
    dispatch(calculateCheckinOutHours(id));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {


      opportunity_id: id,
      help: help,
      new_role: newRole,
      lives_touched: livesTouched,
      organization_role: organizationRole,
      underrepresented_individuals: underrepresentedIndividuals,
      underprivileged_individuals: underprivilegedIndividuals,
      others: others,
      trees_saved: treesSaved,
      wildlife_habitats_preserved: wildlifeHabitatsPreserved,
      natural_areas_cleaned: naturalAreasCleaned,
      historical_buildings_restored: historicalBuildingsRestored,
      cultural_artifacts_conserved: culturalArtifactsConserved,
      local_traditions_promoted: localTraditionsPromoted,
      inkind_supplies_distributed: inKindSuppliesDistributed,
      buildings_improved: buildingsImproved,
      buildings_constructed: buildingsConstructed,
      public_spaces_beautified: publicSpacesBeautified,
      sports_activities_organized: sportsActivitiesOrganized,
      sports_participants_number: sportsParticipantsNumber,
      events_organized: eventsOrganized,
      events_participants_number: eventsParticipantsNumber

    };
    dispatch(addOrganizationEvaluations(data));
    navigate("/opportunities/1");
  };

  const [isFormValid, setIsFormValid] = useState(true);
  const [validationMessage, setValidationMessage] = useState('');

  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input is a valid percentage (e.g., 0-100 with optional decimal)
    const regex = /^(?:100(?:\.0{1,2})?|\d{1,3}(?:[.,]\d{0,2})?)%?$/;
    if (regex.test(inputValue) || inputValue === '') {
      setLivesTouched(inputValue.replace(/%/g, ''));
      setInputLivesTouched(inputValue);
      setValidationMessage('');
    } else {
      setValidationMessage('Enter a valid percentage with optional decimal');
    }
  };
  // const validateForm = () => {
  //   // Check if all required fields are filled
  //   const requiredFieldsFilled =
  //     numberOfBeneficiaries !== "" &&
  //     treesSaved !== "" &&
  //     costSaved !== "" &&
  //     other !== "";

  //   // Check if other required validations are met
  //   // You can add more conditions here

  //   setIsFormValid(requiredFieldsFilled);
  // };

  // useEffect(() => {
  //   validateForm();
  // }, [numberOfBeneficiaries, treesSaved, costSaved, other]);
  return (
    <div className="evaluation_feedback_container">
      <div className="goback_div" onClick={() => navigate("/checkin_out")}>
        <SlArrowDown className="opp_goback_icon" />{" "}
        <div>{t("evaluation_feedback.back")}</div>
      </div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="evaluation_feedback_title uppercase">
          {t("evaluation_feedback.feedback")}
        </div>
        <div className="evaluation_feedback_title2">
          {t("evaluation_feedback.evaluation_feedback_title")}
        </div>
        {/* <div className="evaluation_feedback_title">
          {t("evaluation_feedback.checkin_out_time")}
        </div>
        <div class="input-icons">
          <i
            className={
              lang == "ar"
                ? disabledHours
                  ? "edit-icon left-50"
                  : "hide"
                : disabledHours
                  ? "edit-icon right-50"
                  : "hide"
            }
            onClick={() => {
              setDisabledHours(false);
            }}
          ></i>
          <input
            placeholder={t("evaluation_feedback.hours")}
            className="text_field_getintouch"
            name="hours"
            type="text"
            value={CheckinOutHoursData}
            disabled={disabledHours}
            onChange={(e) => {
              setCheckinOutHoursData(e.target.value);
            }}
          />
        </div>
        <div className="feedbacks_page_emojies_container">
          <div className="evaluation_feedback_title">
            {t("evaluation_feedback.feedbacks_1")}
          </div>
          <div className="feedbacks_page_emojies">
            <div className="feedbacks_page_emojies_row">
              <div className="feedbacks_page_emojies_row_1">
                <img
                  src={notapp}
                  alt="notapp"
                  className={
                    Emojie == "1" ? "active_emojie emojie_logo" : "emojie_logo"
                  }
                  onClick={() => setEmojie("1")}
                />
                <div className="feedbacks_page_emojies_row_1_text">
                  {t("feedbacks.emoji_1")}
                </div>
              </div>
            </div>
            <div className="feedbacks_page_emojies_row">
              <div className="feedbacks_page_emojies_row_1">
                <img
                  src={insuff}
                  alt="notapp"
                  className={
                    Emojie == "2" ? "active_emojie emojie_logo" : "emojie_logo"
                  }
                  onClick={() => setEmojie("2")}
                />
                <div className="feedbacks_page_emojies_row_1_text">
                  {t("feedbacks.emoji_2")}
                </div>
              </div>
            </div>
            <div className="feedbacks_page_emojies_row">
              <div className="feedbacks_page_emojies_row_1">
                <img
                  src={suff}
                  alt="notapp"
                  className={
                    Emojie == "3" ? "active_emojie emojie_logo" : "emojie_logo"
                  }
                  onClick={() => setEmojie("3")}
                />
                <div className="feedbacks_page_emojies_row_1_text">
                  {t("feedbacks.emoji_3")}
                </div>
              </div>
            </div>
            <div className="feedbacks_page_emojies_row">
              <div className="feedbacks_page_emojies_row_1">
                <img
                  src={good}
                  alt="notapp"
                  className={
                    Emojie == "4" ? "active_emojie emojie_logo" : "emojie_logo"
                  }
                  onClick={() => setEmojie("4")}
                />
                <div className="feedbacks_page_emojies_row_1_text">
                  {t("feedbacks.emoji_4")}
                </div>
              </div>
            </div>
            <div className="feedbacks_page_emojies_row">
              <div className="feedbacks_page_emojies_row_1">
                <img
                  src={excellent}
                  alt="notapp"
                  className={
                    Emojie == "5" ? "active_emojie emojie_logo" : "emojie_logo"
                  }
                  onClick={() => setEmojie("5")}
                />
                <div className="feedbacks_page_emojies_row_1_text">
                  {t("feedbacks.emoji_5")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="evaluation_feedback_title">
          {t("evaluation_feedback.activity_impact")}
          <div>{t("evaluation_feedback.try_to_quantify")}</div>
        </div>
        <div className="quantify_div">
          <div className="quantify_div_inputs">
            <div className="quantify_div_input_label">
              <label for="newProjects" className="filter_list_comp">
                {" "}
                {t("evaluation_feedback.numberOfBeneficiaries")}*
              </label>
              <input
                placeholder="#"
                className="text_field_getintouch"
                name="numberOfBeneficiaries"
                type="text"
                required
                onChange={(e) => {
                  setNumberOfBeneficiaries(e.target.value);
                }}
              />
            </div>
            <div className="quantify_div_input_label">
              <label for="newProjects" className="filter_list_comp">
                {" "}
                {t("evaluation_feedback.treesSaved")}*
              </label>
              <input
                placeholder="#"
                className="text_field_getintouch"
                name="treesSaved"
                type="text"
                required
                onChange={(e) => {
                  setTreesSaved(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="quantify_div_inputs">
            <div className="quantify_div_input_label">
              <label for="newProjects" className="filter_list_comp">
                {" "}
                {t("evaluation_feedback.costSavedVolHrs")}*
              </label>
              <input
                placeholder="$"
                className="text_field_getintouch"
                name="costSaved"
                type="text"
                required
                onChange={(e) => {
                  setCostSaved(e.target.value);
                }}
              />
            </div>
            <div className="quantify_div_input_label">
              <label for="newProjects" className="filter_list_comp">
                {" "}
                {t("evaluation_feedback.other")}*
              </label>
              <input
                placeholder="#"
                className="text_field_getintouch"
                name="other"
                type="text"
                required
                onChange={(e) => {
                  setOther(e.target.value);
                }}
              />
            </div>
          </div>
        </div> */}

        <div className="evaluation_feedback_title">
          {t("evaluation_feedback.organization_achieve_message")}
        </div>
        <div className="checkbox_div">
          <div className="filter_list_check">
            <input
              type="checkbox"
              id="newProjects"
              name="newProjects"
              value="New Project"
              className="filter_checkbox"
              onChange={(e) => {
                setHelp(e.target.value);
              }}
            />
            <label for="newProjects" className="filter_list_comp">
              {" "}
              {t("evaluation_feedback.newProjects")}
            </label>
          </div>
          <div className="filter_list_check">
            <input
              type="checkbox"
              id="teamExtention"
              name="teamExtention"
              value="Team Extention"
              className="filter_checkbox"
              onChange={(e) => {
                setHelp(e.target.value);
              }}
            />
            <label for="teamExtention" className="filter_list_comp">
              {" "}
              {t("evaluation_feedback.teamExtention")}
            </label>
          </div>

          <div className="filter_list_check">
            <input
              type="checkbox"
              id="coverMoreArea"
              name="coverMoreArea"
              value="Cover more areas"
              className="filter_checkbox"
              onChange={(e) => {
                setHelp(e.target.value);
              }}
            />
            <label for="coverMoreArea" className="filter_list_comp">
              {" "}
              {t("evaluation_feedback.coverMoreArea")}
            </label>
          </div>

          <div className="filter_list_check">
            <input
              type="checkbox"
              id="other"
              name="other"
              value="Other"
              className="filter_checkbox"
              onChange={(e) => {
                setHelp(e.target.value);
              }}
            />
            <label for="other" className="filter_list_comp">
              {" "}
              {t("evaluation_feedback.other")}
            </label>
          </div>
        </div>

        <div className="evaluation_feedback_title">
          {t("evaluation_feedback.new_role")}
        </div>

        <div className="complete_step1_body2">
          <div className="user_radio_comp">
            <input
              class="complete_radio_btn"
              type="radio"
              id="yes"
              name="newRole"
              value="yes"
              onChange={() => {
                setShowOrganizationRole(true);
                setNewRole(true);
              }}
            />
            <label for="yes" style={{ cursor: "pointer" }}>
              {" "}
              {t("evaluation_feedback.yes")}
            </label>
          </div>
          <div className="user_radio_comp">
            <input
              class="complete_radio_btn"
              type="radio"
              id="no"
              name="newRole"
              value="no"
              onChange={() => {
                setShowOrganizationRole(false);
                setOrganizationRole("");
                setNewRole(false);
              }}
            />
            <label for="no" style={{ cursor: "pointer" }}>
              {t("evaluation_feedback.no")}
            </label>
          </div>
        </div>
        {showOrganizationRole && <div className="organizationRole_div">

          <label for="organizationRole" className="filter_list_comp">
            {" "}
            {t("evaluation_feedback.whatRole")}
          </label>
          <input
            placeholder={t("evaluation_feedback.role")}
            className="text_field_getintouch role_input"
            name="organizationRole"
            type="text"
            onChange={(e) => {
              setOrganizationRole(e.target.value);
            }}
          />
        </div>}


        <div className="evaluation_feedback_title">
          {t("evaluation_feedback.livesTouched")}
        </div>



        <div className="quantify_div_inputs">
          <div className="quantify_div_input_label">
            <input
              class="text_field_getintouch"
              type="text"
              id="livedTouched"
              name="livedTouched"
              value={inputLivesTouched}
              onChange={handleInputChange}
              placeholder="Enter percentage (e.g., 25.5)"
            />
            {validationMessage && <p style={{ color: 'red' }}>{validationMessage}</p>}
          </div>
          <div className="quantify_div_input_label"></div>
          {/* <div className="user_radio_comp">
            <input
              class="complete_radio_btn"
              type="radio"
              id="50"
              name="livedTouched"
              value="50"
              onChange={() => {
                setLivesTouched(50);
              }}
            />
            <label for="yes" style={{ cursor: "pointer" }}>
              {" "}
              50%
            </label>
          </div>


          <div className="user_radio_comp">
            <input
              class="complete_radio_btn"
              type="radio"
              id="75"
              name="livedTouched"
              value="75"
              onChange={() => {
                setLivesTouched(75);
              }}
            />
            <label for="yes" style={{ cursor: "pointer" }}>
              {" "}
              75%
            </label>
          </div>

          <div className="user_radio_comp">
            <input
              class="complete_radio_btn"
              type="radio"
              id="100"
              name="livedTouched"
              value="100"
              onChange={() => {
                setLivesTouched(100);
              }}
            />
            <label for="yes" style={{ cursor: "pointer" }}>
              {" "}
              100%
            </label>
          </div> */}
        </div>



        <div className="evaluation_feedback_title">
          {t("evaluation_feedback.humanitarian")}
        </div>


        <div className="quantify_div">
          <div className="quantify_div_inputs">
            <div className="quantify_div_input_label">
              <label for="underrepresentedIndividuals" className="filter_list_comp">
                {" "}
                {t("evaluation_feedback.underrepresented_individuals")}*
              </label>
              <input
                className="text_field_getintouch"
                name="underrepresentedIndividuals"
                type="number"
                required
                onChange={(e) => {
                  setUnderrepresentedIndividuals(e.target.value);
                }}
              />
            </div>

            <div className="quantify_div_input_label">
              <label for="UnderprivilegedIndividuals" className="filter_list_comp">
                {" "}
                {t("evaluation_feedback.underprivileged_individuals")}*
              </label>
              <input
                className="text_field_getintouch"
                name="UnderprivilegedIndividuals"
                type="number"
                required
                onChange={(e) => {
                  setUnderprivilegedIndividuals(e.target.value);
                }}
              />
            </div>

          </div>

          <div className="quantify_div_inputs">
            <div className="quantify_div_input_label">
              <label for="others" className="filter_list_comp">
                {" "}
                {t("evaluation_feedback.others_beneficeries")}*
              </label>
              <input
                className="text_field_getintouch"
                name="others"
                type="number"
                required
                onChange={(e) => {
                  setOthers(e.target.value);
                }}
              />
            </div>
            <div className="quantify_div_input_label"></div>
          </div>
        </div>


        <div className="evaluation_feedback_title">
          {t("evaluation_feedback.environmental")}
        </div>



        <div className="quantify_div">
          <div className="quantify_div_inputs">
            <div className="quantify_div_input_label">
              <label for="treesSaved" className="filter_list_comp">
                {" "}
                {t("evaluation_feedback.trees_saved")}*
              </label>
              <input
                className="text_field_getintouch"
                name="treesSaved"
                type="number"
                required
                onChange={(e) => {
                  setTreesSaved(e.target.value);
                }}
              />
            </div>

            <div className="quantify_div_input_label">
              <label for="WildlifeHabitatsPreserved" className="filter_list_comp">
                {" "}
                {t("evaluation_feedback.wildlife_habitats_preserved")}*
              </label>
              <input
                className="text_field_getintouch"
                name="WildlifeHabitatsPreserved"
                type="number"
                required
                onChange={(e) => {
                  setWildlifeHabitatsPreserved(e.target.value);
                }}
              />
            </div>

          </div>

          <div className="quantify_div_inputs">
            <div className="quantify_div_input_label">
              <label for="NaturalAreasCleaned " className="filter_list_comp">
                {" "}
                {t("evaluation_feedback.natural_areas_cleaned")}*
              </label>
              <input
                className="text_field_getintouch"
                name="NaturalAreasCleaned"
                type="number"
                required
                onChange={(e) => {
                  setNaturalAreasCleaned(e.target.value);
                }}
              />
            </div>
            <div className="quantify_div_input_label"></div>
          </div>
        </div>





        <div className="evaluation_feedback_title">
          {t("evaluation_feedback.cultural_preservation")}
        </div>



        <div className="quantify_div">
          <div className="quantify_div_inputs">
            <div className="quantify_div_input_label">
              <label for="HistoricalBuildingsRestored" className="filter_list_comp">
                {" "}
                {t("evaluation_feedback.historical_buildings_restored")}*
              </label>
              <input
                className="text_field_getintouch"
                name="HistoricalBuildingsRestored"
                type="number"
                required
                onChange={(e) => {
                  setHistoricalBuildingsRestored(e.target.value);
                }}
              />
            </div>

            <div className="quantify_div_input_label">
              <label for="CulturalArtifactsConserved" className="filter_list_comp">
                {" "}
                {t("evaluation_feedback.cultural_artifacts_conserved")}*
              </label>
              <input
                className="text_field_getintouch"
                name="CulturalArtifactsConserved"
                type="number"
                required
                onChange={(e) => {
                  setCulturalArtifactsConserved(e.target.value);
                }}
              />
            </div>

          </div>

          <div className="quantify_div_inputs">
            <div className="quantify_div_input_label">
              <label for="LocalTraditionsPromoted" className="filter_list_comp">
                {" "}
                {t("evaluation_feedback.local_traditions_promoted")}*
              </label>
              <input
                className="text_field_getintouch"
                name="LocalTraditionsPromoted"
                type="number"
                required
                onChange={(e) => {
                  setLocalTraditionsPromoted(e.target.value);
                }}
              />
            </div>
            <div className="quantify_div_input_label">

              <label for="InKindSuppliesDistributed" className="filter_list_comp">
                {" "}
                {t("evaluation_feedback.in_kind_supplies_distributed")}*
              </label>
              <input
                className="text_field_getintouch"
                name="InKindSuppliesDistributed"
                type="number"
                required
                onChange={(e) => {
                  setInKindSuppliesDistributed(e.target.value);
                }}
              />

            </div>
          </div>
        </div>



        <div className="evaluation_feedback_title">
          {t("evaluation_feedback.community_development")}
        </div>


        <div className="quantify_div">
          <div className="quantify_div_inputs">
            <div className="quantify_div_input_label">
              <label for="BuildingsImproved" className="filter_list_comp">
                {" "}
                {t("evaluation_feedback.buildings_improved")}*
              </label>
              <input
                className="text_field_getintouch"
                name="BuildingsImproved"
                type="number"
                required
                onChange={(e) => {
                  setBuildingsImproved(e.target.value);
                }}
              />
            </div>

            <div className="quantify_div_input_label">
              <label for="BuildingsConstructed" className="filter_list_comp">
                {" "}
                {t("evaluation_feedback.buildings_constructed")}*
              </label>
              <input
                className="text_field_getintouch"
                name="BuildingsConstructed"
                type="number"
                required
                onChange={(e) => {
                  setBuildingsConstructed(e.target.value);
                }}
              />
            </div>

          </div>

          <div className="quantify_div_inputs">
            <div className="quantify_div_input_label">
              <label for="PublicSpacesBeautified" className="filter_list_comp">
                {" "}
                {t("evaluation_feedback.public_spaces_beautified")}*
              </label>
              <input
                className="text_field_getintouch"
                name="PublicSpacesBeautified"
                type="number"
                required
                onChange={(e) => {
                  setPublicSpacesBeautified(e.target.value);
                }}
              />
            </div>
            <div className="quantify_div_input_label">

            </div>
          </div>
        </div>



        <div className="evaluation_feedback_title">
          {t("evaluation_feedback.sports_activities_events_organized")}
        </div>

        <div className="quantify_div">
          <div className="quantify_div_inputs">
            <div className="quantify_div_input_label">
              <label for="SportsActivitiesOrganized" className="filter_list_comp">
                {" "}
                {t("evaluation_feedback.sports_activities_organized")}
              </label>
              <div className="user_radio_comp">
                <input
                  class="complete_radio_btn"
                  type="radio"
                  id="yes"
                  name="SportsActivitiesOrganized"
                  value="yes"
                  onChange={() => {
                    setShowSportsParticipantsNumber(true);
                    setSportsActivitiesOrganized(true);
                  }}
                />
                <label for="yes" style={{ cursor: "pointer" }}>
                  {" "}
                  {t("evaluation_feedback.yes")}
                </label>
              </div>
              <div className="user_radio_comp">
                <input
                  class="complete_radio_btn"
                  type="radio"
                  id="no"
                  name="SportsActivitiesOrganized"
                  value="no"
                  onChange={() => {
                    setShowSportsParticipantsNumber(false);
                    setSportsActivitiesOrganized(false);
                    setSportsParticipantsNumber(0);
                  }}
                />
                <label for="no" style={{ cursor: "pointer" }}>
                  {t("evaluation_feedback.no")}
                </label>
              </div>

              {showSportsParticipantsNumber && <div className="organizationRole_div">

                <label for="SportsParticipantsNumber" className="filter_list_comp">
                  {" "}
                  {t("evaluation_feedback.sports_participants_number")}
                </label>
                <input
                  className="text_field_getintouch"
                  name="SportsParticipantsNumber"
                  type="number"
                  onChange={(e) => {
                    setSportsParticipantsNumber(e.target.value);
                  }}
                />
              </div>}

            </div>


            <div className="quantify_div_input_label">
              <label for="EventsOrganized" className="filter_list_comp">
                {" "}
                {t("evaluation_feedback.events_organized")}
              </label>
              <div className="user_radio_comp">
                <input
                  class="complete_radio_btn"
                  type="radio"
                  id="yes"
                  name="EventsOrganized"
                  value="yes"
                  onChange={() => {
                    setShowEventsParticipantsNumber(true);
                    setEventsOrganized(true);
                  }}
                />
                <label for="yes" style={{ cursor: "pointer" }}>
                  {" "}
                  {t("evaluation_feedback.yes")}
                </label>
              </div>
              <div className="user_radio_comp">
                <input
                  class="complete_radio_btn"
                  type="radio"
                  id="no"
                  name="EventsOrganized"
                  value="no"
                  onChange={() => {
                    setShowEventsParticipantsNumber(false);
                    setEventsOrganized(false);
                    setEventsParticipantsNumber(0);
                  }}
                />
                <label for="no" style={{ cursor: "pointer" }}>
                  {t("evaluation_feedback.no")}
                </label>
              </div>

              {showEventsParticipantsNumber && <div className="organizationRole_div">

                <label for="EventsParticipantsNumber" className="filter_list_comp">
                  {" "}
                  {t("evaluation_feedback.events_participants_number")}
                </label>
                <input
                  className="text_field_getintouch"
                  name="EventsParticipantsNumber"
                  type="number"
                  onChange={(e) => {
                    setEventsParticipantsNumber(e.target.value);
                  }}
                />
              </div>}

            </div>

          </div>
        </div>

        <div className="submit_div">
          <button
            className="evaluation_button"
            type="submit"
            disabled={!isFormValid}
          >
            {" "}
            {t("evaluation_feedback.submit")}
          </button>
        </div>
      </form>
    </div>
  );
}
export default EvaluationFeedback;
