import axios from "axios";
import { getOpportunitiesByStatus } from "../opportunities/opportunityActions";

import { opportunityActions } from "../opportunities/opportunityReducer";
import { FeedbacksActions } from "./FeedbacksReducers";

export const addFeedback = (data) => async (dispatch) => {
  try {
    dispatch(FeedbacksActions.AddFeedbackRequest());
    let response = await axios.post(
      process.env.REACT_APP_API + "/opportunity_feedbacks/",
      data
    );

    dispatch(FeedbacksActions.AddFeedbackRequestSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    dispatch(FeedbacksActions.AddFeedbackRequestFail(error.message));
  }
};
