import axios from "axios";
import { videoBlogAction } from "./videoBlogReducers";

export const getBlogVideos = (token) => async (dispatch) => {
  dispatch(videoBlogAction.fetchVideoBlogInfoRequest());
  try {
    let response = await axios.get(
      process.env.REACT_APP_API + "/video",
      { token }
    );
    dispatch(videoBlogAction.fetchVideoBlogInfoSuccess(response.data.data));
  } catch (error) {
    dispatch(videoBlogAction.fetchVideoBlogInfoFail(error.message));
  }
};