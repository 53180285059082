import "./Faqs.css";

import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import question from "../../assets/images/question-header.jpg";
import { SlArrowDown } from "react-icons/sl";
import { useDispatch } from "react-redux";
import { getFaqs } from "../../Redux/faqs/FaqsActions";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
import questionsar from "../../assets/images/question-headerar.jpg";
function Faqs() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [currentDropdown, setCurrentDropdown] = useState(null);
  const [rotate, setRotate] = useState(false);
  const [index, setindex] = useState(false);

  const { Faqs, loading } = useSelector((state) => state.faqsReducer);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getFaqs());
  }, []);

  return (
    <div className="faqs-page">
      <div className="faqs-green-block">
        <p>{t("Faqs.title")}</p>
      </div>
      <div className="faqs-container">
        <div className="img_container_faqs">
          {lang === "en" ? (
            <img src={question} className="faqs-big-img" alt="faqs" />
          ) : (
            <img src={questionsar} className="faqs-big-img" alt="faqs" />
          )}
        </div>
        {loading ? (
          <Loading />
        ) : (
          (Faqs && Faqs,
          Faqs.data?.map((info, i) => {
            return (
              <div
                className={lang === "en" ? "mapping-box" : "mapping-box-arabic"}
              >
                <div className={lang === "en" ? "line" : "line-arabic"}>
                  <p>{lang === "ar" ? info.question_ar : info.question}</p>
                  <span
                    className="arrow"
                    onClick={() => {
                      setRotate(!rotate);
                      setindex(i);
                    }}
                  >
                    <SlArrowDown
                      className={
                        index == i && !rotate
                          ? "arrow-icon"
                          : "arrow-icon-rotate"
                      }
                      onClick={() => {
                        if (currentDropdown === info.id)
                          setCurrentDropdown(null);
                        else setCurrentDropdown(info.id);
                      }}
                    />
                  </span>
                </div>
                {currentDropdown === info.id && (
                  <div
                    className={
                      lang === "ar"
                        ? "faqs_answer_container_ar"
                        : "faqs_answer_container"
                    }
                  >
                    {lang === "ar" ? info.answer_ar : info.answer}
                  </div>
                )}
              </div>
            );
          }))
        )}
      </div>
    </div>
  );
}

export default Faqs;
