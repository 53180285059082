import { React, useState, useEffect } from "react";
import "./Communityaction.css";
import UpperSection from "../../components/UpperSection/UpperSection";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import CommunityActionFilters from "../../components/Filters/CommunityActionFilters/CommunityActionFilters";
import { FaFilter } from "react-icons/fa";
import img1 from "../../assets/images/Home/main-image2.jpg";
import location from "../../assets/images/feedbacks/location.1247ae54.png";
import clock from "../../assets/images/feedbacks/clock.702492da.png";
import OppCard from "../../components/OppCard/OppCard";
import { AiFillPlusCircle } from "react-icons/ai";
import ReqCard from "../../components/ReqCard/ReqCard";
import CommunityActionFilters_2 from "../../components/Filters/CommunityActionFilters/CommunityActionFilters_2";
import { getFavoritesOpportunities } from "../../Redux/opportunities/opportunityActions";

//redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getallCommunityAct,
  getFavoritesComm,
  getvolCommunityAct,
  getvolreqCommunityAct,
} from "../../Redux/Community_Action/CommunityActActions";
import CreateOpp_Step1 from "../CreateOpp/CreateOpp_steps/Step1/CreateOpp_Step1";
import Loading from "../../components/Loading/Loading";
import CreateOpp_Pop from "../CreateOpp/CreateOpp_Pop/CreateOpp_Pop";

function CommunityAction() {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [showFilt, setShowFilt] = useState(false);
  const dispatch = useDispatch();
  const [volCreated, setVolCreated] = useState();
  const [volPaused, setVolPaused] = useState();
  const [volDraft, setVolDraft] = useState();
  const [volPast, setVolPast] = useState();
  const [role, setRole] = useState("");
  const [lang, setLang] = useState(i18n.language);
  const [footerWidth, setFooterWidth] = useState("8.33%");
  const [openCreate, setOpenCreate] = useState(false);
  const {
    communityAct,
    volcommunityAct,
    reqsvolcommunityAct,
    communityActLoading,
    favComm,
    loading,
  } = useSelector((store) => store.communityActReducer);
  const [activeBtn, setActiveBtn] = useState("created");

  useEffect(() => {
    dispatch(getallCommunityAct());
    getrole();
  }, []);
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);
  const getrole = () => {
    const role = localStorage.getItem("role");
    setRole(role);
  };
  useEffect(() => {
    if (activeBtn === "created") {
      dispatch(getvolCommunityAct());
    }
  }, [activeBtn]);
  useEffect(() => {
    if (activeBtn === "saved") {
      dispatch(getFavoritesComm());
    }
  }, [activeBtn]);

  useEffect(() => {
    if (activeBtn === "requests") {
      dispatch(getvolreqCommunityAct());
    }
  }, [activeBtn]);
  useEffect(() => {
    if (volcommunityAct) {
      let created = volcommunityAct.filter((item) => item.status === "Ongoing");
      let paused = volcommunityAct.filter((item) => item.status === "Paused");
      let draft = volcommunityAct.filter((item) => item.status === "Drafts");
      let past = volcommunityAct.filter((item) => item.status === "Past");
      setVolPast(past);
      setVolCreated(created);
      setVolPaused(paused);
      setVolDraft(draft);
    }
  }, [volcommunityAct]);

  return (
    <div className="Main_community_action">
      <UpperSection
        title={t("communityactions.title")}
        text={t("communityactions.text")}
        subtitle={t("communityactions.communityactions_1")}
        subtitle2={role == 3 && t("communityactions.communityactions_2")}
        page={page}
        setPage={setPage}
      />
      <div className="community_action_main">
        {page === 1 && (
          <div className="opp_requests_main_cont">
            <div className="opp_requests_filters_container">
              <CommunityActionFilters_2 />
            </div>
            {!showFilt && (
              <div
                className="filter_title_resp"
                onClick={() => setShowFilt(true)}
              >
                <FaFilter className="filter_main_icon" />
                {t("opportunity.filter.view_filter")}
                <div className="filers_main_underline"></div>
              </div>
            )}
            <div className="opp_filters_comp_resp">
              {showFilt && (
                <CommunityActionFilters_2 close={() => setShowFilt(false)} />
              )}
            </div>

            <div
              className={
                lang === "en"
                  ? "opp_requests_table_container"
                  : "opp_requests_table_container_ar"
              }
              onClick={() => {
                if (showFilt) {
                  setShowFilt(false);
                }
              }}
            >
              {communityActLoading ? (
                <Loading />
              ) : (
                <div className="community_action_cards_container">
                  {communityAct.map((item) => {
                    let flags = [];
                    if (item.wheelchair_accessible === true)
                      flags = [...flags, "handicap"];
                    if (item.transporation === "Provided")
                      flags = [...flags, "transport"];
                    if (item.food === "Provided") flags = [...flags, "food"];
                    if (item.is_call_to_action)
                      flags = [...flags, "calltoaction"];
                    return (
                      <OppCard
                        community={true}
                        title={item.title}
                        respond={item.respond_time}
                        deadline={new Date(
                          item.deadline_date
                        ).toLocaleDateString()}
                        spots={item?.nb_of_spots - item?.nb_of_volunteers}
                        points={item.type === "Time-based" ? 10 : 20}
                        id={item.id}
                        saved={localStorage.getItem("token") ? true : false}
                        image={item.img_url}
                        flags={flags}
                        location={item?.district.name}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
        {page === 2 && (
          <div className="call_requests_main_cont">
            {activeBtn == "requests" && (
              <div className="opp_requests_filters_container">
                {activeBtn == "requests" && <CommunityActionFilters />}
              </div>
            )}
            {!showFilt && activeBtn == "requests" && (
              <div
                className="filter_title_resp"
                onClick={() => setShowFilt(true)}
              >
                <FaFilter className="filter_main_icon" />
                {t("opportunity.filter.view_filter")}
                <div className="filers_main_underline"></div>
              </div>
            )}
            <div className="opp_filters_comp_resp">
              {showFilt && activeBtn == "requests" && (
                <CommunityActionFilters close={() => setShowFilt(false)} />
              )}
            </div>

            <div
              className={
                activeBtn == "requests"
                  ? "call_requests_table_container"
                  : "call_ongoing_table_container"
              }
              onClick={() => {
                if (showFilt) {
                  setShowFilt(false);
                }
              }}
            >
              <div className="my_opportunities_status_container">
                <div className="opportunities_status">
                  <button
                    className={
                      activeBtn == "created"
                        ? "opportunities_status_btn opportunities_status_btn_active"
                        : "opportunities_status_btn"
                    }
                    onClick={() => {
                      setActiveBtn("created");
                    }}
                  >
                    {t("communityactions.communityactions_5")}
                  </button>

                  <button
                    className={
                      activeBtn == "drafts"
                        ? "opportunities_status_btn opportunities_status_btn_active"
                        : "opportunities_status_btn"
                    }
                    onClick={() => {
                      setActiveBtn("drafts");
                    }}
                  >
                    {t("communityactions.communityactions_7")}
                  </button>
                  <button
                    className={
                      activeBtn == "paused"
                        ? "opportunities_status_btn opportunities_status_btn_active"
                        : "opportunities_status_btn"
                    }
                    onClick={() => {
                      setActiveBtn("paused");
                    }}
                  >
                    {t("communityactions.communityactions_8")}
                  </button>
                  <button
                    className={
                      activeBtn == "saved"
                        ? "opportunities_status_btn opportunities_status_btn_active"
                        : "opportunities_status_btn"
                    }
                    onClick={() => {
                      setActiveBtn("saved");
                    }}
                  >
                    {t("communityactions.communityactions_9")}
                  </button>
                  <button
                    className={
                      activeBtn == "requests"
                        ? "opportunities_status_btn opportunities_status_btn_active"
                        : "opportunities_status_btn"
                    }
                    onClick={() => {
                      setActiveBtn("requests");
                    }}
                  >
                    {t("communityactions.communityactions_6")}
                  </button>
                </div>
              </div>
              <div className="community_action_cards_container2">
                {activeBtn == "created" && (
                  <div className="">
                    <div
                      className="create_button_act"
                      onClick={() => setOpenCreate(true)}
                    >
                      {" "}
                      <AiFillPlusCircle size="2rem" />{" "}
                      {t("communityactions.communityactions_4")}
                    </div>
                    {openCreate && (

                      <CreateOpp_Pop
                        setOpen={setOpenCreate}
                        header={
                          t("opportunity.create_vol_opp")
                        }
                        body={
                          <CreateOpp_Step1
                            community={true}
                            setOpen={setOpenCreate}
                            setFooterWidth={setFooterWidth}
                          />
                        }
                        footer={
                          <div
                            className={
                              lang == "en"
                                ? "create_opp_footer_slider_green"
                                : "create_opp_footer_slider_green create_opp_footer_slider_green_ar"
                            }
                            style={{ width: footerWidth }}
                          ></div>
                        }
                      />
                    )}
                    {communityActLoading ? (
                      <Loading />
                    ) : (
                      <div className="no_requests">
                        {volCreated.map((item) => {
                          let flags = [];
                          if (item?.is_call_to_action)
                            flags = [...flags, "calltoaction"];
                          if (item?.wheelchair_accessible === true)
                            flags = [...flags, "handicap"];
                          if (item?.transporation === "Provided")
                            flags = [...flags, "transport"];
                          if (item?.food === "Provided")
                            flags = [...flags, "food"];
                          return (
                            <OppCard
                              community={true}
                              flags={flags}
                              status={item.status}
                              title={item.title}
                              respond={item.respond_time}
                              deadline={new Date(
                                item.deadline_date
                              ).toLocaleDateString()}
                              spots={item.nb_of_spots - item.nb_of_volunteers}
                              points={item.type === "Time-based" ? 10 : 20}
                              id={item.id}
                              image={item.img_url}
                              location={item?.district.name}
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
                {activeBtn == "requests" &&
                  (communityActLoading ? (
                    <Loading />
                  ) : (
                    <div className="no_requests">
                      {reqsvolcommunityAct.map((item) => {
                        return item.requests.map((reqss) => {
                          return (
                            <ReqCard
                              reqs={reqss}
                              full_name={
                                reqss.volunteer.first_name +
                                " " +
                                reqss.volunteer.last_name
                              }
                              id={reqss.id}
                              points={reqss?.volunteer?.points}
                              title={item.title}
                              score={reqss.score}
                            />
                          );
                        });
                      })}
                    </div>
                  ))}
                {activeBtn == "drafts" &&
                  (communityActLoading ? (
                    <Loading />
                  ) : (
                    <div className="no_requests">
                      {volDraft?.length > 0 &&
                        volDraft?.map((item) => {
                          let flags = [];
                          if (item?.is_call_to_action)
                            flags = [...flags, "calltoaction"];
                          if (item?.wheelchair_accessible === true)
                            flags = [...flags, "handicap"];
                          if (item?.transporation === "Provided")
                            flags = [...flags, "transport"];
                          if (item?.food === "Provided")
                            flags = [...flags, "food"];
                          return (
                            <OppCard
                              community={true}
                              status={item?.status}
                              flags={flags}
                              title={item?.title}
                              respond={item?.respond_time}
                              deadline={
                                item.deadline_date &&
                                new Date(
                                  item?.deadline_date
                                ).toLocaleDateString()
                              }
                              spots={item?.nb_of_spots - item?.nb_of_volunteers}
                              points={item?.type === "Time-based" ? 10 : 20}
                              id={item?.id}
                              image={item?.img_url}
                              location={item?.district?.name}
                            />
                          );
                        })}
                    </div>
                  ))}
                {activeBtn == "paused" &&
                  (communityActLoading ? (
                    <Loading />
                  ) : (
                    <div className="no_requests">
                      {volPaused.map((item) => {
                        let flags = [];
                        if (item?.is_call_to_action)
                          flags = [...flags, "calltoaction"];
                        if (item?.wheelchair_accessible === true)
                          flags = [...flags, "handicap"];
                        if (item?.transporation === "Provided")
                          flags = [...flags, "transport"];
                        if (item?.food === "Provided")
                          flags = [...flags, "food"];
                        return (
                          <OppCard
                            community={true}
                            status={item.status}
                            title={item.title}
                            flags={flags}
                            respond={item.respond_time}
                            deadline={new Date(
                              item.deadline_date
                            ).toLocaleDateString()}
                            spots={item.nb_of_spots - item.nb_of_volunteers}
                            points={item.type === "Time-based" ? 10 : 20}
                            id={item.id}
                            image={item.img_url}
                            location={item?.district.name}
                          />
                        );
                      })}
                    </div>
                  ))}
                {activeBtn == "past" &&
                  (communityActLoading ? (
                    <Loading />
                  ) : (
                    <div className="no_requests">
                      {volPast.map((item) => {
                        let flags = [];
                        if (item?.is_call_to_action)
                          flags = [...flags, "calltoaction"];
                        if (item?.wheelchair_accessible === true)
                          flags = [...flags, "handicap"];
                        if (item?.transporation === "Provided")
                          flags = [...flags, "transport"];
                        if (item?.food === "Provided")
                          flags = [...flags, "food"];
                        return (
                          <OppCard
                            community={true}
                            status={item.status}
                            title={item.title}
                            flags={flags}
                            respond={item.respond_time}
                            deadline={new Date(
                              item.deadline_date
                            ).toLocaleDateString()}
                            spots={item.nb_of_spots - item.nb_of_volunteers}
                            points={item.type === "Time-based" ? 10 : 20}
                            id={item.id}
                            image={item.img_url}
                            location={item?.district.name}
                          />
                        );
                      })}
                    </div>
                  ))}
                {activeBtn == "saved" &&
                  (loading ? (
                    <Loading />
                  ) : (
                    <div className="no_requests">
                      {favComm.length > 0 &&
                        favComm?.map((item) => {
                          let flags = [];
                          if (item?.opportunity.is_call_to_action)
                            flags = [...flags, "calltoaction"];
                          if (item?.opportunity.wheelchair_accessible === true)
                            flags = [...flags, "handicap"];
                          if (item?.opportunity.transporation === "Provided")
                            flags = [...flags, "transport"];
                          if (item?.opportunity.food === "Provided")
                            flags = [...flags, "food"];

                          return (
                            <OppCard
                              community={true}
                              status="Saved"
                              flags={flags}
                              title={item.opportunity.title}
                              respond={item.opportunity.respond_time}
                              deadline={new Date(
                                item.opportunity.deadline_date
                              ).toLocaleDateString()}
                              spots={
                                item.opportunity.nb_of_spots -
                                item.opportunity.nb_of_volunteers
                              }
                              points={
                                item.opportunity.type === "Time-based" ? 10 : 20
                              }
                              id={item.opportunity.id}
                              image={item.opportunity.img_url}
                            />
                          );
                        })}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CommunityAction;
