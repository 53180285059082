import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Loading from "../../../../components/Loading/Loading";
import i18n from "../../../../i18n";
import {
  getuserinfo,
  updateOrgOccupation,
} from "../../../../Redux/user/userActions";
import CompleteProfile from "../../CompleteProfile";
import "./CompleteOrg_Step4.css";

function CompleteOrg_Step4() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [isNext, setIsNext] = useState(true);
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState();
  const [selectesd, setSelectesd] = useState("");
  const size = useWindowSize();

  const token = localStorage.getItem("token");
  const id = localStorage.getItem("id");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userinfo, loading } = useSelector((store) => store.userReducer);

  useEffect(() => {
    if (Object.keys(userinfo).length > 0) {
      if (userinfo.occupation == "Other") {
        setSelectesd("Other");
        setShow(false);
      } else {
        setShow(true);
        setChecked({ value: userinfo.occupation, label: userinfo.occupation });
      }
    }
  }, [userinfo]);

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const onSubmit = (e) => {
    e.preventDefault();
    const step = localStorage.getItem("step");

    if (step == 3) {
      localStorage.setItem("step", 4);
    }
    if (isNext) dispatch(updateOrgOccupation(token, selectesd, navigate, 5));
    else dispatch(updateOrgOccupation(token, selectesd, navigate, 0));
  };

  useEffect(() => {
    dispatch(getuserinfo(token));
  }, []);

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  const handleCheck = (e, radio) => {
    if (radio)
      if (e.target.value == "Yes") {
        setShow(true);
        setSelectesd("");
      } else {
        setShow(false);
        setSelectesd("Other");
      }
  };

  useEffect(() => {
    if (checked?.hasOwnProperty("value")) {
      setSelectesd(checked.value);
    }
  }, [checked]);

  const options = [
    { value: "Company", label: lang == "en" ? "Company" : "شركة" },
    { value: "University", label: lang == "en" ? "University" : "جامعة" },
    { value: "Institution", label: lang == "en" ? "Institution" : "مؤسسة" },
  ];

  return (
    <div className="complete_profile_step2_main_cont">
      <CompleteProfile
        header={t("CompleteProf.complete_your_profile_step9")}
        subtitle={t("CompleteProf.complete_your_profile_org_step4")}
        steps={2}
        body={
          loading ? (
            <Loading />
          ) : (
            <form
              className="complete_step2_main_body"
              onSubmit={(e) => onSubmit(e)}
            >
              <div>
                <div className="complete_step6_org_all_avail">
                  <div className="inner">
                    <input
                      value={"Yes"}
                      type="radio"
                      id={"avail_radio1"}
                      name="availability"
                      className="avail_radio"
                      required
                      onChange={(e) => handleCheck(e, true)}
                      checked={selectesd != "Other" ? true : false}
                    />
                    <label
                      htmlFor="avail_radio1"
                      style={{
                        paddingLeft: "0.3rem",
                        marginTop: "-0.4rem",
                        cursor: "pointer",
                      }}
                    >
                      Yes
                    </label>
                  </div>
                  <div className="inner">
                    <input
                      value={"No"}
                      type="radio"
                      id={"avail_radio2"}
                      name="availability"
                      className="avail_radio"
                      required
                      onChange={(e) => handleCheck(e, true)}
                      checked={selectesd == "Other" ? true : false}
                    />
                    <label
                      htmlFor="avail_radio2"
                      style={{
                        paddingLeft: "0.3rem",
                        marginTop: "-0.4rem",
                        cursor: "pointer",
                      }}
                    >
                      No
                    </label>
                  </div>
                </div>
                {show && (
                  <div className="complete_step3_header">
                    <p
                      className="complete_input_header"
                      style={{ marginBottom: "1rem" }}
                    >
                      {t("CompleteProf.complete_your_profile_step4_org")}
                    </p>{" "}
                    <Select
                      value={checked && checked}
                      className="complete_profile_select"
                      name="nationality"
                      onChange={setChecked}
                      placeholder={t(
                        "CompleteProf.complete_your_profile_step4_org"
                      )}
                      required
                      options={options}
                      isSearchable={false}
                    />
                  </div>
                )}
              </div>

              <div className="complete_step1_footer">
                <div className="divider_line"></div>
                <div className="complete_step_footer_button">
                  <button
                    className={
                      size.width > 768
                        ? "primary_button complete_step_1_next_button"
                        : "primary_button complete_step_2_next_button_resp"
                    }
                    type="button"
                    onClick={() => navigate("/completeProfile/org/step3")}
                  >
                    {size.width > 768 ? (
                      t("CompleteProf.complete_your_profile_btn_5")
                    ) : lang == "en" ? (
                      <AiOutlineArrowLeft size="1.5rem" />
                    ) : (
                      <AiOutlineArrowRight size="1.5rem" />
                    )}
                  </button>

                  <div
                    className={
                      size.width > 768
                        ? "complete_step_right_footer"
                        : "complete_step_right_footer_resp"
                    }
                  >
                    <button
                      className={
                        size.width > 500
                          ? "pink_button complete_step_2_complete_button"
                          : "pink_button complete_step_2_next_button_resp"
                      }
                      onClick={() => setIsNext(false)}
                    >
                      {size.width > 500 ? (
                        t("CompleteProf.complete_your_profile_btn_3")
                      ) : (
                        <BiSave size="1.5rem" />
                      )}
                    </button>
                    <button
                      className={
                        size.width > 768
                          ? "primary_button complete_step_2_next_button"
                          : "primary_button complete_step_2_next_button_resp"
                      }
                      onClick={() => setIsNext(true)}
                    >
                      {size.width > 768 ? (
                        t("CompleteProf.complete_your_profile_btn_4")
                      ) : lang == "en" ? (
                        <AiOutlineArrowRight size="1.5rem" />
                      ) : (
                        <AiOutlineArrowLeft size="1.5rem" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )
        }
        footer={
          <div
            className={
              lang == "en"
                ? "complete_profile_footer_slider_green"
                : "complete_profile_footer_slider_green complete_profile_footer_slider_green_ar"
            }
            style={{ width: "80%" }}
          ></div>
        }
      />
    </div>
  );
}

export default CompleteOrg_Step4;
