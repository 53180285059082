import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  evaluations: [],
  loading: false,
  error: "",
  success: false,
};

const evaluationsSlice = createSlice({
  name: "evaluations",
  initialState,
  reducers: {
    AddEvaluationRequest(state) {
      state.loading = true;
      state.error = "";
      state.success = false;
    },
    AddEvaluationRequestSuccess(state, action) {
      state.loading = false;
      state.evaluations = action.payload;
      state.error = "";
      state.success = true;
    },
    AddEvaluationRequestFail(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
  },
});

export const EvaluationsActions = evaluationsSlice.actions;
export default evaluationsSlice;
