import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./SubscriptionPages.css";
import UpperSection from "../../components/UpperSection/UpperSection";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import SubscriptionCards from "../../components/SubscriptionCards/SubscriptionCards";
import { getSubscriptionValues } from "../../Redux/SubscriptionValues/SubscriptionValuesActions";
import { getSubscriptionsDescriptions } from "../../Redux/SubscriptionDescription/SubscriptionDescriptionActions";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
// Import other JavaScript components if needed

import "react-notifications/lib/notifications.css";
import Switch from "react-switch";

function SubscriptionPages() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [lang, setLang] = useState(i18n.language);
  const [paymentMethode, setPaymentMethode] = useState('six_month');
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const [subscriptionsDescritionsData, setSubscriptionsDescritionsData] = useState([]);
  const [openSuccessMsg, setOpenSuccessMsg] = useState(false);
  const [openWarningMsg, setOpenWarningMsg] = useState(false);
  const { successLoading, successError } = useSelector(
    (store) => store.PayReducer
  );
  const { cashs, cachLoading } = useSelector(
    (store) => store.PayReducer
  );

  const { subscriptionsDescriptions} = useSelector(
    (store) => store.SubscriptionDescriptionReducer
  );
  const { SubscriptionValues} = useSelector(
    (store) => store.SubscriptionValuesReducer
  );

  useEffect(() => {
    if (!successLoading && openSuccessMsg) {
      if (successError.length == 0) {
        NotificationManager.success("You can start benefiting from our volunteer!", "", 3000);
      } else {
        NotificationManager.error(successError, "", 3000);
      }
    }
  }, [successLoading, openSuccessMsg, successError]);


  useEffect(() => {
    if (!cachLoading && openWarningMsg) {
      NotificationManager.warning(t("Subscription.pay_message"), "", 3000);
    }
  }, [cachLoading, openWarningMsg]);

  const TwoPartButton = () => {
    return (
      <div className="buttons_div">
        <span onClick={() => setPaymentMethode('six_month')} className={paymentMethode === 'six_month' ? "active_switch_btn" : "inactive_switch_btn"}>{t("Subscription.6months")}</span>
        | <span onClick={() => setPaymentMethode('annual')} className={paymentMethode === 'annual' ? "active_switch_btn" : "inactive_switch_btn"}>{t("Subscription.yearly")} </span>
      </div>
    );
  };

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    console.log('xxxxxxxx' , subscriptionsDescriptions)
    console.log('subscriptionsDescriptionssubscriptionsDescriptions' , SubscriptionValues)
  }, [SubscriptionValues]);

  useEffect(() => {
    dispatch(getSubscriptionValues());
    dispatch(getSubscriptionsDescriptions());
  }, []);

  useEffect(() => {
    if (SubscriptionValues != []) {
      setSubscriptionsData(SubscriptionValues);
    }
  }, [SubscriptionValues]);


  useEffect(() => {
    if (subscriptionsDescriptions != []) {
      setSubscriptionsDescritionsData(subscriptionsDescriptions);
    }
  }, [subscriptionsDescriptions]);

  return (
    <div className="Main_subscription">
      <div className="subscription_title">
        <UpperSection title={t("Subscription.title")} />
      </div>
      <NotificationContainer />
      <div className="subscription_content">
        <div className="subscription_headings">
          {t("Subscription.heading")}
          <span className="subscription_span">
            {t("Subscription.heading_1")}
          </span>
          {t("Subscription.heading_2")}
        </div>
        <div className="underline_subs" />
      </div>
      <div className="subscription_cards_container">
        <div className="Main_sub_card_div">
          <div className="left_sub_card_div main_sub_card_div"  style={{borderRadius :lang === "en" ? "20px 0px 0px 20px" : "0px 20px 20px 0px" , borderRight: lang === "en" ? "none" : "" , borderLeft: lang === "en" ? "" : "none"}}>
            <div className="sub_card_content_master sub_card_content">
            {subscriptionsDescritionsData.map((subscription, index) => {
              return (

                <div style={{  backgroundColor: index % 2 == 0 ? "#f9f9f9" : ""  , borderRadius : (index == 0 ? ( lang === "en" ? "20px 0px 0px 0px" : "0px 20px 0px 0px") : (index == subscriptionsDescriptions.length - 1 ? ( lang === "en" ? "0px 0px 0px 20px" : "0px 0px 20px 0px"): ""))}} className="content_sub">
                <div className="content_sub_text">
                  {" "}
                  {lang === "en" ? subscription.name : subscription.name_ar}
                </div>
              </div>

              )
            })}

            </div>
          </div>
          <div className="left_sub_card">
            <div className="sub_card_heading">
              <TwoPartButton />
            </div>
          </div>
        </div>
        {subscriptionsData.map((subscription, index) => {
          return (
            <div className="subs_cont">
              <SubscriptionCards
                setOpenSuccessMsg={setOpenSuccessMsg}
                setOpenWarningMsg={setOpenWarningMsg}
                title={subscription.name}
                subtitle={subscription.description}
                subscription_id={subscription.id}
                heading={
                  paymentMethode === 'six_month' ? (
                    <span>
                      {`$${subscription.cost_per_six_month} `}
                      <sub className="currenry_style">USD</sub>
                    </span>
                  ) : (

                    <span>
                      {`$${subscription.cost_per_year} `}
                      <sub className="currenry_style">USD</sub>
                    </span>
                  )
                }
                paymentMethodeVal={paymentMethode}
                content = {subscription.subscriptionValues}
                year_price={subscription.cost_per_year}
                six_month_price={subscription.cost_per_six_month}
                cost_per_month={subscription.cost_per_month}
                color={
                  index % 4 == 0
                    ? "#f06a80"
                    : index % 4 == 1
                      ? "#242E6C"
                      : index % 4 == 2
                        ? "#72c095"
                        : "#1C1C1C"
                }
              />
            </div>
          );
        })}
      </div>

      <div className="subscription_bottom_container">
        <div className="Main_sub_card_div">
          <div className="left_sub_card_div second_main_sub_card_div" style={{borderRadius :lang === "en" ? "20px 0px 0px 20px" : "0px 20px 20px 0px"}}>
            <div className="sub_card_content_master sub_card_content" style={{ borderRight: (lang === "en" ? "1px solid var(--dark-grey)" : "" ), borderLeft: (lang === "en" ? "" : "1px solid var(--dark-grey)" )}}>
              <div className="content_sub" style={{ backgroundColor: "#f9f9f9" , borderRadius : (lang === "en" ? "20px 0px 0px 0px":"0px 20px 0px 0px") , borderBottom:'1px solid var(--dark-grey)'}}>
                <div className="content_sub_text" style={{color: "#f06a80"}}>
                  {" "}
                  {t("Subscription.subscription_benifits")}
                </div>
              </div>

              <div className="content_sub" style={{ backgroundColor: "#f9f9f9" ,  borderRadius : (lang === "en" ? "0px 0px 0px 20px":"0px 0px 20px 0px")}}>
                <div className="content_sub_text" style={{color: "#72c095"}}>
                  {" "}
                  {t("Subscription.win_situation")}
                </div>
              </div>

            </div>
          </div>
        </div>


        <div className="sub_card_content_master second_sub_card_content">
          <div className="content_sub content_sub_first" style={{borderBottom:"1px solid var(--dark-grey)"}}>
            <div className="content_sub_text">
              {" "}
              {t("Subscription.subscription_benifits_resp")}
            </div>
          </div>

          <div className="second_content_sub">
            <div className="content_sub_text">
              {" "}
              {t("Subscription.win_situation_resp")}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default SubscriptionPages;
