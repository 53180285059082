import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import i18n from "../../../../i18n";
import CompleteProfile from "../../CompleteProfile";
import "./Complete_Step8.css";
import Select from "react-select";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import {
  getuserinfo,
  updateUserinfo,
} from "../../../../Redux/user/userActions";
import { getReasons } from "../../../../Redux/Reasons/ReasonsActions";

import Loading from "../../../../components/Loading/Loading";

function Complete_Step8() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const navigate = useNavigate();
  const size = useWindowSize();
  const dispatch = useDispatch();
  const [isNext, setIsNext] = useState(true);
  const [reason, setReason] = useState([]);
  const [isVol, setIsVol] = useState(null);
  const { userinfo, loading } = useSelector((store) => store.userReducer);
  const { reasons } = useSelector((store) => store.ReasonsReducer);
  const [allReasons, setAllReasons] = useState([]);

  const token = localStorage.getItem("token");

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  
  useEffect(() => {
    if (reasons) {
      setAllReasons(reasons)
    }
  }, [reasons]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (reason && isVol != null) {
      const user = {
        volunteered_before: isVol,
        reasons:[...new Set(reason)],
      };

      const step = localStorage.getItem("step");
      if (step == 7) {
        localStorage.setItem("step", 8);
      }
      if (isNext) dispatch(updateUserinfo(token, user, navigate, 9));
      else dispatch(updateUserinfo(token, user, navigate, 0));
    }
  };

  const allVol = [
    {
      label: lang == "en" ? "Yes" : "نعم",
      value: true,
      id: 1,
    },

    {
      label: lang == "en" ? "No" : "لا",
      value: false,
      id: 2,
    },
  ];


  const handleCheck = (e, first) => {
    if (first) {
      setIsVol(e.target.value);
    } else {
      const value = parseInt(e.target.value, 10);
      if (e.target.checked) {
        setReason((prevAvailability) => [...prevAvailability, value]);
      } else {
        setReason((prevAvailability) =>
          prevAvailability.filter((item) => item !== value)
        );
      }
    }
  };

  useEffect(() => {
    dispatch(getuserinfo(token));
    dispatch(getReasons());
  }, []);

  useEffect(() => {
    if (userinfo?.volunteer_reasons) {
      console.log(userinfo?.volunteer_reasons)
        userinfo?.volunteer_reasons?.map((reason) => {
          setReason((prevAvailability) => [...prevAvailability, reason.reson_id]);
        });
      }
  }, [userinfo]);

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  return (
    <div className="complete_profile_step1_main_cont">
      <CompleteProfile
        header={t("CompleteProf.complete_your_profile7_1")}
        body={
          loading ? (
            <Loading />
          ) : (
            <form
              className="complete_step3_main_body"
              onSubmit={(e) => handleSubmit(e)}
            >
              <div>
                <div className="complete_step3_header">
                  <p
                    className="complete_input_header"
                    style={{ marginBottom: "1rem" }}
                  >
                    {t("CompleteProf.complete_your_profile8_1")}
                  </p>{" "}
                  <div className="complete_step6_all_avail">
                    {allVol &&
                      allVol.map((avail) => {
                        return (
                          <div className="complete_step6_avail">
                            <input
                              required
                              value={avail.id}
                              type="radio"
                              id={"volunteered" + avail.id}
                              name="volunteered"
                              className="avail_radio"
                              onChange={(e) => handleCheck(e, true)}
                            />
                            <div className="complete_step4_sector_info">
                              <label
                                className="complete_step4_avail_name"
                                for={"avail_radio" + avail.id}
                              >
                                {" "}
                                {avail.label}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="complete_step3_header">
                  <p
                    className="complete_input_header"
                    style={{ marginBottom: "1rem" }}
                  >
                    {t("CompleteProf.complete_your_profile8_2")}
                  </p>{" "}
                  <div className="complete_step6_all_avail">
                    {allReasons &&
                      allReasons?.map((avail) => {
                        return (
                          <div className="complete_step6_avail">
                            <input
                              value={avail.id}
                              type="checkbox"
                              id={"whyvol" + avail.id}
                              name="whyvol"
                              className="avail_radio"
                              onChange={(e) => handleCheck(e, false)}
                              checked={
                                reason.includes(avail.id) ? "checked" : ""
                              }
                            />
                            <div className="complete_step4_sector_info">
                              <label
                                className="complete_step4_avail_name"
                                for={"avail_radio" + avail.id}
                              >
                                {" "}
                                {lang == "en" ? avail.name : avail.name_ar}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="complete_step1_footer">
                <div className="divider_line"></div>
                <div className="complete_step_footer_button">
                  <button
                    className={
                      size.width > 768
                        ? "primary_button complete_step_1_next_button"
                        : "primary_button complete_step_2_next_button_resp"
                    }
                    type="button"
                    onClick={() => navigate("/completeProfile/step7")}
                  >
                    {size.width > 768 ? (
                      t("CompleteProf.complete_your_profile_btn_5")
                    ) : lang == "en" ? (
                      <AiOutlineArrowLeft size="1.5rem" />
                    ) : (
                      <AiOutlineArrowRight size="1.5rem" />
                    )}
                  </button>

                  <div
                    className={
                      size.width > 768
                        ? "complete_step_right_footer"
                        : "complete_step_right_footer_resp"
                    }
                  >
                    <button
                      className={
                        size.width > 500
                          ? "pink_button complete_step_2_complete_button"
                          : "pink_button complete_step_2_next_button_resp"
                      }
                      onClick={() => setIsNext(false)}
                    >
                      {size.width > 500 ? (
                        t("CompleteProf.complete_your_profile_btn_3")
                      ) : (
                        <BiSave size="1.5rem" />
                      )}
                    </button>
                    <button
                      className={
                        size.width > 768
                          ? "primary_button complete_step_2_next_button"
                          : "primary_button complete_step_2_next_button_resp"
                      }
                      onClick={() => setIsNext(true)}
                    >
                      {size.width > 768 ? (
                        t("CompleteProf.complete_your_profile_btn_4")
                      ) : lang == "en" ? (
                        <AiOutlineArrowRight size="1.5rem" />
                      ) : (
                        <AiOutlineArrowLeft size="1.5rem" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )
        }
        footer={
          <div
            className={
              lang == "en"
                ? "complete_profile_footer_slider_green"
                : " complete_profile_footer_slider_green complete_profile_footer_slider_green_ar"
            }
            style={{ width: "80%" }}
          ></div>
        }
      />
    </div>
  );
}

export default Complete_Step8;
