import React, { useEffect, useState } from "react";
import i18n from "../../../../i18n";
import { useTranslation } from "react-i18next";
import "./Apply_Step6.css";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import apply_img_step6 from "../../../../assets/images/opportunity/apply/apply_step6.png";
import Apply_Step5 from "../Apply_Step5/Apply_Step5";
import Apply_Step1 from "../Apply_Step1/Apply_Step1";
import Apply_Step4 from "../Apply_Step4/Apply_Step4";

function Apply_Step6({ setOpen, open, data, setFooterWidth, opportunityId, openOutOfArea ,previousFirstPage}) {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [openApplyPopUpStep, setOpenApplyPopUpStep] = useState(false);
  const [oppData, setOppData] = useState("");
  const size = useWindowSize();
  const [previous, setPrevious] = useState(false);

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    setPrevious(false);
    setOppData({ ...data });
    setFooterWidth("62.5%");
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setOpenApplyPopUpStep(true);
  };

  return (
    <>

      {previous && previousFirstPage && (
        <Apply_Step1
          setOpen={setOpen}
          open={open}
          opportunityId={opportunityId}
        />
      )}

      {previous &&  !previousFirstPage && (
        <Apply_Step4
          setOpen={setOpen}
          open={open}
          data={data}
          openOutOfAreaa={openOutOfArea}
          opportunityId={opportunityId}
          setFooterWidth={setFooterWidth}
        />
      )}

      {!previous && !openApplyPopUpStep ? (
        <form className="apply_step6" onSubmit={(e) => onSubmit(e)}>
          <img className="apply_img" src={apply_img_step6} />
          <div>{t("opportunity.apply_popup.out_of_area")}</div>

          <div className="dotter_spacer" />
          <div class="complete_step_footer_button">
            <div className="div_buttons div_buttons_left">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setPrevious(true);
                }}
                className={
                  size.width > 768
                    ? "apply_primary_button app_step2_apply_later_button gray-btn"
                    : "apply_primary_button app_step2_apply_later_button_resp gray-btn"
                }
              >
                {t("opportunity.apply_popup.previous")}
              </button>
            </div>

            <div className="div_buttons">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(false);
                }}
                className={
                  size.width > 768
                    ? "apply_primary_button app_step4_apply_later_button"
                    : "apply_primary_button app_step4_apply_later_button_resp"
                }
              >
                {t("opportunity.apply_popup.apply_later")}
              </button>
              <button
                type="submit"
                className={
                  size.width > 768
                    ? "primary_button app_step4_next_button"
                    : "primary_button app_step4_next_button_resp"
                }
              >
                {size.width > 768 ? (
                  t("opportunity.apply_popup.gotIt")
                ) : lang == "en" ? (
                  <AiOutlineArrowRight size="1.5rem" />
                ) : (
                  <AiOutlineArrowLeft size="1.5rem" />
                )}
              </button>
            </div>
          </div>
        </form>
      ) : (
        !previous && <Apply_Step5
          setOpen={setOpen}
          open={open}
          data={oppData}
          openOutOfArea={openOutOfArea}
          opportunityId={opportunityId}
          setFooterWidth={setFooterWidth}
          previousFirstPage = {previousFirstPage}
        />
      )}
    </>
  );
}

export default Apply_Step6;
