import axios from "axios";
import { SubscriptionValuesAction } from "./SubscriptionValuesReducer";

export const getSubscriptionValues = () => async (dispatch) => {
  dispatch(SubscriptionValuesAction.SubscriptionValuesRequest());
  try {
    let response = await axios.get(process.env.REACT_APP_API + "/subscriptionValue/all");
    dispatch(SubscriptionValuesAction.SubscriptionValuesSuccess(response.data.data));
  } catch (error) {
    dispatch(SubscriptionValuesAction.SubscriptionValuesFail(error.message));
  }
};
