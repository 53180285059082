import axios from "axios";
import { userAction } from "../user/userReducers";
import { useDispatch } from "react-redux";

export const getuserinfo = (token) => async (dispatch) => {
  dispatch(userAction.fetchuserinfoRequest());
  try {
    let response = await axios.get(
      process.env.REACT_APP_API + "/users/my-info",
      { token }
    );

    dispatch(userAction.fetchuserinfoSuccess(response.data.data));
  } catch (error) {
    dispatch(userAction.fetchuserinfoFail(error.message));
  }
};
export const updateUserinfo =
  (token, data, navigate, step) => async (dispatch) => {
    if (data.hasOwnProperty("image")) {
      const formData = new FormData();
      formData.append("img_url", data.image);
      try {
        dispatch(userAction.updateUserImgRequest());
        let response = await axios.put(
          process.env.REACT_APP_API + "/organizations/assignUrlImg/",
          formData,
          { Authorization: "Bearer " + token }
        );
        dispatch(userAction.updateUserImgSuccess());
      } catch (error) {
        dispatch(userAction.updateUserImgFail(error.response.data.message));
      }
      delete data.image;
    }
    try {
      dispatch(userAction.updateUserRequest());
      let response = await axios.put(
        process.env.REACT_APP_API + "/users/update-my-info",

        data,

        { Authorization: "Bearer " + token }
      );
      if (step == "profile-info") {
        window.location.reload(true);
       if(data.step != undefined) localStorage.setItem("step", data.step);
      } else {
        if (localStorage.getItem("role") == 3)
          if (step > 0) navigate("/completeProfile/step" + step);
          else navigate("/profile-info");
        else {
          if (step > 0) navigate("/completeProfile/org/step" + step);
          else navigate("/profile-info");
        }
      }

      dispatch(getuserinfo(token));
    } catch (error) {
      dispatch(userAction.updateUserinfoFail(error.message));
    }
  };

export const changeVolunteerWorkStatus = (token, value) => async (dispatch) => {
  dispatch(userAction.updateUserRequest());
  try {
    let response = await axios.put(
      process.env.REACT_APP_API + "/users/update-my-info",

      { open_to_work: value },

      { Authorization: "Bearer " + token }
    );
    dispatch(userAction.updateUserStatus(response.status));
  } catch (error) {
    dispatch(userAction.updateUserinfoFail(error.message));
  }
};

export const updateUserDisc =
  (orgDisc, token, navigate, step) => async (dispatch) => {
    try {
      dispatch(userAction.updateUserRequest());
      let res = await axios.put(
        process.env.REACT_APP_API + "/users/update-my-info",

        { about: orgDisc },

        { Authorization: "Bearer " + token }
      );
      if (step > 0) navigate("/completeProfile/org/step" + step);
      else navigate("/profile-info");

      dispatch(userAction.updateUserSuccess(res.status));
    } catch (error) {
      dispatch(userAction.updateUserinfoFail(error.message));
    }
  };

export const getSectors = (token) => async (dispatch) => {
  try {
    dispatch(userAction.getSectorsofOrgRequest());
    let res = await axios.get(
      process.env.REACT_APP_API + "/organizationsector/organization/",
      {
        token,
      }
    );
    dispatch(userAction.getSectorsofOrgSuccess(res.data.data));
  } catch (error) {
    dispatch(userAction.getSectorsofOrgFail(error.response.data.message));
  }
};
export const updateOrganizationSectors =
  (token, sectors, navigate, step) => async (dispatch) => {
    dispatch(userAction.updateUserRequest());
    try {
      let response = await axios.put(
        process.env.REACT_APP_API + "/organizations/editOrg/",
        {
          sector_id: sectors,
        },
        { Authorization: "Bearer " + token }
      );
      dispatch(userAction.updateUserStatus(response.status));

      if (step > 0) navigate("/completeProfile/org/step" + step);
      else navigate("/profile-info");
    } catch (error) {
      dispatch(userAction.updateUserinfoFail(error.message));
    }
  };
export const updateOrgOccupation =
  (token, selected, navigate, step) => async (dispatch) => {
    dispatch(userAction.updateUserRequest());
    try {
      let response = await axios.put(
        process.env.REACT_APP_API + "/users/update-my-info",
        {
          occupation: selected,
        },
        { Authorization: "Bearer " + token }
      );
      dispatch(userAction.updateUserStatus(response.status));

      if (step > 0) navigate("/completeProfile/org/step" + step);
      else navigate("/profile-info");
    } catch (error) {
      dispatch(userAction.updateUserinfoFail(error.message));
    }
  };
export const updateUserLinkes = (token, data, navigate) => async (dispatch) => {
  dispatch(userAction.updateUserRequest());
  try {
    let response = await axios.put(
      process.env.REACT_APP_API + "/users/update-my-info",
      {
        facebook: data.facebook,
        instagram: data.instagram,
        linked_in: data.linked_in,
        website: data.website,
      },
      { Authorization: "Bearer " + token }
    );
    dispatch(userAction.updateUserStatus(response.status));

    navigate("/profile-info");
  } catch (error) {
    dispatch(userAction.updateUserinfoFail(error.message));
  }
};

export const getuserinfobyid = (id) => async (dispatch) => {
  dispatch(userAction.fetchuserinfobyidRequest());
  try {
    let response = await axios.get(
      process.env.REACT_APP_API + "/volunteers/" + id,
      { Authorization: "Bearer " + localStorage.getItem("token") }
    );
    dispatch(userAction.fetchuserinfobyidSuccess(response.data));
  } catch (error) {
    dispatch(userAction.fetchuserinfobyidFail(error.message));
  }
};
export const getuserpointsbytoken = () => async (dispatch) => {
  dispatch(userAction.fetchpointsRequest());
  try {
    let response = await axios.get(
      process.env.REACT_APP_API + "/trasaction_point",
      { Authorization: "Bearer " + localStorage.getItem("token") }
    );
    dispatch(userAction.fetchpointsSuccess(response.data.data));
  } catch (error) {
    dispatch(userAction.fetchpointsFail(error.message));
  }
};
export const getuserpointsbyid = (id) => async (dispatch) => {
  dispatch(userAction.fetchpointsRequest());
  try {
    let response = await axios.get(
      process.env.REACT_APP_API + "/trasaction_point/" + id,
      { Authorization: "Bearer " + localStorage.getItem("token") }
    );
    dispatch(userAction.fetchpointsSuccess(response.data.data));
  } catch (error) {
    dispatch(userAction.fetchpointsFail(error.message));
  }
};
