import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  donations: [],
  payments: [],
  loading: false,
  error: null,
  success: false,
  paymentsuccess: false,

  donationLoading: false,
  donationError: null,
  donationSuccess: false,

};

const donationsSlice = createSlice({
  name: "donations",
  initialState,
  reducers: {
    createdonationrequest(state, action) {
      state.donations = [];
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    createdonationrequestSuccess(state, action) {
      state.donations = action.payload;
      state.loading = false;
      state.error = null;
      state.success = true;
    },
    createdonationrequestFailure(state, action) {
      state.donations = [];
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    createmasterclasspaymentrequest(state, action) {
      state.payments = [];
      state.loading = true;
      state.error = null;
      state.paymentsuccess = false;
    },
    createmasterclasspaymentrequestSuccess(state, action) {
      state.payments = action.payload;
      state.loading = false;
      state.error = null;
      state.paymentsuccess = true;
    },
    createmasterclasspaymentrequestFailure(state, action) {
      state.payments = [];
      state.loading = false;
      state.error = action.payload;
      state.paymentsuccess = true;
    },

    successRequest(state) {
      state.donationLoading = true;
      state.donationSuccess = [];
      state.donationError = "";
    },
    successSuccess(state, action) {
      state.donationLoading = false;
      state.donationSuccess = action.payload;
      state.donationError = "";
    },
    successFail(state, action) {
      state.donationLoading = false;
      state.donationSuccess = [];
      state.donationError = action.payload;
    },

  },
});

export const DonationsActions = donationsSlice.actions;
export default donationsSlice;
