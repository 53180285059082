import axios from "axios";
import { blogsAction } from "./BlogsReducers";

export const getBlogs = () => async (dispatch) => {
  dispatch(blogsAction.fetchBlogsRequest());
  try {
    let response = await axios.get(
      process.env.REACT_APP_API + "/blog"
    );
    dispatch(blogsAction.fetchBlogsSuccess(response.data.Blogs));
  } catch (error) {
    dispatch(blogsAction.fetchBlogsFail(error.message));
  }
};


export const getBlogId = (id) => async (dispatch) => {
  dispatch(blogsAction.fetchBlogIdRequest());
  try {
    let response = await axios.get(
      process.env.REACT_APP_API + `/blog/${id}`
    );
    dispatch(blogsAction.fetchBlogIdSuccess(response.data.Blog));
  } catch (error) {
    dispatch(blogsAction.fetchBlogIdFail(error.message));
  }
};

export const getfirstBlogs = () => async (dispatch) => {
  dispatch(blogsAction.fetchFirstBlogsRequest());
  try {
    let response = await axios.get(
      process.env.REACT_APP_API + "/blog/FirstSix"
    );
    dispatch(blogsAction.fetchFirstBlogsSuccess(response.data.Blogs));
  } catch (error) {
    dispatch(blogsAction.fetchFirstBlogsFail(error.message));
  }
};
