import React from "react";
import "./CompleteOrg_Step5.css";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import i18n from "../../../../i18n";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import CompleteProfile from "../../CompleteProfile";
import axios from "axios";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import {
  getSectors,
  getuserinfo,
  updateOrganizationSectors,
  updateUserinfo,
  updateUserLinkes,
} from "../../../../Redux/user/userActions";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../../../../components/PopUp/PopUp.js";
import { GiConsoleController } from "react-icons/gi";
import Loading from "../../../../components/Loading/Loading";

function CompleteOrg_Step5() {
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [isNext, setIsNext] = useState(true);
  const [website, setWebsite] = useState("");
  const [facebook, setFacebook] = useState("");
  const [insta, setInsta] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const size = useWindowSize();

  const token = localStorage.getItem("token");
  const id = localStorage.getItem("id");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userinfo, loading } = useSelector((store) => store.userReducer);

  useEffect(() => {
    setFacebook(userinfo.facebook);
    setInsta(userinfo.instagram);
    setLinkedin(userinfo.linked_in);
    setWebsite(userinfo.website);
  }, [userinfo]);

  //checking language
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const onSubmit = (e) => {
    e.preventDefault();
    const step = localStorage.getItem("step");

    if (step == 4) {
      localStorage.setItem("step", 5);
    }
    const data = {
      facebook: facebook,
      instagram: insta,
      linked_in: linkedin,
      website: website,
    };
    dispatch(updateUserLinkes(token, data, navigate));
  };

  useEffect(() => {
    dispatch(getuserinfo(token));
  }, []);

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  const handleEvent = (e) => {};

  return (
    <div className="complete_profile_step2_main_cont">
      <CompleteProfile
        header={t("CompleteProf.complete_your_profile_step9")}
        subtitle={t("CompleteProf.complete_your_profile_step5_org")}
        steps={2}
        body={
          loading ? (
            <Loading />
          ) : (
            <form
              className="complete_step2_main_body"
              onSubmit={(e) => onSubmit(e)}
            >
              <div className="main_card">
                <div style={{ fontSize: "0.8rem" }}>
                  {t("CompleteProf.complete_your_profile_step5_org_1")}
                </div>
                <div className="inputs">
                  <div className="inputs_inner">
                    <input
                      className="complete_profile_input"
                      type="text"
                      required
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                      placeholder={t(
                        "CompleteProf.complete_your_profile_step5_org_2"
                      )}
                    />
                    <input
                      className="complete_profile_input"
                      type="text"
                      required
                      value={facebook}
                      onChange={(e) => setFacebook(e.target.value)}
                      placeholder={t(
                        "CompleteProf.complete_your_profile_step5_org_3"
                      )}
                    />
                  </div>
                  <div className="inputs_inner">
                    <input
                      className="complete_profile_input"
                      type="text"
                      required
                      value={insta}
                      onChange={(e) => setInsta(e.target.value)}
                      placeholder={t(
                        "CompleteProf.complete_your_profile_step5_org_4"
                      )}
                    />
                    <input
                      className="complete_profile_input"
                      type="text"
                      required
                      value={linkedin}
                      onChange={(e) => setLinkedin(e.target.value)}
                      placeholder={t(
                        "CompleteProf.complete_your_profile_step5_org_5"
                      )}
                    />
                  </div>
                </div>
                <div className="bottom_checkbox">
                  <input
                    type="checkbox"
                    id={"agreement_check"}
                    name={"agreement_check"}
                    className="sector_check"
                    required
                    onChange={(e) => handleEvent(e)}
                  />
                 
                  <Link  className="complete_step4_sector_name"
                    htmlFor="agreement_check" to="/termsandconditions"
                    target="_blank"
                    >
                    {t("CompleteProf.complete_your_profile_agreement")}
                </Link>
                </div>
                <div>
                  {t("CompleteProf.complete_your_profile_step5_org_bottom")}
                </div>
              </div>
              <div className="complete_step1_footer">
                <div className="divider_line"></div>
                <div className="complete_step_footer_button">
                  <button
                    className={
                      size.width > 768
                        ? "primary_button complete_step_1_next_button"
                        : "primary_button complete_step_2_next_button_resp"
                    }
                    type="button"
                    onClick={() => navigate("/completeProfile/org/step4")}
                  >
                    {size.width > 768 ? (
                      t("CompleteProf.complete_your_profile_btn_5")
                    ) : lang == "en" ? (
                      <AiOutlineArrowLeft size="1.5rem" />
                    ) : (
                      <AiOutlineArrowRight size="1.5rem" />
                    )}
                  </button>

                  <div
                    className={
                      size.width > 768
                        ? "complete_step_right_footer"
                        : "complete_step_right_footer_resp"
                    }
                  >
                    <button
                      className={
                        size.width > 768
                          ? "primary_button complete_step_2_next_button"
                          : "primary_button complete_step_2_next_button_resp"
                      }
                      onClick={() => setIsNext(true)}
                    >
                      {size.width > 768 ? (
                        t("CompleteProf.complete_your_profile_step10_btn")
                      ) : lang == "en" ? (
                        <AiOutlineArrowRight size="1.5rem" />
                      ) : (
                        <AiOutlineArrowLeft size="1.5rem" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )
        }
        footer={
          <div
            className={
              lang == "en"
                ? "complete_profile_footer_slider_green"
                : "complete_profile_footer_slider_green complete_profile_footer_slider_green_ar"
            }
            style={{ width: "100%" }}
          ></div>
        }
      />
    </div>
  );
}

export default CompleteOrg_Step5;
